import React from 'react';
import Layout from '../../layout/Layout'
import { connect } from "react-redux";
import Paper from '@material-ui/core/Paper';

import {format} from 'date-fns'

import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';


import './MeuUsuario.css'


const MeuUsuario = ({auth}) => { 
    return(
        <Layout pageTitle="Meu Usuário">
            <Paper id="meuUsuario" elevation={2}>
                <div className="container1">
                    <img src={auth.me.avatar} alt="Avatar do Usuário"/>
                    <div className="container2">
                        <p><b>Nome:</b> {auth.me.nome}</p>
                        <p><b>E-mail:</b> {auth.me.email}</p> 
                        <p><b>Grupo de Acesso:</b> {auth.me.grupo}</p>    
                        <p><b>Data de Primeiro Acesso:</b> {
                            auth.me.dataPrimeiroAcesso ? 
                            format(new Date(auth.me.dataPrimeiroAcesso),
                                'dd/MM/yyyy HH:mm:ss') 
                            : '-'} </p>    
                        <p><b>Número de downloads:</b> {auth.me.numeroDownloads}</p>    
                        <p className="centralizadoVerticalmente">
                            <span>
                                <b>Pode Inserir Documentos: </b>          
                                <span>{auth.me.insere === 1 ? "Sim" : "Não"}</span>
                                <span>&nbsp;&nbsp;</span>
                            </span>
                            <Tooltip title={
                                "Se considerar necessário alterar esta condição (de poder ou não inserir documentos), " + 
                                "entrar em contato com o Administrador do Sistema."
                            }>
                                <HelpIcon fontSize='small' style={{color: 'dimgray'}}/>
                            </Tooltip>                   
                        </p>    
                    </div>
                </div>
            </Paper>                     
        </Layout>
    )
}



const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps)(MeuUsuario);
  
