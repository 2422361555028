import React from 'react';
import './Footer.css'

import vbiLogo from "../../../support/images/vbiLogo.png"

import { connect } from "react-redux";

import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const Footer = ({auth}) => {

    const theme = useTheme();
    const primaryColor = theme.palette.primary.main

    return (
        <Paper id="footer" className={(auth.isAuthenticated ? null : "loginBottomPart")} elevation={4}>
            <div className="footerTopPart defaultMaxPageWidth">
                <a href="https://vbirealestate.com/" target="_blank" rel="noreferrer">
                    <img src={vbiLogo} alt="Vbi Real State Logo"/>
                </a>
            </div>
            {auth.isAuthenticated ? (
                <div className="footerBottomPart defaultMaxPageWidth">
                    <p>
                        <span style={{color: primaryColor}}>
                            <a className="footerLink" href="https://apollo27.systems/" target="_blank" rel="noreferrer">
                                Apollo27
                            </a>
                        </span> 
                        <span > são sistemas desenvolvidos pela </span>
                        <span style={{color: primaryColor}}>
                            <a className="footerLink" href="https://vbirealestate.com/" target="_blank" rel="noreferrer">
                                VBI Real Estate
                            </a>
                        </span>                         
                    </p>
                    <p>
                        <span>COPYRIGHT © 2021 - VBI REAL ESTATE | </span>
                        <span style={{color: primaryColor}}>
                            <a className="footerLink" href="mailto:dev@vbirealestate.com" target="_blank" rel="noreferrer">
                                dev@vbirealestate.com
                            </a>
                        </span>    
                        <span> | </span>                        
                        <span style={{color: primaryColor}}>
                            <a className="footerLink" href="https://apollo27.systems/" target="_blank" rel="noreferrer">
                                www.apollo27.systems
                            </a>
                        </span>           
                    </p>       
                </div>
            ) : (
                <></>
            )}            
        </Paper>
    )
}


const mapStateToProps = (state) => {
    return {auth: state.auth}
}

export default connect(mapStateToProps)(Footer)