import SvgIcon from '@material-ui/core/SvgIcon';

function ExcelDownloadIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M 6 2 C 4.9 2 4 2.9 4 4 L 4 20 C 4 21.1 4.9 22 6 22 L 14.171875 22 L 13.292969 21.121094 C 13.104969 20.934094 13 20.679062 13 20.414062 L 13 19 C 13 18.448 13.448 18 14 18 L 16 18 L 16 15 C 16 14.448 16.448 14 17 14 L 20 14 L 20 8 L 14 2 L 6 2 z M 13 3.5 L 18.5 9 L 13 9 L 13 3.5 z M 9.1757812 10 L 10.953125 10 L 11.876953 12.214844 C 11.950953 12.395844 12.008406 12.60575 12.066406 12.84375 L 12.091797 12.84375 C 12.125797 12.70175 12.192969 12.481266 12.292969 12.197266 L 13.322266 10 L 14.943359 10 L 13.005859 13.669922 L 14.783203 17 L 13.085938 17 L 12.158203 14.990234 C 12.116203 14.905234 12.066391 14.736047 12.025391 14.498047 L 12.009766 14.498047 C 11.984766 14.612047 11.934375 14.782719 11.859375 15.011719 L 10.736328 17.40625 L 9 17.40625 L 11.060547 13.697266 L 9.1757812 10 z M 18 16 L 18 20 L 15 20 L 19 24 L 23 20 L 20 20 L 20 16 L 18 16 z"></path>
        </SvgIcon>
    );
}    

function ExcelFileIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m1.8 18H14l-2-3.4l-2 3.4H8.2l2.9-4.5L8.2 11H10l2 3.4l2-3.4h1.8l-2.9 4.5l2.9 4.5M13 9V3.5L18.5 9H13z"/>
        </SvgIcon>
    );
}    

function ExcelIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M 12 3 L 2 5 L 2 19 L 12 21 L 12 3 z M 14 5 L 14 7 L 16 7 L 16 9 L 14 9 L 14 11 L 16 11 L 16 13 L 14 13 L 14 15 L 16 15 L 16 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 18 7 L 20 7 L 20 9 L 18 9 L 18 7 z M 4.1757812 8.296875 L 5.953125 8.296875 L 6.8769531 10.511719 C 6.9519531 10.692719 7.0084063 10.902625 7.0664062 11.140625 L 7.0917969 11.140625 C 7.1247969 10.997625 7.1919688 10.779141 7.2929688 10.494141 L 8.3222656 8.296875 L 9.9433594 8.296875 L 8.0078125 11.966797 L 10 15.703125 L 8.2714844 15.703125 L 7.1582031 13.289062 C 7.1162031 13.204062 7.0663906 13.032922 7.0253906 12.794922 L 7.0097656 12.794922 C 6.9847656 12.908922 6.934375 13.079594 6.859375 13.308594 L 5.7363281 15.703125 L 4 15.703125 L 6.0605469 11.996094 L 4.1757812 8.296875 z M 18 11 L 20 11 L 20 13 L 18 13 L 18 11 z M 18 15 L 20 15 L 20 17 L 18 17 L 18 15 z"></path>                
        </SvgIcon>
    );
}    


export {
    ExcelDownloadIcon,
    ExcelFileIcon,
    ExcelIcon
}