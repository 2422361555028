import {useState} from 'react';
import {Alert, AlertTitle} from '@material-ui/lab';

import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';


const ClosableAlert = ({ 
    children,
    alertTitle=null,
    severity="info",
    marginBottom = "0rem",
    elevation = 1,
}) => {
    const [open, setOpen] = useState(true);
    return (
        <Collapse in={open}>
            <Alert 
                elevation={elevation} 
                severity={severity}
                style={{marginBottom : marginBottom}}
                action={
                    <IconButton
                        aria-label="close" size="small" color="inherit" 
                        onClick={() => {setOpen(false)}}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {alertTitle? <AlertTitle>{alertTitle}</AlertTitle>: <></>}
                {children}
            </Alert>
        </Collapse>
    )
}

export default ClosableAlert;  