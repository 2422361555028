export let itensDeAjuda = {
    'Exportação de Dados - JSON':
        'As células do .xlsx exportado com forma [{...}, ..., {...}] estão no formato JSON, que pode ser importado diretamente pelo Excel, ou convertido para um formato mais amigável usando de ferramentas gratuitas na internet. Essa é uma forma de incluir tabelas dentro de tabelas.',
    'Administradores Fiduciários':
        'A associação de administradores fiduciários e entidades é configurada na página de entidades.',
    'Descritivos':
        'Todo documento, necessariamente, tem associado a si um descritivo (que pode ser uma string vazia, i.e., um descritivo nulo). Todo descritivo é filho de um tipo de documento, que por sua vez é filho de um tema, numa relação análogo de cidades, estados e países (uma cidade faz parte de um estado que faz parte de um país). Dos fatos anteriores, segue que todo documento é associado a um e somente um tipo de documento, e uma e somente uma entidade.',
    'Entidades Modeladas Internamente':
        'O atributo "Modelada Internamente" na página de entidades identifica se a entidade é modelada pelo departamento de Planejamento da VBI.',
    'Sub-Parâmetros':
        'Novos sub-parâmetros podem ser cadastrados na página do parâmetro relevante, clicando no botão "Editar Sub-Parâmetros". E.g., é possível cadastrar um novo Canal de Distribuição na página de Entidades. Não é possível remover sub-parâmetros que se encontram em uso no sistema.',
    'Segmentos':
        'É possível associar 0, 1 ou múltiplos segmentos a uma dada entidade. Os segmentos de uma entidade podem ser usados para definir quais grupos podem visualizá-la.',
    'Frequência de um tipo de documento':
        'A frequência de um tipo de documento define como funciona a data de referência para aquele tipo de documento. Um tipo de documento de frequência MENSAL apresenta data de referência na forma YYYY-MM. Um tipo de documento de frequência SEMESTRAL apresenta data de referência na forma YYYY-MM, onde MM é 06 ou 12. Não é possível inserir no sistema dois documentos de um mesmo relatório, mesma entidade, e mesma data de referência. De um ponto de vista conceitual, eles são o mesmo documento.',
    'Controle de Acesso':
        'O controle de acesso é feito com base nos grupos de acesso. Todo usuário deve participar de um, e somente um grupo de acesso. Os grupos de acesso são definidos por seus temas, e conjuntos de critério e restrição.',
    'Grupos de Acesso - Temas':
        'Os grupos de acesso podem visualizar somente os documentos cujos temas se relacionam com o grupo. Essa informação pode ser visualizada na coluna "temas" da página de Grupos de Acesso.',
    'Grupos de Acesso - Entidades':
        'Os grupos de acesso podem visualizar somente os documentos cujas entidades satisfaçam as regras de restrição e critério. Essa informação pode ser visualizada na coluna "criterios" da página de Grupos de Acesso.',
    'Grupos de Acesso - Critérios':
        'Um critério indica o atributo que será analisado para avaliação de possibilidade de acesso dos documentos de uma dada entidade, para dado grupo de acesso. E.g., o critério "Segmento", quando usado com a restrição "Residencial", indica que o grupo de acesso pode acessar apenas entidades que apresentem o segmento Residencial como característica. Um critério "Canal de Distribuição" avalia o acesso de um dado grupo com base no canal de distribuição daquela entidade (e.g., Listado).',
    'Grupos de Acesso - Restrição':
        'Podemos entender a restrição de acesso como o "valor" que uma entidade deve assumir para dado critério, para que o grupo de acesso tenho acesso à entidade.',
    'Grupos de Acesso - Operador Lógico':
        'É desejável poder combinar vários pares (critério, restrição). Para tal, de modo a evitar a ambiguidade na forma de evaluação, introduzimos um terceiro item à definição de uma regra de acesso: o operador lógico. O operador lógico pode assumir os valores "E" (produto lógico" e "OU" (soma lógica).',
    'Grupos de Acesso - Operador "E"':
        'De modo a evitar a ambiguidade, regras de acesso com o operador "E" vão para a esquerda da asserção, enquanto que as regras com o operador "OU" vão para a direita da asserção. Portanto, as regras (A1, B1, E), (A2, B2, OU), (A3, B3, E), (A4, B4, OU) resultam na asserção "((A1, B1) E (A3, B3)) OU (A2, B2) OU (A4, B4)".',
    'Grupos de Acesso - Mesmo Critério e Operador':
        'As restrições para um mesmo par (criterio, operador lógico) são concatenadas por meio do operador de soma lógica. I.e., as regras (Segmento, Logístico, E) e (Segmento, Residencial, E) resultam na asserção "E Segmento é (Logístico OU Residencial)". Regras de mesmo critério, mas com operador lógico diferente não são combinadas. O conjunto {(Segmento, Logístico, E), (Modelado, Sim, E), (Segmento, Residencial, OU)} é evaluado como "Entidades tal que (Segmento é Logístico E Modelada é Sim) OU (Segmento é Residencial)", enquanto que o conjunto {(Segmento, Logístico, E), (Modelado, Sim, E), (Segmento, Residencial, E)} é evaluado como "Entidades tal que (Segmento é (Logístico OU Residencial) E Modelada é Sim)".',
    'Grupos de Acesso - Regra Geral de Evaluação de Critérios':
        'Primeiro as asserções irredutíveis de mesmo par (critério, operador lógico) são substituídas por asserções compostas unindo os valores de restrição por meio da disjunção lógica (preservando o operador lógico compartilhado por todas as asserções originais), e em seguida as novas asserções são combinadas de modo que as regras de operador "E" vem à esquerda, e as regras de operador "OU" vem à direita, gerando uma asserção final que independe da ordem em que foram processadas as regras de acesso do grupo.',
    'Grupos de Acesso - Cadastro de Critérios':
        'O cadastro de novos critérios de acesso no sistema é uma tarefa que inerentemente envolve algum tipo de interação com o banco de dados por meio algum tipo de linguagem estruturada de programação. Com base nisso, foi tomada a decisão que a implementação de novos critérios deve ser efetuada pela equipe de desenvolvimento diretamente no código-fonte do sistema. Para a solicitação de cadastro de novos critérios, favor entrar em contato com a equipe de desenvolvimento.',
    'Desativação de Arquivos, Versões de Documentos, e Documentos':
        'Documentos são definidos pelo seu conteúdo informacional, versões de documento são os arquivos de um dado documento que foram inseridos simultaneamente no sistema, e representam o estado na data de inserção do conteúdo informacional do documento (que pode variar no tempo), e o arquivo é o binário em si. Essas três coisas podem ser desativadas quase que independentemente; a desativação de um documento requer a desativação de todas as suas versões posteriores à original, e a desativação de uma versão qualquer do documento acarreta na desativação de todos os arquivos que compõe aquela versão.',
    'Regras de Notificação':
        'Com o intuito de facilitar a disseminação de novos relatórios do sistema, além da pré-configuração das notificações que novos usuários devem receber, é permitida a criação de novas regras de notificação para os usuários cadastrados no sistema. Além disso, é permitida a remoção de regras existentes, com o intuito de retificação do uso errôneo da funcionalidade listada anteriormente. Por favor, use dessa funcionalidade com sabedoria!',
    'Dicionário de Sugestões':
        'O dicionário de sugestões permite associar termos a entidades e relatórios, de modo a possibilitar a caracterização de um documento com base no filename do arquivo sendo carregado no sistema. Além dos termos do dicionário de sugestões, as sugestões de classificação são feitas de modo com base no apelido da entidade ou nome do relatório.',
    'Dicionário de Sugestões - Múltiplos Matches':
        'No caso de múltiplos matches no processo de classificação de um arquivo, é retornado o match de maior número de caracteres caso todos os outros matches forem subconjunto dele. Caso contrário, não é retornada nenhuma sugestão, de modo a evitar a tomada de decisões num cenário de ambiguidade. E.g., o arquivo "demonstrativo_de_caixa_banco_cc_cobranca_gpa.pdf" apresenta como match os relatórios "Demonstrativo de Caixa Banco" e "Demonstrativo de Caixa Banco - CC Cobrança GPA" (pois o nome de ambos relatórios se encontra dentro do filename), e retorna "Demonstrativo de Caixa Banco - CC Cobrança GPA" como sugestão, pois ela é a maior string, e contém todas as outras como subconjunto. Se adicionassemos a string "demonstrativo caixa banco - cc cobrança gpa" ao dicionário de sugestões para o relatório "XPTO", ele também seria um match, mas o sistema não retornaria nenhuma sugestão (pois a string "XPTO" não é subconjunto da string "Demonstrativo de Caixa Banco - CC Cobrança GPA").',
    'Log de Atividades':
        'O log de atividades da página do administrador não contempla tudo que é feito no sistema, sendo extremamente limitado em seu escopo. Para se obter uma visão geral de tudo que aconteceu no sistema, é possível usar o log de auditoria, disponível diretamente no banco de dados.'
};
