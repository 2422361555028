import {format} from 'date-fns'
import {ptBR} from "date-fns/locale"

const capitalizeFirstLetter = (string) =>  string.charAt(0).toUpperCase() + string.slice(1);

const converterArrayParaTextoEmPortugues = (myArray) => {
    //TODO: acrescentar a descrição desta função
    return (myArray.length <= 1) ? (
        myArray.toString()
    ) : (
        myArray.slice(0, myArray.length - 1).join(', ').concat(
                ' e ' + myArray[myArray.length - 1])
    );
}


function estaNoModoAdmin(location, auth){
    const firstRoute = location.pathname.split('/')[1] 
    return (firstRoute === "admin") && (auth.me.master === 1)
}


// eslint-disable-next-line no-useless-escape
const formatarCnpj = (valor) => (valor? valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5") : "")  //neste caso foi desativado o eslint porque ele retorna vários falsos positivos de "Unnecessary escape character"

const formatarDataDeReferencia = (dia, mes, ano) => {
    //Recebe dia mês e ano e devolve em formato de data de referência "YYYY.MM.DD"
        //obs: exclui as datas parciais (exemplo: se receber 2020-12-00 retorna 2020.12)
    let dataDeReferencia = "";
    if (mes < 10) mes = `0${mes}`;
    if (dia < 10) dia = `0${dia}`;
    if (ano !== "00") dataDeReferencia += `${ano}.`;
    if (mes !== "00") dataDeReferencia += `${mes}.`;
    if (dia !== "00") dataDeReferencia += `${dia}.`;
    dataDeReferencia = dataDeReferencia.slice(0, -1);
    if(dataDeReferencia === "0") {dataDeReferencia = "-"};
    return(dataDeReferencia)
};

const formatarDateString = (dateString) => {
    //Recebe um string no formato "YYYY-MM-DD" e devolve em formato de data de referência "YYYY.MM.DD"
        //obs: exclui as datas parciais (exemplo: se receber 2020-12-00 retorna 2020.12)
    let dataDeReferencia = "";
    let ano = dateString.slice(0,4) || "0000"
    let mes = dateString.slice(5,7) || "00"
    let dia = dateString.slice(8,10) || "00"
    if (ano !== "0000") dataDeReferencia += `${ano}.`;
    if (mes !== "00") dataDeReferencia += `${mes}.`;
    if (dia !== "00") dataDeReferencia += `${dia}.`;
    dataDeReferencia = dataDeReferencia.slice(0, -1);
    if(!dataDeReferencia) {dataDeReferencia = "-"};
    return(dataDeReferencia)
}
    

const getDistinctValuesFromAnArrayOfObjects = (array, key) => {
    /*Get Distinct Values From An Array Of Objects*/
        /*More information in: https://stackoverflow.com/a/35092559/5091674 */
    return [...new Set(array.map(item => item[key]))];
}


function getOnlyNumbers(string){
    return string? string.replace(/\D/g, ""): null;
}


const handleClickExibirMensagemEmDesenvolvimento = () => alert("Esta funcionalidade ainda está em desenvolvimento");


const handleClickRetornar = (history) => history.goBack()


function isIsoDate(str) {
    //referência: https://stackoverflow.com/a/52869830/5091674
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str); 
    return d.toISOString()===str;
}


const isValidDateString = (dateString) => {
    /*Verifica se o dateString no formato "yyyy-mm-dd" é válido
        -(Aceitando não só datas completas como "2012-12-12", mas também datas parciais como "2012-12-00" ou "2012-00-00", por exemplo)
        -Referência da função original: #https://stackoverflow.com/a/35413963/5091674 */

    //---------------------------------------------------------------------
    /*(Edição na função original) Converte datas parciais para datas não parciais:
        -(Exemplo: 2012-00-00 é convertido para 2012-01-01 para também ser considerado válido na verificação)*/
    let ano = dateString.slice(0,4) 
    let separador1 = dateString.slice(4,5)
    let mes = dateString.slice(5,7)
    let separador2 = dateString.slice(7,8)
    let dia = dateString.slice(8,10) 
    if(mes==="00" && dia==="00"){mes="01"}
    if(dia==="00"){dia="01"}
    dateString=`${ano}${separador1}${mes}${separador2}${dia}`

    

    //---------------------------------------------------------------------
    /*(Função Original) Verificar se o DateString é válido:*/
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if(!dateString.match(regEx)) return false;  // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0,10) === dateString;
}


const imprimir = valor => valor? valor: "Não Disponível";


function obterDataPorExtenso(date){        
    try{
        return (
            `${format(date, 'dd', { locale: ptBR })}` +
            ` de ` + 
            `${format(date, 'MMMM', { locale: ptBR })}` +
            ` de ` + 
            `${format(date, 'yyyy', { locale: ptBR })}` +
            ` as ` + 
            `${format(date, 'HH')}` + 
            `h` + 
            `${format(date, 'mm')}`
        )    
    } catch {
        return null;
    }
}


function obterQuantidadeDeDocumentosUnicos(arquivos){
	/*
	*	arquivos (array of objects) = [
	*		{..., "documentoId": 1, ...},
	*		{..., "documentoId": 2, ...},
	*		{..., "documentoId": 2, ...},
	*		{..., "documentoId": 2, ...},
	*		...
	*		{..., "documentoId": 53, ...},
	*		{..., "documentoId": 53, ...},
	*		...
	*	] 
	*/
	return [...new Set(arquivos.map(item => item["documentoId"]))].length
}


function obterTituloDoDocumento(
    tipoDeDocumento, descritivo, entidade, ano, mes, dia, versao 
){
    // Observações:
    //  'mes', 'dia' e 'versao' são opcionais (podem ser passados como null tb)
    //  Caso não tenha 'descritivo', o parâmetro tb pode ser passado como null
    /*  Opcionalmente 'tipoDeDocumento' também pode ser passado como a 
     *  concatenação de 'tipoDeDocumento' e 'descritivo' (e neste caso 
     *  'descritivo' passado como null)*/
    function capitalizeFirstLetter (string) {
        return(string.charAt(0).toUpperCase() + string.slice(1))
    }
    function obterTituloDataDeReferencia (ano, mes, dia) {
        if (!ano && !mes && !dia){return null}
        return (
            (dia ? dia + " de ": "") +
            (mes? 
                capitalizeFirstLetter(
                    format(new Date(2001, mes-1, 1),'MMMM', { locale: ptBR })
                ) + " de " :
                ""
            ) +
            ano
        )
    }
    const data = obterTituloDataDeReferencia(ano, mes, dia);
    return(
        `${tipoDeDocumento}` +
        `${descritivo ? ' - ' + descritivo : ''}` +
        `${entidade ? ' - ' + entidade : ''}` +
        `${data ? ' - ' + data : ''}` +
        `${versao > 1 ? ' - Versão ' + versao : ''}`
    )
}


function openInNewTab(url) {
    //Referência: https://stackoverflow.com/questions/47789347/open-link-in-new-tab-in-react-router-programmatically
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
}


const recarregarPagina = () => {
    window.location.reload();
}


function sortArrayOfArray(index) {
    /*Sort array of array by index value*/
        /*More information in: https://stackoverflow.com/a/4382151/5091674 */
    return function (a, b){
        // Sort by the index in each array
        if ( a[index].toString().toLowerCase() === 
            b[index].toString().toLowerCase() ) return 0;
        return (a[index].toString().toLowerCase() <
            b[index].toString().toLowerCase() ? -1 : 1);
    }
    /*TODO: ".toString()" acima não fará uma boa ordenação para números, 
        alterar esta parte caso a ordenação seja usada para números também*/
}


function sortArrayOfObjects(property, isNumber = false) {
    /*Sort array of objects by string property value*/
      /*More information in: https://stackoverflow.com/a/4760279/5091674 */
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        let value1;
        let value2;
        if(isNumber){
            value1 = a[property];
            value2 = b[property];
        } else {
            //Se não for número tratar valores como "case insensitive"
            value1 = a[property]?.toString()?.toLowerCase();
            value2 = b[property]?.toString()?.toLowerCase();    
        }

        var result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        return result * sortOrder;
    }
}
  
function sortArrayOfObjectsMultiple()  {
    /*Sort array of objects by string property value*/
        /*More information in: https://stackoverflow.com/a/4760279/5091674 */

        /*
        * save the arguments object as it will be overwritten
        * note that arguments object is an array-like object
        * consisting of the names of the properties to sort by
        */
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        /* try getting a different result from 0 (equal)
            * as long as we have extra properties to compare
            */
        while(result === 0 && i < numberOfProperties) {
            result = sortArrayOfObjects(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
}


function updateQueryStringParameters(parameters) {
    //Parameters (array) = [ {key: "", value: ""}, {key: "", value: ""}, ... ]
        //Referência: https://stackoverflow.com/a/41542008/5091674
    if ('URLSearchParams' in window) {
        var searchParams = new URLSearchParams(window.location.search);
        parameters.map(parameter => {
            searchParams.set(parameter.key, parameter.value);
            return false;
        })
        window.location.search = searchParams.toString();
    }
}


export {  
    capitalizeFirstLetter,
    converterArrayParaTextoEmPortugues,
    estaNoModoAdmin,
    formatarCnpj,
    formatarDataDeReferencia,
    formatarDateString,
    getDistinctValuesFromAnArrayOfObjects,
    getOnlyNumbers,
    handleClickExibirMensagemEmDesenvolvimento,
    handleClickRetornar,
    isIsoDate,
    isValidDateString,
    imprimir,
    obterDataPorExtenso,
    obterQuantidadeDeDocumentosUnicos,
    obterTituloDoDocumento,
    openInNewTab,
    recarregarPagina,
    sortArrayOfArray,
    sortArrayOfObjects, 
    sortArrayOfObjectsMultiple,
    updateQueryStringParameters
}