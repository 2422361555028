import React from 'react';
import MUIDataTable from "mui-datatables";
import Paper from '@material-ui/core/Paper';
import {format} from 'date-fns'

import './simplerDataTable.css'

import textLabels from "../../../../support/MuiDatatableSupport/TextLabels"
import CustomToolbarSelect from "../../../../support/MuiDatatableSupport/CustomToolbarSelect"
import Loader from '../../../../components/Loader/Loader'

import {openInNewTab} from "../../../../support/publicFunctions"

import { useHistory } from "react-router-dom";


const SimplerDataTable = ({title, arquivos, isLoading, noMatchMessage}) => {

    let history = useHistory();

    const options = {
      tableBodyHeight: "445px", //TODO: aqui talvez o ideal fosse ocupar 100% do tamanho do elemento pai, ver se vale a pena tentar se aventurar nisso (pode ser que seja tão oneroso que não valha a pena, analisar)
      responsive: "simple",
      elevation: 2,
      selectableRowsOnClick: false, 
      rowsPerPage: 8,
      rowsPerPageOptions: [8, 20, 80],
      /*Oculta todos os ícones da tabela no canto superior direito*/
      search: true,
      viewColumns: false,
      filter: false,
      download: false,
      print: false,      
      /*Fim - Oculta [...]*/
      /*Este option abaixo visa substituir o texto em inglês para textos em Português no Mui Data Tables*/
            /*obs: como é possível observar, ele permite também uma mensagem de "no match" personalizada*/
      textLabels: {
            ...textLabels,
            body: {
                ...textLabels.body,
                noMatch: (noMatchMessage? noMatchMessage : textLabels.body.noMatch),
            },      
      },                  
      onRowClick: (rowData, rowMeta, clickEvent) => {
            const colunaDocumentoUuid = 1;
            const colunaNumeroDaVersao = 2;
            let documentoUuid = rowData[colunaDocumentoUuid];
            let numeroDaVersao = rowData[colunaNumeroDaVersao];
            if (clickEvent.ctrlKey) {
                openInNewTab(`/documentos/${documentoUuid}/versoes/${numeroDaVersao}`); 
                return;
            };
            history.push(`/documentos/${documentoUuid}/versoes/${numeroDaVersao}`); 
            //TODO: futuramente avaliar se realmente no caso abaixo será aberto uma nova página mesmo e apagar a linha abaixo
                // history.push(`/documentos/${documentoUuid}/versoes/${numeroDaVersao}`); 
       },  
       customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect 
            selectedRows={selectedRows} 
            displayData={displayData} 
            setSelectedRows={setSelectedRows} 
            colunas={columns}
            nomeDaColunaQueContemUuidDoArquivo = "arquivoUuid"
        />
      ),      

    };
        
    const columns = [ 
        {
            name: 'arquivoUuid',
            options: {
                display: "excluded",
            }
        },
        {
            name: 'documentoUuid',
            options: {
                display: "excluded",
            }
        },
        {
            name: 'numeroDaVersao',
            options: {
                display: "excluded",
            }
        },            
        {
            name: "data_de_upload",
            label: "Inserido em",
            options: {
                display: true,
            }
        },        
        {
            name: "nome_do_arquivo",
            label: "Documento",
            options: {
                display: true,
                customBodyRenderLite: (dataIndex) => {
                    const colunaDocumentos = 4;
                    return <span className="tdDocumentos">  
                        {dados[dataIndex][colunaDocumentos]} 
                    </span>
                },                
            }
        },
    ];

    let dados = [] //TODO: ver a viabilidade (e se é uma melhor prática) de colocar essa variável como um state do componente!
                        //obs: aqui talvez seja ok usar uma variável normal do javascript em vez de state, porque não há useEffect nesta função


    if(arquivos.length){ //TODO: ver viabilidade  (e se é uma melhor prática)  de mudar essa linha aqui por "isLoading"
        dados = arquivos.map(           //(obs: provavelmente não, porque o isLoading pode não está inicializado)
            (arquivo)=>{                    //ps: na verdade aqui teria que usar algo semelhante a "isLoaded" em vez de "isLoading"
                return [                        //porque preciso de algo que comece false e depois vire true (e não comece true e depois vire false)
                    arquivo.uuid,                   //porque "começar true" é nada confiável
                    arquivo.documentoUuid,
                    arquivo.numeroDaVersao,
                    format(new Date(arquivo.dataDeUpload),'dd/MM/yyyy'),
                    arquivo.nome,              
                ];
            }
        )        
    } 

    return (
        <>

        {(isLoading) ? (
            <Paper elevation={2}>
                <Loader/>  
            </Paper>          
        ) : (
            <div id="simplerDataTable" className="clickableTd">
                <MUIDataTable
                    title={title}
                    data={dados}
                    columns={columns}
                    options={options}
                />              
            </div>
        )}
        </>
    )
}


  
export default SimplerDataTable;
