import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

//List Items
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

//Icons
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FileCopyIcon from '@material-ui/icons/FileCopy';


const StyledMenuItem = withStyles(
  (theme) => {
    return {
      root: {      
        '&:hover': {
          '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
              color: theme.palette.primary.main,
          },
        },
        '& .MuiListItemText-primary': {
          fontWeight: 500
        },     
        color: 'dimgray'     
      },
    }
  }
)(MenuItem)


const MenuDeBaixarDocumentos = ({
  arquivos, baixarArquivos, tooltipTitle, children
}) => {
  // arquivos (array de objects "arquivo")
  // arquivo (object) = {uuid: "", extensão: ""}
  // baixarArquivos (function)
  // tooltipTitle (string)
  // children (ícone de download)

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBaixarArquivos = (uuids) => {
    baixarArquivos({uuids: uuids})
    setAnchorEl(null);
  };


  return (
    <>
      <Tooltip title={tooltipTitle}>
        <IconButton
          onClick={handleClick}      
          aria-controls="baixar-documento-menu" //abre submenu c/ esta propriedade
          aria-haspopup="true" //indica que este elemento controle o submenu 
        >
          {children}
        </IconButton>
      </Tooltip>
      <Menu
        id="baixar-documento-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        //...
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem>
          <span style={{color: "dimgray"}}>Baixar no formato:</span>
        </MenuItem>
        {arquivos?.length > 1 &&
          <>
            <Divider light />
            <StyledMenuItem 
              onClick={()=>{return(
                handleBaixarArquivos(arquivos.map(arquivo => arquivo.uuid))
            )}}>
                <ListItemIcon>
                    <FileCopyIcon />
                </ListItemIcon>
                <ListItemText primary="Todos"/>          
            </StyledMenuItem>
          </>
        }         
        {arquivos.map(arquivo => {return(
          <>
            <Divider light />
            <StyledMenuItem onClick={()=>{
              return handleBaixarArquivos([arquivo.uuid])
            }}>
              <ListItemIcon>
                  <InsertDriveFileIcon />
              </ListItemIcon>
              <ListItemText primary={arquivo.extensao}/>          
            </StyledMenuItem>          
          </>
        )})}
      </Menu>
    </>
  );
}

export default MenuDeBaixarDocumentos;