export const BAIXAR_ARQUIVOS_LOADING = 'BAIXAR_ARQUIVOS_LOADING';
export const BAIXAR_ARQUIVOS_SUCCESS = 'BAIXAR_ARQUIVOS_SUCCESS';
export const BAIXAR_ARQUIVOS_FAIL = 'BAIXAR_ARQUIVOS_FAIL';

export const BUSCAR_ARQUIVO_LOADING = 'BUSCAR_ARQUIVO_LOADING';
export const BUSCAR_ARQUIVO_SUCCESS = 'BUSCAR_ARQUIVO_SUCCESS';
export const BUSCAR_ARQUIVO_FAIL = 'BUSCAR_ARQUIVO_FAIL';

export const BUSCAR_ARQUIVOS_LOADING = 'BUSCAR_ARQUIVOS_LOADING';
export const BUSCAR_ARQUIVOS_SUCCESS = 'BUSCAR_ARQUIVOS_SUCCESS';
export const BUSCAR_ARQUIVOS_FAIL = 'BUSCAR_ARQUIVOS_FAIL';

export const BUSCAR_ARQUIVOS_E_CATEGORIZAR_LOADING = 'BUSCAR_ARQUIVOS_E_CATEGORIZAR_LOADING';
export const BUSCAR_ARQUIVOS_E_CATEGORIZAR_SUCCESS = 'BUSCAR_ARQUIVOS_E_CATEGORIZAR_SUCCESS';
export const BUSCAR_ARQUIVOS_E_CATEGORIZAR_FAIL = 'BUSCAR_ARQUIVOS_E_CATEGORIZAR_FAIL';

export const BUSCAR_DESCRITIVOS_LOADING = 'BUSCAR_DESCRITIVOS_LOADING';
export const BUSCAR_DESCRITIVOS_SUCCESS = 'BUSCAR_DESCRITIVOS_SUCCESS';
export const BUSCAR_DESCRITIVOS_FAIL = 'BUSCAR_DESCRITIVOS_FAIL';

export const BUSCAR_DICIONARIO_LOADING = 'BUSCAR_DICIONARIO_LOADING';
export const BUSCAR_DICIONARIO_SUCCESS = 'BUSCAR_DICIONARIO_SUCCESS';
export const BUSCAR_DICIONARIO_FAIL = 'BUSCAR_DICIONARIO_FAIL';

export const BUSCAR_DOCUMENTO_LOADING = 'BUSCAR_DOCUMENTO_LOADING';
export const BUSCAR_DOCUMENTO_SUCCESS = 'BUSCAR_DOCUMENTO_SUCCESS';
export const BUSCAR_DOCUMENTO_FAIL = 'BUSCAR_DOCUMENTO_FAIL';

export const BUSCAR_DOCUMENTOS_POR_ATRIBUTOS_LOADING = 'BUSCAR_DOCUMENTOS_POR_ATRIBUTOS_LOADING';
export const BUSCAR_DOCUMENTOS_POR_ATRIBUTOS_SUCCESS = 'BUSCAR_DOCUMENTOS_POR_ATRIBUTOS_SUCCESS';
export const BUSCAR_DOCUMENTOS_POR_ATRIBUTOS_FAIL = 'BUSCAR_DOCUMENTOS_POR_ATRIBUTOS_FAIL';

export const BUSCAR_DOCUMENTOS_POR_UUID_LOADING = 'BUSCAR_DOCUMENTOS_POR_UUID_LOADING';
export const BUSCAR_DOCUMENTOS_POR_UUID_SUCCESS = 'BUSCAR_DOCUMENTOS_POR_UUID_SUCCESS';
export const BUSCAR_DOCUMENTOS_POR_UUID_FAIL = 'BUSCAR_DOCUMENTOS_POR_UUID_FAIL';

export const BUSCAR_ENTIDADES_LOADING = 'BUSCAR_ENTIDADES_LOADING';
export const BUSCAR_ENTIDADES_SUCCESS = 'BUSCAR_ENTIDADES_SUCCESS';
export const BUSCAR_ENTIDADES_FAIL = 'BUSCAR_ENTIDADES_FAIL';

export const BUSCAR_TIPOS_DE_DOCUMENTO_LOADING = 'BUSCAR_TIPOS_DE_DOCUMENTO_LOADING';
export const BUSCAR_TIPOS_DE_DOCUMENTO_SUCCESS = 'BUSCAR_TIPOS_DE_DOCUMENTO_SUCCESS';
export const BUSCAR_TIPOS_DE_DOCUMENTO_FAIL = 'BUSCAR_TIPOS_DE_DOCUMENTO_FAIL';

export const CRIAR_DOCUMENTOS_LOADING = 'CRIAR_DOCUMENTOS_LOADING';
export const CRIAR_DOCUMENTOS_SUCCESS = 'CRIAR_DOCUMENTOS_SUCCESS';
export const CRIAR_DOCUMENTOS_FAIL = 'CRIAR_DOCUMENTOS_FAIL';

export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT_LOADING = 'LOGOUT_LOADING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const ME_LOADING = 'ME_LOADING';
export const ME_SUCCESS = 'ME_SUCCESS';
export const ME_FAIL = 'ME_FAIL';

export const RESETAR_REDUX_STORE = 'RESETAR_REDUX_STORE';

export const SUBSTITUIR_DOCUMENTOS_LOADING = 'SUBSTITUIR_DOCUMENTOS_LOADING';
export const SUBSTITUIR_DOCUMENTOS_SUCCESS = 'SUBSTITUIR_DOCUMENTOS_SUCCESS';
export const SUBSTITUIR_DOCUMENTOS_FAIL = 'SUBSTITUIR_DOCUMENTOS_FAIL';

//REGRAS_DE_NOTIFICACAO
export const BUSCAR_REGRAS_DE_NOTIFICACAO_LOADING = 'BUSCAR_REGRAS_DE_NOTIFICACAO_LOADING';
export const BUSCAR_REGRAS_DE_NOTIFICACAO_SUCCESS = 'BUSCAR_REGRAS_DE_NOTIFICACAO_SUCCESS';
export const BUSCAR_REGRAS_DE_NOTIFICACAO_FAIL = 'BUSCAR_REGRAS_DE_NOTIFICACAO_FAIL';

export const CRIAR_REGRAS_DE_NOTIFICACAO_LOADING = 'CRIAR_REGRAS_DE_NOTIFICACAO_LOADING';
export const CRIAR_REGRAS_DE_NOTIFICACAO_SUCCESS = 'CRIAR_REGRAS_DE_NOTIFICACAO_SUCCESS';
export const CRIAR_REGRAS_DE_NOTIFICACAO_FAIL = 'CRIAR_REGRAS_DE_NOTIFICACAO_FAIL';

export const REMOVER_REGRAS_DE_NOTIFICACAO_LOADING = 'REMOVER_REGRAS_DE_NOTIFICACAO_LOADING';
export const REMOVER_REGRAS_DE_NOTIFICACAO_SUCCESS = 'REMOVER_REGRAS_DE_NOTIFICACAO_SUCCESS';
export const REMOVER_REGRAS_DE_NOTIFICACAO_FAIL = 'REMOVER_REGRAS_DE_NOTIFICACAO_FAIL';

