import axios from 'axios';

import {attachTokenToHeaders, handleErrorForAllActions, 
  encontrarMensagemDeErro} from '../actionFunctions';
import {
  BUSCAR_SUB_PARAMETROS_LOADING,
  BUSCAR_SUB_PARAMETROS_SUCCESS,
  BUSCAR_SUB_PARAMETROS_FAIL,
  CRIAR_SUB_PARAMETRO_LOADING,
  CRIAR_SUB_PARAMETRO_SUCCESS,
  CRIAR_SUB_PARAMETRO_FAIL,
  REMOVER_SUB_PARAMETRO_LOADING,
  REMOVER_SUB_PARAMETRO_SUCCESS,
  REMOVER_SUB_PARAMETRO_FAIL,
  EDITAR_PARAMETRO_LOADING,
  EDITAR_PARAMETRO_SUCCESS,
  EDITAR_PARAMETRO_FAIL,
} from '../actionTypesAdmin';


const definirSubParametros = ({chavesUnicas}) => async (dispatch, getState) => {
  const subParametros = (chavesUnicas).sort();
  dispatch({ 
    type: BUSCAR_SUB_PARAMETROS_LOADING,
    payload: {tipo: "SUGESTOES"},
  });
  try {
    dispatch({
      type: BUSCAR_SUB_PARAMETROS_SUCCESS,
      payload: {subParametros},
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    const error = {
      title:  `As chave únicas não foram buscados porque ocorreu um Erro`,
      message: encontrarMensagemDeErro(err)     
    }    
    alert(`${error?.title} \n\n${error?.message}` + 
      ` \n\nNenhuma modificação foi feita.`);
    dispatch({
      type: BUSCAR_SUB_PARAMETROS_FAIL,
      payload: {error},
    });
  }  
}


const adicionarChaveUnica = ({parametro, chaveUnica}) => async (dispatch, getState) => {
  const {id, parametroTipo, parametroId} = parametro;
  const subParametro = chaveUnica;
  //subparâmetro
  dispatch({ 
    type: CRIAR_SUB_PARAMETRO_LOADING
  });
  //parâmetro
  dispatch({ 
    type: EDITAR_PARAMETRO_LOADING,
    payload: {id}
  });
  try {
    //obter dicionário
    const dicionario = await apoio.buscarDicionario({getState});
    //Verifica se a chave única já existe para aquele parâmetro
    const chavesUnicas = dicionario?.[parametroTipo]?.[parametroId] || [];
    const chaveJaExiste = chavesUnicas.includes(chaveUnica);
    if(chaveJaExiste) throw new Error("ERRO: Chave Única já existente");
    //modifica dicionário adicionando chave única
    const novoDicionario = {
      ...dicionario,
      [parametroTipo]: {
        ...dicionario[parametroTipo],
        [parametroId]: [
          ...(chavesUnicas), 
          chaveUnica
        ].filter(Boolean),
      }
    }
    //posta o dicionário
    await apoio.postarDicionario({getState, novoDicionario});
    //dispatch 1 (atualização do sub-parâmetro)
    dispatch({
      type: CRIAR_SUB_PARAMETRO_SUCCESS,
      payload: {subParametro},
    });
    //dispatch 2 (atualização do parâmetro)
    const novasChavesUnicas = apoio.obterNovasChavesUnicas({novoDicionario, parametro});
    const novoParametro = {...parametro, chavesUnicas: novasChavesUnicas};
    dispatch({
      type: EDITAR_PARAMETRO_SUCCESS,
      payload: {parametro: novoParametro}
    })
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    const error = {
      title:  `A chave única não foi adicionada porque ocorreu um Erro`,
      message: encontrarMensagemDeErro(err)     
    }
    alert(`${error?.title} \n\n${error?.message}`);
    //subparâmetro
    dispatch({
      type: CRIAR_SUB_PARAMETRO_FAIL,
      payload: {error, subParametro},
    });
    //parâmetro
    dispatch({
      type: EDITAR_PARAMETRO_FAIL,
      payload: {error, id}
    })
  }
}


const removerChaveUnica = ({parametro, chaveUnica}) => async (dispatch,  getState) => {
  const {id, parametroTipo, parametroId} = parametro;
  const subParametro = chaveUnica;
  //subparâmetro
  dispatch({ 
    type: REMOVER_SUB_PARAMETRO_LOADING,
    payload: { subParametro},
  });
  //parâmetro
  dispatch({ 
    type: EDITAR_PARAMETRO_LOADING,
    payload: {id}
  });
  try {
    //obter dicionário
    const dicionario = await apoio.buscarDicionario({getState});
    //modifica dicionário removendo chave única
    let novoDicionario = dicionario;
    const index = novoDicionario[parametroTipo][parametroId].indexOf(chaveUnica);
    if (index > -1) {novoDicionario[parametroTipo][parametroId].splice(index, 1)}
    //posta o dicionário
    await apoio.postarDicionario({getState, novoDicionario});
    //dispatch 1 (atualização do sub-parâmetro)
    dispatch({
      type: REMOVER_SUB_PARAMETRO_SUCCESS,
      payload: {subParametro},
    });
    //dispatch 2 (atualização do parâmetro)
    const novasChavesUnicas = apoio.obterNovasChavesUnicas({novoDicionario, parametro});
    const novoParametro = {...parametro, chavesUnicas: novasChavesUnicas};
    dispatch({
      type: EDITAR_PARAMETRO_SUCCESS,
      payload: {parametro: novoParametro}
    })    
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    const error = {
      title:  `A chave única não foi removida porque ocorreu um Erro`,
      message: encontrarMensagemDeErro(err)     
    }
    alert(`${error?.title} \n\n${error?.message}`);
    //subparâmetro
    dispatch({
      type: REMOVER_SUB_PARAMETRO_FAIL,
      payload: {error, subParametro},
    });
    //parâmetro
    dispatch({
      type: EDITAR_PARAMETRO_FAIL,
      payload: {error, id}
    })
  }
}



const apoio = {
  buscarDicionario: async function({getState}){
    const options = attachTokenToHeaders(getState, {'Accept': 'application/json'}); 
    const url = `/api/administradores/dicionario`;
    const response = await axios.get(url, options);
    const dicionario = response.data
    return dicionario;
  },
  postarDicionario: async function({getState, novoDicionario}){

      //cria arquivo dicionario.json
      const json = JSON.stringify(novoDicionario);
      const blob = new Blob([json], {
        type: 'application/json'
      });

      //FormData
      const formData = new FormData();
      formData.append('dicionario', blob);
      
      // Inspect formData
      console.log("formData");
      for (var pair of formData.entries()) {
          console.log('    _ _ ' + pair[0]+ ', ' + pair[1]); 
      }
      
      //posta o novo dicionario
      const options = attachTokenToHeaders(getState, {'Accept': 'application/json'}); 
      const url = `/api/administradores/dicionario`;
      
      //Posta
      await axios.post(url, formData, options)
      
      //Retorno
      return false;

  },
  obterNovasChavesUnicas: function({parametro, novoDicionario}){
    const {parametroTipo, parametroId} = parametro;
    const novasChavesUnicas = novoDicionario?.[parametroTipo]?.[parametroId] ?
      novoDicionario?.[parametroTipo]?.[parametroId].map(chaveUnica => ({valor: chaveUnica})) :
      [];
    return novasChavesUnicas;
  }
}


export {  
  adicionarChaveUnica,
  removerChaveUnica,
  definirSubParametros
}