import axios from 'axios';
import {attachTokenToHeaders, handleErrorForAllActions, 
  encontrarMensagemDeErro} from '../actionFunctions';
import {sortArrayOfObjects, sortArrayOfObjectsMultiple} from 
  '../../support/publicFunctions'

import { BUSCAR_OPTIONS_LOADING, BUSCAR_OPTIONS_SUCCESS, BUSCAR_OPTIONS_FAIL,
} from '../actionTypesAdmin';

import {configuracoes, urls} from '../dataAdmin/optionsData'


const buscarOptions = ({tipos}) => async (dispatch, getState) => {  
  dispatch({ 
    type: BUSCAR_OPTIONS_LOADING
  });
  try {
    //1. Busca elementos 
    const promises = tipos.map(tipo => apoio.buscarOption({tipo, getState}));
    const responses = await Promise.all(promises);
    //2. Faz Configurações
    const options = {};
    responses.map(r => options[r.tipo] = r.option);
    //3. Faz o dispatch
    dispatch({
      type: BUSCAR_OPTIONS_SUCCESS,
      payload: { options },
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    const error = {message: encontrarMensagemDeErro(err)};
    dispatch({
      type: BUSCAR_OPTIONS_FAIL,
      payload: {error},
    });
  }
}


const apoio = {
  buscarOption: async function({tipo, getState}) {
      //I. Casos Particulares
      if(tipo === "BOOLEANO_0OU1" 
        || tipo === "GRUPOS_DE_ACESSO_RESTRICOES"
      ){
        let option = await apoio.buscarOptionParaCasosParticulares({
          tipo, getState});
        return {option, tipo};
      } else {
      //II. Casos Padrões
        //1. Busca elementos
        const url = urls[tipo];
        const config = attachTokenToHeaders(getState); 
        const response = await axios.get(url, config); 
        //2. Faz configuracoes
        let option = (response.data).map(o => {
          if (configuracoes[tipo]?.map) {
            return ({
              value: o[configuracoes[tipo].map.value],
              label: o[configuracoes[tipo].map.label],
              group: o[configuracoes[tipo].map.group] || null,
            })
          } else {
            return ({
              value: o,
              label: o,
              group: null,
            })
          }
        });
        option = option.sort(sortArrayOfObjectsMultiple("group", "label"));
        //3. Retorna option
        return {option, tipo};
      }
  },
  obterOptionOptionBooleano_0ou1: function() {
    return [ 
      {value: 1, label: "Sim"}, 
      {value:0, label:"Não"}
    ];
  },
  obterOption0ou1ouPorcentagem: function() {
    return [ 
      {value: 1, label: "Sim"}, 
      {value: 0, label: "Não"}, 
      {value: "%", label: "Todos"},   
    ]
  },  
  buscarOptionParaCasosParticulares: async function({tipo, getState}) {
    let option;
    switch(tipo) {
      case "BOOLEANO_0OU1":
        option = apoio.obterOptionOptionBooleano_0ou1();
        break;
      case "GRUPOS_DE_ACESSO_RESTRICOES":
        option = await apoio.buscarOptionParaRestricoesDeGruposDeAcesso({getState});
        break;
      default:
        //...
    }
    return option;
  },
  buscarOptionParaRestricoesDeGruposDeAcesso: async function({getState}) {

    function ajustarOptionParaRestricoes({restricoes, criterio}) {
      /*TODO: a forma como é definido value e label nesta função é 
        similar a forma como é definido o customId/customView na função 
        incluirCamposCustomizadosEmCriterios (em parametrosActions), ou seja, 
        dois processos repetidos. Seria interessante refatorar ambos para ser 
        um processo só */
      let optionAjustada = []
      //operadoresLogicos 
      const operadoresLogicos = [
        {value: "E", label: "e"},
        {value: "OU", label: "ou"}
      ];
      //ajustarOptionParaRestricoes
      restricoes.map((restricao)=>{ 
        operadoresLogicos.map((operadorLogico)=>{
          optionAjustada.push({
            value: `${criterio}:${restricao.value}:${operadorLogico.value}`,
            label: `${operadorLogico.label} ${criterio}: "${restricao.label}"`,
            group: `${operadorLogico.label} ${criterio}`
          })
          return false;
        })
        return false;
      })
      optionAjustada = optionAjustada.sort(sortArrayOfObjects("label"));
      return optionAjustada;
    }


    const optionCanaldeDistribuicao = ajustarOptionParaRestricoes({
      restricoes: (await apoio.buscarOption({tipo: "ENTIDADES_CANAIS_DE_DISTRIBUICAO", getState}))["option"],
      criterio: 'Canal de Distribuição'
    });     

    const optionModelado = ajustarOptionParaRestricoes({
      restricoes: apoio.obterOption0ou1ouPorcentagem(),
      criterio: 'Modelado'
    });

    const optionSegmento = ajustarOptionParaRestricoes({
      restricoes: (await apoio.buscarOption({tipo: "SEGMENTOS", getState}))["option"],
      criterio: 'Segmento'
    });    
        
    const optionTipoDeEntidade = ajustarOptionParaRestricoes({
      restricoes: (await apoio.buscarOption({tipo: "ENTIDADES_TIPOS", getState}))["option"],
      criterio: 'Tipo de Entidade'
    });
    

    return [
      ...optionCanaldeDistribuicao,
      ...optionModelado,
      ...optionSegmento,
      ...optionTipoDeEntidade,
    ]
  },
}



export {  
  buscarOptions,
}