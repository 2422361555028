import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ScreenShareIcon from '@material-ui/icons/ScreenShare';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  EmailShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  EmailIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";


export default function BotaoDeCompartilhar({tituloDoBotao, textoDoBotao, tooltipTitle, children}) {
  //Referência: https://material-ui.com/components/dialogs/#form-dialogs
  const [open, setOpen] = React.useState(false);

  const linkParaCompartilhar = window.location.href;
  const textoCompleto = textoDoBotao + linkParaCompartilhar
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleCopiarLink = (e) => {
    //Referência: https://stackoverflow.com/a/52033479/5091674
    navigator.clipboard.writeText(linkParaCompartilhar) 
    setOpen(false);
  };

  const handleCopiarTudo = (e) => {
    //Referência: https://stackoverflow.com/a/52033479/5091674
    navigator.clipboard.writeText(textoCompleto) 
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFocus = (event) => event.target.select();
  

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <IconButton 
            onClick={handleClickOpen}
        >
            {children}
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
            <div style={{display: "flex", alignItems: "center", justifyContent: 'space-between'}}>
              <strong>{tituloDoBotao}</strong>
              <IconButton onClick={handleClose}>
                <CloseIcon fontSize="small"/>
              </IconButton>
            </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {textoDoBotao}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            value={linkParaCompartilhar}
            multiline
            readOnly
            onFocus={handleFocus}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>        
          &nbsp;  &nbsp;
          <Button onClick={handleCopiarLink} color="primary" style={{textAlign: "left"}}>
            Copiar <br />Link
          </Button>
          <Button onClick={handleCopiarTudo} color="primary"  style={{textAlign: "left"}}>
            Copiar <br /> Tudo
          </Button>                                           
          <div style={{flex: '1 0 0'}} />
          <Tooltip title='Compartilhar via Whatsapp'>
            <WhatsappShareButton url={textoCompleto}>
                <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>       
          </Tooltip>
          &nbsp;   
          <Tooltip title='Compartilhar via Telegram'>
            <TelegramShareButton url={textoCompleto}>
                <TelegramIcon size={32} round={true} />
            </TelegramShareButton>           
          </Tooltip>
          &nbsp;
          <Tooltip title='Compartilhar via Email'>
            <EmailShareButton url={textoCompleto}>
                <EmailIcon size={32} round={true} />
            </EmailShareButton>        
          </Tooltip>
          &nbsp;  &nbsp;
        </DialogActions>
      </Dialog>
    </>
  );
}
