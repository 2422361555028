import './Preferencias.css'
import {useState, useEffect} from 'react';
import Layout from '../../layout/Layout'
import Button from '@material-ui/core/Button';
import {handleClickExibirMensagemEmDesenvolvimento} 
    from '../../support/publicFunctions'
import Paper from '@material-ui/core/Paper';    
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import CheckboxesTags from '../../components/CheckboxesTags/CheckboxesTags'
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";

//Ícones
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import CloseIcon from '@material-ui/icons/Close';

//...
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//...
import Chip from '@material-ui/core/Chip';

//...
import {connect} from "react-redux";
import {buscarRegrasDeNotificacao} from '../../redux/actions/usuarioActions'

import Loader from '../../components/Loader/Loader'



const Preferencias = ({
  regrasBuscadas, regrasBuscadasLoading, buscarRegrasDeNotificacao
}) => { 

  useEffect(()=>{
    buscarRegrasDeNotificacao();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  //Inicializar States
  const [regras, setRegras] = useState(regrasStateInicial);  
  const [contador, setContador] = useState(10);
  //States Dialog
  const [dialogIdentityDaRegra, setDialogIdentityDaRegra] = useState(null);
  const [dialogNomeDaRegra, setDialogNomeDaRegra] = useState("");
  const [dialogEntidades, setDialogEntidades] = useState([]);
  const [dialogTiposDeDocumento, setDialogTiposDeDocumento] = useState([]);

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [dialogIsForEditing, setDialogIsForEditing] = useState(false);
  
  //acompanhar mudanças
  useEffect(()=>{
    console.log("regras", regras)
  },[regras])    
  
    //TODO: completar os console.log aqui em "acompanhar mudanças"
  

  //Funções de apoio
  const handleCloseDialog = () => {
      return setDialogIsOpen(false);
  };

  const handleClickOpenDialog = (regra, isForEditing) => {
      setDialogIdentityDaRegra(regra?.identity || null);
      setDialogNomeDaRegra(regra?.nome || "");
      setDialogEntidades(regra?.entidadesSelecionadas || []);
      setDialogTiposDeDocumento(regra?.tiposDeDocumentoSelecionados || []);
      setDialogIsForEditing(isForEditing);
      return setDialogIsOpen(true);
  };

  const handleChangeTiposDeDocumento = (event, values) => setDialogTiposDeDocumento(values)
  const handleChangeEntidades = (event, values) => setDialogEntidades(values)
  const handleChangeNomeDaRegra = (event) => setDialogNomeDaRegra(event.target.value)
  
  const validarRegraAntesDeCriacaoOuEdicao = () => {
    if(!dialogEntidades.length && !dialogTiposDeDocumento.length){
      alert("Selecione pelo menos um Relatório ou pelo menos uma Entidade. " + 
        "No momento, você não está selecionando nada.");
      return false;
    }
    return true;
  }

  const handleClickCriarNovaRegra = (event) => {
    event.preventDefault(); //evitar o reload da página após o submit
    if(!validarRegraAntesDeCriacaoOuEdicao()){return false};

    const novaIdentity = contador;
    setContador(contador + 1);

    const novaRegra =  {
      identity : novaIdentity,
      nome: dialogNomeDaRegra,
      tiposDeDocumentoSelecionados: dialogTiposDeDocumento,
      entidadesSelecionadas: dialogEntidades
    }

    setRegras([...regras, novaRegra]);
    return handleCloseDialog();
  }


  const handleClickEditarRegra = (event) => {
    event.preventDefault(); //evitar o reload da página após o submit
    if(!validarRegraAntesDeCriacaoOuEdicao()){return false};

    const novaRegra =  {
      identity : dialogIdentityDaRegra,
      nome: dialogNomeDaRegra,
      tiposDeDocumentoSelecionados: dialogTiposDeDocumento,
      entidadesSelecionadas: dialogEntidades
    }

    //acha o index
    const indexDaRegra = regras.findIndex((obj => obj.identity === dialogIdentityDaRegra));
    console.log("indexDaRegra", indexDaRegra);

    let novasRegras = [...regras];
    novasRegras[indexDaRegra] = novaRegra;

    //Finaliza
    setRegras([...novasRegras]);
    return handleCloseDialog();
  }


  const handleClickDelete = (regra) => {
    //acha o index
    const indexDaRegra = regras.findIndex((obj => obj.identity === regra.identity));
    console.log("indexDaRegra", indexDaRegra);
    if(window.confirm(
      `Você tem certeza que deseja deletar esta regra? `+ 
      `(esta ação é irreversível)`
    )){
      //remove
      let novasRegras = [...regras];
      novasRegras.splice(indexDaRegra, 1);
      //Finaliza
      setRegras([...novasRegras]);
    } else {
        alert('Nenhuma modificação foi feita.')
    }   
  }  



  return (<>{
    (regrasBuscadasLoading) ? (
        <Layout pageTitle="Carregando...">
            <Loader mensagem={""}/>
        </Layout>
    ) : (
      <Layout pageTitle="Preferências de Notificação"><div id="preferencias">
        <Dialog 
          disableBackdropClick 
          fullWidth={true} 
          maxWidth={'md'} 
          onClose={handleCloseDialog} 
          open={dialogIsOpen}
        >
          <form onSubmit={dialogIsForEditing ? (handleClickEditarRegra) : (handleClickCriarNovaRegra)} >
            <DialogTitle id="form-dialog-title">
              <div style={{display: "flex", alignItems: "center", justifyContent: 'space-between'}}>
                <strong>{dialogIsForEditing ? "Editar Regra" : "Criar Nova Regra"}</strong>
                <div>
                  <Tooltip title='Ajuda'>
                    <IconButton 
                        onClick={handleClickExibirMensagemEmDesenvolvimento}
                    >
                        <HelpIcon fontSize="normal" />
                    </IconButton>
                  </Tooltip>       
                  <IconButton onClick={handleCloseDialog}>
                    <CloseIcon fontSize="small"/>
                  </IconButton>
                </div>
              </div>
            </DialogTitle>              
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                fullWidth
                placeholder={"Escreva um título para a Regra de Notificação"}
                value={dialogNomeDaRegra}
                onChange={handleChangeNomeDaRegra}
                // multiline
                variant="outlined"
                inputProps={{
                  minLength: 3,
                  maxLength: 40, /*TODO: ver se o tamanho máximo da nome da 
                  regra vai ser só 40 caracteres mesmo, parece bem pequeno
                  (mas precisaria alterar em sintonia com o BD, se for o caso)!*/
                  style: {
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "0.5rem"
                  }
                }}
              />                      
            </DialogContent>
            <DialogContent>
              <DialogContentText>
                <div style={{marginBottom: '1.25rem', fontSize: '0.875rem', fontWeight: '500'}}>
                  Como você deseja ser notificado?
                </div>
              </DialogContentText>
              <CheckboxesTags 
                  options={listaDeTiposDeDocumento}
                  optionLabelKey={"nome"}
                  groupByKey={"tema"}
                  placeholder={"Selecionar"}
                  label={"Com estes Relatórios"}
                  limitTags={-1}
                  textFieldVariant="outlined"
                  value={dialogTiposDeDocumento}
                  onChange={
                    handleChangeTiposDeDocumento
                  }
              /> 
            </DialogContent>
            <DialogContent>
              <CheckboxesTags 
                  options={listaDeEntidades}
                  optionLabelKey={"apelido"}
                  groupByKey={"tipo"}
                  placeholder={"Selecionar"}
                  label={"para estas Entidades"}
                  limitTags={-1}
                  value={dialogEntidades}
                  textFieldVariant="outlined"
                  onChange={handleChangeEntidades}
              />       
            </DialogContent>
            <DialogContent>
              <DialogActions>
                <Button color="primary" onClick={handleCloseDialog}> 
                  Cancelar
                </Button>      
                {dialogIsForEditing ? (
                  <Button color="primary" variant="contained" type="submit" >
                    Salvar Edição
                  </Button>
                ) : (
                  <Button color="primary" variant="contained" type="submit" >
                    Salvar
                  </Button>
                )}          
              </DialogActions>                                        
            </DialogContent>
          </form>
        </Dialog>
        <p className="lunar textoDescritivo">
          Você será notificado via e-mail com novos documentos conforme 
          as regras de notificações abaixo, que você pode editar, 
          criar ou deletar como preferir. 
        </p>      
        <Button 
            variant="contained" 
            color="primary" 
            onClick={handleClickOpenDialog}
            className="botaoCriarNovaRegra"
        >
            <AddCircleOutlineIcon /> &nbsp; 
            Criar Nova Regra
        </Button>
        <h2 className="lunar h2preferencias">Minhas Regras</h2>    
        <Grid container spacing={0}>
          {regras.slice(0).reverse().map((regra) => {
              return (
                <Grid item xs={12} md={12}>
                    <Paper elevation={2} className="paper paperBody">
                        <div className="cardHead">
                          <span className="tituloDaRegra" onClick={
                              ()=>{handleClickOpenDialog(regra, true)}
                          }>
                            {regra.nome}
                          </span>
                          <span className="bodyIconsContainer" >
                              <Tooltip title={"Editar Regra"}>
                                  <IconButton
                                      onClick={
                                        ()=>{handleClickOpenDialog(regra, true)}
                                      }
                                  >
                                  <EditIcon fontSize="small" />
                                  </IconButton>
                              </Tooltip>
                              <Tooltip title={"Deletar Regra"}>
                                  <IconButton
                                      onClick={
                                        ()=>{handleClickDelete(regra)}
                                      }
                                  >
                                      <DeleteIcon fontSize="small" />
                                  </IconButton>
                              </Tooltip>
                          </span>
                        </div>
                        <div className="cardBody">
                            <fieldset className="chipsContainer" onClick={
                              ()=>{handleClickOpenDialog(regra, true)}
                            }>
                              <legend className="subTituloDaRegra">
                                Serei notificado com estes Relatórios
                              </legend>  
                              {(regra.tiposDeDocumentoSelecionados.length) ? (
                                regra.tiposDeDocumentoSelecionados.map(
                                  (tipoDeDocumentoSelecionados) => {
                                    return <Chip 
                                      className="chip"
                                      label={tipoDeDocumentoSelecionados.nome} 
                                    />
                                  }
                              )) : (
                                <Chip 
                                  className="chip chipTodos"
                                  icon={<AllInclusiveIcon fontSize="small"  />}
                                  label={"TODOS OS RELATÓRIOS"} 
                                />
                              )}
                          </fieldset>                      
                        </div>
                            <fieldset className="chipsContainer" onClick={
                              ()=>{handleClickOpenDialog(regra, true)}
                            }>
                              <legend className="subTituloDaRegra">
                                para estas Entidades. 
                              </legend>
                              {(regra.entidadesSelecionadas.length) ? (
                                regra.entidadesSelecionadas.map(
                                  (entidadeSelecionada) => {
                                    return <Chip 
                                      className="chip"
                                      label={entidadeSelecionada.apelido} 
                                    />
                                  }
                              )) : (
                                <Chip
                                    className="chip chipTodos"
                                    icon={<AllInclusiveIcon fontSize="small" />}
                                    label={"TODAS AS ENTIDADES"}
                                />
                              )}                        
                            </fieldset>
                    </Paper>
                </Grid>
              )
          })}
          {!regras.length && (
            <Grid item xs={12} md={12}>
                <Paper elevation={2} className="paper">
                    <div>
                      <p className="semRegrasTexto">
                        Você não possui Regras de Notificação.
                        Utilize o botão de criar nova regra para criá-las conforme
                        suas Preferências.
                      </p>
                    </div>
                </Paper>
            </Grid>
          )}
        </Grid>
      </div></Layout>
    )
  }</>)
}





const regrasStateInicial = [
  {
    identity: 1,
    nome: "Demonstrativo de Caixa dos INRs",
    tiposDeDocumentoSelecionados: [
        {
            id: 2,
            nome: 'Demonstrativo de Caixa Administrador',
            frequencia: 'MENSAL',
            temaId: 1,
            tema: 'Ativo',
            dataDesativacao: null,
            usuarioCadastraId: 34,
            usuarioCadastra: 'Vinicius Ferreira',
            usuarioDesativaId: null,
            usuarioDesativa: null
        },
    ],
    entidadesSelecionadas: [
        {
            id: 13,
            nome: 'Basset Hound LLC',
            apelido: 'Basset Hound LLC',
            canalDeDistribuicao: 'Private Equity',
            tipo: 'INR - Passagem',
            subtipo: 'LLC',
            qualificacaoDoInvestidor: 'Profissional',
            modelada: 1,
            cnpj: '28990395000136',
            isin: null,
            administrador: 'BRL DTVM',
            identificacaoNoAdministrador: 'INR BRL 1333',
            administradorId: 2,
            dataDesativacao: null,
            usuarioCadastraId: 34,
            usuarioCadastra: 'Vinicius Ferreira',
            usuarioDesativaId: null,
            usuarioDesativa: null
          },
          {
            id: 20,
            nome: 'Brazil Student Housing JV LLC',
            apelido: 'Brazil Student Housing LLC',
            canalDeDistribuicao: 'Private Equity',
            tipo: 'INR - Passagem',
            subtipo: 'LLC',
            qualificacaoDoInvestidor: 'Profissional',
            modelada: 1,
            cnpj: '31866399000111',
            isin: null,
            administrador: 'BRL DTVM',
            identificacaoNoAdministrador: 'INR BRL 1612',
            administradorId: 2,
            dataDesativacao: null,
            usuarioCadastraId: 34,
            usuarioCadastra: 'Vinicius Ferreira',
            usuarioDesativaId: null,
            usuarioDesativa: null
          },
          {
            id: 14,
            nome: 'BREOF II Luxco S.a.r.l.',
            apelido: 'BREOF II Luxco SarL',
            canalDeDistribuicao: 'Private Equity',
            tipo: 'INR - Passagem',
            subtipo: 'LLC',
            qualificacaoDoInvestidor: 'Profissional',
            modelada: 1,
            cnpj: '28990389000189',
            isin: null,
            administrador: 'BRL DTVM',
            identificacaoNoAdministrador: 'INR BRL 1334',
            administradorId: 2,
            dataDesativacao: null,
            usuarioCadastraId: 34,
            usuarioCadastra: 'Vinicius Ferreira',
            usuarioDesativaId: null,
            usuarioDesativa: null
          },
          {
            id: 19,
            nome: 'Brazil Real Estate Opportunities Fund III, S.C.S.',
            apelido: 'BREOF III SCS',
            canalDeDistribuicao: 'Private Equity',
            tipo: 'INR - Passagem',
            subtipo: 'SCS',
            qualificacaoDoInvestidor: 'Profissional',
            modelada: 1,
            cnpj: '28990390000103',
            isin: null,
            administrador: 'BRL DTVM',
            identificacaoNoAdministrador: 'INR BRL 1340',
            administradorId: 2,
            dataDesativacao: null,
            usuarioCadastraId: 34,
            usuarioCadastra: 'Vinicius Ferreira',
            usuarioDesativaId: null,
            usuarioDesativa: null
          },
          {
            id: 15,
            nome: 'Canaryanna LLC',
            apelido: 'Canaryanna LLC',
            canalDeDistribuicao: 'Private Equity',
            tipo: 'INR - Passagem',
            subtipo: 'LLC',
            qualificacaoDoInvestidor: 'Profissional',
            modelada: 1,
            cnpj: '28990392000100',
            isin: null,
            administrador: 'BRL DTVM',
            identificacaoNoAdministrador: 'INR BRL 1335',
            administradorId: 2,
            dataDesativacao: null,
            usuarioCadastraId: 34,
            usuarioCadastra: 'Vinicius Ferreira',
            usuarioDesativaId: null,
            usuarioDesativa: null
          },
          {
            id: 16,
            nome: 'Espírito Santo LLC',
            apelido: 'Espirito Santo LLC',
            canalDeDistribuicao: 'Private Equity',
            tipo: 'INR - Passagem',
            subtipo: 'LLC',
            qualificacaoDoInvestidor: 'Profissional',
            modelada: 1,
            cnpj: '28990391000158',
            isin: null,
            administrador: 'BRL DTVM',
            identificacaoNoAdministrador: 'INR BRL 1336',
            administradorId: 2,
            dataDesativacao: null,
            usuarioCadastraId: 34,
            usuarioCadastra: 'Vinicius Ferreira',
            usuarioDesativaId: null,
            usuarioDesativa: null
          },
          {
            id: 17,
            nome: 'Floripa LLC',
            apelido: 'Floripa',
            canalDeDistribuicao: 'Private Equity',
            tipo: 'INR - Passagem',
            subtipo: 'LLC',
            qualificacaoDoInvestidor: 'Profissional',
            modelada: 1,
            cnpj: '28990393000147',
            isin: null,
            administrador: 'BRL DTVM',
            identificacaoNoAdministrador: 'INR BRL 1338',
            administradorId: 2,
            dataDesativacao: null,
            usuarioCadastraId: 34,
            usuarioCadastra: 'Vinicius Ferreira',
            usuarioDesativaId: null,
            usuarioDesativa: null
          },
          {
            id: 18,
            nome: 'Tabbyal LLC',
            apelido: 'Tabbyal',
            canalDeDistribuicao: 'Private Equity',
            tipo: 'INR - Passagem',
            subtipo: 'LLC',
            qualificacaoDoInvestidor: 'Profissional',
            modelada: 1,
            cnpj: '28990394000191',
            isin: null,
            administrador: 'BRL DTVM',
            identificacaoNoAdministrador: 'INR BRL 1341',
            administradorId: 2,
            dataDesativacao: null,
            usuarioCadastraId: 34,
            usuarioCadastra: 'Vinicius Ferreira',
            usuarioDesativaId: null,
            usuarioDesativa: null
          }
    ]
  },
  {
      identity: 2,
      nome: "Fechamento Mensal dos Fundos",
      tiposDeDocumentoSelecionados: [
          {
              id: 1,
              nome: 'Carteira Administrador',
              frequencia: 'MENSAL',
              temaId: 1,
              tema: 'Ativo',
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
          },
          {
            id: 7,
            nome: 'Demonstrativo de Caixa Gerencial',
            frequencia: 'MENSAL',
            temaId: 5,
            tema: 'Gerencial',
            dataDesativacao: null,
            usuarioCadastraId: 33,
            usuarioCadastra: 'Vinicius Ferreira',
            usuarioDesativaId: null,
            usuarioDesativa: null
          },
          {
              id: 2,
              nome: 'Demonstrativo de Caixa Administrador',
              frequencia: 'MENSAL',
              temaId: 1,
              tema: 'Ativo',
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
          },
          {
              id: 3,
              nome: 'Demonstrativo de Caixa Banco',
              frequencia: 'MENSAL',
              temaId: 1,
              tema: 'Ativo',
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
              },
      ],
      entidadesSelecionadas: [
          {
              id: 2,
              nome: 'Fundo de Investimento em Direitos Creditórios BREOF Real Estate Credit',
              apelido: 'BREOF FIDC',
              canalDeDistribuicao: 'Private Equity',
              tipo: 'Fundo',
              subtipo: 'FIDC',
              qualificacaoDoInvestidor: 'Profissional',
              modelada: 1,
              cnpj: '22061694000100',
              isin: 'BRBREOCTF003',
              administrador: 'BRL DTVM',
              identificacaoNoAdministrador: 'FIDC BRL 1098',
              administradorId: 2,
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
            },
            {
              id: 1,
              nome: 'BREOF Fundo de Investimento em Participações - Multiestratégia',
              apelido: 'BREOF FIP Multiestratégia',
              canalDeDistribuicao: 'Private Equity',
              tipo: 'Fundo',
              subtipo: 'FIP',
              qualificacaoDoInvestidor: 'Profissional',
              modelada: 1,
              cnpj: '11288558000130',
              isin: 'BRBREFCTF001',
              administrador: 'BRL DTVM',
              identificacaoNoAdministrador: 'FIP BRL 1295',
              administradorId: 2,
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
            },
            {
              id: 3,
              nome: 'BREOF III - Fundo de Investimento em Participacoes Multiestratégia',
              apelido: 'BREOF III FIP',
              canalDeDistribuicao: 'Private Equity',
              tipo: 'Fundo',
              subtipo: 'FIP',
              qualificacaoDoInvestidor: 'Profissional',
              modelada: 1,
              cnpj: '24701488000142',
              isin: 'BRBRFPCTF007',
              administrador: 'MODAL DTVM',
              identificacaoNoAdministrador: '883',
              administradorId: 1,
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
            },
            {
              id: 4,
              nome: 'Nova I - Fundo de Investimento Imobiliario - FII',
              apelido: 'Nova I FII',
              canalDeDistribuicao: 'Private Equity',
              tipo: 'Fundo',
              subtipo: 'FII',
              qualificacaoDoInvestidor: 'Profissional',
              modelada: 1,
              cnpj: '22003469000117',
              isin: 'BRNVIFCTF009',
              administrador: 'MODAL DTVM',
              identificacaoNoAdministrador: '884',
              administradorId: 1,
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
            },
            {
              id: 6,
              nome: 'SBC Fundo de Investimento Imobiliário ',
              apelido: 'SBC FII',
              canalDeDistribuicao: 'Coinvestimento',
              tipo: 'Fundo',
              subtipo: 'FII',
              qualificacaoDoInvestidor: 'Profissional',
              modelada: 1,
              cnpj: '31907591000109',
              isin: null,
              administrador: 'BRL DTVM',
              identificacaoNoAdministrador: 'FII BRL 1643',
              administradorId: 2,
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
            },
            {
              id: 5,
              nome: 'Trilliant Reit Fundo de Investimento Multimercado Crédito Privado',
              apelido: 'Trilliant Reit FIM',
              canalDeDistribuicao: 'Private Equity',
              tipo: 'Fundo',
              subtipo: 'FIM',
              qualificacaoDoInvestidor: 'Profissional',
              modelada: 1,
              cnpj: '23872250000117',
              isin: 'BRSFNGCTF009',
              administrador: 'BRL DTVM',
              identificacaoNoAdministrador: 'FIM BRL 1203',
              administradorId: 2,
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
            },
            {
              id: 12,
              nome: 'VBI Consumo Essencial Fundo de Investimento Imobiliário',
              apelido: 'VBI Consumo Essencial FII',
              canalDeDistribuicao: 'Listado',
              tipo: 'Fundo',
              subtipo: 'FII',
              qualificacaoDoInvestidor: 'Público em Geral',
              modelada: 0,
              cnpj: '34691520000100',
              isin: 'BREVBICTF007',
              administrador: 'BRL DTVM',
              identificacaoNoAdministrador: 'FII BRL1873',
              administradorId: 2,
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
            },
            {
              id: 8,
              nome: 'Fundo de Investimento Imobiliário - VBI CRI',
              apelido: 'VBI CRI FII',
              canalDeDistribuicao: 'Listado',
              tipo: 'Fundo',
              subtipo: 'FII',
              qualificacaoDoInvestidor: 'Qualificado',
              modelada: 1,
              cnpj: '28729197000113',
              isin: 'BRCVBICTF001',
              administrador: 'BRL DTVM',
              identificacaoNoAdministrador: 'FII BRL 1663',
              administradorId: 2,
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
            },
            {
              id: 10,
              nome: 'Fundo de Investimento Imobiliário - VBI Logístico',
              apelido: 'VBI Logístico FII',
              canalDeDistribuicao: 'Listado',
              tipo: 'Fundo',
              subtipo: 'FII',
              qualificacaoDoInvestidor: 'Público em Geral',
              modelada: 0,
              cnpj: '30629603000118',
              isin: 'BRLVBICTF002',
              administrador: 'BTG DTVM',
              identificacaoNoAdministrador: null,
              administradorId: 3,
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
            },
            {
              id: 11,
              nome: 'Fundo de Investimento Imobiliário - VBI Prime Properties',
              apelido: 'VBI Prime Properties FII',
              canalDeDistribuicao: 'Listado',
              tipo: 'Fundo',
              subtipo: 'FII',
              qualificacaoDoInvestidor: 'Público em Geral',
              modelada: 0,
              cnpj: '35652102000176',
              isin: 'BRPVBICTF003',
              administrador: 'BTG DTVM',
              identificacaoNoAdministrador: null,
              administradorId: 3,
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
            },
            {
              id: 9,
              nome: 'VBI Reits FoF Fundo de Investimento Imobiliario',
              apelido: 'VBI Reits FoF FII',
              canalDeDistribuicao: 'Listado',
              tipo: 'Fundo',
              subtipo: 'FII',
              qualificacaoDoInvestidor: 'Público em Geral',
              modelada: 0,
              cnpj: '35507457000171',
              isin: 'BRRVBICTF009',
              administrador: 'BRL DTVM',
              identificacaoNoAdministrador: 'FII BRL 1798',
              administradorId: 2,
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
            },
            {
              id: 7,
              nome: 'VBI Uliving - Fundo de Investimento Imobiliário',
              apelido: 'VBI Uliving FII',
              canalDeDistribuicao: 'Coinvestimento',
              tipo: 'Fundo',
              subtipo: 'FII',
              qualificacaoDoInvestidor: 'Profissional',
              modelada: 1,
              cnpj: '30871660000109',
              isin: 'BR018CCTF005',
              administrador: 'BRL DTVM',
              identificacaoNoAdministrador: 'FII BRL 1589',
              administradorId: 2,
              dataDesativacao: null,
              usuarioCadastraId: 34,
              usuarioCadastra: 'Vinicius Ferreira',
              usuarioDesativaId: null,
              usuarioDesativa: null
          },
      ]
  },
  {
    identity: 3,
    nome: "Performance Breakdown de todas entidades",
    tiposDeDocumentoSelecionados: [
        {
            id: 17,
            nome: 'Performance Breakdown',
            frequencia: 'MENSAL',
            temaId: 5,
            tema: 'Gerencial',
            dataDesativacao: null,
            usuarioCadastraId: 34,
            usuarioCadastra: 'Vinicius Ferreira',
            usuarioDesativaId: null,
            usuarioDesativa: null
        },
    ],
    entidadesSelecionadas: []
  },
]

const listaDeTiposDeDocumento = [
  {
    id: 1,
    nome: 'Carteira Administrador',
    frequencia: 'MENSAL',
    temaId: 1,
    tema: 'Ativo',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 2,
    nome: 'Demonstrativo de Caixa Administrador',
    frequencia: 'MENSAL',
    temaId: 1,
    tema: 'Ativo',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 3,
    nome: 'Demonstrativo de Caixa Banco',
    frequencia: 'MENSAL',
    temaId: 1,
    tema: 'Ativo',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 21,
    nome: 'Dados Cadastrais',
    frequencia: 'EVENTUAL',
    temaId: 8,
    tema: 'Cadastro',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 8,
    nome: 'Contratos de Prestador de Serviço do Fundo',
    frequencia: 'EVENTUAL',
    temaId: 3,
    tema: 'Contratos',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 9,
    nome: 'Regulamento',
    frequencia: 'EVENTUAL',
    temaId: 3,
    tema: 'Contratos',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 4,
    nome: 'Balancete',
    frequencia: 'MENSAL',
    temaId: 2,
    tema: 'Contábil',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 5,
    nome: 'Demonstração Financeira Auditada',
    frequencia: 'ANUAL',
    temaId: 2,
    tema: 'Contábil',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 6,
    nome: 'Fechamento Contábil',
    frequencia: 'MENSAL',
    temaId: 2,
    tema: 'Contábil',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 7,
    nome: 'Razão',
    frequencia: 'MENSAL',
    temaId: 2,
    tema: 'Contábil',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 10,
    nome: 'Ata de Assembléia',
    frequencia: 'EVENTUAL',
    temaId: 4,
    tema: 'Eventos Societários',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 11,
    nome: 'Demonstrativo de Caixa Gerencial',
    frequencia: 'MENSAL',
    temaId: 5,
    tema: 'Gerencial',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 12,
    nome: 'Distribuição de Rendimento',
    frequencia: 'MENSAL',
    temaId: 5,
    tema: 'Gerencial',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 13,
    nome: 'DRE Gerencial',
    frequencia: 'MENSAL',
    temaId: 5,
    tema: 'Gerencial',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 14,
    nome: 'Fluxo de Caixa Gerencial',
    frequencia: 'MENSAL',
    temaId: 5,
    tema: 'Gerencial',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 15,
    nome: 'Histórico de Cotas e PL',
    frequencia: 'MENSAL',
    temaId: 5,
    tema: 'Gerencial',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 16,
    nome: 'Memória de Cálculo de Taxas',
    frequencia: 'MENSAL',
    temaId: 5,
    tema: 'Gerencial',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 17,
    nome: 'Performance Breakdown',
    frequencia: 'MENSAL',
    temaId: 5,
    tema: 'Gerencial',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 18,
    nome: 'Extrato de Cotista do Fundo',
    frequencia: 'MENSAL',
    temaId: 6,
    tema: 'Passivo',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 19,
    nome: 'Posição de Investidores do Fundo',
    frequencia: 'MENSAL',
    temaId: 6,
    tema: 'Passivo',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 20,
    nome: 'Relatório de Controle de Enquadramento',
    frequencia: 'MENSAL',
    temaId: 7,
    tema: 'Risco',
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  }
]

const listaDeEntidades = [
  {
    id: 2,
    nome: 'Fundo de Investimento em Direitos Creditórios BREOF Real Estate Credit',
    apelido: 'BREOF FIDC',
    canalDeDistribuicao: 'Private Equity',
    tipo: 'Fundo',
    subtipo: 'FIDC',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '22061694000100',
    isin: 'BRBREOCTF003',
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'FIDC BRL 1098',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 1,
    nome: 'BREOF Fundo de Investimento em Participações - Multiestratégia',
    apelido: 'BREOF FIP Multiestratégia',
    canalDeDistribuicao: 'Private Equity',
    tipo: 'Fundo',
    subtipo: 'FIP',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '11288558000130',
    isin: 'BRBREFCTF001',
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'FIP BRL 1295',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 3,
    nome: 'BREOF III - Fundo de Investimento em Participacoes Multiestratégia',
    apelido: 'BREOF III FIP',
    canalDeDistribuicao: 'Private Equity',
    tipo: 'Fundo',
    subtipo: 'FIP',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '24701488000142',
    isin: 'BRBRFPCTF007',
    administrador: 'MODAL DTVM',
    identificacaoNoAdministrador: '883',
    administradorId: 1,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 4,
    nome: 'Nova I - Fundo de Investimento Imobiliario - FII',
    apelido: 'Nova I FII',
    canalDeDistribuicao: 'Private Equity',
    tipo: 'Fundo',
    subtipo: 'FII',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '22003469000117',
    isin: 'BRNVIFCTF009',
    administrador: 'MODAL DTVM',
    identificacaoNoAdministrador: '884',
    administradorId: 1,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 6,
    nome: 'SBC Fundo de Investimento Imobiliário ',
    apelido: 'SBC FII',
    canalDeDistribuicao: 'Coinvestimento',
    tipo: 'Fundo',
    subtipo: 'FII',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '31907591000109',
    isin: null,
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'FII BRL 1643',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 5,
    nome: 'Trilliant Reit Fundo de Investimento Multimercado Crédito Privado',
    apelido: 'Trilliant Reit FIM',
    canalDeDistribuicao: 'Private Equity',
    tipo: 'Fundo',
    subtipo: 'FIM',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '23872250000117',
    isin: 'BRSFNGCTF009',
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'FIM BRL 1203',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 12,
    nome: 'VBI Consumo Essencial Fundo de Investimento Imobiliário',
    apelido: 'VBI Consumo Essencial FII',
    canalDeDistribuicao: 'Listado',
    tipo: 'Fundo',
    subtipo: 'FII',
    qualificacaoDoInvestidor: 'Público em Geral',
    modelada: 0,
    cnpj: '34691520000100',
    isin: 'BREVBICTF007',
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'FII BRL1873',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 8,
    nome: 'Fundo de Investimento Imobiliário - VBI CRI',
    apelido: 'VBI CRI FII',
    canalDeDistribuicao: 'Listado',
    tipo: 'Fundo',
    subtipo: 'FII',
    qualificacaoDoInvestidor: 'Qualificado',
    modelada: 1,
    cnpj: '28729197000113',
    isin: 'BRCVBICTF001',
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'FII BRL 1663',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 10,
    nome: 'Fundo de Investimento Imobiliário - VBI Logístico',
    apelido: 'VBI Logístico FII',
    canalDeDistribuicao: 'Listado',
    tipo: 'Fundo',
    subtipo: 'FII',
    qualificacaoDoInvestidor: 'Público em Geral',
    modelada: 0,
    cnpj: '30629603000118',
    isin: 'BRLVBICTF002',
    administrador: 'BTG DTVM',
    identificacaoNoAdministrador: null,
    administradorId: 3,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 11,
    nome: 'Fundo de Investimento Imobiliário - VBI Prime Properties',
    apelido: 'VBI Prime Properties FII',
    canalDeDistribuicao: 'Listado',
    tipo: 'Fundo',
    subtipo: 'FII',
    qualificacaoDoInvestidor: 'Público em Geral',
    modelada: 0,
    cnpj: '35652102000176',
    isin: 'BRPVBICTF003',
    administrador: 'BTG DTVM',
    identificacaoNoAdministrador: null,
    administradorId: 3,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 9,
    nome: 'VBI Reits FoF Fundo de Investimento Imobiliario',
    apelido: 'VBI Reits FoF FII',
    canalDeDistribuicao: 'Listado',
    tipo: 'Fundo',
    subtipo: 'FII',
    qualificacaoDoInvestidor: 'Público em Geral',
    modelada: 0,
    cnpj: '35507457000171',
    isin: 'BRRVBICTF009',
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'FII BRL 1798',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 7,
    nome: 'VBI Uliving - Fundo de Investimento Imobiliário',
    apelido: 'VBI Uliving FII',
    canalDeDistribuicao: 'Coinvestimento',
    tipo: 'Fundo',
    subtipo: 'FII',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '30871660000109',
    isin: 'BR018CCTF005',
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'FII BRL 1589',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 13,
    nome: 'Basset Hound LLC',
    apelido: 'Basset Hound LLC',
    canalDeDistribuicao: 'Private Equity',
    tipo: 'INR - Passagem',
    subtipo: 'LLC',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '28990395000136',
    isin: null,
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'INR BRL 1333',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 20,
    nome: 'Brazil Student Housing JV LLC',
    apelido: 'Brazil Student Housing LLC',
    canalDeDistribuicao: 'Private Equity',
    tipo: 'INR - Passagem',
    subtipo: 'LLC',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '31866399000111',
    isin: null,
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'INR BRL 1612',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 14,
    nome: 'BREOF II Luxco S.a.r.l.',
    apelido: 'BREOF II Luxco SarL',
    canalDeDistribuicao: 'Private Equity',
    tipo: 'INR - Passagem',
    subtipo: 'LLC',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '28990389000189',
    isin: null,
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'INR BRL 1334',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 19,
    nome: 'Brazil Real Estate Opportunities Fund III, S.C.S.',
    apelido: 'BREOF III SCS',
    canalDeDistribuicao: 'Private Equity',
    tipo: 'INR - Passagem',
    subtipo: 'SCS',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '28990390000103',
    isin: null,
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'INR BRL 1340',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 15,
    nome: 'Canaryanna LLC',
    apelido: 'Canaryanna LLC',
    canalDeDistribuicao: 'Private Equity',
    tipo: 'INR - Passagem',
    subtipo: 'LLC',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '28990392000100',
    isin: null,
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'INR BRL 1335',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 16,
    nome: 'Espírito Santo LLC',
    apelido: 'Espirito Santo LLC',
    canalDeDistribuicao: 'Private Equity',
    tipo: 'INR - Passagem',
    subtipo: 'LLC',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '28990391000158',
    isin: null,
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'INR BRL 1336',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 17,
    nome: 'Floripa LLC',
    apelido: 'Floripa',
    canalDeDistribuicao: 'Private Equity',
    tipo: 'INR - Passagem',
    subtipo: 'LLC',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '28990393000147',
    isin: null,
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'INR BRL 1338',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  },
  {
    id: 18,
    nome: 'Tabbyal LLC',
    apelido: 'Tabbyal',
    canalDeDistribuicao: 'Private Equity',
    tipo: 'INR - Passagem',
    subtipo: 'LLC',
    qualificacaoDoInvestidor: 'Profissional',
    modelada: 1,
    cnpj: '28990394000191',
    isin: null,
    administrador: 'BRL DTVM',
    identificacaoNoAdministrador: 'INR BRL 1341',
    administradorId: 2,
    dataDesativacao: null,
    usuarioCadastraId: 34,
    usuarioCadastra: 'Vinicius Ferreira',
    usuarioDesativaId: null,
    usuarioDesativa: null
  }
]

const mapStateToProps = (state) => ({
  //entidades
  regrasBuscadas: state.regrasDeNotificacao.regrasDeNotificacao,
  regrasBuscadasLoading: state.regrasDeNotificacao.isLoading,
});

export default connect(mapStateToProps, {buscarRegrasDeNotificacao}) (Preferencias);