import axios from 'axios';
import {attachTokenToHeaders, mapApiDataArray, handleErrorForAllActions} from '../actionFunctions';

import {
  BUSCAR_DESCRITIVOS_LOADING, BUSCAR_DESCRITIVOS_SUCCESS, BUSCAR_DESCRITIVOS_FAIL,
} from '../actionTypes';

import {sortArrayOfObjectsMultiple} from '../../support/publicFunctions'

const descritivosApiDataMapping = {
  "id": "id",
  "nome": "nomeDiferenciador",
  "data_cadastral": "dataCadastral",
  "tipo_de_documento_id": "tipoDeDocumentoId",
  "tipo_de_documento_nome": "tipoDeDocumento",
  "tema_id": "temaId",
  "tema_nome": "tema",
  "tipo_de_documento_frequencia": "frequencia",
  "usuario_que_cadastra_id": "usuarioQueCadastraId",
  "usuario_que_cadastra": "usuarioQueCadastra"
}


const buscarDescritivos = () => async (dispatch, getState) => {
  dispatch({ 
    type: (BUSCAR_DESCRITIVOS_LOADING) 
  });
  try {
    const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
    const response = await axios.get('api/descritivos', options); 
    const descritivosBuscados = mapApiDataArray(response.data, descritivosApiDataMapping);
    //ordena descritivos primeiro por tema, depois por tipo de documento e por último pelo nomeDiferenciador:
    let descritivos = descritivosBuscados.sort(sortArrayOfObjectsMultiple("tema", "tipoDeDocumento", "nomeDiferenciador"));
    //adiciona campo nomeCompleto em descritivos (nome do tipoDeDocumento + nome do descritivo em si)
    descritivos.map(descritivo =>  (
      descritivo['nomeCompleto'] = [descritivo.tipoDeDocumento, descritivo.nomeDiferenciador].filter(Boolean).join(" - ")));    
    dispatch({
      type: (BUSCAR_DESCRITIVOS_SUCCESS),
      payload: {descritivos: descritivos},
    });

  } catch (err) {
    handleErrorForAllActions(err, getState); 
    dispatch({
      type: (BUSCAR_DESCRITIVOS_FAIL),
      payload: { error: err.message},
    });
  }
};




export {
  buscarDescritivos,
}
