import axios from 'axios';
import {attachTokenToHeaders, encontrarMensagemDeErro, mapApiDataArray, handleErrorForAllActions} 
  from '../actionFunctions';

import {
  BUSCAR_LOGS_DE_ATIVIDADE_LOADING, 
  BUSCAR_LOGS_DE_ATIVIDADE_SUCCESS, 
  BUSCAR_LOGS_DE_ATIVIDADE_FAIL,
  BUSCAR_LOGS_DE_DOWNLOAD_LOADING, 
  BUSCAR_LOGS_DE_DOWNLOAD_SUCCESS, 
  BUSCAR_LOGS_DE_DOWNLOAD_FAIL,
} from '../actionTypesAdmin';

const LogsDeAtividadeApiDataMapping = {
  "usuario_id": "usuarioId",
  "usuario_nome": "usuario",
  "acao": "acao",
  "tipo_de_objeto": "tipoDeObjeto",
  "objeto_id": "objetoId",
  "objeto_nome": "objeto",
  "data": "data"
};


const LogsDeDownloadApiDataMapping = {
  'data_download': 'dataDownload', 
  'usuario_que_baixa_id': 'usuarioQueBaixaId', 
  'usuario_que_baixa_nome': 'usuarioQueBaixa', 
  'usuario_que_baixa_grupo_id': 'grupoDoUsuarioQueBaixaId', 
  'usuario_que_baixa_grupo_nome': 'grupoDoUsuarioQueBaixa', 
  'usuario_que_baixa_ativo': 'usuarioQueBaixaEstaAtivo', 
  'representacao_baixada_id': 'arquivoId', 
  'representacao_baixada_uuid': 'arquivoUuid', 
  'documento_representado_id': 'documentoId', 
  'documento_representado_uuid': 'documentoUuid', 
  'hash': 'hash', 
  'tamanho_em_bytes': 'tamanhoEmBytes', 
  'data_referencia_dia': 'dataRefDia', 
  'data_referencia_mes': 'dataRefMes', 
  'data_referencia_ano': 'dataRefAno', 
  'data_vencimento': 'dataVencimento', 
  'numero_versao': 'numeroVersao', 
  'extensao': 'extensao', 
  'tipo_de_documento_que_tipifica_id': 'tipoDeDocumentoId', 
  'tipo_de_documento_que_tipifica_nome': 'tipoDeDocumento', 
  'tipo_de_documento_que_tipifica_frequencia': 'frequencia', 
  'tema_que_etiqueta_id': 'temaId', 
  'tema_que_etiqueta_nome': 'tema', 
  'descritivo_que_descreve_id': 'descritivoId', 
  'descritivo_que_descreve': 'descritivo', 
  'entidade_referida_id': 'entidadeId', 
  'entidade_referida_nome': 'entidadeNomeCompleto', 
  'entidade_referida_apelido': 'entidadeApelido', 
  'usuario_que_sobe_id': 'usuarioQueSobeId', 
  'usuario_que_sobe_nome': 'usuarioQueSobe', 
  'data_upload': 'dataUpload', 
  'identificacao_lote': 'identificacaoLoteSequencial', 
  'identificacao_lote_md5sum': 'identificacaoLoteMd5Sum', 
  'nome_do_arquivo': 'nomeDoArquivo', 
}

const buscarLogsDeDownload = (limite, dataInicio, dataFim) => async (dispatch, getState) => {
  dispatch({ 
    type: (BUSCAR_LOGS_DE_DOWNLOAD_LOADING) 
  });
  try {
    const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
    const response = await axios.get(
      `/api/administradores/downloads?limite=${limite}` + 
      (dataInicio ? `&dataInicio=${dataInicio}` : "") + 
      (dataFim ? `&dataFim=${dataFim}` : ""), 
      options); 
    const logsDeDownload = mapApiDataArray(response.data, LogsDeDownloadApiDataMapping);    
    dispatch({
      type: (BUSCAR_LOGS_DE_DOWNLOAD_SUCCESS),
      payload: {logsDeDownload: logsDeDownload},
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    dispatch({
      type: (BUSCAR_LOGS_DE_DOWNLOAD_FAIL),
      payload: {error: encontrarMensagemDeErro(err)},
    });
  }
};

const buscarLogsDeAtividade = (limite, dataInicio, dataFim) => async (dispatch, getState) => {
  dispatch({ 
    type: (BUSCAR_LOGS_DE_ATIVIDADE_LOADING) 
  });
  try {
    const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
    const response = await axios.get(`/api/administradores/registros?limite=${limite}` + 
      (dataInicio ? `&dataInicio=${dataInicio}` : "") + 
      (dataFim ? `&dataFim=${dataFim}` : ""), 
      options); 
    const logsDeAtividade = mapApiDataArray(response.data, LogsDeAtividadeApiDataMapping);    
    dispatch({
      type: (BUSCAR_LOGS_DE_ATIVIDADE_SUCCESS),
      payload: {logsDeAtividade: logsDeAtividade},
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    dispatch({
      type: (BUSCAR_LOGS_DE_ATIVIDADE_FAIL),
      payload: {error: encontrarMensagemDeErro(err)},
    });
  }
};


export {  
    buscarLogsDeAtividade,
    buscarLogsDeDownload
}  
