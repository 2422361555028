import {
    BUSCAR_DESCRITIVOS_LOADING, BUSCAR_DESCRITIVOS_SUCCESS, BUSCAR_DESCRITIVOS_FAIL,
} from '../actionTypes';


const initialState = {
    descritivos: [],
    isLoading: false,
    error: null,
};


const descritivos = (state = initialState, { type, payload }) => {
    switch (type) {
        case BUSCAR_DESCRITIVOS_LOADING:            
            return {
                ...state,
                isLoading: true,
                error: null,
            }                        
        case BUSCAR_DESCRITIVOS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                descritivos: payload.descritivos
            }                   
        case BUSCAR_DESCRITIVOS_FAIL:
            return {
                ...state,
                isLoading: false,
                descritivos: [], 
                error: payload.error                        
            }          
        default:
            return state;
    }
}

export default descritivos