import axios from 'axios';
import {attachTokenToHeaders, mapApiDataArray, handleErrorForAllActions} from '../actionFunctions';

import {
  BUSCAR_REGRAS_DE_NOTIFICACAO_LOADING, 
  BUSCAR_REGRAS_DE_NOTIFICACAO_SUCCESS, 
  BUSCAR_REGRAS_DE_NOTIFICACAO_FAIL,
  CRIAR_REGRAS_DE_NOTIFICACAO_LOADING, 
  CRIAR_REGRAS_DE_NOTIFICACAO_SUCCESS, 
  CRIAR_REGRAS_DE_NOTIFICACAO_FAIL,
  REMOVER_REGRAS_DE_NOTIFICACAO_LOADING, 
  REMOVER_REGRAS_DE_NOTIFICACAO_SUCCESS, 
  REMOVER_REGRAS_DE_NOTIFICACAO_FAIL,  
} from '../actionTypes';


const apiDataMapping = {
  "tipo": "tipo",
  "nome": "nome",
  "usuario_que_favorita_id": "usuarioId",
  "usuario_que_favorita_nome": "usuario",
  "tipo_de_documento_favoritado_id": "tipoDeDocumentoId",
  "tipo_de_documento_favoritado_nome": "tipoDeDocumento",
  "entidade_favoritada_id": "entidadeId",
  "entidade_favoritada_apelido": "entidade"
};


const editarRegraDeNotificacao = ({
  nomeDaRegraOld, nomeDaRegraNew, entidades, tiposDeDocumento
}) => async (dispatch, getState) => {
  //TODO: analisar se esta action precisa de refatoração!!!
  dispatch({ 
    type: (REMOVER_REGRAS_DE_NOTIFICACAO_LOADING) 
  });
  try {
    await removerRegra(nomeDaRegraOld, getState);
    dispatch({
      type: (REMOVER_REGRAS_DE_NOTIFICACAO_SUCCESS)
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    dispatch({
      type: (REMOVER_REGRAS_DE_NOTIFICACAO_FAIL),
      payload: {error: err}
    });
  }  
  dispatch({ 
    type: (CRIAR_REGRAS_DE_NOTIFICACAO_LOADING) 
  });
  try {
    await criarRegra({nomeDaRegra: nomeDaRegraNew, entidades, tiposDeDocumento, getState})
    dispatch({
      type: (CRIAR_REGRAS_DE_NOTIFICACAO_SUCCESS)
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    dispatch({
      type: (CRIAR_REGRAS_DE_NOTIFICACAO_FAIL),
      payload: {error: err}
    });
  }
}

const criarRegraDeNotificacao = ({
  nomeDaRegra, entidades, tiposDeDocumento
}) => async (dispatch, getState) => {
  dispatch({ 
    type: (CRIAR_REGRAS_DE_NOTIFICACAO_LOADING) 
  });
  try {
    await criarRegra({nomeDaRegra, entidades, tiposDeDocumento, getState});
    dispatch({
      type: (CRIAR_REGRAS_DE_NOTIFICACAO_SUCCESS)
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    dispatch({
      type: (CRIAR_REGRAS_DE_NOTIFICACAO_FAIL),
      payload: {error: err}
    });
  }
}


const removerRegraDeNotificacao = (nomeDaRegra) => async (dispatch, getState) => {
  dispatch({ 
    type: (REMOVER_REGRAS_DE_NOTIFICACAO_LOADING) 
  });
  try {
    await removerRegra(nomeDaRegra, getState);
    dispatch({
      type: (REMOVER_REGRAS_DE_NOTIFICACAO_SUCCESS)
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    dispatch({
      type: (REMOVER_REGRAS_DE_NOTIFICACAO_FAIL),
      payload: {error: err}
    });
  }
}

async function criarRegra({
  nomeDaRegra, entidades, tiposDeDocumento, getState
}){
    //url
    const url = `api/usuarios/notificacoes/`;
    //headers
    const options = attachTokenToHeaders(getState, 
      {'Accept': 'application/json'}
    );
    //params
    const params = new URLSearchParams(); //TODO: ajustar esta parte aqui!!
    params.append('nome', nomeDaRegra);
    entidades.map(entidade => 
      params.append('listaDeEntidades', entidade)
    );
    tiposDeDocumento.map(tipoDeDocumento => 
      params.append('listaDeTiposDeDocumento', tipoDeDocumento)
    );    
    //response
    const response = await axios.post(url, params, options); 
    //return 
    return response
}

async function removerRegra(nomeDaRegra, getState) {
    const url = `api/usuarios/notificacoes/${nomeDaRegra}`;
    const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
    const response = await axios.delete(url, options); 
    return response
}

const buscarRegrasDeNotificacao = () => async (dispatch, getState) => {
  dispatch({ 
    type: (BUSCAR_REGRAS_DE_NOTIFICACAO_LOADING) 
  });
  try {
    const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
    const response = await axios.get('api/usuarios/notificacoes', options); 
    const regrasDeNotificacao  = mapApiDataArray(response.data, apiDataMapping);

    dispatch({
      type: (BUSCAR_REGRAS_DE_NOTIFICACAO_SUCCESS),
      payload: {regrasDeNotificacao: regrasDeNotificacao},
    });

  } catch (err) {
    handleErrorForAllActions(err, getState); 
    dispatch({
      type: (BUSCAR_REGRAS_DE_NOTIFICACAO_FAIL),
      payload: {error: err},
    });
  }
};

export {  
  buscarRegrasDeNotificacao,
  criarRegraDeNotificacao,
  removerRegraDeNotificacao,
  editarRegraDeNotificacao
}  
