import React from 'react';
import './Loader.css'

import CircularProgress from '@material-ui/core/CircularProgress';


const Loader = ({
    mensagem = "Carregando...",
    fullPage = false,
}) => {
    return (
        <> 
            <div id="loader" className={(fullPage ? "fullPage": null)}>
                <CircularProgress/> 
                &nbsp; &nbsp;
                <p className="lunar textoDescritivo"> {mensagem} </p>
            </div>
        </>
    );
};

export default Loader;  