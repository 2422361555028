import {
  LOGIN_LOADING,
  LOGIN_SUCCESS, 
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_LOADING,
  ME_LOADING,
  ME_SUCCESS,
  ME_FAIL,
} from '../actionTypes';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  isLoading: false,
  me: null,
  error: null,
  loginError: null,
  appLoaded: false,
  isGoogleAuthLoading: false
};

const auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case ME_LOADING:
      return {
        ...state,
        isLoading: true,
        appLoaded: false,
        error: null,
      };
    case LOGIN_LOADING:
      return {
        ...state,
        isLoading: true,
        isGoogleAuthLoading: true,
        loginError: null,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        isGoogleAuthLoading: false,
        token: payload.token,
        me: payload.me,
        loginError: null,
      };
    case ME_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        me: payload.me,
        error: null,
        appLoaded: true,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isGoogleAuthLoading: false,
        loginError: payload.error
      }      
    case ME_FAIL:
      localStorage.removeItem('token');
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        me: null,
        error: payload.error,
        appLoaded: true,
      };
    case LOGOUT_LOADING:
      return {
        ...state,
        isGoogleAuthLoading: true,
        loginError: null
      }      
    case LOGOUT_SUCCESS:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        me: null,
        isAuthenticated: false,
        isLoading: false,
        error: null, //payload message ovde i razdvoj logout i fail
        loginError: null,
        isGoogleAuthLoading: true 
          //o GoogleAuthLoading não vira false porque o ideal é ficar mostrando tela 
            //de loading até o sistema finalizar completamente o redirecionamento 
            //para a página de logout do Google. Que é quando os reducers voltam para o 
            // state inicial e só aí isGoogleAuthLoading fica false    
              //TODO: melhorar esta descrição acima    
      };
    default:
      return state;
  }
}

export default auth