import './TabelaParametros.css'

import MUIDataTable from "mui-datatables";

import {isIsoDate} from '../../../../support/publicFunctions'
import {format} from 'date-fns'

import textLabels from "../../../../support/MuiDatatableSupport/TextLabels"
import onDownload from "../../../../support/MuiDatatableSupport/onDownload"
import {ExcelDownloadIcon as DownloadIcon} from '../../../../components/Icons/Icons'
import {formatarCnpj} from "../../../../support/publicFunctions"

import Chip from '@material-ui/core/Chip';

import ToolbarSelectParametro from './components/ToolbarSelectParametro'

//...
import LinearProgress from '@material-ui/core/LinearProgress';



const TabelaDeParametros = ({
    parametros, tipoDeParametro, colunas, configuracoes, handleClickOpenDialog,
    setIsEdit, setDadosIniciaisDaEdicao, desativados
}) => {

    const textoDeLoading = "Carregando..."

    function getColunasDaTabela(){
        const colunasDaTabela = colunas.map(coluna => {return {
            label: coluna, 
            name: coluna, 
            options: {
                display: ((configuracoes.colunas?.displayExcluded
                        ?.indexOf(coluna)> -1)
                    ) ? (
                        "excluded"
                    ) : ((
                            (configuracoes.colunas?.exibir?.indexOf(coluna) >-1) 
                            || (!configuracoes.colunas?.exibir?.length)
                        ) ? (
                            true
                        ) : (
                            false
                        )
                    ),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    //Primeiro verifica se é loading (e substitui pelo efeito de loading)
                    if(value === textoDeLoading){
                        return <LinearProgress />
                    //Caso não prossegue com os dados normalmente
                    } else {
                        //verifica se é booleano
                        if(configuracoes.colunas?.formatarComoBooleano?.indexOf(coluna) > -1){
                            if(value === 1){return "Sim"}
                            if(value === 0){return "Não"}
                            return value;
                        }
                        //verifica se é cnpj
                        if(configuracoes.colunas?.formatarComoCnpj?.indexOf(coluna) > -1){
                            return formatarCnpj(value);
                        }
                        //verifica se é a data 
                        if(isIsoDate(value)){
                            return format(new Date(value), 'yyyy-MM-dd HH:mm:ss')
                        }; 
                        //verifica se é a array
                        if(Array.isArray(value)){ //TODO: ?Refatorar abaixo? (?ver se a linha abaixo ainda precisa de refatoração?):
                            const labelName = (configuracoes.colunas?.formatarComoArray?.find(o => o?.coluna === coluna))?.label;
                            return value?.map((val, index) => {
                                const label = val?.[labelName];
                                return <Chip className={"chip publico"} label={label} key={index} />;
                            })
                        }
                        //verifica valor em branco
                        if(!value){
                            return <span className={"valorEmBranco"}>∅</span>;
                        }
                        //retorno default
                        return value
                    }
                },
                setCellProps: (value) => (
                    desativados ? {style: {
                        textDecoration: "line-through",
                        color: "gray"
                    }} 
                        : null
                )
            }
        }})
        return colunasDaTabela; 
    }

    function getDadosDaTabela(){
        let dadosDaTabela = parametros.map((parametro)=>{
            //Primeiro verifica se é loading (e preenche tudo com texto de loading)
            if (parametro?.isLoading) {
                const valuesWithLoading = Array(colunas.length).fill(textoDeLoading);
                return valuesWithLoading;
            //Caso não retorna os dados normalmente
            } else {
                const values = Object.values(parametro || {});
                return values;
            }
        })        
        return (dadosDaTabela);
    }

    function buscaGeneralizada(query, row, columns) {
        // Faz uma busca por texto olhando também para dentro de arrays e objetos.
        // Isso permite, por exemplo, fazer uma busca por usuário na página de grupos
        // de acesso.

        //vide https://web.archive.org/web/20210317173528/https://stackoverflow.
        //com/questions/990904/remove-accents-diacritics-in-a-string-in-
        //javascript/37511463
        const removerAcentos = texto => texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        // A checagem de igualdade entre strings será feita uma vez removidos
        // acentos e espaços, e feita a conversão para lowercase
        const formatarStringParaComparacao = (texto) => {
            return removerAcentos(texto).toLowerCase().replace(/ /g,"");
        };

        // Busca uma string dentro de um texto
        const buscarQuery = (query, texto) => {
            return formatarStringParaComparacao(texto).includes(formatarStringParaComparacao(query));
        };

        // Gera o texto a ser buscado
        const gerarTexto = lista => {
            let textoFinal = "";
            for (var i in lista) {
                if (columns[i].display === "false") {
                    // Não dá match caso a coluna esteja ocultada
                } else if (Array.isArray(lista[i])) {
                    // Se for um array, converte os valores dos objetos pra texto
                    // e dá join nos valores do array
                    textoFinal += lista[i].map(y => {
                        if (typeof(y) === "object") {
                            let clonedObject = {...y}
                            try {
                                // A prop. id nunca é exibida nas colunas, então
                                // podemos excluí-la da busca
                                delete clonedObject.id;
                            } catch(error) {
                                console.log(error)
                            };
                            return Object.values(clonedObject).join("");
                        } else {
                            return y;
                        };
                    }).join("");
                } else {
                    textoFinal += lista[i];
                };
            };
            return textoFinal;
        };

        return buscarQuery(query, gerarTexto(row));
    };

    function getOpcoesDaTabela(){
        const opcoesDaTabela = {
            textLabels: textLabels,
            onDownload: onDownload,
            elevation: 2,
            filterType: "multiselect",
            responsive: "standard",  
            selectableRows: (
                configuracoes?.metodos?.podeSerEditado 
                    || (desativados && configuracoes?.metodos?.podeSerReativado)
                    || (!desativados && (
                        configuracoes?.metodos?.podeSerRemovido
                        || configuracoes?.metodos?.podeSerDesativado
                        || configuracoes?.metodos?.podeRetirarAdministrador
                        || configuracoes?.metodos?.podeDesativarVersaoMaisRecenteDeDocumento
                    ))
            ) ? 
                'single' : 
                'none',
            sort: false, //nota: desabilitar esta propriedade é uma forma simples de resolver diversos bugs e inconvinientes
            rowsPerPageOptions: [3, 10, 15, 100],
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <ToolbarSelectParametro 
                    selectedRows={selectedRows} 
                    displayData={displayData} 
                    setSelectedRows={setSelectedRows} 
                    colunas={getColunasDaTabela()}
                    tipoDeParametro={tipoDeParametro}
                    parametros={parametros}
                    handleClickOpenDialog={handleClickOpenDialog}
                    setIsEdit={setIsEdit}
                    setDadosIniciaisDaEdicao={setDadosIniciaisDaEdicao}
                    configuracoes={configuracoes}
                    desativados={desativados}
                />
            ),
            setRowProps: (row, dataIndex, rowIndex) => {            
                //Em caso de erro alterar "style" da linha com erro conforme abaixo:
                const CorAmarelaDoAlertDoMaterialUiComSeverityWarning = "#FFF4E5"
                if(parametros[dataIndex]?.error){
                    return {
                        style: { 
                            backgroundColor: CorAmarelaDoAlertDoMaterialUiComSeverityWarning
                        },
                      };
                }
                return false;
            },
            customSearch: buscaGeneralizada,
        }               
        return opcoesDaTabela;
    }


    function getTituloDaTabela(){
        const tituloDaTabela =  (configuracoes.titulo?.noPlural + (desativados ? 
            " Desativados (as)" : "")) 
        /*a tabela só exibe título quando a página principal possui um título  
            customizado diferente do seu título padrão*/
        return (configuracoes.titulo?.daPaginaPrincipal ? tituloDaTabela : null)
    }

    return(
        <div id="tabelaDeParametros">
            <MUIDataTable
                title={getTituloDaTabela()}
                columns={getColunasDaTabela()}
                data={getDadosDaTabela()}
                options={getOpcoesDaTabela()}
                components={{icons: {DownloadIcon}}}
            />        
        </div>
    )
}

export default TabelaDeParametros;
