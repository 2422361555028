import axios from 'axios';
import {attachTokenToHeaders, mapApiData, mapApiDataArray, handleErrorForAllActions} from '../actionFunctions';
import {openInNewTab} from '../../support/publicFunctions'

import {
  BUSCAR_ARQUIVO_LOADING, BUSCAR_ARQUIVO_SUCCESS, BUSCAR_ARQUIVO_FAIL,
  BUSCAR_ARQUIVOS_LOADING, BUSCAR_ARQUIVOS_SUCCESS, BUSCAR_ARQUIVOS_FAIL,
  BUSCAR_ARQUIVOS_E_CATEGORIZAR_LOADING, BUSCAR_ARQUIVOS_E_CATEGORIZAR_SUCCESS, BUSCAR_ARQUIVOS_E_CATEGORIZAR_FAIL,
  BAIXAR_ARQUIVOS_LOADING, BAIXAR_ARQUIVOS_SUCCESS, BAIXAR_ARQUIVOS_FAIL
} from '../actionTypes';


const apiDataMapping = {
  'ativa': 'ativo',
  'data_de_upload': 'dataDeUpload',
  'data_vencimento' : 'dataDeVencimento',
  'data_referencia_ano': 'dataDeReferenciaAno',
  'data_referencia_dia': 'dataDeReferenciaDia',
  'data_referencia_mes': 'dataDeReferenciaMes',
  'descritivo_que_descreve': 'descritivo',
  'descritivo_que_descreve_id': 'descritivoId',
  'documento_representado_id': 'documentoId',
  'documento_representado_uuid': 'documentoUuid',
  'entidade_referida_apelido': 'entidade',
  'entidade_referida_id': 'entidadeId',
  'entidade_referida_nome': 'entidadeNomeCompleto',
  'extensao': 'extensao',
  'hash': 'hash',
  'identificacao_lote': 'identificacaoDoLote',
  'identificacao_lote_md5sum' : 'identificacaoDoLoteMd5sum',
  'nome_do_arquivo': 'nome',
  'numero_downloads': 'numeroDeDownloads',
  'numero_versao': 'numeroDaVersao',
  'representacao_id': 'id',
  'representacao_uuid': 'uuid',
  'tamanho_em_bytes' : 'tamanhoEmBytes',
  'tema_que_etiqueta_id': 'temaId',
  'tema_que_etiqueta_nome': 'tema',
  'tipo_de_documento_que_tipifica_id': 'tipoDeDocumentoId',
  'tipo_de_documento_que_tipifica_nome': 'tipoDeDocumento',
  "tipo_de_documento_que_tipifica_frequencia": 'frequenciaDotipoDeDocumento',
  'usuario_que_sobe_id': 'usuarioQueCarregouId',
  'usuario_que_sobe_nome': 'usuarioQueCarregou',  
};  



const buscarArquivos = ({
    limite = 0, 
    ordernarPorRecentes = false, 
    apenasVersoesAtuais = false, 
    apenasFavoritos = false, 
    dataInicio = "",
    dataFim = "",
    entidades = [],
    temas = [],
    tiposDeDocumento = [],
    keyParaCategorizacao = ""
}) => async (dispatch, getState) => {
  
    //-------------------------------------------------------------------------------------
    // Parâmetro              //Tipagem       //Tipo          //Descrição
    //-------------------------------------------------------------------------------------
    // limite                 int               limite          indicar o limite de arquivos a ser recebido (usar 0 p/ ausência de limite)
    // ordernarPorRecentes    boolean           ordenação       ordenar do mais recentes p/ o mais antigos    
    // apenasVersoesAtuais    boolen            filtro          retorna apenas a versão mais atual dos documentos
    // apenasFavoritos        boolean           filtro          obter apenas os arquivos favoritos
    // dataInicio             string            filtro          string no formato "YYYY-MM-DD"
    // dataFim                string            filtro          string no formato "YYYY-MM-DD"
    // entidades              array de string   filtro          //TODO terminar a descrição aqui
    // temas                  array de string   filtro          //TODO terminar a descrição aqui
    // tiposDeDocumento       array de string   filtro          //TODO terminar a descrição aqui
    // keyParaCategorizacao   string            identificador   passar esse parâmetro apenas caso queira categorizar arquivos por object key e salvá-los de forma separada no reducer (obs: a key deve ser um string no padrão "camelCase", exemplo "arquivosFavoritos")
    //-------------------------------------------------------------------------------------    
        
    dispatch({ 
      type: (keyParaCategorizacao ? BUSCAR_ARQUIVOS_E_CATEGORIZAR_LOADING: BUSCAR_ARQUIVOS_LOADING), 
      payload: {key:keyParaCategorizacao} 
    });

    try {
      const url = ('/api/representacoes?'+
        `${limite? `limite=${limite}&` : ''}`+
        `${ordernarPorRecentes? `recentes=${ordernarPorRecentes}&` : ''}`+
        `${apenasVersoesAtuais? `atual=${apenasVersoesAtuais}&` : ''}`+
        `${apenasFavoritos? `favoritos=${apenasFavoritos}&` : ''}` + 
        `${dataInicio? `dataInicio=${dataInicio}&` : ''}` + 
        `${dataFim? `dataFim=${dataFim}&` : ''}` +
        `${entidades.length? (entidades.map(entidade => `entidade=${entidade}&`).join('')) : ''}` +
        `${temas.length? (temas.map(tema => `tema=${tema}&`).join('')) : ''}` +
        `${tiposDeDocumento.length? (tiposDeDocumento.map(tipoDeDocumento => `relatorio=${tipoDeDocumento}&`).join('')) : ''}` +
        ``).slice(0, -1)
      console.log("url", url);

      const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
      const response = await axios.get(url, options); 
      const mappedData = mapApiDataArray(response.data, apiDataMapping);
      
      dispatch({
        type: (keyParaCategorizacao ? BUSCAR_ARQUIVOS_E_CATEGORIZAR_SUCCESS : BUSCAR_ARQUIVOS_SUCCESS),
        payload: {key:keyParaCategorizacao, arquivos: mappedData},
      });

    } catch (err) {
      handleErrorForAllActions(err, getState); 
      dispatch({
        type: (keyParaCategorizacao ? BUSCAR_ARQUIVOS_E_CATEGORIZAR_FAIL : BUSCAR_ARQUIVOS_FAIL ),
        payload: {key:keyParaCategorizacao,  error: err.message},
      });
    }
};


const buscarArquivosDeUmDocumento = (documentoUuid, numeroDaVersao) => async (dispatch, getState) => {

  //-------------------------------------------------------------------------------------
  // Parâmetro          //Tipagem                   //Tipo          //Descrição
  //-------------------------------------------------------------------------------------
  // documentoUuid      string (obrigatório)    -   filtro      -   obter os arquivos de um determinado documento    
  // numeroDaVersao     int (opcional)          -   filtro      -   obter os arquivos de uma determinada versão
  //-------------------------------------------------------------------------------------
  
  dispatch({ 
    type: (BUSCAR_ARQUIVOS_LOADING) 
  });
  try {
    const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
    const response = await axios.get(
      `/api/documentos/${documentoUuid}/representacoes${numeroDaVersao? "?versao=" + numeroDaVersao: ""}`, options); 
    const mappedData = mapApiDataArray(response.data, apiDataMapping);

    dispatch({
      type: (BUSCAR_ARQUIVOS_SUCCESS),
      payload: {arquivos: mappedData},
    });

  } catch (err) {
    handleErrorForAllActions(err, getState); 
    dispatch({
      type: (BUSCAR_ARQUIVOS_FAIL),
      payload: { error: err.message},
    });
  }
};


const buscarArquivosDeUmLote = (numeroLoteHasheado, favoritos) => async (dispatch, getState) => {
  //-------------------------------------------------------------------------------------
  // Parâmetro            //Tipagem                   //Tipo          //Descrição
  //-------------------------------------------------------------------------------------
  // numeroLoteHasheado   string (obrigatório)    -   filtro      -   obter os arquivos de um determinado lote    
  // favoritos            Boolean (opcional)      -   filtro      -   se true, retorna apenas arquivos favoritos
  //-------------------------------------------------------------------------------------
  
  dispatch({ 
    type: (BUSCAR_ARQUIVOS_LOADING) 
  });
  try {
    const url = `/api/documentos/lotes/${numeroLoteHasheado}?favoritos=${favoritos}`
    const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
    const response = await axios.get(url, options); 
    const mappedData = mapApiDataArray(response.data, apiDataMapping);

    dispatch({
      type: (BUSCAR_ARQUIVOS_SUCCESS),
      payload: {arquivos: mappedData},
    });

  } catch (err) {
    handleErrorForAllActions(err, getState); 
    dispatch({
      type: (BUSCAR_ARQUIVOS_FAIL),
      payload: { error: err.message},
    });
  }
};



const buscarArquivo = (uuid) => async (dispatch, getState) => { //TODO: ver a viabilidade (?e se a melhor forma?) de colocar o history ou não
    //TODO: ?apagar essa action aqui se ele não for mais utilizada?
    dispatch({ 
      type: BUSCAR_ARQUIVO_LOADING 
    });
    try {
      const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
      const response = await axios.get(`/api/representacoes/${uuid}`, options);     
      const mappedData = mapApiData(response.data, apiDataMapping)

      dispatch({
        type: BUSCAR_ARQUIVO_SUCCESS,
        payload: {arquivo: mappedData},
      });

    } catch (err) {
      handleErrorForAllActions(err, getState); 
      if (err?.response.status === 404) { //TODO: refatorar o lado servidor para passar um error 404 caso não encontre o id!
        // history.push('/nao-encontrado'); 
          //TODO: fazer essa parte aqui
      }
      dispatch({
        type: BUSCAR_ARQUIVO_FAIL,
        payload: { error: err?.response?.data.message || err.message },
      });
    }

};


//    Função que recebe por padrão um array de uuids e baixa os arquivos 
//    correspondentes
/*  
*     -Caso o array de uuids contenha um item será baixado apenas um arquivo
*     -Caso o array de uuids contenha mais de um item, serão baixados múltiplos 
*     arquivos, mas a função retornará um único arquivo em formato zip
*     -O retorno da função é um download de arquivo pelo próprio navegador
*
*     -Na situação de baixar apenas um arquivo, aceita parâmetro isInline=true,
*     p/ o Content-Disposition ser isInline (por padrão é attachment), indicando
*     que o arquivo pode ser mostrado dentro de um página web (funciona com pdf) 
*     em vez de ser baixado para o computador do usuário. Para múltiplos 
*     arquivosqualquer ou qualquer outra situação diferente desta manter o 
*     isInline = false.
*/
const baixarArquivos = ({
  uuids = [],
  isInline = false
}) => async (dispatch, getState) => { 

  
  dispatch({ 
    type: BAIXAR_ARQUIVOS_LOADING,
    payload: {isInline: isInline}
  });

  try {
    
    const baixarApenasUmArquivo = async () => {
      const uuid = uuids[0];
      const url = `/api/representacoes/${uuid}/?inline=${isInline}`
      const options = attachTokenToHeaders(getState, {
        'Accept': 'text/plain'
      });
      const response = await axios.get(url, options);     
      const urlDoArquivo = response.data;    
      await baixarArquivoViaUrl(urlDoArquivo, isInline);  
    }

    const baixarUmZipComMultiplosArquivos = async () => {
      const url = ('/api/representacoes?'+
        `${uuids.length? (uuids.map(uuid => `uuid=${uuid}&`).join('')) : ''}` +
        ``).slice(0, -1)      
      let options = attachTokenToHeaders(getState, {
          'Accept': 'application/zip', 
          'Request-Type': 'application/x-www-form-urlencoded'
      });
      options.responseType = 'blob' /*Unless you need the ability to write/edit 
      (using an ArrayBuffer), then Blob format is probably best.*/
      const response = await axios.get(url, options);
      const arquivosZipados = response.data;
      const fileName = 'documentos.zip' /* //TODO: usar o filename fornecido 
      pelo servidor e deixar este aqui apenas como fallback!!! */
      await baixarArquivoBinario(arquivosZipados, fileName);      
    }

    if (uuids.length === 1) {
      await baixarApenasUmArquivo();
    } else if (uuids.length > 1) {        
      await baixarUmZipComMultiplosArquivos();
    }

    dispatch({
      type: BAIXAR_ARQUIVOS_SUCCESS,
      payload: {isInline: isInline}
    });           
  
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    if (err?.response.status === 404) {
      //TODO: refatorar o lado servidor para passar um error 404 caso não encontre o id?
      //TODO: ver a viabilidade (?e se a melhor forma?) de colocar o history ou não
      // history.push('/nao-encontrado'); 
        //TODO: fazer essa parte aqui?
    }
    dispatch({
      type: BAIXAR_ARQUIVOS_FAIL,
      payload: { 
        error: err?.response?.data.message || err.message, 
        isInline: isInline
      },
    });
  }

};




const baixarArquivoViaUrl = async (urlDoArquivo, inNewTab = false) => {
  if(inNewTab){
    openInNewTab(urlDoArquivo);
  } else {
    window.location.href = urlDoArquivo;
  }
}


const baixarArquivoBinario = (arquivoBinario, fileName) => {
  //Referência: https://stackoverflow.com/questions/49040247/download-binary-file-with-axios/50220546#50220546
      //TODO: ver se essa parte aqui precisa de refatoção ou realmente esta seria a melhor forma de escrever essa função (referência dela no link acima)
  const url = window.URL.createObjectURL(new Blob([arquivoBinario]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); //or any other extension 
  document.body.appendChild(link);
  link.click();                   
}


export {  
  buscarArquivos, 
  buscarArquivosDeUmDocumento, 
  buscarArquivosDeUmLote,
  buscarArquivo, 
  baixarArquivos,
}
