
import {DropzoneArea} from 'material-ui-dropzone'
import './Step1.css'

import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import {sortArrayOfObjects} from '../../../../support/publicFunctions'

import { connect } from "react-redux";

const UploadDropzone = ({
    avancarEtapa, handleCancelar, setFiles, files, auth
}) => {
    
    const handleChange = (files) => {
      setFiles(files);
    }

    const handleAvancar = () => {
        //Verifica se o usuário pode inserir documentos
        if(auth.me.insere !== 1){
            alert(
                "Seu usuário não pode inserir documentos. " + 
                "Se considerar necessário alterar esta condição, " + 
                "entrar em contato com o Administrador do Sistema."
            );
            return false;            
        }        
        //Verifica se há arquivo inserido
        if(!files.length){
            alert("Você precisa inserir pelo menos um arquivo para avançar");
            return false;
        }
        //ordena files por "name"
        const sortedFiles = files.sort(sortArrayOfObjects("name"))
        setFiles([...sortedFiles]); /*spread operators força update do state*/
        //return
        return avancarEtapa(); 
    };    
    
    return (
        <>
            <Paper elevation={2} id="uploadDropzone">
                <DropzoneArea
                    dropzoneText = "Arrastar e soltar os arquivos aqui ou clicar para inseri-los"
                    previewText="Arquivos Selecionados"
                    filesLimit = "50"  
                    onChange={handleChange}
                    showPreviews={false}
                    showPreviewsInDropzone={true}
                    useChipsForPreview
                    maxFileSize={64000000}//64MB de limite p/ upload de arquivos
                />
            </Paper>
            <div className="stepperButtons">
                <Button className="backButton" onClick={handleCancelar}
                    disabled={files.length === 0} 
                >
                    Cancelar
                </Button>
                <Button variant="contained" color="primary" onClick={handleAvancar}>
                    Avançar
                </Button>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(UploadDropzone)

 
