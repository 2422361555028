import {
    BUSCAR_REGRAS_DE_NOTIFICACAO_LOADING, 
    BUSCAR_REGRAS_DE_NOTIFICACAO_SUCCESS, 
    BUSCAR_REGRAS_DE_NOTIFICACAO_FAIL,
} from '../actionTypes';


const initialState = {
    regrasDeNotificacao: [],
    isLoading: false,
    error: null,
};


const regrasDeNotificacao = (state = initialState, { type, payload }) => {
    switch (type) {
        case BUSCAR_REGRAS_DE_NOTIFICACAO_LOADING:            
            return {
                ...state,
                isLoading: true,
                error: null,
            }                        
        case BUSCAR_REGRAS_DE_NOTIFICACAO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                regrasDeNotificacao: payload.regrasDeNotificacao
            }                   
        case BUSCAR_REGRAS_DE_NOTIFICACAO_FAIL:
            return {
                ...state,
                isLoading: false,
                regrasDeNotificacao: [], 
                error: payload.error                        
            }          
        default:
            return state;
    }
}

export default regrasDeNotificacao