import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {connect} from "react-redux";
import {buscarOptions} from '../../../../redux/actionsAdmin/optionsActions';
import {useEffect} from 'react';
import Loader from '../../../../components/Loader/Loader';
import LunarDialogTitle from 
  '../../../../components/LunarDialogTitle/LunarDialogTitle';
import LunarDialogActions from 
  '../../../../components/LunarDialogActions/LunarDialogActions'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";

import {concederOuRetirarAdministrador} from 
  '../../../../redux/actionsAdmin/administradoresActions'



function DialogAdministradores({open, setOpen, buscarOptions, 
  options, optionsIsLoading, concederOuRetirarAdministrador, administradores}) {
    
  const usuarioOptions = options['USUARIOS']?.map (o => {
    //desativar opção de seleção se usuário for administrador
    const isAdmin = (administradores?.find(a => a.id === o.value)? true: false);
    return { ...o, disabled: isAdmin}
  });

  const handleClose = () => {
    setOpen(null);
    setValue(null);
  };

  useEffect(()=>{
    buscarOptions({tipos: ["USUARIOS"]});
  }, [buscarOptions, open]);


  const [value, setValue] = React.useState(options[0]);
  
  useEffect(()=>{console.log("value", value)},[value]) 

  const handleSubmit = (event) => {
    event.preventDefault();
    concederOuRetirarAdministrador({id: value?.value, concederAdm: true})
    handleClose();
  }  

  return (
    <Dialog
      open={open === "DialogAdministradores"}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      disableBackdropClick      
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {(optionsIsLoading) ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <LunarDialogTitle onClose={handleClose} >
            Conceder Novo Administrador
          </LunarDialogTitle>          
          <DialogContent dividers>
            <Autocomplete
              options={usuarioOptions}
              getOptionLabel={(option) => option.label}
              groupBy={(option) => option.group}
              getOptionDisabled={option => option.disabled}
              value={value}
              onChange={(event, newValue) => {setValue(newValue)}}              
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Usuário" 
                  placeholder="Usuário"
                  variant="outlined" 
                  fullWidth
                  margin="dense"
                  required
                />
              )}
            />
          </DialogContent>      
          <LunarDialogActions 
            onCancel={handleClose}
            submitButtonLabel = {"Conceder"}
          />  
        </form>
      )}
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  options: state.adminOptions.options,
  optionsIsLoading: state.adminOptions.isLoading,
});

export default connect(
  mapStateToProps, {buscarOptions, concederOuRetirarAdministrador}
)(DialogAdministradores);