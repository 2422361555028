import React from 'react';
import DialogSubParametros from './components/DialogSubParametros/DialogSubParametros'
import Divider from "@material-ui/core/Divider";
import FileCopyIcon from '@material-ui/icons/FileCopy';
//List Items
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//...
import MenuLunar from '../../../../components/MenuLunar/MenuLunar'
import MenuItemLunar from '../../../../components/MenuItemLunar/MenuItemLunar'
import MenuButtonLunar from 
  '../../../../components/MenuButtonLunar/MenuButtonLunar'


export default function MenuSubParametros({tipos, children}) {
  
  //States
  const [tipo, setTipo] = React.useState(null);
  const menuAnchorElState = React.useState(null);
  const dialogOpenState = React.useState(false);

  
  //Apoio
  const menu = {
    anchorEl: menuAnchorElState[0],
    setAnchorEl: menuAnchorElState[1],
    handleOpen: (event) => {menu.setAnchorEl(event.currentTarget)},
    handleClose: () => {menu.setAnchorEl(null)},
  }

  const dialog = {
    open: dialogOpenState[0],
    setOpen: dialogOpenState[1],
    handleOpen: ({tipo}) => {
      setTipo(tipo);
      menu.handleClose();
      dialog.setOpen(true);
    },
    handleClose: () => {
      window.location.reload();
      dialog.setOpen(false);
    }    
  }


  return (
    <>
      <MenuButtonLunar onClick={menu.handleOpen}> 
        {children}
      </MenuButtonLunar>
      <MenuLunar
        anchorEl={menu.anchorEl}
        open={Boolean(menu.anchorEl)}
        onClose={menu.handleClose}
      >
        <DialogSubParametros 
          open={dialog.open} 
          handleClose={dialog.handleClose} 
          tipo={tipo}
        />
        <MenuItemLunar>
          Editar Sub-Parâmetros
        </MenuItemLunar>
        <Divider light />
        {tipos.map(t=>
          <>
            <MenuItemLunar onClick={()=>{dialog.handleOpen({tipo: t})}}>
              <ListItemIcon>
                  <FileCopyIcon />
              </ListItemIcon>
              <ListItemText primary={t}/>                    
            </MenuItemLunar>
            <Divider light />
          </>
        )}
      </MenuLunar>
    </>
  );
}