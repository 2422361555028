import React from 'react';
import Layout from '../../layout/Layout'
import {connect} from "react-redux";

import { useEffect  } from 'react';

import {buscarDicionario} from '../../redux/actions/dicionarioActions'

import {buscarOptions} from '../../redux/actionsAdmin/optionsActions'
import InputMask from 'react-input-mask';

import TextField from "@material-ui/core/TextField";

import Input from '@material-ui/core/Input';


const PaginaTeste = ({buscarDicionario}) => {

    useEffect(()=>{
        buscarDicionario();
    },[])

    return (
        <Layout pageTitle={"Página Teste"}>
            <p>teste 1</p>
        </Layout>
    ) 
}

export default connect(null, {buscarDicionario}) (PaginaTeste);