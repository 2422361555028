import {
    BUSCAR_LOGS_DE_DOWNLOAD_LOADING, BUSCAR_LOGS_DE_DOWNLOAD_SUCCESS, BUSCAR_LOGS_DE_DOWNLOAD_FAIL,
} from '../actionTypesAdmin';


const initialState = {
    logsDeDownload: [],
    isLoading: false,
    error: null,
};


const logsDeDownload = (state = initialState, { type, payload }) => {
    switch (type) {
        case BUSCAR_LOGS_DE_DOWNLOAD_LOADING:            
            return {
                ...state,
                isLoading: true,
                error: null,
            }                        
        case BUSCAR_LOGS_DE_DOWNLOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                logsDeDownload: payload.logsDeDownload
            }                   
        case BUSCAR_LOGS_DE_DOWNLOAD_FAIL:
            return {
                ...state,
                isLoading: false,
                logsDeDownload: [], 
                error: payload.error                        
            }          
        default:
            return state;
    }
}

export default logsDeDownload