import MUIDataTable from "mui-datatables";
import textLabels from "../../support/MuiDatatableSupport/TextLabels"
import onDownload from "../../support/MuiDatatableSupport/onDownload"
import CustomToolbarSelect from "../../support/MuiDatatableSupport/CustomToolbarSelect"
import {openInNewTab} from "../../support/publicFunctions"

import { useHistory } from "react-router-dom";

import {ExcelDownloadIcon} from '../Icons/Icons'

import './MuiDatatable.css'

const MuiDatatable = ({
    title="",
    data=[],
    columns=[],
    options={}, 
    nomeDaColunaQueContemUuidDoArquivo,
    nomeDaColunaQueContemUuidDoDocumento,
    nomeDaColunaQueContemNumeroDaVersaoDoArquivo
}) => {

    let history = useHistory();

    let customToolbarSelectCallback = null;
    let onRowClickCallback = null;

    const definirCustomToolbarSelectCallback = () => {
        if(nomeDaColunaQueContemUuidDoArquivo){
          customToolbarSelectCallback= (selectedRows, displayData, setSelectedRows) => (
            <CustomToolbarSelect 
                selectedRows={selectedRows} 
                displayData={displayData} 
                setSelectedRows={setSelectedRows} 
                colunas={columns}
                nomeDaColunaQueContemUuidDoArquivo = {nomeDaColunaQueContemUuidDoArquivo}
            />
        )}
    }

    const definirOnCellClickCallback = () => {
        if(nomeDaColunaQueContemUuidDoDocumento && nomeDaColunaQueContemNumeroDaVersaoDoArquivo){
            onRowClickCallback = (colData, rowMeta, clickEvent) => { 
                const obterValorDaColuna = key =>  data[rowMeta.dataIndex][columns.findIndex(o => o.name===key)]        
                const documentoUuid = obterValorDaColuna(nomeDaColunaQueContemUuidDoDocumento);
                const numeroDaVersao = obterValorDaColuna(nomeDaColunaQueContemNumeroDaVersaoDoArquivo);
                sessionStorage.setItem("ultimaConsulta", window.location.href)
                if (clickEvent.ctrlKey) {
                    openInNewTab(`/documentos/${documentoUuid}/versoes/${numeroDaVersao}`);
                    return;
                };
                history.push(`/documentos/${documentoUuid}/versoes/${numeroDaVersao}`);

                //TODO: futuramente avaliar se realmente no caso abaixo será aberto uma nova página mesmo e apagar a linha abaixo
                    //history.push(`/documentos/${documentoUuid}/versoes/${numeroDaVersao}`); 

            } 
        }
    }

    const handleColumnDrag = (newColumnOrder, columnIndex, newPosition) => {
        // o item "ordemConsulta" é atualizado para refletir as mudanças de coluna,
        // enquanto que o item "ordemInicialConsulta" serve apenas para rastrear o progresso de
        // atualização das colunas, uma vez que o MUI Datatable dificulta esse processo!
        var ordemDasColunas = JSON.parse(localStorage.getItem("ordemConsulta")).ordemDasColunas;
        var ordemInicial = JSON.parse(localStorage.getItem("ordemInicialConsulta")).ordemDasColunas;
        var primeiraColuna = ordemInicial[columnIndex];
        var segundaColuna = ordemInicial[newPosition];
        var indiceAntigo = ordemDasColunas.findIndex(e => e.label === primeiraColuna.label);
        var indiceNovo = ordemDasColunas.findIndex(e => e.label === segundaColuna.label);
        var colunaMovida = ordemDasColunas.splice(indiceAntigo, 1)[0];
        ordemDasColunas.splice(indiceNovo, 0, colunaMovida);
        localStorage.setItem("ordemConsulta", JSON.stringify({ordemDasColunas}));
    };

    const handleColumnToggle = (changedColumn) => {
        // Guarda quais colunas devem ser visíveis para o usuário
        var ordemDasColunas = JSON.parse(localStorage.getItem("ordemConsulta")).ordemDasColunas;
        ordemDasColunas = ordemDasColunas.map((coluna) => {
            if (coluna.name === changedColumn) {
                coluna.options.display = !(coluna.options.display);
            };
            return coluna;
        });
        localStorage.setItem("ordemConsulta", JSON.stringify({ordemDasColunas}));
    };

    const handleFilterChange = (changedColumn, filterList, type, changedColumnIndex, displayData) => {
        var nomeDaColuna = changedColumn;
        var filtro = JSON.parse(sessionStorage.getItem("filtros")) || {};
        filtro[nomeDaColuna] = filterList[changedColumnIndex];
        sessionStorage.setItem("filtros", JSON.stringify(filtro))
    };

    const components = {
        icons: {
          DownloadIcon: ExcelDownloadIcon,
        }
    };    

    definirCustomToolbarSelectCallback();
    definirOnCellClickCallback();

    return(    
      <div className="clickableTd">
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          components={components}
          options={{
            // tableBodyHeight: "400px", //TODO: aqui talvez o ideal fosse ocupar 100% do tamanho do elemento pai, ver se vale a pena tentar se aventurar nisso (pode ser que seja tão oneroso que não valha a pena, analisar)
            elevation: 2,
            responsive: "standard",   
            print: false,
            download: true,
            search: true,           
            viewColumns: true,  
            filter: true,
            filterType: "multiselect",
            draggableColumns: {enabled:true},
            onColumnOrderChange: handleColumnDrag,
            onViewColumnsChange: handleColumnToggle,
            onFilterChange: handleFilterChange,
            rowsPerPageOptions: [5, 10, 15, 25, 100], //TODO: analisar aqui qual a melhor combinação possível neste contexto da Lunar
            rowsPerPage: 10, //TODO: analisar aqui também qual a melhor opção default
            textLabels: textLabels,
            onDownload: onDownload,
            customToolbarSelect: customToolbarSelectCallback,     
            onRowClick: onRowClickCallback,
            ...options
          }}
        />
      </div>

    )
}

export default MuiDatatable


