import React from 'react';
import {Pie} from 'react-chartjs-2';

import {useState} from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const PieChart = ({data}) => (
  <Pie data={data} />
);


const MenuDeSelecao = ({opcao, opcoes, handleChange}) => {
  return(
    <FormControl variant="standard">
      <Select
        value={opcao}
        onChange={handleChange}
      >
          {Object.entries(opcoes).map(entry => {
            return(
              <MenuItem value={entry[1]}>{entry[0]}</MenuItem>
            )
          })}
      </Select>
    </FormControl>
  )
}


const PieChartContainer = ({logsDeDownload}) => {


  //...
  const [opcao, setOpcao] = useState('tema');

  const handleChange = (event) => {
    setOpcao(event.target.value);
  };


  const opcoes = {
    'Entidade' : 'entidadeApelido',
    'Extensão' : 'extensao',
    'Frequência' : 'frequencia',
    'Grupo' : 'grupoDoUsuarioQueBaixa',
    'Inserido Por' : 'usuarioQueSobe',
    'Tema' : 'tema',
    'Tipo de Documento' : 'tipoDeDocumento',
    'Usuário' : 'usuarioQueBaixa',
    'Versão' : 'numeroVersao'    
  }

  function getTipos() {
    return (logsDeDownload.map(log => log[opcao]).sort());
  }

  function getDistribuicaoDeDownloadsPorTipos(){
    var tipos = getTipos();
    var distribuicao = {};
    for (var i = 0; i < tipos.length; i++) {
      var tipo = tipos[i];
      distribuicao[tipo] = distribuicao[tipo] ? distribuicao[tipo] + 1 : 1;
    }
    //Ordenar do maior para o menor
    distribuicao = Object.fromEntries(
      Object.entries(distribuicao).sort(([,a],[,b]) => b-a)
    );
    return distribuicao;
  }

  function getChartLabels(){
    return Object.keys(getDistribuicaoDeDownloadsPorTipos());
  }

  function getDatasetsData(){
    return Object.values(getDistribuicaoDeDownloadsPorTipos());
  }

  const data = {
    labels: getChartLabels(),
    datasets: [
      {
        label: 'Downloads',
        data: getDatasetsData(),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <div style={{display: 'flex', height: '100%'}}>
        <span style={{display: 'inline-block', alignSelf: 'flex-end', paddingRight: '0.75rem', paddingBottom: "0.4rem"}}>
          Segmentar por 
        </span>
        <span style={{display: 'inline-block', alignSelf: 'flex-end'}}>
          <MenuDeSelecao opcao={opcao} opcoes={opcoes} handleChange={handleChange} />
        </span>          
      </div>
      <br />
      <PieChart data={data} />
    </div>
  )
}



export default PieChartContainer
