import {
    BUSCAR_DOCUMENTO_LOADING,
    BUSCAR_DOCUMENTO_SUCCESS,
    BUSCAR_DOCUMENTO_FAIL  
} from '../actionTypes';
  
const initialState = {
    documento: {}, 
    isLoading: false,
    wasLoaded: false,
    error: null
};

const documento = (state = initialState, { type, payload }) => { 
    switch (type) {
        case BUSCAR_DOCUMENTO_LOADING:
            return {
                ...state,
                isLoading: true,
                wasLoaded: false,
                error: null,
            }        
        case BUSCAR_DOCUMENTO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                wasLoaded: true,
                documento: payload.documento
            }                
        case BUSCAR_DOCUMENTO_FAIL:
            return {
                ...state,
                isLoading: false,
                wasLoaded: false,
                documento: {},
                error: payload.error,
            }                     
        default:
            return state;
    }
}

export default documento