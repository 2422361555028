import { KeyboardDatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField';


import './DatePickers.css'

const DatePickers = ({
  id,
  tipo = "DIARIO",
  tipoDeFormato = "PADRAO",
  value,
  onChange,
  tabIndex,
  onBlur,
  onAccept,
  label,
  disableFuture = false,
  labelAPartirDoTipo = false,
  required = false
}) => {
//-------------------------------------------------------------------------------------
// Parâmetro       //Tipagem               //Descrição
//-------------------------------------------------------------------------------------
// tipo             string enum =          //TODO: ?completar descrição
//                      "DIARIO",          
//                      "MENSAL"           
//                      "ANUAL"
// tipoDeFormato    string enum =          //TODO: ?completar descrição
//                      "PADRAO",
//                      "DATADEREFERENCIA"
// value            date                   //TODO: ?completar descrição
// onChange         callback function      //TODO: ?completar descrição
// labelAPartirDoTipo                      //TODO: ?completar descrição
//-------------------------------------------------------------------------------------    
    
    let openTo=""; 
    let format=""; 
    let views=[]; 
    let placeholder="";

    switch(tipo) {
        case "UNITARIO":         
            break 
        case "ANUAL":
            openTo="year"
            views=[ "year"];
            if(tipoDeFormato === "PADRAO"){
                format="yyyy";
                placeholder="aaaa";
            } else if (tipoDeFormato === "DATADEREFERENCIA") {
                format="yyyy";
                placeholder="aaaa";
            }                
            break;        
        case "MENSAL":
        case "BIMESTRAL":
        case "TRIMESTRAL":
        case "SEMESTRAL":            
            openTo="year"
            views=["year", "month"]; 
            if(tipoDeFormato === "PADRAO"){
                format="MM/yyyy";
                placeholder="mm/aaaa"  
            } else if (tipoDeFormato === "DATADEREFERENCIA") {
                format="yyyy.MM";
                placeholder="aaaa.mm";
            }            
            break;
        case "DIARIO":
        case "EVENTUAL":
        default:
            openTo="year"
            views=[ "year", "month", "date"];
            if(tipoDeFormato === "PADRAO"){
                format="dd/MM/yyyy";
                placeholder="dd/mm/aaaa"
            } else if (tipoDeFormato === "DATADEREFERENCIA") {
                format="yyyy.MM.dd";
                placeholder="aaaa.mm.dd";
            }  
            break;
    }    


    //Definir label
    if(labelAPartirDoTipo){
        switch(tipo) {
            case "UNITARIO":
                label = "Sem Data Ref.";
                break;            
            case "DIARIO":
                label="Data";
                break;
            case "EVENTUAL":
                label="Data";
                break;
            case "MENSAL":
                label="Data Mensal";
                break;
            case "BIMESTRAL":
                label="Data Bimestral";
                break;
            case "TRIMESTRAL":
                label="Data Trimestral"; 
                break;
            case "SEMESTRAL":   
                label="Data Semestral";   
                break;
            case "ANUAL":
                label="Data Anual";
                break;        
            default:
                label="Data";
                break;
        }
    }
    
    
    return (<>
        {/* Documentos unitários não carregam informação temporal de referência */}
        {tipo === "UNITARIO" ? (
            <TextField
                id={id}       
                fullWidth={true}     

                value={null}
                label={label}

                inputProps={{ 
                    tabIndex: tabIndex,
                    disabled: true
                }}                
            />
        ) : (
            <KeyboardDatePicker 
                id={id}
                fullWidth={true}      

                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onAccept={onAccept}

                openTo={openTo}
                format={format}
                views={views}
                placeholder={placeholder}
                
                autoOk={true} /* Melhora a experiência do usuário ao diminuir o número de cliques */
                disableToolbar={false} /*Ao utilizar autoOk e view invertidos (ano -> month -> date) a Toolbar não traz uma boa experiência, por isso a desabilitação */
                invalidDateMessage={"Formato de Data Inválido"}
                maxDateMessage={"A data não deve ser superior à data máxima"}
                minDateMessage={"A data não deve ser inferior à data mínima"}
                disableFuture={disableFuture} //TODO: decidir esta configuração aqui, se realmente vai ser true ou false

                label={label}

                KeyboardButtonProps={{
                "aria-label": "change date"
                }}              
                
                inputProps={{ 
                    tabIndex: tabIndex,
                    required: required 
                }}
            />
        )}
    </>);
  }

export default DatePickers