import {
    BAIXAR_ARQUIVOS_LOADING, BAIXAR_ARQUIVOS_SUCCESS, BAIXAR_ARQUIVOS_FAIL,
} from '../actionTypes';

const initialState = {
    isLoading: false,
    isLoadingConteudoInline: false,
    error: null
};

const downloadDosArquivos = (state = initialState, { type, payload }) => { 
    switch (type) {
        case BAIXAR_ARQUIVOS_LOADING:
            return {
                ...state,
                isLoading: true,
                isLoadingConteudoInline: 
                    payload.isInline? true : state.isLoadingConteudoInline,
                error: null,
            }        
        case BAIXAR_ARQUIVOS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoadingConteudoInline: 
                    payload.isInline? false : state.isLoadingConteudoInline,                
            }                
        case BAIXAR_ARQUIVOS_FAIL:
            return {
                ...state,
                isLoading: false,
                isLoadingConteudoInline: 
                    payload.isInline? false : state.isLoadingConteudoInline,                  
                error: payload.error,
            }               
        default:
            return state;
    }
}

export default downloadDosArquivos