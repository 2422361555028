import React from 'react';
import {NavLink} from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import './DesktopMainMenu.css'

import DesktopDropDownMenu from './components/DesktopDropDownMenu'


const DesktopMainMenu = ({mainMenuOptions}) => {

    const theme = useTheme();
    const primaryColor = theme.palette.primary.main

    return(
        <div id="desktopMainMenu" style={{color: primaryColor}}> {/* Obs: "style ={{color: primaryColor}}" é um "hacking" (como aparentemente não posso colocar a cor primária diretamente no meu arquivo .css, coloquei ele no meu arquivo .js no elemento pai e utilizo ele no arquivo .css a partir da propriedade inherit no elemento filho) */}            
            {mainMenuOptions.map(
                (option)=>{
                    if(option.dropdownOptions){
                        return (
                            <DesktopDropDownMenu options={option.dropdownOptions} activeLink={option.activeLink}>
                                {option.label} 
                            </DesktopDropDownMenu>
                        )
                    } else {
                        return (
                            <NavLink className="navLink" to={option.to}> 
                                {option.label} 
                            </NavLink>
                        )
                    }
            })}
        </div>
    )
}


export default DesktopMainMenu