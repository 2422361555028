/*
Obs: esta função foi copiada da pasta server/src/routes/api/helpers (03/05/2021)

Converte a data YYYY-MM-DD para uma data compatível com a frequênciado tipo de
documento utilizado

Os meses são convertidos p/ o último mês do período e.g., para uma frequência
trimestral, o mês 8 vira mês 9. De modo geral, temos que para uma frequência
que divite o ano em N períodos (tal que d = 12/N):
    f(x) = (1 + floor((x-1)/d)) * d ; É uma função que retorna o mês desejado
    N = 4 (=> d = 3), x = 8 =>
        f(8) = (1 + floor((8-1)/3)) * 3
        f(8) = (1 + floor(7/3)) * 3
        f(8) = (1 + floor(2,333...)) * 3
        f(8) = (1 + 2) * 3
        f(8) = 3 * 3 = 9, conforme o esperado

e.g., dados a data "2020-11-29" para um tipo de documento cuja frequência é
"MENSAL" para a data "2020-11-00". O racional por trás disso é que, se a
frequência é mensal, não é de nosso interesse usar o dia como referência para
o documento, e portanto, não deve havar nenhuma informação de dia associada ao
documento.
*/

function converterData(dataOriginal, frequencia) {
    // dataOriginal (object) = {ano: YYYY, mes: MM, dia: DD}

    var novaData = dataOriginal;
    // Documentos eventuais e diários carregam toda a informação
    // temporal passada
    if (frequencia in ["EVENTUAL", "DIARIO"]) return dataOriginal;

    if (frequencia === "MENSAL") {
        // A informação de dia não interessa p/ um documento mensal
        novaData.dia = 0;
    } else if (frequencia === "BIMESTRAL") {
        // Pega o último mês do bimestre
        // f(x) = {x, x par
        //        {x+1, x ímpar
        novaData.dia = 0;
        novaData.mes = (novaData.mes % 2) ? novaData.mes + 1 : novaData.mes;
    } else if (frequencia === "TRIMESTRAL") {
        // Último mês do trimestre
        // f(x) = (1+floor((x-1)/3))*3
        novaData.dia = 0;
        novaData.mes = (1 + Math.floor((novaData.mes - 1)/3)) * 3;
    } else if (frequencia === "SEMESTRAL") {
        // Último mês do semestre
        // f(x) = (1+floor((x-1)/6))*6
        novaData.dia = 0;
        novaData.mes = (1 + Math.floor((novaData.mes - 1)/6)) * 6;
    } else if (frequencia === "ANUAL") {
        novaData.dia = 0;
        novaData.mes = 0;
    } else if (frequencia === "UNITARIO") {
        // Documentos unitários não carregam informação temporal de referência
        novaData.ano = 0;
        novaData.mes = 0;
        novaData.dia = 0;
    };
    return novaData
};

export default converterData;
