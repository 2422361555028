import { Alert, AlertTitle } from '@material-ui/lab';
import Button from "@material-ui/core/Button";
import {recarregarPagina} from '../../../../support/publicFunctions'


function getErrors(error, parametros){
    const errosDosParametros = parametros.filter((p)=>p?.error).map(p=>p?.error);
    const errors = [error, ...errosDosParametros].filter(Boolean);
    return errors;
}

const ListaDeErros = ({error, parametros}) =>{

    const errors = getErrors(error, parametros);

    return(
        <>
            {errors.length ? (
                <>
                    <>
                        <div className="linhaHorizontal publico"/>
                        <h2 className="lunar">Erros</h2>
                    </>
                    <>
                        {(errors.map(e => (
                            <>
                                <Alert elevation={1} severity="warning">
                                    <AlertTitle>{e.title}</AlertTitle>
                                    {e.message}
                                </Alert>
                                <br />
                            </>
                        )))}
                    </>
                    <>
                        <Button onClick={recarregarPagina} variant="contained">
                            Limpar Erros e Recarregar Página
                        </Button>              
                        <br />  
                        <br />
                        <br />
                    </>
                </>
            ) : (
                null
            )}
        </>
    )
}

export default ListaDeErros;