import {
    BUSCAR_TIPOS_DE_DOCUMENTO_LOADING, BUSCAR_TIPOS_DE_DOCUMENTO_SUCCESS, BUSCAR_TIPOS_DE_DOCUMENTO_FAIL,
} from '../actionTypes';


const initialState = {
    tiposDeDocumento: [],
    isLoading: false,
    wasLoaded: false,
    error: null,
};


const tiposDeDocumento = (state = initialState, { type, payload }) => {
    switch (type) {
        case BUSCAR_TIPOS_DE_DOCUMENTO_LOADING:            
            return {
                ...state,
                isLoading: true,
                wasLoaded: false,
                error: null,
            }                        
        case BUSCAR_TIPOS_DE_DOCUMENTO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                wasLoaded: true,
                tiposDeDocumento: payload.tiposDeDocumento
            }                   
        case BUSCAR_TIPOS_DE_DOCUMENTO_FAIL:
            return {
                ...state,
                isLoading: false,
                wasLoaded: false,
                arquivos: [], 
                error: payload.error                        
            }          
        default:
            return state;
    }
}

export default tiposDeDocumento