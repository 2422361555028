import React from 'react';
import QueryStringUpdateDialog from 
  '../../../../components/QueryStringUpdateDialog/QueryStringUpdateDialog';

function DialogLimitacaoDados({
  open, setOpen
}) {

  const title = "Configurar limite de dados carregados"
  const inputs = [
    {key: "limite", label: "Limite de Ocorrências", type: "number"},
    {key: "dataInicio", label: "Data Inicial", type: "date"},
    {key: "dataFim", label: "Data Final", type: "date"}
  ]

  let childOpen;
  if(open === "DialogLimitacaoDados"){childOpen = true} else {childOpen = false}

  return (
    <QueryStringUpdateDialog
      open={childOpen}
      setOpen={setOpen}
      title={title}
      inputs={inputs}
    />
  )
}


export default DialogLimitacaoDados;