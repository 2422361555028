import {
    BUSCAR_ARQUIVO_LOADING,
    BUSCAR_ARQUIVO_SUCCESS,
    BUSCAR_ARQUIVO_FAIL  
} from '../actionTypes';
  
const initialState = {
    arquivo: {}, 
    isLoading: false,
    error: null,
};

const arquivo = (state = initialState, { type, payload }) => { 
    switch (type) {
        case BUSCAR_ARQUIVO_LOADING:
            return {
                ...state,
                isLoading: true,
                error: null,
            }        
        case BUSCAR_ARQUIVO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                arquivo: payload.arquivo
            }                
        case BUSCAR_ARQUIVO_FAIL:
            return {
                ...state,
                isLoading: false,
                arquivo: {},
                error: payload.error,
            }                     
        default:
            return state;
    }
}

export default arquivo