import axios from 'axios';
import {attachTokenToHeaders, handleErrorForAllActions, 
  encontrarMensagemDeErro} from '../actionFunctions';


import {
  CRIAR_PARAMETRO_LOADING,
  CRIAR_PARAMETRO_SUCCESS,
  CRIAR_PARAMETRO_FAIL,
  REMOVER_PARAMETRO_LOADING, 
  REMOVER_PARAMETRO_SUCCESS, 
  REMOVER_PARAMETRO_FAIL,  
} from '../actionTypesAdmin';



const criarRegrasDeNotificacao = ({dados}) => async (dispatch, getState) => {
  const {usuarios} = dados;
  //Busca usuariosNomes (a ser utilizado se houver mensagens de erros)
  dispatch({ 
    type: CRIAR_PARAMETRO_LOADING
  }); 
  const usuariosNomes = await apoio.buscarUsuariosNomes({getState});
  //Cria regras de notificação para cada usuário:
  for (let index = 0; index < usuarios.length; index++) {
    const usuarioId = usuarios[index];
    const usuarioNome = usuariosNomes[usuarioId];
    await apoio.criarRegraDeNotificacao({usuarioId, dados, index, usuarioNome, 
      dispatch, getState})
  }
}


const removerRegraDeNotificacao = ({dados}) => async (dispatch, getState) => {
  const {id, nome, usuarioId} = dados;
  dispatch({ 
    type: REMOVER_PARAMETRO_LOADING,
    payload: { id },
  });
  try {
    //1. Remove o parâmetro
    const url = `/api/administradores/usuarios/${usuarioId}/notificacoes/${nome}`
    const options = attachTokenToHeaders(getState);
    await axios.delete(url, options); 
    //2. Verifica se a regra foi realmente removido
    const regraRemovida = await apoio.buscarRegra({nome, usuarioId, getState});
    if(regraRemovida?.nome === nome && regraRemovida?.usuarioId === usuarioId){
      throw new Error("ERRO: Parâmetro não removido. Erro não identificado.")}; 
    //3. Se sim, faz o dispatch
    dispatch({
      type: REMOVER_PARAMETRO_SUCCESS,
      payload: { id },
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    const error = {
      title:  `O Parâmetro não foi removido porque ocorreu um Erro ` + 
      `(ID do Parâmetro: ${id})`,
      message: encontrarMensagemDeErro(err)     
    }  
    alert(`${error?.title} \n\n${error?.message}` + 
      ` \n\nNenhuma modificação foi feita.`);
    dispatch({
      type: REMOVER_PARAMETRO_FAIL,
      payload: { error, id },
    });
  }
}


const apoio = {
  buscarRegra: async function({nome, usuarioId, getState}){
    const options = attachTokenToHeaders(getState, {
      'Accept': 'application/json'}); 
    const url = `/api/administradores/usuarios/${usuarioId}/notificacoes`
    const regras = (await axios.get(url, options)).data;
    const regra = regras?.find(p => p.nome === nome);
    return regra; 
  },
  buscarUsuariosNomes: async function({getState}){
    const options = attachTokenToHeaders(getState, {'Accept': 
      'application/json'});
    const url = `/api/administradores/usuarios/`
    const response = await axios.get(url, options);
    const usuarios = response.data;
    let usuariosNomes = {};
    usuarios.map(usuario => {
      usuariosNomes = {...usuariosNomes, [usuario.id]: usuario.nome}
      return false;
    })
    return usuariosNomes
  },
  criarRegraDeNotificacao: async ({usuarioId, dados, index, usuarioNome,  
    dispatch, getState
  }) => {
    const {nome, listaDeEntidades, listaDeTiposDeDocumento} = dados;
    if(index !== 0){dispatch({  
      //(obs:Novo Loading só a partir 2ªiteração p/evitar duplicação de loading)
      type: CRIAR_PARAMETRO_LOADING
    })} 
    try {
      //1. Criar Regra
      const url = `/api/administradores/usuarios/${usuarioId}/notificacoes`
      const options = attachTokenToHeaders(getState);
      await axios.post(url, dados, options); 
      //2. Busca e verifica se a regra foi realmente criada
      const regra = await apoio.buscarRegra({nome, usuarioId, getState});
      if(!listaDeEntidades?.length && !listaDeTiposDeDocumento?.length){
        throw new Error("ERRO: Selecione pelo menos um Relatório ou pelo" + 
          " menos uma Entidade para criar uma regra válida.")};
      if(!(regra?.nome === nome && regra?.usuarioId === usuarioId)){
        throw new Error("ERRO: Ocorreu algum erro não identificado.")};
      const fakeId = ([regra.usuarioId, regra.nome]).join();
      const parametro = ({id: fakeId, ...regra});
      //3. Faz o dispatch
      dispatch({
        type: CRIAR_PARAMETRO_SUCCESS,
        payload: {parametro},
      });      
    } catch (err) {
      handleErrorForAllActions(err, getState); 
      const error = {
        title:  `Atenção: O usuário "${usuarioNome}" não teve regra de` + 
          ` notificação criada porque ocorreu um Erro (ID do Usuário:` +
          ` ${usuarioId})`,
        message: encontrarMensagemDeErro(err)     
      }
      alert(`${error?.title} \n\n${error?.message}` + 
        ` \n\nNenhuma modificação foi feita.`);
      dispatch({
        type: CRIAR_PARAMETRO_FAIL,
        payload: {error},
      });
    }
  }
}


export {  
  criarRegrasDeNotificacao,
  removerRegraDeNotificacao
}