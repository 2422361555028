import {useEffect, useState} from 'react';

import MUIDataTable from "mui-datatables";
import textLabels from "../../../../support/MuiDatatableSupport/TextLabels"

import './StepFinal.css'
import Loader from '../../../../components/Loader/Loader'

import {connect} from "react-redux";

import {
    criarDocumentos, substituirDocumentos, 
    enviarEmailsPendentesNoLote, obterNumeroDoLote,
    buscarDocumentosPorAtributos, buscarDocumentosPorUuid
} from '../../../../redux/actions/documentoActions'


import {obterTituloDoDocumento} from '../../../../support/publicFunctions'

import {Link} from 'react-router-dom';    

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';    

import ClosableAlert from "../../../../components/ClosableAlert/ClosableAlert"

import Chip from '@material-ui/core/Chip';

import Button from '@material-ui/core/Button';

import { useTheme } from '@material-ui/core/styles';


const StepFinal = ({
    handleReset, documentosParaFiltrarECriar, documentosParaFiltrarESubstituir,
    token, 
    criarDocumentos, criacaoDeDocumentosIsLoading, 
    substituirDocumentos,  substituicaoDeDocumentosIsLoading,
    criacaoDeDocumentosResponses, substituicaoDeDocumentosResponses, 
    documentosCriados, documentosCriadosIsLoading,
    documentosSubstituidos, documentosSubstituidosIsLoading,
    buscarDocumentosPorAtributos, buscarDocumentosPorUuid,
}) => {

    const theme = useTheme();
    const linkStyle = {color: theme.palette.primary.dark};

    const [atualizacaoInicialIsLoading, setAtualizacaoInicialIsLoading] = 
        useState(true);    
    const [documentosParaCriar, setDocumentosParaCriar] = useState(null);
    const [documentosParaSubstituir, setDocumentosParaSubstituir] = 
        useState(null);
    const [dadosDoCancelamento, setDadosDoCancelamento] = useState(null);
    const [dadosDoSucesso, setDadosDoSucesso] = useState([]);
    const [dadosDaFalha, setDadosDaFalha] = useState([]);
    const [numeroLoteHasheado, setNumeroLoteHasheado] = useState([]);

    const linkLote = 
        `${process.env.REACT_APP_CLIENT_URL}/lotes/${numeroLoteHasheado}`
    

    // inspecionar states
    useEffect(()=>{
        console.log("atualizacaoInicialIsLoading", atualizacaoInicialIsLoading)
    },[atualizacaoInicialIsLoading]);    
    useEffect(()=>{
        console.log("documentosParaCriar", documentosParaCriar)
    },[documentosParaCriar]);
    useEffect(()=>{
        console.log("documentosParaSubstituir", documentosParaSubstituir)
    },[documentosParaSubstituir]);
    useEffect(()=>{
        console.log("dadosDoCancelamento", dadosDoCancelamento)
    },[dadosDoCancelamento]);    
    useEffect(()=>{
        console.log("dadosDoSucesso", dadosDoSucesso)
    },[dadosDoSucesso]);    
    useEffect(()=>{
        console.log("dadosDaFalha", dadosDaFalha)
    },[dadosDaFalha]);    
    useEffect(()=>{
        console.log("numeroLoteHasheado", numeroLoteHasheado)
    },[numeroLoteHasheado]);        


    useEffect(() => {
        function scrollPageToTop(){window.scrollTo(0, 0)}
        scrollPageToTop()
    }, [])

    useEffect(() => { 
        const [
            documentosParaCriar, documentosParaSubstituir, documentosCancelados
        ] = filtrarDocumentos();        
        function filtrarDocumentos(){
            //Filtra apenas por documentos Confirmados
            const documentosParaCriar = (documentosParaFiltrarECriar
                .filter(documento => documento.inserir === true)) || [];
            const documentosParaSubstituir = (documentosParaFiltrarESubstituir
                .filter(documento => documento.inserir === true))  || [];
            //Filtra apenas por documentos Cancelados
            const documentosParaCriarCancelados = documentosParaFiltrarECriar
                .filter(documento => documento.inserir === false);
            const documentosParaSubstituirCancelados = 
                documentosParaFiltrarESubstituir
                .filter(documento => documento.inserir === false);        
            //Consolida documentos cancelados
            const documentosCancelados = [
                ...documentosParaCriarCancelados,
                ...documentosParaSubstituirCancelados
            ]
            //return 
            return [documentosParaCriar, documentosParaSubstituir, 
                documentosCancelados]
        }
        function atualizarStatesDosDocumentos(){
            //Atualiza states
            setDocumentosParaCriar(documentosParaCriar);
            setDocumentosParaSubstituir(documentosParaSubstituir);
            return false;
        }                
        function criarDadosDoCancelamento(){
            if (!documentosCancelados) 
                return setDadosDoCancelamento([])
            const dadosDoCancelamento = documentosCancelados?.map(documento => {
                const titulo = obterTituloDoDocumento(
                    documento?.atributos?.descritivoCompleto,
                    null,
                    documento?.atributos?.entidade,
                    documento?.atributos?.ano,
                    documento?.atributos?.mes,
                    documento?.atributos?.dia,
                    documento?.atributos?.novaVersao,
                )
                const nomeDosArquivos = documento?.atributos?.nomeDosArquivos;  
                return [titulo, nomeDosArquivos];
            })
            return setDadosDoCancelamento(dadosDoCancelamento);
        }        
        async function inserirDocumentos(){            
            //obter número do lote (apenas para múltiplos documentos)
            const {numeroLote, numeroLoteHasheado} =
                documentosParaCriar?.length + documentosParaSubstituir?.length 
                > 1 ? 
                (await obterNumeroDoLote(token)).data :  
                {};
                console.log("numeroLote", numeroLote);
                console.log("numeroLoteHasheado", numeroLoteHasheado);
            //salvar numeroLoteHasheado no state
            setNumeroLoteHasheado(numeroLoteHasheado);
            //criar e substituir documentos em paralelo
            const promises = [
                criarDocumentos({
                    documentos: documentosParaCriar, 
                    numeroDoLote: numeroLote
                }),
                substituirDocumentos({
                    documentos: documentosParaSubstituir, 
                    numeroDoLote: numeroLote
                })   
            ]
            await Promise.all(promises);
            //enviar e-mails do lote (apenas para múltiplos documentos)
            const responseEnvioDeEmailsPendentesNoLote = 
                documentosParaCriar?.length + documentosParaSubstituir?.length 
                > 1 ?  
                await enviarEmailsPendentesNoLote(token, numeroLote):
                null;

            console.log(
                "responseEnvioDeEmailsPendentesNoLote", 
                responseEnvioDeEmailsPendentesNoLote
            );
            //Encerra o loading da atualização inicial se não há       
                //documentosParaCriar e documentosParaSubstituir
            if(!documentosParaCriar?.length && !documentosParaSubstituir?.length
                ){setAtualizacaoInicialIsLoading(false)} 
            return false;            
        }
        atualizarStatesDosDocumentos();
        criarDadosDoCancelamento();
        inserirDocumentos();
    }, []) // eslint-disable-line 
    /* (neste caso acima o lint pode ser desativado, porque é desejável apenas 
     * uma única execução, logo quando a página carrega) */




    useEffect(() => {
        function buscarDocumentosCriados(){
            console.log("criacaoDeDocumentosResponses", criacaoDeDocumentosResponses);
            if(!criacaoDeDocumentosResponses?.length){return false}
            setAtualizacaoInicialIsLoading(false); /*TODO: analisar se há um
                lugar melhor para colocar essa linha*/
            const atributos = documentosParaCriar?.map(doc => doc.atributos)
            return buscarDocumentosPorAtributos(atributos);
        }
        buscarDocumentosCriados();
    
    }, [criacaoDeDocumentosResponses, documentosParaCriar, 
        buscarDocumentosPorAtributos]) 
    

    useEffect(() => {
        function buscarDocumentosSubstituidos(){
            console.log("substituicaoDeDocumentosResponses", substituicaoDeDocumentosResponses);
            if(!substituicaoDeDocumentosResponses?.length){return false}
            setAtualizacaoInicialIsLoading(false); /*TODO: analisar se há um
                lugar melhor para colocar essa linha*/
            const uuids = documentosParaSubstituir?.map(doc => doc.uuid)
            return buscarDocumentosPorUuid(uuids);
        }
        buscarDocumentosSubstituidos();
    }, [substituicaoDeDocumentosResponses, documentosParaSubstituir, 
        buscarDocumentosPorUuid])


    useEffect(() => {
        function verificarSucessoDeCriacaoDeDocumentos(){
            if(!documentosCriados?.length){return false};
            if(!documentosParaCriar?.length){return false};
            const dadosDoSucessoDaCriacao = [];
            const dadosDaFalhaDaCriacao = [];
            documentosCriados?.map((documento, index) => {
                if (documento?.id){ //verifica se o documento existe
                    const uuid = documento.uuid;
                    const titulo = obterTituloDoDocumento(
                        documento.tipoDeDocumento, documento.descritivo,
                        documento.entidade, documento.ano, documento.mes,
                        documento.dia, documento.numeroDaVersaoMaisRecente
                    );      
                    const novaVersao = 1;
                    dadosDoSucessoDaCriacao.push([titulo, uuid, novaVersao]);
                } else {
                    const {descritivoCompleto, entidade, ano, mes, dia} = 
                        documentosParaCriar[index].atributos
                    const titulo = obterTituloDoDocumento(
                        descritivoCompleto, null, entidade, ano, mes,dia
                    )        
                    const nomeDosArquivos = documentosParaCriar[index]
                        ?.atributos?.nomeDosArquivos;           
                    const response = criacaoDeDocumentosResponses[index];                     
                    const mensagemDeErro = getMensagemDeErro(response);
                    dadosDaFalhaDaCriacao.push(
                        [titulo, nomeDosArquivos, mensagemDeErro, response]
                    );
                }
                return false;
            })
            const newDadosDoSucesso = 
                [...dadosDoSucesso, ...dadosDoSucessoDaCriacao]
            const newDadosDaFalha = 
                [...dadosDaFalha, ...dadosDaFalhaDaCriacao];
            setDadosDoSucesso(newDadosDoSucesso);
            setDadosDaFalha(newDadosDaFalha);
        }
        verificarSucessoDeCriacaoDeDocumentos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentosCriados]) /*Neste caso o lint pode ser desativado porque é
        desejável uma única execução logo após a criação dos documentos*/


    useEffect(() => {
        function verificarSucessoDeSubstituicaoDeDocumentos(){
            if(!documentosSubstituidos?.length){return false};
            if(!documentosParaSubstituir?.length){return false};
            const dadosDoSucessoDaSubstituicao = [];
            const dadosDaFalhaDaSubstituicao = [];
            documentosSubstituidos?.map((documento, index) => {
                const titulo = obterTituloDoDocumento(
                    documento.tipoDeDocumento, documento.descritivo,
                    documento.entidade, documento.ano, documento.mes,
                    documento.dia, documento.numeroDaVersaoMaisRecente
                )
                const novaVersao = documentosParaSubstituir[index]?.atributos
                    ?.novaVersao;
                const versaoMaisRecente = documento.numeroDaVersaoMaisRecente;
                if ( /*Verifica se nova versão foi realmente criada */
                    versaoMaisRecente && novaVersao && 
                    (versaoMaisRecente === novaVersao) 
                ){
                    const uuid = documento.uuid;
                    dadosDoSucessoDaSubstituicao.push(
                        [titulo, uuid, novaVersao]
                    );
                } else {
                    const nomeDosArquivos = documentosParaSubstituir[index]
                        ?.atributos?.nomeDosArquivos;         
                    const response = substituicaoDeDocumentosResponses[index];     
                    const mensagemDeErro = getMensagemDeErro(response);
                    dadosDaFalhaDaSubstituicao.push(
                        [titulo, nomeDosArquivos, mensagemDeErro, response]
                    );
                }
                return false;
            })
            const newDadosDoSucesso = 
                [...dadosDoSucesso, ...dadosDoSucessoDaSubstituicao]
            const newDadosDaFalha = 
                [...dadosDaFalha, ...dadosDaFalhaDaSubstituicao];
            setDadosDoSucesso(newDadosDoSucesso);
            setDadosDaFalha(newDadosDaFalha);            
        }
        verificarSucessoDeSubstituicaoDeDocumentos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentosSubstituidos]) /*Neste caso o lint pode ser desativado porque 
        é desejável uma única execução logo após a substituição dos documentos*/

        
    function getMensagemDeErro(err) {
        if(err?.response?.data?.code === "ER_DUP_ENTRY"){
            return "ERRO: Não é permitido repetir extensão ao inserir " + 
                "um documento (exemplo: inserir dois ou mais arquivos em " + 
                "pdf para um mesmo documento). Favor, repetir " + 
                "o processo alterando esta condição."
        }
        return err?.response?.data?.message || err?.message || 
            "ERRO: Erro não identificado"
    }


    const opcoesDaTabela = {
        elevation: 2,
        responsive: "simple",  
        print: false,
        download: false,
        viewColumns: false,
        search: false,                     
        pagination: false,
        sort: false,
        filter: false, 
        filterType: "multiselect", 
        selectableRows: 'none',
        textLabels: textLabels,
        sortOrder: {
            name: 'documento',
            direction: 'asc'
        }
    }    

    const colunasDaFalha = [
        {
            label: 'Documentos Não Inseridos', 
            name: 'documento', 
        },      
        {
            label: 'Arquivos Não Inseridos', 
            name: 'nomeDosArquivos', 
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let colunaIndex = colunasDaFalha.findIndex(
                        obj => obj.name === "nomeDosArquivos"
                    );
                    let value = dadosDaFalha[dataIndex][colunaIndex];
                    return value?.map((val, key) => {
                        return <Chip label={val} key={key} />;
                    });
                },
        }},          
        {
            label: 'Erro', 
            name: 'mensagemDeErro', 
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let colunaIndex = colunasDaFalha.findIndex(
                        obj => obj.name === "mensagemDeErro"
                    );
                    let mensagemDeErro = dadosDaFalha[dataIndex][colunaIndex];
                    return mensagemDeErro;
                },
        }}
    ]

    const colunasDoCancelamento = [
        {
            label: 'Documentos Cancelados', 
            name: 'documento', 
        },	
        {
            label: 'Arquivos Cancelados', 
            name: 'nomeDosArquivos', 
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let colunaIndex = colunasDoCancelamento.findIndex(
                        obj => obj.name === "nomeDosArquivos"
                    );
                    let value = dadosDoCancelamento[dataIndex][colunaIndex];
                    return value?.map((val, key) => {
                        return <Chip label={val} key={key} />;
                    });
                },
        }},            
    ]        


    const colunasDoSucesso = [
        {
            label: 'Documentos Inseridos', 
            name: 'documento', 
        },
        {
            label: 'Visualizar', 
            name: 'uuidDoDocumento',
            options: {
                display: true,
                filter: false,
                sort: false, 
                customBodyRenderLite: (dataIndex) => {
                    let colunaUuidDoDocumento = colunasDoSucesso
                        .findIndex(obj => obj.name === "uuidDoDocumento");
                    let uuid = dadosDoSucesso[dataIndex][colunaUuidDoDocumento] 

                    let colunaNovaVersao = colunasDoSucesso
                        .findIndex(obj => obj.name === "novaVersao");
                    let novaVersao = 
                        dadosDoSucesso[dataIndex][colunaNovaVersao];

                    return (
                        <Link
                            to={`/documentos/${uuid}/versoes/${novaVersao}`}
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <Tooltip title= {'Visualizar o documento inserido ' + 
                                '(o link abrirá em uma nova página)'
                            }>
                                <IconButton>
                                    <VisibilityIcon fontSize='small'/>
                                </IconButton>
                            </Tooltip>
                        </Link>
                    )
                },
        }},      
        {
            label: 'Nova Versão', 
            name: 'novaVersao', 
            options: {
                display: "excluded",
                filter: false,
                sort: false, 
            }
        }
    ]

   
    return (<>
        {( 
            atualizacaoInicialIsLoading ||
            criacaoDeDocumentosIsLoading || substituicaoDeDocumentosIsLoading ||
                documentosCriadosIsLoading || documentosSubstituidosIsLoading
        ) ? (
            <Loader mensagem="Inserindo Documentos..." />
        ) : (
            <div id="stepFinal">
                <h2 className="lunar">
                    Processo <u>Finalizado!</u>
                </h2>
                <br /> {/*TODO: trocar o br por CSS!!*/}
                {dadosDaFalha?.length ? (
                    <>
                        <ClosableAlert 
                            alertTitle="Erro"
                            severity={"error"}
                            marginBottom = {"1rem"}
                            elevation = {1}
                        >
                            {dadosDaFalha?.length + " "} 
                            documento(s) <u>não inserido</u>(s) 
                            porque ocorreu algum erro:
                        </ClosableAlert>                            
                        <MUIDataTable className="muiDataTable"
                            data={dadosDaFalha}
                            columns={colunasDaFalha}
                            options={opcoesDaTabela}                
                        />
                    </>
                ) : (
                    <></>
                )}                   
                {dadosDoCancelamento?.length ? (
                    <>
                        <ClosableAlert 
                            alertTitle="Cancelamento"
                            severity={"warning"}
                            marginBottom = {"1rem"}
                            elevation = {1}
                        >
                            {dadosDoCancelamento?.length + " "}
                            documento(s) <u>cancelado</u>(s) por você:
                        </ClosableAlert>                             
                        <MUIDataTable className="muiDataTable"
                            data={dadosDoCancelamento}
                            columns={colunasDoCancelamento}
                            options={opcoesDaTabela}                
                        />
                    </>
                ) : (
                    <></>
                )}
                <ClosableAlert
                    alertTitle="Sucesso"
                    severity={"success"}
                    marginBottom = {"1rem"}
                    elevation = {1}
                >
                    {dadosDoSucesso?.length + " "}
                    documento(s) <u>inserido</u>(s) com Sucesso!:
                </ClosableAlert>
                <MUIDataTable className="muiDataTable"
                    data={dadosDoSucesso}
                    columns={colunasDoSucesso}
                    options={opcoesDaTabela}
                />
                {dadosDoSucesso?.length > 1 &&
                    (<p className="lunar textoDescritivo">
                        <span>
                            Se desejar compartilhar os documentos 
                            deste lote utilize o link: 
                        </span>
                        <span> </span>
                        <span style={linkStyle}>
                            <a 
                                className="textoLink visitedColorChangeDisabled" 
                                target="_blank" 
                                rel="noreferrer"
                                href={linkLote}
                            >
                                {linkLote}
                            </a>
                        </span>
                    </p>)
                }
                <Button onClick={handleReset} className={"resetButton"}>
                   Reiniciar
                </Button>
            </div>
        )}
    </>)
}
   
const mapStateToProps = (state) => ({
    //token
    token: state.auth.token,
    //criacaoDeDocumentos
    criacaoDeDocumentosResponses: state.criacaoDeDocumentos.responses,
    criacaoDeDocumentosIsLoading: state.criacaoDeDocumentos.isLoading,    
    //substituicaoDeDocumentos
    substituicaoDeDocumentosResponses: state.substituicaoDeDocumentos.responses,
    substituicaoDeDocumentosIsLoading: state.substituicaoDeDocumentos.isLoading,    
    //documentosBuscadosPorAtributos
    documentosCriados: state.documentosBuscadosPorAtributos.documentos,
    documentosCriadosIsLoading: state.documentosBuscadosPorAtributos.isLoading,      
    //documentosBuscadosPorUuid
    documentosSubstituidos: state.documentosBuscadosPorUuid.documentos,
    documentosSubstituidosIsLoading: state.documentosBuscadosPorUuid.isLoading,
});


export default connect(mapStateToProps, 
    {criarDocumentos, substituirDocumentos, 
        buscarDocumentosPorAtributos, buscarDocumentosPorUuid}
)(StepFinal)

 