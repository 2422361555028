/*Este objeto visa substituir a ToolbarSelect do Data Table de tal forma que após os selects apareça a _
    _ opção de "download" e não a opção de "delete" como é o padrão aparercer no Mui DataTables*/    
  
/* Obs - Código de Referência: 
    https://codesandbox.io/s/github/gregnb/mui-datatables?file=/examples/customize-toolbarselect/CustomToolbarSelect.js */


import {useState} from 'react';
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from '@material-ui/icons/GetApp';
import { withStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';

import {baixarArquivos} from '../../redux/actions/arquivoActions'

import Dialog from '@material-ui/core/Dialog';
import HelpIcon from '@material-ui/icons/Help';

import shiftGif from '../../support/images/exemplos/shift.gif';

const textoSobreShift = "Você pode selecionar múltiplos itens sequenciais usando a tecla Shift. Clique para visualizar um exemplo";

const defaultToolbarSelectStyles = {
  iconContainer: {
    marginRight: "24px", /* Isso aqui evita que o botão fique muito a direita, que não é interessante esteticamente */
  }
};

const CustomToolbarSelect = ({
  selectedRows, 
  displayData, 
  setSelectedRows, 
  colunas = [], 
  nomeDaColunaQueContemUuidDoArquivo="", 
  baixarArquivos, 
  downloadDosArquivosIsLoading, 
  classes
}) => {

    const [shiftDialog, SetShiftDialog] = useState(false);
    const handleShiftDialogClose = () => {
        return SetShiftDialog(false);
    };
    const handleClickAjudaShift = () => {
        SetShiftDialog(!shiftDialog);
    };
    const handleClickDownloadSelectedFiles = () => {
      const baixarArquivosSelecionados = () => {
          const obterValorDaColuna = (key, selectedRowsIndex) =>  displayData[selectedRows.data[selectedRowsIndex].index].data[colunas.findIndex(o => o.name===key)]                    
          const uuidDosArquivos = selectedRows.data.map((selectedRow, index) => obterValorDaColuna(nomeDaColunaQueContemUuidDoArquivo, index));
          baixarArquivos({uuids: uuidDosArquivos});
      }
      baixarArquivosSelecionados();
    }      


    const handleClickExibirMensagemAguardarDownload = () => {
      alert("Favor, aguardar a conclusão do último download antes de tentar baixar novos arquivos.")
    }

    
    return (            
        <div className={classes.iconContainer}>
          <>
            <Dialog maxWidth={'md'} onClose={handleShiftDialogClose} open={shiftDialog}>
                <img src={shiftGif} alt="Gif com instruções de ajuda"/>
            </Dialog>
            {downloadDosArquivosIsLoading ? (
              <IconButton onClick={handleClickExibirMensagemAguardarDownload}>
                <CircularProgress size={"1.5rem"} />
              </IconButton>
            ) : (
              <Tooltip title={"Baixar Selecionados"}>
                <IconButton onClick={handleClickDownloadSelectedFiles}> 
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={textoSobreShift}>
                <IconButton 
                    onClick={handleClickAjudaShift}
                >
                    <HelpIcon />
                </IconButton>
            </Tooltip>                            
          </>
        </div>
    );
}


const mapStateToProps = (state) => ({
  //downloadDosArquivos
  downloadDosArquivosIsLoading: state.downloadDosArquivos.isLoading
});


export default connect(mapStateToProps, {baixarArquivos})(
    (withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect))
);
