import { createMuiTheme} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale'; //Baixa a linguagem pt-BR pro Material UI

import muiColorPalettes from './muiColorPalettes'


/*===========================================================================*/
/*Definir aqui as cores primárias e secundárias do tema global do Material UI*/
/*===========================================================================*/
const primaryPalette = muiColorPalettes.azulVbi1
const secondaryPalette = muiColorPalettes.laranjaVbi1
/*===========================================================================*/

const stickyStyle = {
  //Obs: eslint desativado abaixo porque ele dá warning de "Duplicate key 'position'"
  position: "-webkit-sticky", // eslint-disable-line
  position: "sticky", // eslint-disable-line
  top: 0,
}


/*Cria o tema global do app a partir do Material UI*/
const muiTheme = createMuiTheme(
    {
        //Define o tema global de cores primárias e secundárias:
        palette: {
            primary: primaryPalette,
            secondary: secondaryPalette,
        },
        //Define a cor dos Icons Buttons do Material UI quando o mouse está em cima
        overrides: {
            MuiIconButton: {
              root: {
                '&:hover': {
                  color: primaryPalette.main, 
                },
                //Faz a mudança também para ícones customizados que não são do Material UI e feitos em svg
                    //Como no caso do ícone do Excel (que não tem no Material UI e então foi feito em svg)
                    //Como ele é svg tem mudar o "fill" e não o "color" e aí utilizar a classe "changeFillOnHover"
                '&:hover .changeFillOnHover *': {
                  fill: primaryPalette.main
                }        
              }
            },  
            MUIDataTableToolbarSelect: {
              root: {
                ...stickyStyle,
              }
            },
            MUIDataTableToolbar: {
              root: {
                ...stickyStyle,
                zIndex: 120, //Isso aqui evita que o elemento fique com um zIndex menor do que a tabela quando ele sobrepõe ela com sticy (Obs: 120 é o mesmo z-index do MUIDataTableToolbarSelect, enquanto o da tabela é 100)
                backgroundColor: 'inherit', //Isso aqui evita que a Toolbar fique transparente
              }
            },                    
        }          
    },
    ptBR //Exibe pros usuários os componentes do Material UI em Pt-BR
);


export default muiTheme