import React from 'react';
import {connect} from "react-redux";
import {logOutUser} from '../../../redux/actions/authActions'

import { withStyles, useTheme } from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Divider from '@material-ui/core/Divider';

import { Link } from 'react-router-dom';


import './UserIconMenu.css'

//...
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import { useLocation } from 'react-router-dom'
import { estaNoModoAdmin } from '../../../support/publicFunctions'

const StyledMenuItem = withStyles(
    // Isso aqui é para fazer o hover da cor primária quando selecionadas as opções do submenu
    (theme) => { 
        return {
            root: {
                '&:hover': {
                    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                        color: theme.palette.primary.main,
                    },
                },
            },
        }
    }
)(MenuItem)


const UserIconMenu = ({auth, logOutUser}) => {

    const location = useLocation();

    const theme = useTheme();
    const modoAdmin = estaNoModoAdmin(location, auth);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };    

    const onLogOut = (event) => {
        event.preventDefault();
        logOutUser();
    }

    return (
        <>  
            <div id="UserIconMenu">
                <IconButton
                    className="iconButton"
                    onClick={handleClick}
                    color="inherit"
                    //Relactionado ao submenu que abre embaixo:
                    aria-controls="userIconSubMenu" //abre o submenu com esta propriedade
                    aria-haspopup="true" //indica que este elemento controle o submenu
                >
                    <AccountCircleIcon />
                </IconButton>        
            </div>
            <Menu
                id="userIconSubMenu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                //...
                elevation={2}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <MenuItem component="a" href="/usuario" className="infoMenuItem">
                    {/* Obs: component="a" é usado para forçar o reload da 
                        página "usuario" e assim manter a exibição das 
                        informações do usuário sempre atualizadas, 
                        especialmente a informação "número de downloads" */}
                    <div className="infoContainer">
                        <img className={"infoUserAvatar"} src={auth.me.avatar} 
                            alt="Avatar do Usuário"/>
                        <div className={"infoUserName"}>{auth.me.nome}</div>
                        <div className={"infoUserEmail"}>{auth.me.email}</div>
                    </div>
                </MenuItem>             
                <Divider light />
                <StyledMenuItem theme={theme} component="a" href="/usuario">
                    <ListItemIcon>
                        <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Meu Usuário"/>
                </StyledMenuItem>                
                {(auth.me.master === 1) && (
                    /*Apenas Usuário Master tem opção de "Modo Administrador"*/
                    <>
                        <Divider light />
                        <StyledMenuItem theme={theme} component={Link} to= {
                            modoAdmin ? "/inicio" : "/admin"
                        }>
                            <ListItemIcon>
                                {modoAdmin ? 
                                    <SettingsBackupRestoreIcon /> : 
                                    <SettingsApplicationsIcon />
                                }
                            </ListItemIcon>
                            <ListItemText primary= { modoAdmin ? 
                                "Encerrar Modo Administrador" : "Modo Administrador"
                            }/>
                        </StyledMenuItem>
                    </>
                )}
                <Divider light />
                <StyledMenuItem theme={theme} onClick={onLogOut}>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sair" />
                </StyledMenuItem>                      
            </Menu>
        </>
    )
}

const mapStateToProps = (state) => {
    return {auth: state.auth}
}

export default connect(mapStateToProps, {logOutUser})(UserIconMenu)

