import Layout from '../../layout/Layout'
import {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

import {buscarParametros} from '../../redux/actionsAdmin/parametroActions'

import Grid from '@material-ui/core/Grid';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import ListIcon from '@material-ui/icons/List';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import {handleClickRetornar, updateQueryStringParameters} from 
    '../../support/publicFunctions'

import {connect} from "react-redux";

import Loader from '../../components/Loader/Loader'

import TabelaParametros from './components/TabelaParametros/TabelaParametros'
import DialogParametro from './components/DialogParametro/DialogParametro'
import ListaDeErros from './components/ListaDeErros/ListaDeErros'
import MenuSubParametros from './components/MenuSubParametros/MenuSubParametros'

import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import {resetarReduxStore} from '../../redux/actions/authActions'

import { useLocation } from "react-router-dom";

import DialogAdministradores from 
    './components/DialogAdministradores/DialogAdministradores'
import DialogLimitacaoDados from 
    './components/DialogLimitacaoDados/DialogLimitacaoDados'
import DialogSugestoes from 
    './components/DialogSugestoes/DialogSugestoes'


const Parametros = ({
    //props
    tipoDeParametro,
    //reducer states
    parametros,
    isLoading, 
    error,
    colunas, 
    configuracoes,
    //action creators
    resetarReduxStore,
    buscarParametros,
}) => { 

    //Query strings
    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();   
    const desativados = query.get("desativados") === "true" ? true : false;
    const limite = query.get("limite");
    const dataInicio = query.get("dataInicio");
    const dataFim = query.get("dataFim");

    //...
    let history = useHistory();    

    const [openDialogName, setOpenDialogName] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [dadosIniciaisDaEdicao, setDadosIniciaisDaEdicao] = useState(null);

    //Acompanhar states
    useEffect(()=>{console.log("isEdit", isEdit)},[isEdit]) 
    useEffect(()=>{console.log("dadosIniciaisDaEdicao", dadosIniciaisDaEdicao)},
        [dadosIniciaisDaEdicao]);

    useEffect(() => {
        resetarReduxStore();
        const queryStrings = {limite, dataInicio, dataFim}
        buscarParametros({tipo: tipoDeParametro, desativados, queryStrings});
    // eslint-disable-next-line react-hooks/exhaustive-deps            
    }, [tipoDeParametro, desativados]);

    const handleClickOpenDialog = (dialogName) => {
        setOpenDialogName(dialogName);
    };

    return (
        <>{(
            isLoading
        ) ? (
            <Layout pageTitle="Carregando...">
                <Loader mensagem={""}/>
            </Layout>
        ) : (        
            <Layout pageTitle={configuracoes.titulo?.daPaginaPrincipal ||
                (configuracoes.titulo?.noPlural + (desativados ? 
                    " Desativados (as)" : ""))}
            >
                <div>
                    {configuracoes?.metodos?.podeSerCriado 
                        && !desativados ? (
                        <ButtonCustom handleClick = {() => 
                            handleClickOpenDialog("DialogParametro")}
                        >
                            Criar {configuracoes.titulo?.noSingular}
                        </ButtonCustom>
                    ) : (null)}
                    {configuracoes?.metodos?.podeConcederAdministrador 
                        && !desativados ? (
                        <ButtonCustom handleClick = {() => 
                            handleClickOpenDialog("DialogAdministradores")}
                        >
                            Conceder Administrador
                        </ButtonCustom>
                    ) : (null)}                    
                    {!desativados && (
                        configuracoes?.metodos?.podeSerCriado 
                        || configuracoes?.metodos?.podeConcederAdministrador
                    ) ? (
                        <><br /><br /><br /></>
                    ) : (null)}
                    {tipoDeParametro === "ADMINISTRADORES" ? (
                        /* Caso Particular */
                        <DialogAdministradores
                            open = {openDialogName}
                            setOpen = {setOpenDialogName}
                            administradores = {parametros}
                        />
                    ) : (
                        /* Caso Padrão */
                        <DialogParametro
                            openDialogName={openDialogName}
                            setOpenDialogName={setOpenDialogName}
                            tipoDeParametro={tipoDeParametro}
                            titulo={configuracoes.titulo}
                            setIsEdit={setIsEdit}
                            isEdit={isEdit}
                            setDadosIniciaisDaEdicao={setDadosIniciaisDaEdicao}
                            dadosIniciaisDaEdicao={dadosIniciaisDaEdicao}
                        />
                    )}
                    {(tipoDeParametro === "SUGESTOES") && (
                        <DialogSugestoes
                            openDialogName={openDialogName}
                            setOpen = {setOpenDialogName}
                            dadosIniciaisDaEdicao={dadosIniciaisDaEdicao}
                        />
                    )}
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12} >
                            <div className="linhaHorizontal publico"/>
                        </Grid>
                        <Grid 
                            className="barraDeFerramentas publico" 
                            item xs={12} md={12}
                        >
                            <Tooltip title='Retornar'>
                                <IconButton onClick={
                                    ()=>{handleClickRetornar(history)}
                                }>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Tooltip>
                            {configuracoes?.subParametrosTipos 
                                && !desativados ? (
                                <MenuSubParametros 
                                    tipos={configuracoes?.subParametrosTipos}
                                >
                                    <Tooltip 
                                        title={`Editar Sub-Parâmetros`}
                                    >
                                        <IconButton>
                                            <ListIcon />
                                        </IconButton>
                                    </Tooltip>
                                </MenuSubParametros>
                            ) : null}                              
                            {(configuracoes?.metodos?.podeSerDesativado 
                                || configuracoes?.metodos
                                    ?.podeDesativarVersaoMaisRecenteDeDocumento)
                                && !desativados ? (
                                <Tooltip 
                                    title={`Visualizar ${configuracoes.titulo
                                        ?.noPlural} Desativados (as)`}
                                >
                                    <IconButton onClick={() => 
                                        {updateQueryStringParameters([
                                            {key: "desativados", value: "true"},
                                        ])}
                                    }>
                                        <VisibilityOffIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : null} 
                            {configuracoes?.metodos?.podeConfigurarLimitacaoDeDados 
                                && !desativados ? (
                                <>
                                    <Tooltip title='Configurar Limitação de Dados'>
                                        <IconButton onClick={() => (
                                            handleClickOpenDialog(
                                                "DialogLimitacaoDados"
                                    ))}>
                                            <PermDataSettingIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <DialogLimitacaoDados
                                        open = {openDialogName}
                                        setOpen = {setOpenDialogName}
                                    />
                                </>
                            ): null}
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <ListaDeErros 
                                error={error}
                                parametros={parametros}
                            />
                        </Grid>   
                        <Grid item xs={12} md={12} >                           
                            <TabelaParametros 
                                parametros={parametros} 
                                tipoDeParametro={tipoDeParametro}
                                colunas={colunas}
                                configuracoes={configuracoes}
                                handleClickOpenDialog={handleClickOpenDialog}
                                setIsEdit={setIsEdit}
                                setDadosIniciaisDaEdicao={
                                        setDadosIniciaisDaEdicao}
                                desativados={desativados}
                            />
                        </Grid>                        
                    </Grid>                    
                </div>
            </Layout>
        )}</>            
    ) 
}

const ButtonCustom = ({handleClick, children, ...other}) => {
    return(
        <Button 
            variant="contained" 
            color="primary" 
            onClick={handleClick}
            style={{marginRight: "1rem"}}
            {...other}
        >
            <AddCircleOutlineIcon /> &nbsp; 
            {children}
        </Button>        
    )
}


const mapStateToProps = (state) => ({
    //adminParametro
    parametros: state.adminParametro.parametros,
    isLoading: state.adminParametro.isLoading,
    error: state.adminParametro.error,
    colunas: state.adminParametro.colunas,
    configuracoes: state.adminParametro.configuracoes,
});

export default connect(
    mapStateToProps, 
    {resetarReduxStore, buscarParametros}
)(Parametros)