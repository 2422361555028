import axios from 'axios';
import {attachTokenToHeaders, handleErrorForAllActions, 
  encontrarMensagemDeErro} from '../actionFunctions';

import {apoioBuscarParametro} from './parametroActions'

import {
  EDITAR_PARAMETRO_LOADING,
  EDITAR_PARAMETRO_SUCCESS,
  EDITAR_PARAMETRO_FAIL,
} from '../actionTypesAdmin';


const desativarVersaoDeDocumento = ({id, numeroVersao}) => async (dispatch, getState) => {
  dispatch({ 
    type: EDITAR_PARAMETRO_LOADING,
    payload: {id}
  });
  try {
    //1. Desativa versão e todos os seus arquivos
    const url = `/api/administradores/documentos/${id}/versoes/${numeroVersao}`;
    const options = attachTokenToHeaders(getState);
    await axios.delete(url, options);
    //2. busca novamente o documento após a edição acima
    const documentoAposDesativacao = await apoio.buscarParametro({getState, 
      tipo: "DOCUMENTOS", id});
    //3. Faz o dispatch
    dispatch({
      type: EDITAR_PARAMETRO_SUCCESS,
      payload: {parametro: documentoAposDesativacao}
    })
  } catch (err) {
    handleErrorForAllActions(err, getState);
    const error = {
      title: `A versão do Documento não foi desativada porque ocorreu um Erro`,
      message: encontrarMensagemDeErro(err),
    }
    alert(`${error?.title} \n\nNenhuma modificação foi feita.`);
    dispatch({
      type: EDITAR_PARAMETRO_FAIL,
      payload: {error, id}
    })
  }
}

const apoio = {
  buscarParametro: apoioBuscarParametro,
}


export {  
  desativarVersaoDeDocumento,
}