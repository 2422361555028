import React, { useEffect, useState } from 'react';
import Layout from '../../layout/Layout'


import {connect} from "react-redux";
import {useParams, useHistory} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import {ptBR} from "date-fns/locale"
import {format} from 'date-fns'

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import Paper from '@material-ui/core/Paper';
import ReactPdf from "./components/ReactPdf"

import {imprimir, converterArrayParaTextoEmPortugues,
    formatarCnpj, capitalizeFirstLetter, obterTituloDoDocumento} from '../../support/publicFunctions'
import {buscarDocumento} from '../../redux/actions/documentoActions'
import {buscarArquivosDeUmDocumento, baixarArquivos} from '../../redux/actions/arquivoActions'

//Componentes
import Loader from '../../components/Loader/Loader'
import ClosableAlert from '../../components/ClosableAlert/ClosableAlert'

//Ícones
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import PageviewIcon from '@material-ui/icons/Pageview';


import './Documento.css'

import { useTheme } from '@material-ui/core/styles';

import MenuDeBaixarDocumentos from './components/MenuDeBaixarDocumentos'
import BotaoDeCompartilhar from '../../pages/Testes/PaginaTeste'
import MenuDeAcessarOutrasVersoes from '../../pages/Testes/PaginaTeste2'
import MenuDeConsultarHistorico  from '../../pages/Testes/PaginaTeste3'

//...
import NaoEncontrado from '../../pages/NaoEncontrado/NaoEncontrado'
import CircularProgress from '@material-ui/core/CircularProgress';


const Documento = ({token, 
    documento, documentoIsLoading,  documentoWasLoaded, buscarDocumento,
    arquivos, arquivosIsLoading, arquivosWasLoaded, buscarArquivosDeUmDocumento,
    baixarArquivos, baixarIsLoading, visualizarPdfIsLoading,
}) => {


    const [dataDeUploadDoArquivo, setDataDeUploadDoArquivo] = useState(null);
    const [usuarioQueCarregouArquivo, setUsuarioQueCarregouArquivo] = 
        useState(null);
    const [cnpjDaEntidade, setCnpjDaEntidade] = useState(null);
    const [formatosDisponíveis, setFormatosDisponíveis] = useState(null);
    const [versaoDoDocumento, setVersaoDoDocumento] = useState(null);
    const [uuidDoArquivoEmPdf, setUuidDoArquivoEmPdf] = useState(null);

    const parametroVersaoDoDocumento = useParams().numeroDaVersao; //Obs: importante diferenciar a versão do documento que é recebida e passada como parâmetro, da versão do documento que é recebida da API e exibida na página (porque esta última recebe um string de "Não disponível" quando não é encontrada)
    const parametroUuidDoDocumento = useParams().uuid;

    let history = useHistory();

    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 
    // 1. Funções Principais
    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 
    
    //Buscar documentos e os arquivos daquela versão do documento
    useEffect(() => {
        buscarDocumento(parametroUuidDoDocumento); 
        buscarArquivosDeUmDocumento(parametroUuidDoDocumento, parametroVersaoDoDocumento);     
    }, []); // eslint-disable-line 
                //(neste caso pode ser desativado, porque é desejável apenas uma única execução)

    
    //Carregar informações depois que o documento e os arquivos da versão deste documento são carregados
    useEffect(() => {
        const carregarInformacoesDaPagina = () => {
            if (documentoWasLoaded && arquivosWasLoaded) {
                setCnpjDaEntidade(formatarCnpj(documento.cnpjDaEntidade));
                setDataDeUploadDoArquivo(formatarDataDeUploadDoArquivo());
                setUsuarioQueCarregouArquivo(obterUsuarioQueCarregouArquivo());
                setVersaoDoDocumento(obterVersãoDoDocumento());
                setFormatosDisponíveis(obterFormatosDisponíveis());
            }
        }
        carregarInformacoesDaPagina();
    }, [documentoWasLoaded, arquivosWasLoaded]); // eslint-disable-line 
                                                    //(neste caso pode ser desativado, porque é desejável apenas uma única execução)       

    // Obter uuid do arquivo em pdf (depois que as informações dos arquivos foram carregadas)
    useEffect(() => {
        if (arquivosWasLoaded) {
            setUuidDoArquivoEmPdf(obterUuidDoArquivoEmPdf)
    }}, [arquivosWasLoaded]); // eslint-disable-line 
        //(obs: nos casos acima (setUuidDoArquivoEmPdf) o "eslint-disable-line" pode ser utilizado, porque é desejável apenas uma única execução)


    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 
    // 2. Funções para apoio
    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 

    const handleClickRetornar = () => history.goBack()

    const obterUuidDoArquivoEmPdf = () => {
        let arquivoEmPdf = arquivos.find(arquivo => arquivo.extensao === "pdf");        
        let uuidDoArquivoEmPdf = (arquivoEmPdf ? arquivoEmPdf.uuid : "")
        return(uuidDoArquivoEmPdf)
    }
    

    const formatarDataDeUploadDoArquivo = () => {
        //Esta formatação retorna um string ao qual o mês é escrito em português e com a primeira letra maíscula
            //Exemplo: 01/Dez/2020
        let dataDeUpload =  obterDataDeUploadDoArquivo();
        return(
            dataDeUpload? 
                format(new Date(dataDeUpload),'dd') + ' de ' + 
                capitalizeFirstLetter( format(new Date(dataDeUpload),'MMMM', { locale: ptBR }) ) + ' de ' +
                format(new Date(dataDeUpload),'yyyy') 
                :""
        )
    }

    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 
    /*[ATENÇÃO!] Obs: esta página, e em especial as funções abaixo (obterDataDeUploadDoArquivo e obterDataDeUploadDoArquivo), adotam como 
        premissa que todos os arquivos de um mesma versão de um documento serão carregadas em uma mesma data e por um mesmo usuário*/
        /*Datas de upload distintas (ou usuários distintos no upload) implicariam versões distintas*/  
        /*Assim, caso esta premissa mude, esta estas funções e esta página como um todo precisam mudar este paradigma */
    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 
    const obterDataDeUploadDoArquivo = () =>  (arquivos[0] ? arquivos[0].dataDeUpload : null);
    const obterUsuarioQueCarregouArquivo = () =>  (arquivos[0] ? arquivos[0].usuarioQueCarregou : null);
    const obterVersãoDoDocumento = () => (arquivos[0] ? arquivos[0].numeroDaVersao : null);
    const obterIdentificacaoDoLote = () => (arquivos[0] ? arquivos[0].identificacaoDoLoteMd5sum : null);
    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 

    const obterFormatosDisponíveis = () => {
        let arrayFormatosDisponíveis = arquivos.map(
            (arquivo)=>{
                return (`${arquivo.extensao}`)
        })
        return converterArrayParaTextoEmPortugues(arrayFormatosDisponíveis)
    }


    const handleClickVisualizarEmPdf =  (event) => {        
        function visualizarEmPdf() { 
            if(uuidDoArquivoEmPdf){
                baixarArquivos({
                    uuids: [uuidDoArquivoEmPdf],
                    isInline: true
                })
            } else {
                alert("Visualização em PDF não disponível");
            }
        }
        event.preventDefault();
        visualizarEmPdf();
    }    

    
    const tituloDoDocumento = obterTituloDoDocumento(
        documento.tipoDeDocumento,
        documento.descritivo,
        documento.entidade, 
        documento.ano, 
        documento.mes, 
        documento.dia,
        versaoDoDocumento
    );


    function getTextoDoBotaoDeCompartilhar(){
        return (
            `Documento "${tituloDoDocumento}" ` +
            `(disponível a todos os usuários do Sistema Apollo Lunar que ` + 
            `tenham permissão de acesso a este conteúdo): `
        )
    }

    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 
    // 3. Renderização do componente
    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 
    return(<>{
        (documentoIsLoading || arquivosIsLoading) ?(
            <Layout pageTitle="Carregando...">
                <Loader mensagem={""}/>
            </Layout>
        ) : (
            !documento.id? (
                <NaoEncontrado />
            ) : (
                <Layout pageTitle={tituloDoDocumento}>
                    <div id="documento">
                        <Grid container spacing={1}> 
                            <Grid item xs={12} md={6} >
                                <p class="lunar subCabecalhoDescritivo hasMarginRight"><b>Documento</b></p>                            
                                <p class="lunar subCabecalhoDescritivo hasMarginRight">Versão Atual: {versaoDoDocumento === documento.numeroDaVersaoMaisRecente ? "Sim" : "Não"}</p>
                                <p class="lunar subCabecalhoDescritivo hasMarginRight">Versão: {imprimir(versaoDoDocumento)} (de {imprimir(documento.numeroDaVersaoMaisRecente)})</p>
                                <p class="lunar subCabecalhoDescritivo hasMarginRight">Inserido por: {imprimir(usuarioQueCarregouArquivo)}, em {imprimir(dataDeUploadDoArquivo)}</p>
                                <p class="lunar subCabecalhoDescritivo hasMarginRight">Formatos: {imprimir(formatosDisponíveis)}</p>
                                <p class="lunar subCabecalhoDescritivo hasMarginRight">Frequência deste Relatório: {imprimir(capitalizeFirstLetter((documento.frequenciaDoTipoDeDocumento || "").toLowerCase()))}</p>
                            </Grid>    
                            <Grid item xs={12} md={6}>
                                <p class="lunar subCabecalhoDescritivo"><b>Entidade</b></p>
                                <p class="lunar subCabecalhoDescritivo">Apelido: {imprimir(documento.entidade)}</p>
                                <p class="lunar subCabecalhoDescritivo">Razão Social: {imprimir(documento.razaoSocialDaEntidade)}</p>
                                <p class="lunar subCabecalhoDescritivo">CNPJ: {imprimir(cnpjDaEntidade)}</p>
                                <p class="lunar subCabecalhoDescritivo">ISIN: {imprimir(documento.isinDaEntidade)}</p> 
                                <p class="lunar subCabecalhoDescritivo">Administrador: {imprimir(documento.administradorDaEntidade)}</p>
                            </Grid>                         
                        </Grid>    
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={12} >
                                <div className="linhaHorizontal"></div>
                            </Grid>
                            <Grid className="barraDeFerramentas publico" item xs={12} md={12} >
                                <Tooltip title='Retornar'>
                                    <IconButton  
                                        onClick={handleClickRetornar}
                                    >
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Tooltip>
                                {visualizarPdfIsLoading ? (
                                    <IconButton>
                                        <IconLoader />
                                    </IconButton>
                                ) : (
                                    <Tooltip title='Visualizar em PDF'>
                                        <IconButton 
                                            onClick={handleClickVisualizarEmPdf}
                                        >
                                            <PictureAsPdfIcon />
                                        </IconButton>
                                     </Tooltip>       
                                )}         
                                    <MenuDeBaixarDocumentos
                                        arquivos={arquivos}
                                        baixarArquivos={baixarArquivos}
                                        tooltipTitle='Baixar'
                                    >
                                        {baixarIsLoading && !visualizarPdfIsLoading ? (
                                            <IconLoader />
                                        ) : (
                                            <GetAppIcon />
                                        )}      
                                    </MenuDeBaixarDocumentos>                                        
                                <MenuDeAcessarOutrasVersoes
                                    numeroDaVersaoMaisRecente =
                                        {documento.numeroDaVersaoMaisRecente}
                                    versaoAtual={versaoDoDocumento}
                                    uuidDoDocumento={documento.uuid}
                                    tooltipTitle=
                                        {'Acessar outras Versões'}
                                >
                                    <DynamicFeedIcon />
                                </MenuDeAcessarOutrasVersoes>                              
                                <MenuDeConsultarHistorico
                                    documento={documento}
                                    tituloDoDocumento = {obterTituloDoDocumento(
                                        documento.tipoDeDocumento,
                                        documento.entidade
                                    )}
                                    identificacaoDoLote = {
                                        obterIdentificacaoDoLote()
                                    }
                                    tooltipTitle={"Sugestões de Consulta"}
                                >
                                    <PageviewIcon />
                                </MenuDeConsultarHistorico>
                                <BotaoDeCompartilhar 
                                    textoDoBotao = {getTextoDoBotaoDeCompartilhar()}
                                    tituloDoBotao = "Compartilhar Documento"
                                    tooltipTitle = 'Compartilhar este Documento'
                                >
                                    <ScreenShareIcon />
                                </BotaoDeCompartilhar>                                                  
                            </Grid>
                            {versaoDoDocumento !== 
                                documento.numeroDaVersaoMaisRecente && 
                                versaoDoDocumento > 0 &&
                                <Grid item xs={12} md={12} >
                                    <AlertaDeVersaoAntiga documento={documento}/>
                                </Grid>                            
                            }
                            <Grid item xs={12} md={12} >
                                <Paper elevation={2} className="reactPdfContainer">
                                    {(uuidDoArquivoEmPdf) ? (
                                        <> 
                                            <ReactPdf arquivoEmPdf={{
                                                url: "/api/representacoes/" + uuidDoArquivoEmPdf,
                                                httpHeaders: {
                                                    'Authorization': 'Bearer ' + token,
                                                    'Accept': 'application/octet-stream'
                                                }
                                            }}/>
                                        </>
                                    ) : (
                                        <div className="mensagemVisualizacaoNaoDisponivel">
                                            <span>Visualização não disponível</span>
                                        </div>
                                    )}

                                </Paper>                                                           
                            </Grid>                        
                        </Grid>                    
                    </div>
                </Layout>   
            )
        )}</>
    )
}


const AlertaDeVersaoAntiga = ({documento}) => {
    const theme = useTheme();
    const linkStyle = {color: theme.palette.primary.dark};
    const href = `/documentos/${documento.uuid}/` + 
        `versoes/${documento.numeroDaVersaoMaisRecente}`

    return(
        <ClosableAlert 
            alertTitle="Versão Antiga"
            severity={"warning"}
            marginBottom = {"0.75rem"}
            elevation = {1}
        >
            <span>Este documento foi atualizado e caso queira acessar a </span>
            <span style={linkStyle}>
                {/*Obs: a utilização abaixo do elemento "a" no lugar do elemento 
                    "Link" é proposital, para forçar o reset dos states*/}
                <a className="textoLink visitedColorChangeDisabled" href={href}>
                    <b>versão mais atual clique aqui</b>
                </a>
            </span>
            <span>.<br /></span>
            <span> Ou se realmente deseja visualizar esta versão desatualizada, 
                fique a vontade para fechar este aviso.
            </span>
        </ClosableAlert>   
    )
}

const IconLoader = () => {
    return(
        <CircularProgress size={"1.5rem"} />
    )
}

/*-----------------------------------------------------------------------------------------------------------------------------------*/ 
// 4. Configurações do componente
/*-----------------------------------------------------------------------------------------------------------------------------------*/ 
const mapStateToProps = (state) => ({
    //token (para a requisição do pdf que é renderizado na página)
    token: state.auth.token,
    //documento
    documento: state.documento.documento,
    documentoIsLoading: state.documento.isLoading,
    documentoWasLoaded: state.documento.wasLoaded,
    //arquivos
    arquivos: state.arquivos.arquivos,
    arquivosIsLoading: state.arquivos.isLoading,
    arquivosWasLoaded: state.arquivos.wasLoaded,
    //downloadDosArquivos
    baixarIsLoading: state.downloadDosArquivos.isLoading,
    visualizarPdfIsLoading: state.downloadDosArquivos.isLoadingConteudoInline,
});

export default connect(mapStateToProps, {buscarDocumento, buscarArquivosDeUmDocumento, baixarArquivos})(Documento);
