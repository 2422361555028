
import { useHistory } from "react-router-dom";
import Loader from '../../../components/Loader/Loader'

const RedirectionToLogin = () => {    
    const history = useHistory();  
    
    guardarUrlAtualNoLocalStorage();  
    history.push('/login');
    
    return(
      <Loader fullPage/>
    )
  
}
    
const LoginSucessHandler = () => {
    const history = useHistory();
    const redirectionUrl = localStorage.getItem('urlAntesDoLogin');    

    limparLocalStorage();
    if(redirectionUrl){
      window.location.href = redirectionUrl} 
      else {history.push("/inicio")};

    return(
      <Loader fullPage/>
    )
}


const LoginFailHandler = ({forceLoginError}) => {
  const history = useHistory();

  limparLocalStorage();
  forceLoginError(); //TODO: analisar se esta é realmente uma boa solução para esta caso (?ter criado esta action do Redux foi ok? Seria uma prática adequada?)
  history.push("/login");
  
  return(
    <Loader fullPage/>
  )
}


function guardarUrlAtualNoLocalStorage() {
  localStorage.setItem('urlAntesDoLogin', window.location.href)
}
function limparLocalStorage() {
  localStorage.removeItem('urlAntesDoLogin');
  sessionStorage.removeItem('forcouNovoLogin');
}



export {
    RedirectionToLogin, 
    LoginSucessHandler,
    LoginFailHandler
}