import { BUSCAR_OPTIONS_LOADING, BUSCAR_OPTIONS_SUCCESS, BUSCAR_OPTIONS_FAIL,
    } from '../actionTypesAdmin';


const initialState = {
    options: [],
    isLoading: false,
    error: null,
};


const options = (state = initialState, { type, payload }) => {
    switch (type) {
        case BUSCAR_OPTIONS_LOADING:            
            return {
                ...state,
                isLoading: true,
                error: null,
            }                        
        case BUSCAR_OPTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                options: payload.options
            }                   
        case BUSCAR_OPTIONS_FAIL:
            return {
                ...state,
                isLoading: false,
                options: [], 
                error: payload.error                        
            }          
        default:
            return state;
    }
}

export default options