import './DesktopDropDownMenu.css'

import {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useTheme} from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {withStyles} from '@material-ui/core/styles';

import ListItemText from '@material-ui/core/ListItemText';


const StyledMenuItem = withStyles(
    (theme) => { 
        return {
            root: {
                '&:hover': {
                    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                        color: theme.palette.primary.main,
                    },
                },
                '& .MuiListItemText-primary': {
                    fontWeight: 400
                },     
            },
        }
    }
)(MenuItem)
  

const DesktopDropDownMenu = ({options, activeLink, children}) => {

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      event.preventDefault(); /*para evitar que o link seja acessado (já que
        o link neste contexto é unicamente p/ deixar o botão no modo "active")*/
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };    

    return(
        <span id = "desktopDropDownMenu">  
            <NavLink 
                className="navLink navLinkMenu centralizadoVerticalmente"
                onClick={handleClick} 
                to={activeLink}  
                //Relactionado ao submenu que abre embaixo:
                aria-controls="desktopDropDownMenu" 
                aria-haspopup="true" 
            >
                <span>{children}</span>
                <ArrowDropDownIcon className="arrowDropDownIcon"/>
            </NavLink>
            <Menu
                id="desktopDropDownSubMenu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                //...
                elevation={2}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
            >   
                {options.map(option => {return(
                    <StyledMenuItem 
                        component={NavLink} 
                        to={option.to} 
                        onClick={handleClose}
                        theme={theme} 
                    >
                        <ListItemText primary={option.label} />
                    </StyledMenuItem>                            
                )})}
            </Menu>
        </span>
    )
}

export default DesktopDropDownMenu