import { useEffect, useState } from 'react';
import Layout from '../../layout/Layout'
import {useParams, useHistory} from "react-router-dom";
import {connect} from "react-redux";

import Grid from '@material-ui/core/Grid';

import {format} from 'date-fns'
import {ptBR} from "date-fns/locale"

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import {converterArrayParaTextoEmPortugues, obterTituloDoDocumento} 
    from '../../support/publicFunctions'
import {buscarDocumento} from '../../redux/actions/documentoActions'
import {buscarArquivosDeUmDocumento} from '../../redux/actions/arquivoActions'

//Componentes
import Loader from '../../components/Loader/Loader'

//Ícones
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MUIDataTable from "mui-datatables";

//...
import NaoEncontrado from '../../pages/NaoEncontrado/NaoEncontrado'
import textLabels from "../../support/MuiDatatableSupport/TextLabels"

import './Versoes.css'


const Versoes = ({
    documento, documentoIsLoading,  documentoWasLoaded, buscarDocumento,
    arquivos, arquivosIsLoading, arquivosWasLoaded, buscarArquivosDeUmDocumento,
}) => {

    let history = useHistory();    
    const uuidDoDocumento = useParams().uuid;

    const [dados, setDados] = useState([]); 
    const [colunas, setColunas] = useState([]); 

    /*------------------------------------------------------------------------*/ 
    // 1. Funções Principais
    /*------------------------------------------------------------------------*/     
    useEffect(() => {
        buscarDocumento(uuidDoDocumento); 
        buscarArquivosDeUmDocumento(uuidDoDocumento);    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
    
    useEffect(() => {
        function carregarColunas() {
            const colunas = [
                'Versão Nº', 'Inserida Por', 'Inserida Em', 'Formatos'
            ]
            setColunas(colunas);
        }
        function carregarDados (){
            const dados = [];
            const ultimaVersao = documento.numeroDaVersaoMaisRecente
            for (let i = 1; i <= ultimaVersao; i++){
                let dado = [];
                const versaoAtual = i;
                const arquivo = arquivos
                    .find(arquivo => arquivo.numeroDaVersao === versaoAtual)
                if(arquivo){
                    //usuarioQueCarregou
                    const usuarioQueCarregou = arquivo.usuarioQueCarregou;
                    //dataDeUpload
                    let dataDeUpload = arquivo.dataDeUpload
                    //extensoes
                    const arquivosNestaVersao = arquivos
                        .filter(arquivo => 
                            arquivo.numeroDaVersao === versaoAtual)                
                    let extensoes = arquivosNestaVersao
                        .map(arquivo => arquivo.extensao)
                    //formatação dos dados
                    dataDeUpload = format(
                        new Date(dataDeUpload), 'yyyy-MM-dd', { locale: ptBR });
                    extensoes = converterArrayParaTextoEmPortugues(extensoes);
                    //final
                    dado = [
                        versaoAtual, 
                        usuarioQueCarregou, 
                        dataDeUpload, 
                        extensoes
                    ];
                } else {
                    dado = [
                        versaoAtual, 
                        "NÃO DISPONÍVEL", 
                        "NÃO DISPONÍVEL", 
                        "NÃO DISPONÍVEL",
                    ];                    
                }
                dados.push(dado);
            }
            setDados(dados);
        }
        if (documentoWasLoaded && arquivosWasLoaded) {
            carregarDados();
            carregarColunas();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentoWasLoaded, arquivosWasLoaded]);    


    /*------------------------------------------------------------------------*/ 
    // 2. Funções para apoio
    /*------------------------------------------------------------------------*/
    const handleClickRetornar = () => history.goBack()

    const tituloDoDocumento = obterTituloDoDocumento(
        documento.tipoDeDocumento,
        documento.descritivo,
        documento.entidade, 
        documento.ano, 
        documento.mes, 
        documento.dia
    );

    const opcoesDaTabela = {
        elevation: 2,
        responsive: "vertical",  
        print: false,
        download: false,
        viewColumns: false,
        search: false,                     
        pagination: false,
        sort: true,
        filter: false, 
        filterType: "multiselect", 
        selectableRows: 'none',
        onRowClick: onRowClick,
        textLabels: textLabels,
        sortOrder: {
            name: 'Versão Nº',
            direction: 'desc'
        }
    }    

    function onRowClick(rowData, rowMeta){
        let numeroDaVersao = rowData[0];
        history.push(`/documentos/${uuidDoDocumento}/versoes/${numeroDaVersao}`)
    }    

    
/*------------------------------------------------------------------------*/ 
// 3. Renderização do componente
/*------------------------------------------------------------------------*/ 
    return(
        <>{(documentoIsLoading || arquivosIsLoading 
            ) ? (
                <Layout pageTitle="Carregando...">
                    <Loader mensagem={""}/>
                </Layout>
            ) : (
                !documento.id? (
                    <NaoEncontrado />
                ) : (
                    <Layout pageTitle = 
                        {"Versões do Documento: '" + tituloDoDocumento + "'"}
                    >
                        <div id="versoes">
                            <Grid container spacing={0}>
                                <Grid item xs={12} md={12} >
                                    <div className="linhaHorizontal"></div>
                                </Grid>
                                <Grid className="barraDeFerramentas publico" 
                                    item xs={12} md={12} 
                                >
                                    <Tooltip title='Retornar'>
                                        <IconButton  
                                            onClick={handleClickRetornar}
                                        >
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} md={12} >                           
                                    <MUIDataTable className="clickableTd"
                                        data={dados}
                                        columns={colunas}
                                        options={opcoesDaTabela}
                                    />
                                </Grid>                        
                            </Grid>                    
                        </div>
                    </Layout>
                )
         )}</>
    )
}


/*------------------------------------------------------------------------*/ 
// 4. Configurações do componente
/*------------------------------------------------------------------------*/ 
const mapStateToProps = (state) => ({
    //documento
    documento: state.documento.documento,
    documentoIsLoading: state.documento.isLoading,
    documentoWasLoaded: state.documento.wasLoaded,
    //arquivos
    arquivos: state.arquivos.arquivos,
    arquivosIsLoading: state.arquivos.isLoading,
    arquivosWasLoaded: state.arquivos.wasLoaded,
});

export default connect(
    mapStateToProps, 
    {buscarDocumento, buscarArquivosDeUmDocumento}
)(Versoes);
