import {useEffect, useState} from 'react';
import './Step3.css'

import MUIDataTable from "mui-datatables";
import textLabels from "../../../../support/MuiDatatableSupport/TextLabels"

import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import {connect} from "react-redux";

import {buscarDocumentosPorAtributos} from '../../../../redux/actions/documentoActions'

import Loader from '../../../../components/Loader/Loader'


import {Link} from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';

import Button from '@material-ui/core/Button';


const Step3 = ({
    avancarEtapa, handleCancelar, documentosParaRevisar,
    documentosParaCriar, documentosParaSubstituir,
    setDocumentosParaCriar, setDocumentosParaSubstituir,
    buscarDocumentosPorAtributos, dadosDeDocumentosJaExistentes, dadosDeDocumentosJaExistentesIsLoading
}) => { 

    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 
    // 1. Parte Principal
    /*-----------------------------------------------------------------------------------------------------------------------------------*/   
    
    //States
    const [dadosDeDocumentosParaInsercao, setDadosDeDocumentosParaInsercao] = useState(null); //obs: aqui tem que ser necessariamente null para não dar problema no criacaoDeDadosIsLoading
    const [dadosDeDocumentosParaSubstituicao, setDadosDeDocumentosParaSubstituicao] = useState(null); //obs: aqui tem que ser necessariamente null para não dar problema no criacaoDeDadosIsLoading
    const [criacaoDeDadosIsLoading, setCriacaoDeDadosIsLoading] = useState(true);

    //Acompanhar mudanças
    useEffect(()=>console.log("dadosDeDocumentosParaInsercao", dadosDeDocumentosParaInsercao),[dadosDeDocumentosParaInsercao])
    useEffect(()=>console.log("dadosDeDocumentosParaSubstituicao", dadosDeDocumentosParaSubstituicao),[dadosDeDocumentosParaSubstituicao])
    useEffect(()=>console.log("criacaoDeDadosIsLoading", criacaoDeDadosIsLoading),[criacaoDeDadosIsLoading])    

    useEffect(() => {
        function scrollPageToTop(){
            window.scrollTo(0, 0)
        }
        scrollPageToTop()
    }, [])
    
    useEffect(() => {
        function buscarDadosDeDocumentosJaExistentes() {
            if(documentosParaRevisar?.length){
                setCriacaoDeDadosIsLoading(true);
                const arrayDeAtributos = documentosParaRevisar.map(
                    (documentoParaRevisar)=>{
                        const atributos = {
                            descritivoId: documentoParaRevisar?.dados?.descritivoId,
                            entidadeId: documentoParaRevisar?.dados?.entidadeId,
                            ano: documentoParaRevisar?.dados?.ano,
                            mes: documentoParaRevisar?.dados?.mes,
                            dia: documentoParaRevisar?.dados?.dia
                        };
                        return atributos;
                })
                buscarDocumentosPorAtributos(arrayDeAtributos); 
            } else {
                setCriacaoDeDadosIsLoading(false);
            }
        }
        buscarDadosDeDocumentosJaExistentes();
    }, [documentosParaRevisar, buscarDocumentosPorAtributos]);    


    useEffect(() => {
        //TODO: fazer refatoração completamente na função abaixo!!!
        function carregarInformacoesDaPagina(){
            if(dadosDeDocumentosJaExistentes?.length){

                let dadosDeDocumentosParaInsercao = [];
                let dadosDeDocumentosParaSubstituicao = [];
                let documentosParaCriar = [];
                let documentosParaSubstituir = [];

                dadosDeDocumentosJaExistentes.map(
                    (documentoJaExistente, index)=>{

                        //inicializacões
                        const textoChaveUnica = documentosParaRevisar[index]?.chaveUnica
                        const documentoParaRevisar = documentosParaRevisar[index]?.dados;
                        const documentoJaExiste = documentoJaExistente?.id ? true: false;
                        
                        //dados                        
                        const documentoAnterior = null;
                        const inserir = 'SIM';
                        const chaveUnica = textoChaveUnica;
                        const tema = documentoParaRevisar?.tema; 
                        const descritivoCompleto = documentoParaRevisar?.descritivoCompleto; 
                        const descritivoId = documentoParaRevisar?.descritivoId; 
                        const entidade = documentoParaRevisar?.entidade;
                        const entidadeId = documentoParaRevisar?.entidadeId;
                        const dataDeReferencia = 
                            (documentoParaRevisar?.ano ? documentoParaRevisar.ano : "") + 
                            (documentoParaRevisar?.mes ? "." + documentoParaRevisar.mes : "") + 
                            (documentoParaRevisar?.dia ? "." + documentoParaRevisar.dia : "") 
                            || "-"
                        const ano = documentoParaRevisar?.ano; 
                        const mes = documentoParaRevisar?.mes; 
                        const dia = documentoParaRevisar?.dia; 
                        const novaVersao = documentoJaExiste ? 
                            documentoJaExistente.numeroDaVersaoMaisRecente + 1 : 
                            "1"                       
                        const uuidDoDocumento = documentoJaExiste ? 
                            documentoJaExistente.uuid :
                            null;
                        const nomeDosArquivos = documentosParaRevisar[index]?.files.map(file => file.name); 
                        const arquivos = documentosParaRevisar[index]?.files                     
                        
                        //dados array
                        const dados = [
                            documentoAnterior,
                            inserir,
                            chaveUnica,
                            tema,
                            descritivoCompleto,
                            descritivoId,
                            entidade,
                            entidadeId,
                            dataDeReferencia, 
                            ano,
                            mes, 
                            dia,
                            novaVersao,
                            uuidDoDocumento,
                            nomeDosArquivos,
                        ]

                        //documentos
                        const documentos = {
                            inserir: true, 
                            files: arquivos,
                            uuid: uuidDoDocumento,
                            atributos: {
                                'entidade': entidade,
                                'entidadeId': entidadeId,
                                'descritivoCompleto' : descritivoCompleto,
                                'descritivoId': descritivoId,
                                'ano': ano,
                                'mes': mes,
                                'dia': dia,
                                'novaVersao': novaVersao,         
                                'nomeDosArquivos': nomeDosArquivos
                            }                                                        
                        }
                        
                        //Atualização final
                        if(documentoJaExiste){
                            dadosDeDocumentosParaSubstituicao.push(dados);
                            documentosParaSubstituir.push(documentos);
                        } else {
                            dadosDeDocumentosParaInsercao.push(dados);
                            documentosParaCriar.push(documentos);
                        }

                        //fim
                        return false
                })

                //Atualiza States
                setDadosDeDocumentosParaInsercao(dadosDeDocumentosParaInsercao);
                setDadosDeDocumentosParaSubstituicao(dadosDeDocumentosParaSubstituicao);
                setDocumentosParaCriar(documentosParaCriar);
                setDocumentosParaSubstituir(documentosParaSubstituir);
                /*Resetar buscar documentos por atributos //TODO: analisar se esta linha
                    abaixo vai ser mantida ou apagada (ou ver uma forma bem melhor de fazer isto aqui!!!)*/
                buscarDocumentosPorAtributos([]);
            }
        }
        carregarInformacoesDaPagina();
    }, [dadosDeDocumentosJaExistentes, documentosParaRevisar, setDocumentosParaCriar, setDocumentosParaSubstituir, buscarDocumentosPorAtributos]);    

    useEffect(() => {
        function encerrarLoading(){ //TODO: ?aprimorar este loadind aqui para contemplar também documentosParaCriar e documentosParaSubstituir?
            if (dadosDeDocumentosParaInsercao && dadosDeDocumentosParaSubstituicao){
                setCriacaoDeDadosIsLoading(false);
            }
        }
        encerrarLoading();
    }, [dadosDeDocumentosParaInsercao, dadosDeDocumentosParaSubstituicao])


    

    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 
    // 2. Funções para apoio
    /*-----------------------------------------------------------------------------------------------------------------------------------*/

    function getColunas({modoDeSubstituicao = false}){
        let colunas = [
            {
                label: 'Documento Existente', 
                name: 'documentoAnterior',
                options: {
                    display: modoDeSubstituicao? true: "excluded",
                    filter: false,
                    sort: false, 
                    customBodyRenderLite: (dataIndex) => {
                        let colunaUuidDoDocumento = colunas.findIndex(obj => obj.name === "uuidDoDocumento");
                        let colunaNovaVersao = colunas.findIndex(obj => obj.name === "novaVersao");

                        let uuidDoDocumento  = modoDeSubstituicao ?
                            dadosDeDocumentosParaSubstituicao[dataIndex][colunaUuidDoDocumento] :
                            null
                        let novaVersao = modoDeSubstituicao ?
                            dadosDeDocumentosParaSubstituicao[dataIndex][colunaNovaVersao] :
                            null

                        let value = modoDeSubstituicao ? (
                            <Link className="textoLink"
                                to={`/documentos/${uuidDoDocumento}/versoes/${novaVersao - 1}`}
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <Tooltip title='Verificar o documento que será substituído (o link abrirá em uma nova página)'>
                                    <IconButton>
                                        <DescriptionIcon fontSize='normal'/>
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        ) : (
                            <></>
                        );
                        return value
                    },
            }},               
            {
                label: `Confirmar${modoDeSubstituicao? " Substituição" : ""}?`, 
                name: 'inserir', 
                options: {
                    filter: false,  
                    sort: false, 
                    customBodyRenderLite: (dataIndex) => {                        
                        const getValue = () => {
                            if(modoDeSubstituicao){
                                return documentosParaSubstituir[dataIndex]?.inserir;
                            } else {
                                return documentosParaCriar[dataIndex]?.inserir;
                            }
                        }
                        const updateValue = (newValue) => {
                            if(modoDeSubstituicao){
                                let newArray = [...documentosParaSubstituir] /*isso aqui força que o state seja atualizado*/ 
                                newArray[dataIndex].inserir = newValue;
                                setDocumentosParaSubstituir(newArray);                                 
                            } else {
                                let newArray = [...documentosParaCriar] /*isso aqui força que o state seja atualizado*/ 
                                newArray[dataIndex].inserir = newValue;
                                setDocumentosParaCriar(newArray); 
                            }
                        }
          
                        const handleChange = (event) => {
                            updateValue(event.target.checked);
                        };
                        return (
                            <FormControlLabel
                                label={getValue() ? "Sim" : "Não"}
                                control = {
                                    <Switch
                                        color="primary"
                                        checked={getValue()}
                                        onChange={handleChange}
                                    />
                                }
                            />
                        );
                    }            
                }
            },    
            {
                label: '', 
                name: 'chaveUnica',
                options: {display: "excluded", filter: false} 
            },             
            {
                label: '', 
                name: 'tema',
                options: {display: "excluded", filter: false} 
            },               
            {
                label: 'Relatório', 
                name: 'descritivoCompleto', 
            },
            {
                label: '', 
                name: 'descritivoId', 
                options: {display: "excluded", filter: false} 
            },            
            {
                label: 'Entidade', 
                name: 'entidade', 
            },  
            {
                label: '', 
                name: 'entidadeId', 
                options: {display: "excluded", filter: false} 
            },            
            {
                label: 'Referência', 
                name: 'dataDeReferencia', 
            },               
            {
                label: '', 
                name: 'ano', 
                options: {display: "excluded", filter: false} 
            },       
            {
                label: '', 
                name: 'mes', 
                options: {display: "excluded", filter: false} 
            },       
            {
                label: '', 
                name: 'dia', 
                options: {display: "excluded", filter: false} 
            },                               
            {
                label: modoDeSubstituicao ? "Nova Versão" : "Nova Versão",
                name: 'novaVersao', 
                options: {
                    filter: modoDeSubstituicao? true: false,
                    display: modoDeSubstituicao? true: "excluded"
                }
            },          
          
            {
                label: '', 
                name: 'uuidDoDocumento', 
                options: {display: "excluded", filter: false} 
            },            
            {
                label: 'Arquivos que serão Inseridos', 
                name: 'arquivos', 
                options: {
                    filter: false,
                    sort: false,
                    customBodyRenderLite: (dataIndex) => {
                        let colunaArquivos = colunas.findIndex(obj => obj.name === "arquivos");
                        let value = modoDeSubstituicao ? 
                            dadosDeDocumentosParaSubstituicao[dataIndex][colunaArquivos] :
                            dadosDeDocumentosParaInsercao[dataIndex][colunaArquivos];
                        return value?.map((val, key) => {
                            return <Chip label={val} key={key} />;
                        });
                    },
            }},                         
        ]    
        return colunas;
    }
    


    const opcoesDaTabela = {
        elevation: 2,
        responsive: "simple",  
        print: false,
        download: false,
        viewColumns: false,
        search: true, 
        filter: true, 
        filterType: "multiselect",                     
        pagination: false,
        sort: true,
        textLabels: textLabels,
        selectableRows: 'none'
    }

    const handleAvancar = () => {
        return avancarEtapa(); 
    };        


    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 
    // 3. Renderização
    /*-----------------------------------------------------------------------------------------------------------------------------------*/             
    return (
        <div id="step3">
            <>{(dadosDeDocumentosJaExistentesIsLoading || criacaoDeDadosIsLoading) ? (
                <Loader />
            ) : (<>   
                {dadosDeDocumentosParaInsercao?.length ? (
                    <MUIDataTable className="muiDataTable"
                        title={`Revisar e Confirmar a Criação de ${dadosDeDocumentosParaInsercao?.length} Novo(s) Documento(s)`}
                        data={dadosDeDocumentosParaInsercao}
                        columns={getColunas({modoDeSubstituicao:false})}
                        options={opcoesDaTabela}                
                    />
                ) : (
                    <></>
                )}
                {dadosDeDocumentosParaSubstituicao?.length ? (
                    <MUIDataTable  className="muiDataTable"
                        title={`Revisar e Confirmar a Substituição de ${dadosDeDocumentosParaSubstituicao?.length} Documento(s)`}
                        data={dadosDeDocumentosParaSubstituicao}
                        columns={getColunas({modoDeSubstituicao:true})}
                        options={opcoesDaTabela}                
                    />
                ) : (
                    <></>
                )}
                <br />
                <div className="stepperButtons">
                    <Button className="backButton" onClick={handleCancelar}>
                        Cancelar
                    </Button>
                    <Button variant="contained"color="primary" onClick={handleAvancar}>
                        Finalizar
                    </Button>
                </div>
            </>)}</>  
        </div>
    ) 
}

const mapStateToProps = (state) => ({
    //documentos
    dadosDeDocumentosJaExistentes: 
        state.documentosBuscadosPorAtributos.documentos,
    dadosDeDocumentosJaExistentesIsLoading: 
        state.documentosBuscadosPorAtributos.isLoading
});

export default connect(mapStateToProps, {buscarDocumentosPorAtributos}) (Step3);
