import {
    CRIAR_DOCUMENTOS_LOADING, CRIAR_DOCUMENTOS_SUCCESS, CRIAR_DOCUMENTOS_FAIL
} from '../actionTypes';
  
const initialState = {
    responses: [], 
    isLoading: false,
    error: null,
};

const criacaoDeDocumentos = (state = initialState, { type, payload }) => { 
    switch (type) {
        case CRIAR_DOCUMENTOS_LOADING:
            return {
                ...state,
                isLoading: true,
                error: null,
            }        
        case CRIAR_DOCUMENTOS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                responses: payload.responses
            }                
        case CRIAR_DOCUMENTOS_FAIL:
            return {
                ...state,
                isLoading: false,
                responses: [],
                error: payload.error,
            }                     
        default:
            return state;
    }
}

export default criacaoDeDocumentos