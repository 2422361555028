import Menu from '@material-ui/core/Menu';

const MenuLunar = ({children, ...other}) => {
    return (
      <Menu
        keepMounted
        //...
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}      
        {...other}  
      >
        {children}
      </Menu>
    )
  }

  export default MenuLunar