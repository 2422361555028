/* Função responsável pelo processamento de um filename, de modo a extrair,
 * se possível, um conjunto de (descritivo, entidade, data) a partir do nome 
 * do arquivo (para nosso propósito, pode ser entendida como uma string.)
 *
 * Recebe como argumento os parâmetros:
 *
 *  filename = "nome do arquivo.ext"
 *
 *  dicionario = {
 *    entidade: {
 *        "Entidade A": ["Chave A1", "Chave A2", ..., "Chave AN"],
 *        ...
 *        "Entidade Z": ["Chave Z1", "Chave Z2", ..., "Chave ZN"]
 *    },
 *    descritivo: {
 *        "Descritivo A": ["Chave A1", "Chave A2", ..., "Chave AN"],
 *        ...
 *        "Descritivo Z": ["Chave Z1", "Chave Z2", ..., "Chave ZN"]
 *    }
 *  }
 *
 * e retorna um objeto contendo tres strings da forma
 *
 *  {entidade: "Entidade J", descritivo: "Descritivo L", 
 *  data: "YYYY/MM/DD"}
 *
 * de modo que a data pode ser "YYYY", "YYYY/MM" ou "YYYY/MM/DD" a depender
 * do que foi encontrado no nome do arquivo.
 * Caso não haja correspondência para algum dos parâmetros de interesse, é 
 * retornado o valor null
 */

export function parseFilename(filename, dicionario) {
    // Processa o dicionário obtido em busca de matches entre o filename, 
    // e entradas do dicionário. Caso haja mais de um nome compatível para
    // algum atributo (i.e., houve match do filename com as entidades XPTO I
    // e ABCD FIP) será retornado um valor nomeEncontrado.atributo nulo de 
    // modo a evitar uma escolha arbitrária.

    // vide https://web.archive.org/web/20210317173528/https://stackoverflow.
    // com/questions/990904/remove-accents-diacritics-in-a-string-in-
    // javascript/37511463
    var removerAcentos = texto => texto.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

    // A checagem de igualdade entre strings será feita uma vez removidos
    // acentos, espaços, hífens, underscore, pontos, e os caracteres restantes
    // convertidos p/ minúscula
    var formatarStringParaComparacao = (texto) => {
        return removerAcentos(texto).toLowerCase().replace(/ |-|_|\./g,"");
    };

    // Dado um array de strings, retorna a maior string
    var obterMaiorString = (lista) => {
        var listaDeStrings = [...lista];
        var maiorString = listaDeStrings.sort((a, b) => b.length - a.length)[0];
        return maiorString;
    };

    // Dado um array de strings, checa se a maior contém todas as outras e retorna true ou false
    var maiorStringContemAsOutras = (lista) => {
        var maiorString = obterMaiorString(lista);
        var contemTodasAsOutras = true;
        lista.forEach(x => {
            if (!maiorString.includes(x)) contemTodasAsOutras = false;
        });
        return contemTodasAsOutras;
    };


    // Tenta extrair a data de uma string. Para esta finalidade, faz uma busca exaustiva
    var obterData = (texto) => {
        try{
            // Ordem de checagem:
            // YYYY MM DD -> YYYY MM -> YYYY -> MM YYYY -> DD MM YYYY
            var anoMesDia = /20[1-9][0-9][0-1][0-9][0-3][0-9]/;
            var anoMes = /20[1-9][0-9][0-1][0-9]/;
            var ano = /20[1-9][0-9]/;
            var mesAno = /[0-1][0-9]20[1-9][0-9]/;
            var diaMesAno = /[0-3][0-9][0-1][0-9]20[1-9][0-9]/;
            var datasEncontradas = [];

            // Prenche uma lista com o que foi encontrado. Os formatos não encontrados retornam "null"
            datasEncontradas.push(formatarStringParaComparacao(texto).match(
                anoMesDia));
            datasEncontradas.push(formatarStringParaComparacao(texto).match(
                diaMesAno));
            datasEncontradas.push(formatarStringParaComparacao(texto).match(
                anoMes));
            datasEncontradas.push(formatarStringParaComparacao(texto).match(
                mesAno));
            datasEncontradas.push(formatarStringParaComparacao(texto).match(
            ano));

            // Checa se os valores encontrados acima são datas válidas usando Date.parse()
            datasEncontradas = datasEncontradas.map((dataLista, indice) => {

                // Usaremos a função Date.parse(x), onde x deve ser uma string no formato
                // YYYY-MM-DDTHH:mm:ss.sssZ (ou subconjuntos, começando com YYYY)
                // Caso o match retorne "null", não precisamos processar o que foi encontrado
                if (!dataLista) return null
                var data = dataLista[0] // Pega a expressão encontrada e.g., "20210515"

                // Checa, caso a caso, se é uma data válida (antes, convertendo p/ o formato exigido pela função Date.parse)
                var dataFormatada;
                if (indice === 0) {
                    // anoMesDia
                    dataFormatada = data.slice(0, 4) + "-" + data.slice(4, 6) + "-" + data.slice(6, 8);
                } else if (indice === 1) {
                    // diaMesAno
                    dataFormatada = data.slice(4, 8) + "-" + data.slice(2, 4) + "-" + data.slice(0, 2)
                } else if (indice === 2) {
                    // anoMes
                    dataFormatada = data.slice(0, 4) + "-" + data.slice(4, 6)
                } else if (indice === 3) {
                    // mesAno
                    dataFormatada = data.slice(2, 6) + "-" + data.slice(0, 2)
                } else {
                    // ano
                    // Não é necessário fazer nada p/ uma entrada "YYYY"
                };

                // Caso não tenha sido retornado null, é porque dataFormatada é válida
                if (!Date.parse(dataFormatada)) {
                    return null;
                } else {
                    return dataFormatada.split("-")
                };
            });

            return datasEncontradas.filter(x => x != null)[0] || null

        } catch (err) {
            console.log("err", err);
            return null;
        }
    };

    var nomeEncontrado = {
        entidade: null,
        descritivo: null,
        data: obterData(filename)
    };

    // Dicionário clona a si mesmo para evitar alteração no objeto que
    // é passado como parâmetro 
    //TODO: verificar se esta linha pode trazer problema de performance
    dicionario = JSON.parse(JSON.stringify(dicionario))

    // Checa os conjuntos de Atributo X: [Chave1, ..., ChaveN], e faz a
    // checagem de compatibilidade entre o filename e as diversas chaves,
    // excluindo no processo os atributos cuja compatibilidade não foi obtida
    // para nenhuma chave.
    // Além disso, há a checagem se o atributo do dicionário também é um
    // atributo da variável nomeEncontrado. Isto foi feito para evitar o uso
    // de atributos arbitrários além dos atributos de interesse (i.e., entidade,
    // descritivo)
    for (var atributo in dicionario) {
        for (var nome in dicionario[atributo]) {
            dicionario[atributo][nome].push(nome);
            dicionario[atributo][nome] = dicionario[atributo][nome]
            .filter((identificador) => {
                return formatarStringParaComparacao(filename).includes(
                    formatarStringParaComparacao(identificador));
            });
            if (dicionario[atributo][nome].length === 0) {
                delete dicionario[atributo][nome]
            };
        };
        // Para casos de múltiplos matches, retorna o maior, caso ele contenha todos os outros como
        // subconjunto. Caso não contenha, não retorna nada.
        var matches = Object.keys(dicionario[atributo])
        var numeroDeMatches = matches.length
        var atributoValido = nomeEncontrado[atributo] === null;
        if (numeroDeMatches === 1 && atributoValido) {
            nomeEncontrado[atributo] = Object.keys(dicionario[atributo])[0];
        } else if (numeroDeMatches > 1 && atributoValido && maiorStringContemAsOutras(matches)) {
            nomeEncontrado[atributo] = obterMaiorString(matches);
        };
    };

    return nomeEncontrado;
};
