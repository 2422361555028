import {useState, useEffect} from 'react';
import React from 'react';
import Layout from '../../layout/Layout'
import './Resultado.css'

import Grid from '@material-ui/core/Grid';

import {connect} from "react-redux";

import {buscarArquivos} from '../../redux/actions/arquivoActions'

import {
    isValidDateString, converterArrayParaTextoEmPortugues, formatarDateString, obterQuantidadeDeDocumentosUnicos
} from '../../support/publicFunctions'

import { useLocation } from "react-router-dom";

import SubPaginaArquivos from '../../components/SubPaginaArquivos/SubPaginaArquivos'

import Loader from '../../components/Loader/Loader'



const Resultado = ({
    buscarArquivos, arquivos, arquivosIsLoading,  usuarioNome
}) => { 

    const [datasInvalidas, setDatasInvalidas ] = useState(false);
    
    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();    

    //Otber query strings
    const dataInicio = query.get("data-inicial");
    const dataFim = query.get("data-final");
    const entidades = query.getAll("entidade");
    const temas = query.getAll("tema");
    const tiposDeDocumento = query.getAll("relatorio");

    useEffect(() => {        
        const validarDatas = () => {
            if (dataInicio && !isValidDateString(dataInicio)){setDatasInvalidas(true)};
            if (dataFim && !isValidDateString(dataFim)){setDatasInvalidas(true)};
        }
        validarDatas();
    }, [dataInicio, dataFim]);
    

    useEffect(() => {
        buscarArquivos({
            apenasVersoesAtuais: true, 
            ordernarPorRecentes: true, 
            limite: 30000, //TODO: analisar qual realmente seria o limite mais adequado para incluir aqui (se é que precisa de um, acho que sim!) 
            apenasFavoritos: false,
            dataInicio: dataInicio,
            dataFim: dataFim,
            entidades: entidades,
            temas: temas,
            tiposDeDocumento: tiposDeDocumento            
        });          
    // eslint-disable-next-line
    }, [buscarArquivos, dataInicio, dataFim]); //neste caso foi desativado o eslint, porque se adicionar variáveis que recebem valor do query.getAll (tais como entidades, temas e tiposDeDocumento) ele entra em loop infinito (//TODO: ver o que causa este loop infinito e ver se o melhor aqui é desativar o eslint mesmo rs)


    function getQuantidadeDeDocumentos(){
        return obterQuantidadeDeDocumentosUnicos(arquivos);
    }


    /*-----------------------------------------------------------*/ 
    /*RENDERIZAÇÃO*/
    /*-----------------------------------------------------------*/   
    return(
        <>{(arquivosIsLoading) ? (
            <Layout pageTitle="Consultando Documentos...">
                <Loader mensagem={""}/>
            </Layout>
        ) : (<>      
            <Layout pageTitle={`Sua Consulta retornou ${getQuantidadeDeDocumentos()} Documentos`} >
                <div id="resultado">  
                    <Grid container spacing={1}> 
                        <Grid item xs={12} md={12}>       
                                                                                                               
                            <p class="lunar subCabecalhoDescritivo">
                                <b>Entidade{entidades.length!==1? "s": ""}: </b>
                                <span>{entidades.length? converterArrayParaTextoEmPortugues(entidades) : "Todas"}</span>
                            </p>
                            <p class="lunar subCabecalhoDescritivo">
                                <b>Tema{temas.length!==1? "s": ""}: </b>
                                <span>{temas.length? converterArrayParaTextoEmPortugues(temas) : "Todos"}</span>
                            </p>
                            <p class="lunar subCabecalhoDescritivo">
                                <b>Relatório{tiposDeDocumento.length!==1? "s": ""}: </b>
                                <span>{tiposDeDocumento.length? converterArrayParaTextoEmPortugues(tiposDeDocumento) : "Todos"}</span>
                            </p>
                            <p class="lunar subCabecalhoDescritivo">
                                <b>Período: </b>
                                    {datasInvalidas ? (
                                        <span className="datasInvalidas">Data inválida (uma ou mais datas informadas contém valores inválidos)</span>
                                    ) : (
                                        <>
                                            {dataInicio   &&  dataFim  ? <span> {`De ${formatarDateString(dataInicio)} até ${formatarDateString(dataFim)}`}</span> : <></>}
                                            {dataInicio   &&  !dataFim ? <span> Todos os documentos desde {formatarDateString(dataInicio)} </span> : <></>}
                                            {!dataInicio  &&  dataFim  ? <span> Todos os documentos até {formatarDateString(dataFim)} </span> : <></>}
                                            {!dataInicio  &&  !dataFim ? <span>Todos os documentos</span> : <></>}
                                        </>
                                    )}
                            </p>
                            <p class="lunar subCabecalhoDescritivo">
                                <b>Quantidade de Arquivos: </b>
                                <span>{arquivos.length}</span>
                            </p>                                  
                        </Grid>                         
                    </Grid>                    
                    {datasInvalidas ? (
                        <></>
                    ):(                    
                        <SubPaginaArquivos 
                            arquivos={arquivos}
                            usuarioNome={usuarioNome}
                        />
                    )}       
                </div>
            </Layout>                   
        </>)}</>
    )
}

const mapStateToProps = (state) => ({
    //arquivos
    arquivos: state.arquivos.arquivos,
    arquivosIsLoading: state.arquivos.isLoading,
    usuarioNome: state.auth.me.nome,
});

export default connect(mapStateToProps, {buscarArquivos})(Resultado);
