import axios from 'axios';
import {attachTokenToHeaders, handleErrorForAllActions} from '../actionFunctions';
import {
  BUSCAR_DICIONARIO_LOADING, BUSCAR_DICIONARIO_SUCCESS, BUSCAR_DICIONARIO_FAIL,
} from '../actionTypes';


const buscarDicionario = () => async (dispatch, getState) => {
    dispatch({ 
      type: (BUSCAR_DICIONARIO_LOADING) 
    });
    try {
      const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
      const url = '/api/documentos/dicionario';
      const response = await axios.get(url, options); 
      const dicionario = response.data;
      dispatch({
        type: (BUSCAR_DICIONARIO_SUCCESS),
        payload: {dicionario},
      });
    } catch (err) {
      handleErrorForAllActions(err, getState); 
      dispatch({
        type: (BUSCAR_DICIONARIO_FAIL),
        payload: {error: err},
      });
    }
};


export {  
    buscarDicionario
}  
