import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import LunarDialogTitle from 
  '../../../../../../components/LunarDialogTitle/LunarDialogTitle'
import {connect} from "react-redux";
import {buscarSubParametros, criarSubParametro, removerSubParametro} from 
  '../../../../../../redux/actionsAdmin/subParametroActions'
import {useEffect, useState} from 'react';
import Loader from  '../../../../../../components/Loader/Loader'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import AddIcon from '@material-ui/icons/Add';

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import TextField from "@material-ui/core/TextField";

import LinearProgress from '@material-ui/core/LinearProgress';


function DialogSubParametros({
  open, handleClose, tipo,
  buscarSubParametros, criarSubParametro, removerSubParametro,
  subParametros, subParametrosIsLoading,
}) {

  const [novoValor, setNovoValor] = useState(""); 
  
  const handleChange = (event) => {
    setNovoValor(event.target.value);
  };

  useEffect(()=>{
    if(tipo){
      buscarSubParametros({tipo});
    }
  }, [tipo, buscarSubParametros]);


  const handleSubmit = (event) => {
    event.preventDefault();
    const subParametro = novoValor;
    setNovoValor("");
    criarSubParametro({tipo, subParametro});
  }

  const handleRemover = ({tipo, subParametro}) => {
    removerSubParametro({tipo, subParametro});
  }

  const anActionIsLoading = () => {
    return subParametros.find(s => s === "Carregando...");
  }

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      disableBackdropClick
    >
      {(subParametrosIsLoading) ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <LunarDialogTitle onClose={handleClose}>
            {tipo}
          </LunarDialogTitle>
          <List>
            {subParametros.map(s => (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <InsertDriveFileIcon />
                  </Avatar>
                </ListItemAvatar>
                {s==="Carregando..." ? (
                  <ListItem style={{ paddingLeft: 0}}>
                    <LinearProgress style={{width: "100%"}}/>
                  </ListItem>
                ) : (
                  <ListItemText primary={s} />
                )}
                <ListItemSecondaryAction>
                  <Tooltip title='Remover'>
                    <IconButton 
                      disabled = {anActionIsLoading()} 
                      onClick={() => handleRemover({tipo, subParametro: s})} 
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>          
            ))}
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <InsertDriveFileIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItem style={{ paddingLeft: 0}}>
                <TextField 
                  placeholder={"Criar Novo"}
                  value={novoValor}
                  onChange={handleChange}
                  autoFocus
                  required
                  disabled = {anActionIsLoading()}   
                  inputProps={{
                    minLength: 3,
                    maxLength: 191,
                  }}             
                />
              </ListItem>
              <ListItemSecondaryAction>
                <Tooltip title='Adicionar'>
                  <IconButton type="submit" disabled = {anActionIsLoading()}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
              </ListItem>                      
          </List>                
        </form>
      )}
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  subParametros: state.adminSubParametro.subParametros,
  subParametrosIsLoading: state.adminSubParametro.isLoading,
});

export default connect(mapStateToProps, {buscarSubParametros, criarSubParametro,
  removerSubParametro})(DialogSubParametros);