//React
import React, { useEffect }  from 'react';

//React Router
import {Switch, Route, Redirect} from 'react-router-dom';

//React Redux
import { connect } from "react-redux";

//Other React Packages
import Cookies from 'js-cookie';

//Pages
import Inicio from './pages/Inicio/Inicio'
import Ajuda from './pages/Ajuda/Ajuda'
import AjudaOld from './pages/Ajuda/Old/AjudaOld' //TODO: futuramente deletar esta rota
import Resultado from './pages/Resultado/Resultado'
import Notificacoes from './pages/Notificacoes/Notificacoes' //TODO: verificar esta parte (provavelmente mover este arquivo de pasta)
import ResultadoOLD from './pages/Resultado/ResultadoOLD' //TODO: futuramente deletar esta linha aqui 
import Consulta from './pages/Consulta/Consulta'
import Preferencias from './pages/Preferencias/Preferencias'
import Preferencias2 from './pages/Preferencias/Preferencias2' //TODO: futuramente remover esta parte aqui
import Documento from './pages/Documento/Documento'
import Versoes from './pages/Versoes/Versoes'
import Insercao from './pages/Insercao/Insercao'
import MeuUsuario from './pages/MeuUsuario/MeuUsuario'
import PaginaEmDesenvolvimento from './pages/Temp/PaginaEmDesenvolvimento' //TODO: futuramente deletar esta linha aqui
import PaginaTeste from './pages/Testes/PaginaTeste'
import PaginaTeste2 from './pages/Testes/PaginaTeste2'
import PaginaTeste3 from './pages/Testes/PaginaTeste3'
import PaginaTeste4 from './pages/Testes/PaginaTeste4'
import PaginaTeste4b from './pages/Testes/PaginaTeste4b'
import PaginaTeste4c from './pages/Testes/PaginaTeste4c'
import PaginaTeste4d from './pages/Testes/PaginaTeste4d'
import PaginaTeste4e from './pages/Testes/PaginaTeste4e'
import PaginaTeste5 from './pages/Testes/PaginaTeste5'
import PaginaTeste6 from './pages/Testes/PaginaTeste6'
import PaginaTeste7 from './pages/Testes/PaginaTeste7'
import Login from './pages/Login/Login'
import NaoEncontrado from './pages/NaoEncontrado/NaoEncontrado'

//Admin Pages
import LogsDeAtividade from './pagesAdmin/LogsDeAtividade/LogsDeAtividade'
import LogsDeDownload from './pagesAdmin/LogsDeDownload/LogsDeDownload'
import GraficosDeDownload from './pagesAdmin/GraficosDeDownload/GraficosDeDownload'
import Parametros from './pagesAdmin/Parametros/Parametros'
import AjudaProAdministrador from './pagesAdmin/AjudaProAdministrador/Ajuda'


//Components
import Loader from './components/Loader/Loader'

//Redux Actions
import {logInUser, loadMe, forceLoginError} from './redux/actions/authActions'

//Login Redirection Handlers
import {
  RedirectionToLogin, 
  LoginSucessHandler, 
  LoginFailHandler
} from './pages/Login/support/LoginHandlers'

//Outros
import {tipos as tiposDeParametro} from './redux/dataAdmin/parametrosData'



const App = ({ logInUser, auth, loadMe, forceLoginError }) => {

  useEffect(() => {
    loadMe();
  }, [loadMe]);


  //ordem do hook
  useEffect(() => {
    if (window.location.hash === '#_=_') window.location.hash = '';

    const cookieJwt = Cookies.get('x-auth-token');
    if (cookieJwt) {
      Cookies.remove('x-auth-token');
      logInUser(cookieJwt);
    }    
  }, []); // eslint-disable-line
            //(neste caso pode ser desativado, porque é desejável apenas uma única execução)


  useEffect(() => {
    if (!auth.appLoaded && !auth.isLoading && auth.token && !auth.isAuthenticated) {
      loadMe();
    }
  }, [auth.isAuthenticated, auth.token, loadMe, auth.isLoading, auth.appLoaded]);


  
  return(
    <>
      {auth.appLoaded && !auth.isGoogleAuthLoading? (
        auth.isAuthenticated? (
          <Switch>
            {(auth.me.master === 1) && (
              /*Routes exclusivas do admin*/
              <Route path="/admin">
                <Switch>
                  <Route exact path="/admin"><Redirect to="/admin/inicio?limite=1000"/></Route>  
                  <Route exact path="/admin/inicio"><GraficosDeDownload /></Route>  
                  <Route exact path="/admin/ajuda"><AjudaProAdministrador pageTitle="Ajuda pro Administrador"/></Route>  
                  <Route exact path="/admin/voltar"><Redirect to="/inicio"/></Route>  
                  {/* Parametros */}
                  <Route exact path="/admin/parametros/adm-fiduciarios"><Parametros tipoDeParametro={tiposDeParametro.ADM_FIDUCIARIOS} /></Route>
                  <Route exact path="/admin/parametros/descritivos"><Parametros tipoDeParametro={tiposDeParametro.DESCRITIVOS} /></Route>
                  <Route exact path="/admin/parametros/entidades"><Parametros tipoDeParametro={tiposDeParametro.ENTIDADES} /></Route>  
                  <Route exact path="/admin/parametros/segmentos"><Parametros tipoDeParametro={tiposDeParametro.SEGMENTOS} /></Route>  
                  <Route exact path="/admin/parametros/temas"><Parametros tipoDeParametro={tiposDeParametro.TEMAS} /></Route>
                  <Route exact path="/admin/parametros/tipos-de-documento"><Parametros tipoDeParametro={tiposDeParametro.TIPOS_DE_DOCUMENTO} /></Route>
                  {/* Acessos */}
                  <Route exact path="/admin/acessos/usuarios"><Parametros tipoDeParametro={tiposDeParametro.USUARIOS} /></Route>
                  <Route exact path="/admin/acessos/administradores"><Parametros tipoDeParametro={tiposDeParametro.ADMINISTRADORES} /></Route>                 
                  <Route exact path="/admin/acessos/grupos"><Parametros tipoDeParametro={tiposDeParametro.GRUPOS_DE_ACESSO} /></Route>
                  {/* Controles */}
                  <Route exact path="/admin/controles/arquivos"><Parametros tipoDeParametro={tiposDeParametro.ARQUIVOS} /></Route>
                  <Route exact path="/admin/controles/documentos"><Parametros tipoDeParametro={tiposDeParametro.DOCUMENTOS} /></Route>
                  <Route exact path="/admin/controles/sugestoes"><Parametros tipoDeParametro={tiposDeParametro.SUGESTOES} /></Route>
                  <Route exact path="/admin/controles/notificacoes"><Parametros tipoDeParametro={tiposDeParametro.NOTIFICACOES} /></Route>
                  {/* Logs */}
                  <Route exact path="/admin/logs/atividades"><LogsDeAtividade /></Route>
                  <Route exact path="/admin/logs/downloads"><LogsDeDownload /></Route>  
                  {/* Não Encontrado */}
                  <Route path="/admin"><NaoEncontrado /></Route>
                </Switch>
              </Route>
            )}          
            {/*Todas demais routes*/}
            <Route exact path="/"><Redirect to="/inicio"/></Route>
            <Route exact path="/inicio"><Inicio /></Route>
            <Route exact path="/consulta"><Consulta /></Route>
            <Route exact path="/consulta/documentos"><Resultado /></Route>
            <Route exact path="/consulta/documentos-old"><ResultadoOLD /></Route> {/* //TODO: futuramente deletar esta linha aqui  */}
            <Route exact path="/insercao"><Insercao /></Route>
            <Route exact path="/preferencias"><Preferencias /></Route>
            <Route exact path="/preferencias2"><Preferencias2 /></Route> {/* //TODO: futuramente deletar esta linha aqui  */}
            <Route exact path="/ajuda"><Ajuda /></Route>
            <Route exact path="/ajuda-old"><AjudaOld /></Route>  {/* //TODO: futuramente deletar esta linha */}
            <Route exact path="/usuario"><MeuUsuario /></Route>                
            <Route exact path="/documentos"><Redirect to="/consulta/documentos"/></Route>    
            <Route exact path="/documentos/:uuid/"><Versoes /></Route> {/* //TODO: futuramente fazer com esta rota redirecione para /documentos/:uuid/versoes/ em vez de renderizar diretamente o componente <Versoes /> sem modificar a URL como eu gostaria */}
            <Route exact path="/documentos/:uuid/versoes/"><Versoes /></Route>
            <Route exact path="/documentos/:uuid/versoes/:numeroDaVersao"><Documento /></Route>
            <Route exact path="/lotes/:numeroLoteHasheado"><Notificacoes /></Route> {/* //TODO: futuramente deletar esta parte aqui e deixar só o notificacoes mesmo*/}
            <Route exact path="/login"><Redirect to="/inicio"/></Route>
            <Route exact path="/login/callback"><LoginSucessHandler /></Route>                
            <Route exact path="/teste"><PaginaTeste /></Route>
            <Route exact path="/teste2"><PaginaTeste2 /></Route>
            <Route exact path="/teste3"><PaginaTeste3 /></Route>  
            <Route exact path="/teste4"><PaginaTeste4 /></Route>         
            <Route exact path="/teste4b"><PaginaTeste4b /></Route>         
            <Route exact path="/teste4c"><PaginaTeste4c /></Route>    
            <Route exact path="/teste4d"><PaginaTeste4d /></Route>  
            <Route exact path="/teste4e"><PaginaTeste4e /></Route>
            <Route exact path="/teste5"><PaginaTeste5 /></Route>   
            <Route exact path="/teste6"><PaginaTeste6 /></Route>   
            <Route exact path="/teste7"><PaginaTeste7 /></Route>                
            <Route exact path="/nao-encontrado"><NaoEncontrado /></Route>                
            <Route path="/"><NaoEncontrado /></Route>
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/login"><Login/></Route>
            <Route exact path="/login/callback"><LoginFailHandler forceLoginError={forceLoginError} /></Route>
            <Route path="/"><RedirectionToLogin /></Route>
          </Switch>                 
        )
      ) : (
        <Loader fullPage/>          
      )
    }
    </>
  )
}


const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {logInUser, loadMe, forceLoginError })(App);
