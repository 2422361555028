import React from 'react';
import {Bar} from 'react-chartjs-2';

import {format} from 'date-fns'

const VerticalBar = ({data}) => {
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return(
    <Bar data={data} options={options} />
  )
};

const VerticalBarContainer = ({logsDeDownload}) => {

  function getDias() {
    return (logsDeDownload.map(log => new Date(log.dataDownload).setHours(0,0,0,0))).sort();
  }

  function getDistribuicaoDiariaDeDownloads(){
    var dias = getDias();
    var distribuicao = {};
    for (var i = 0; i < dias.length; i++) {
      var dia = dias[i];
      distribuicao[dia] = distribuicao[dia] ? distribuicao[dia] + 1 : 1;
    }
    if(dias.length > 1 ){
      /* Excluir o dia mais antigo porque pode conter dados incompletos*/
      const diaMaisAntigo = dias[0]
      delete distribuicao[diaMaisAntigo];
    } 
    console.log("distribuicao", distribuicao);
    return distribuicao;
  }

  function getDiasUnicos(){
    return Object.keys(getDistribuicaoDiariaDeDownloads());
  }

  function getDiasUnicosFormatados(){
    return getDiasUnicos().map((dia)=>format(new Date(parseInt(dia)), 'dd/MM/yy'));
  }

  function getChartLabels(){
    return getDiasUnicosFormatados();
  }

  function getDatasetsData(){
    return Object.values(getDistribuicaoDiariaDeDownloads());
  }

 
  const data = {
    labels: getChartLabels(),
    datasets: [
      {
        label: 'Downloads',
        data: getDatasetsData(),
        backgroundColor: [
          '#00b3f0' //TODO: colocar a cor primária via importação
        ],
        borderColor: [
          '#0083bd' //TODO: colocar a cor primária via importação
        ],
        borderWidth: 1,
      },
    ],
  };


  return <VerticalBar data={data}/>
}



export default (VerticalBarContainer)
