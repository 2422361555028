import {
    BUSCAR_DOCUMENTOS_POR_UUID_LOADING,
    BUSCAR_DOCUMENTOS_POR_UUID_SUCCESS,
    BUSCAR_DOCUMENTOS_POR_UUID_FAIL  
} from '../actionTypes';
  
const initialState = {
    documentos: [], 
    isLoading: false,
    error: null
};

const documentosBuscadosPorUuid = (state = initialState, { type, payload }) => { 
    switch (type) {
        case BUSCAR_DOCUMENTOS_POR_UUID_LOADING:
            return {
                ...state,
                isLoading: true,
                error: null,
            }        
        case BUSCAR_DOCUMENTOS_POR_UUID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                documentos: payload.documentos
            }                
        case BUSCAR_DOCUMENTOS_POR_UUID_FAIL:
            return {
                ...state,
                isLoading: false,
                documentos: [],
                error: payload.error,
            }                     
        default:
            return state;
    }
}

export default documentosBuscadosPorUuid