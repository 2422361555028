import {
    BUSCAR_SUB_PARAMETROS_LOADING,
    BUSCAR_SUB_PARAMETROS_SUCCESS,
    BUSCAR_SUB_PARAMETROS_FAIL,
    CRIAR_SUB_PARAMETRO_LOADING,
    CRIAR_SUB_PARAMETRO_SUCCESS,
    CRIAR_SUB_PARAMETRO_FAIL,
    REMOVER_SUB_PARAMETRO_LOADING,
    REMOVER_SUB_PARAMETRO_SUCCESS,
    REMOVER_SUB_PARAMETRO_FAIL,  
} from '../actionTypesAdmin';

const initialState = {
    subParametros: [], 
    isLoading: false,
    error: null, 
};

const subParametro = (state = initialState, { type, payload }) => {
    switch (type) {
        //BUSCAR
        case BUSCAR_SUB_PARAMETROS_LOADING:            
            return {
                ...state,
                isLoading: true,
                error: null,
                tipo: payload.tipo
            }    
        case BUSCAR_SUB_PARAMETROS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                subParametros: payload.subParametros
            }         
        case BUSCAR_SUB_PARAMETROS_FAIL:
            return {
                ...state,
                isLoading: false,
                subParametros: [],
                error: payload.error                        
            }        
        //CRIAR
        case CRIAR_SUB_PARAMETRO_LOADING:    
            return {
                ...state,
                subParametros: [
                    ...state.subParametros,
                    'Carregando...',
                ]
            }              
        case CRIAR_SUB_PARAMETRO_SUCCESS:        
            return {
                ...state,
                subParametros: state.subParametros.map(s => {
                    if (s === 'Carregando...') return payload.subParametro;
                    return s;
                })

            }            
        case CRIAR_SUB_PARAMETRO_FAIL:
            return {
                ...state,
                isLoading: false,
                error: payload.error,
                subParametros: state.subParametros.filter(s => 
                    s !== "Carregando...")
            }
        //REMOVER
        case REMOVER_SUB_PARAMETRO_LOADING:    
            return {
                ...state,
                subParametros: state.subParametros.map(s => {
                    if(s === payload.subParametro){return "Carregando..."};
                    return s;
                })
            }              
        case REMOVER_SUB_PARAMETRO_SUCCESS:        
            return {
                ...state,
                subParametros: state.subParametros.filter(s => (
                    s !==  "Carregando..."))
            }            
        case REMOVER_SUB_PARAMETRO_FAIL:
            return {
                ...state,
                error: payload.error,
                subParametros: state.subParametros.map(s => {
                    if(s === "Carregando..."){return (payload.subParametro)};
                    return s;
                })
            }            
        default:
            return state;
    }
}

export default subParametro