import React from 'react';

import {Link} from 'react-router-dom';

import {connect} from "react-redux";

import './Navbar.css'

import MainMenu from '../MainMenu/MainMenu'

import apolloLunarLogo from "../../../support/images/apolloLunarLogo.png"

import Paper from '@material-ui/core/Paper';

import UserIconMenu from "../UserIconMenu/UserIconMenu"

const Navbar = ({auth}) => {
  
    return(
        <Paper id="navBar" className={(auth.isAuthenticated ? null : "loginTopPart")} elevation={4}>
        
            <div className="headerBar defaultMaxPageWidth">
                <Link to="/inicio">
                    <img src={apolloLunarLogo} alt="Apollo Lunar Logo"/>
                </Link>
            </div>
            
            {auth.isAuthenticated ? (
                <div className="menuBar defaultMaxPageWidth">    
                    <div className="mainMenuContainer">
                        <MainMenu/>
                    </div>
                    <div className="userIconMenuContainer">
                        <UserIconMenu/>
                    </div>
                 </div>
            ) : (
                <></>
            )}
        </Paper>
    )
}

const mapStateToProps = (state) => {
    return {auth: state.auth}
}

export default  connect(mapStateToProps)(Navbar)