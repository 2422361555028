const tipos = {
  //PADRÃO
  ADM_FIDUCIARIOS: "ADM_FIDUCIARIOS",
  DESCRITIVOS: "DESCRITIVOS",
  ENTIDADES: "ENTIDADES",
  SEGMENTOS: "SEGMENTOS",
  TEMAS: "TEMAS",
  TIPOS_DE_DOCUMENTO: "TIPOS_DE_DOCUMENTO",
  //...
  GRUPOS_DE_ACESSO: "GRUPOS_DE_ACESSO",
  USUARIOS: "USUARIOS",
  //ENUM
  TIPOS_DE_DOCUMENTO_FREQUENCIAS: "TIPOS_DE_DOCUMENTO_FREQUENCIAS",
  ENTIDADES_CANAIS_DE_DISTRIBUICAO: "ENTIDADES_CANAIS_DE_DISTRIBUICAO",
  ENTIDADES_TIPOS: "ENTIDADES_TIPOS",
  ENTIDADES_SUBTIPOS: "ENTIDADES_SUBTIPOS",
  ENTIDADES_QUALIFICACOES_DO_INVESTIDOR: "ENTIDADES_QUALIFICACOES_DO_INVESTIDOR",
  //CASO PARTICULARES
  BOOLEANO_0OU1: "BOOLEANO_0OU1",
  GRUPOS_DE_ACESSO_RESTRICOES: "GRUPOS_DE_ACESSO_RESTRICOES"
}


const {
  //PADRÃO
  ADM_FIDUCIARIOS, DESCRITIVOS, ENTIDADES, SEGMENTOS, TEMAS, TIPOS_DE_DOCUMENTO,
  //...
  GRUPOS_DE_ACESSO, USUARIOS,
  //ENUM
  TIPOS_DE_DOCUMENTO_FREQUENCIAS, ENTIDADES_CANAIS_DE_DISTRIBUICAO, ENTIDADES_TIPOS,
  ENTIDADES_SUBTIPOS, ENTIDADES_QUALIFICACOES_DO_INVESTIDOR,  
} = tipos



const urls = {
  //PADRÃO
  [ADM_FIDUCIARIOS]: '/api/administradores/administradores',
  [DESCRITIVOS]: '/api/administradores/descritivos',
  [ENTIDADES]: '/api/administradores/entidades',
  [SEGMENTOS]: '/api/administradores/segmentos',
  [TEMAS]: '/api/administradores/temas',
  [TIPOS_DE_DOCUMENTO]: '/api/administradores/tipos-de-documento',
  //...
  [GRUPOS_DE_ACESSO]: '/api/administradores/grupos-de-acesso',
  [USUARIOS]: '/api/administradores/usuarios',
  //ENUM
  [TIPOS_DE_DOCUMENTO_FREQUENCIAS]: '/api/administradores/tipos-de-documento/frequencias',
  [ENTIDADES_CANAIS_DE_DISTRIBUICAO]: '/api/administradores/entidades/parametros/canais-de-distribuicao',
  [ENTIDADES_TIPOS]: '/api/administradores/entidades/parametros/tipos-de-entidade',
  [ENTIDADES_SUBTIPOS]: '/api/administradores/entidades/parametros/subtipos',
  [ENTIDADES_QUALIFICACOES_DO_INVESTIDOR]: '/api/administradores/entidades/parametros/qualificacoes'
};  


const configuracoes = {
  //PADRÃO
  [ADM_FIDUCIARIOS]: {
    map: {
      value: "id",
      label: "nome",
      group: null
    }
  },  
  [DESCRITIVOS]: {
    map: {
      value: "id",
      label: "nome",
      group: "tipo_de_documento_nome"
    }
  },
  [ENTIDADES]: {
    map: {
      value: "id",
      label: "apelido",
      group: "tipo"
    }
  },    
  [SEGMENTOS]: {
    map: {
      value: "id",
      label: "nome",
      group: null
    }
  },
  [TEMAS]: {
    map: {
      value: "id",
      label: "nome",
      group: null
    }
  },
  [TIPOS_DE_DOCUMENTO]: {
    map: {
      value: "id",
      label: "nome",
      group: "tema"
    }
  },
  //...
  [GRUPOS_DE_ACESSO]: {
    map: {
      value: "id",
      label: "nome",
      group: null
    }
  }, 
  [USUARIOS]: {
    map: {
      value: "id",
      label: "nome",
      group: "grupo"
    }
  },    
  //ENUM
}

export {
  configuracoes,
  tipos,
  urls
}