import React from 'react';
import {useState} from 'react';
import Layout from '../../layout/Layout'
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { itensDeAjuda } from './Conteudo';
import './Ajuda.css'

import { useTheme } from '@material-ui/core/styles';
import { createMuiTheme} from '@material-ui/core/styles';  //TODO: Reanalizar as partes que utilizam esta linha aqui para ver se farei refatoração
import {ThemeProvider } from '@material-ui/core/styles'; //TODO: Reanalizar as partes que utilizam esta linha aqui para ver se farei refatoração
import muiThemeGlobal from '../../support/materialUI/muiTheme' //TODO: Reanalizar as partes que utilizam esta linha aqui para ver se farei refatoração

//...
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import textLabels from '../../support/MuiDatatableSupport/TextLabels'




const Ajuda = () => { 

    const theme = useTheme();
    const muiThemeLocal = createMuiTheme(
        /* Esta parte aqui é para fazer com que as linhas mudem para cor 
          "primary" quando o usuário fizer hover em cima das perguntas
        */
        //TODO: reanalizar abaixo e ver se precisa refatorar algo
        {   
            ...muiThemeGlobal,
            overrides: { 
                ...muiThemeGlobal?.overrides,     
                MuiTableRow: { 
                  root: {
                    ...muiThemeGlobal?.overrides?.MuiTableRow?.root,    
                    '&:hover': {
                      color: theme.palette.primary.main,
                    }
                  }
                },    
                MuiTableCell: {
                  root: {
                    ...muiThemeGlobal?.overrides?.MuiTableCell?.root,  
                    color: "inherit !important",
                  }
                },                  
            }          
        }
    );

    const linkStyle = {color: theme.palette.primary.main};

    const perguntas = Object.keys(itensDeAjuda).map(x => [x]);
    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [tamanhoDaPagina, setTamanhoDaPagina] = useState(25);

    const options = {
        elevation: 0,
        responsive: 'standard',
        selectableRows: "none",
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        sort: false,
        textLabels: textLabels,
        rowsPerPage: tamanhoDaPagina,
        rowsPerPageOptions: [5, 10, 15, 25, 100],
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        rowsExpanded: itensSelecionados,
        renderExpandableRow: (rowData, rowMeta) => {
            const trueRowData = rowData[0].props.children;
            const colSpan = trueRowData.length + 1;
            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>
                        <div className="respostas" style={{color: "black"}}>
                            {itensDeAjuda[trueRowData]}
                        </div> 
                    </TableCell>
                </TableRow>
            );
        },
        customSearch: (searchQuery, currentRow, columns) => {
            let pesquisa = searchQuery.toLowerCase();
            let pergunta = currentRow[0].toLowerCase();
            let resposta = itensDeAjuda[currentRow[0]].toLowerCase();
            let perguntaIncluiPesquisa = pergunta.includes(pesquisa);
            let respostaIncluiPesquisa = resposta.includes(pesquisa);
            return perguntaIncluiPesquisa || respostaIncluiPesquisa;
        },
        onRowExpansionChange: (currentRowsExpanded, allRowsExpanded, rowsExpanded) => {
            if (currentRowsExpanded[0].dataIndex === itensSelecionados[0]) {
                setItensSelecionados([])
            } else {
                setItensSelecionados([currentRowsExpanded[0].dataIndex]);
            };
        },
        onChangeRowsPerPage: (numberOfRows) => setTamanhoDaPagina(numberOfRows)
    };

    return(
        <Layout pageTitle="Ajuda">
            <div id="ajuda">
                <Paper elevation={2} style={{marginBottom: '1.5rem', padding: '0.5rem 1.5rem'}}>
                    <span style={linkStyle}>
                        <Link 
                            className="linkSuporte centralizadoVerticalmente"
                            href="mailto:dev@vbirealestate.com?subject=Suporte - Apollo Lunar" 
                        >
                            <ContactMailIcon fontSize='large' style={{marginRight: '1rem'}}/>
                            <h2 className="lunar">Fale com o Suporte</h2>
                        </Link>
                    </span>
                </Paper>
                <Paper elevation={2} style={{paddingTop: '1rem'}}>
                    <ThemeProvider theme={muiThemeLocal}>  {/* //TODO: Reanalizar as partes aqui para ver se farei refatoração */}
                        <MUIDataTable className="styledTd"
                            title={(
                                <span className="centralizadoVerticalmente" >
                                    <QuestionAnswerIcon fontSize='large' style={{marginRight: '1rem'}}/>
                                    <h2 className="lunar" style={{margin: 0}}>Perguntas e Respostas</h2>
                                </span>
                            )}
                            data={perguntas}
                            columns={[{
                                name: "Perguntas", 
                                options: { 
                                    customHeadLabelRender: ()=>null,
                                    customBodyRender: (value, tableMeta, updateValue)  => {
                                        return(
                                            <span style={{color: tableMeta.rowIndex === itensSelecionados[0] ? theme.palette.primary.main : null}}>
                                                {value}
                                            </span>
                                        )
                                    }
                                },
                            }]}
                            options={options}
                        />
                    </ThemeProvider>
                </Paper>
            </div>
        </Layout>
    )
}

export default Ajuda
