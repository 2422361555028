import React from 'react';
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import PageTitle from './components/PageTitle/PageTitle'
import { connect } from "react-redux";

import './Layout.css'

const Layout = ({pageTitle, maxPageWidth, auth, children}) => {
    return(
        <div id="layout" className={(auth.isAuthenticated? null : "loginLayout")}>
          <Navbar/>          
          <div className={"mainContainer " + (auth.isAuthenticated? null : "loginMainContainer")}>
            <div className={(maxPageWidth? maxPageWidth : "defaultMaxPageWidth")}>
              <PageTitle pageTitle={pageTitle}/>
              {children}
            </div>
          </div>
          <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Layout);
