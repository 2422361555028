
export let itensDeAjuda = {
    'O que é uma entidade?':
        'Entidade é qualquer pessoa jurídica, fundo, ou veículo de investimento que esteja inserido no contexto da atividade de gestão de carteiras da VBI, podendo ser domiciliado no Brasil, ou em outras jurídições. O que torna uma entidade única é o seu CNPJ, ou tax id correspondente na juridição onde está baseada.',
    'O que é um tema?': 
        'Tema é um agrupamento gerencial, utilizado para facilitar a busca de um relatório. Ainda que seja uma opção para facilitar a consulta, seu preenchimento não é obrigatório.',
    'Como funciona a seção Documentos em Destaque?': 
        'Na tela Início, a seção Documentos em Destaque mostra, em ordem cronológica do mais novo para o mais antigo, todos os relatórios carregados no sistema que estão selecionados em Minhas Regras. Para saber mais sobre as Minha Regras, consultar a pergunta "Como criar uma nova regra?".',
    'Como funciona a seção Documentos Recentes?': 
        'Na tela Início, a seção Documentos Recentes mostra, em ordem cronológica do mais novo para o mais antigo, todos os relatórios carregados no sistema que o usuário tenha permissão para consultar.',
    'Quando usar as dicas?': 
        'Estão espalhados por diversas telas do sistema um icone com um símbolo de interrogação (?). Ao passar o mouse pelo ícone, o usuário poderá encontrar alguma dica de uso daquela tela. Ao clicar no ícone, é apresentado o funcionamento da dica descrita.',
    'Consultar documentos': 
        'Para consultar algum documento é necessário selecionar Consultar na barra superior. Na tela selecionada,nenhum campo da consulta tem preenchimento obrigatório, mas o uso desses campos pode gerar consultas mais rápidas e assertivas. Uma consulta retornará o máximo de 10.000 arquivos. Observe que em todos os campos é possivel selecionar uma ou mais opção numa lista, ou então buscar uma informação digitando um texto.',
    'Compartilhar uma consulta ou um documento': 
        //TODO: substituir abaixo por um array
        'Na tela de exibição de uma consulta, um ícone de compartilhar é exibido no canto superior esquerdo. Qualquer consulta pode ser compartilhada clicando no símbolo do Whatsapp, Telegram, ou E-mail. É necessário que o usuário esteja logado nesses aplicativos para que o compartihamento seja finalizado. ' +
        'Outra opção de compartilhamento pode ser utilizada clicando nos textos Copiar Link ou Copiar Tudo. Depois de clicar em uma dessas opções, colar o texto ou link selecionado na ferramenta de preferência (usualmente e-mail ou aplicativos). Observe que ao colar o link em um navegador da internet, a consulta será exibida. ' + 
        'Importante lembrar que apenas os usuários com permissão de consulta à Entidade e/ou Relatório selecionado poderão visualizar a consulta compartilhada.',
    'Acessar versão de um documento': 
        'Na tela de visualização de um documento, um ícone de versionamento é exibido no canto superior esquerdo. Ao clicar no ícone, o usuário poderá consultar qualquer versão do mesmo documento carregada no sistema. Abaixo das versões disponíveis, ao clicar na opção Todas, é possivel consultar quando as versões foram inseridas, por qual usuário, e em quais formatos elas estão disponívies. ',
    'Consultar histórico de um documento': 
        'Na tela de visualização de um documento, um ícone de histórico é exibido no canto superior esquerdo. Ao clicar no ícone, o usuário poderá consultar o histórico no sistema do relatório selecionado, ou mais específico, o histórico do relatório e entidades selecionados nessa exibição. ',
    'Pesquisar por informações na exibição da consulta de um documento.': 
        'Toda vez que uma consulta for realizada, principalmente se a consulta foi feita sem o preenchimento de um campo, uma busca pode ser feita na tela de exibição da consulta. Essa funcionalidade pode ser usada quando o usuário desconhece um parâmetro de um documento, mas após a consulta queira restringir os dados carregados. Observe que o mesmo efeito pode ser obtido com o uso de filtros. Ambas funcionalidades estão disponíveis no canto superior direito.',
    'Configurar a visualização de uma consulta': 
        'Na tela de exibição de uma consulta, um ícone com três barras é exibido no canto superior direito. Ao clicar no ícone, o usuário pode configurar quais colunas retornarão em uma consulta. Ainda há opção de mudar a ordem em que elas aparecem, selecionando o título da coluna e arrastando para outro lugar na tela. Uma vez configurado, os sistema guardará a preferência de visulização para os próximos acessos, que pode ser modificado a qualquer momento.',
    'Inserir documentos': 
        'A inserção de um documento é feita em três etapas, sendo elas Insera os Arquivos > Classifique os Arquivos > Revise e Confirme. Essas etapas são sequênciais, e apenas estão disponpiveis para usuários autorizados. Para iniciar o processo basta arrastar em soltar na tela um arquivo, ou então selecionar através de um local no computador do usário ou rede. Nessa etapa poderão ser inseridos no mínimo 1 arquivo, e no máximo 50.',
    'Classificar documentos inseridos': 
        'A classificação de um documento é feita completando o Relatório, a Entidade, e a Data de Referência. Essas informações podem ser preenchidas selecionando uma das opções na lista de cada campo, ou digitando no campo de interesse. Cada relatório tem uma peridiocidade de data de referência, podendo ser Anual, Semestral, Trimestral, Mensal ou Diária. Alguns documentos tem peridiocidade Unitário, o que significa que apenas novas versões de um mesmo documento podem ser carregadas, não sendo possivel carregar mais de um documento com data de referênia diferente.',
    'Utilizar sugestão de classificação de um documento': 
        'Quando mais de um documento for carregado de uma única vez, supondo que a sintaxe do nome tenha sido previamente cadastrada, o sistema poderá sugerir a classificação automáticamente. Para utilizar essa funcionalidade, selecionar os documentos desejados, e clicar no ícone Preencher com Sugestão, localizado no campo superior direito. A sugestão poderá ser utilizada para um campo específico, ou para todos os campos conjuntamente. Atenção: é de extrema importância sempre avaliar se a sugestão do sistema preencheu os campos corretamente. ',
    'Repetir classificação de um documento': 
        'Quando mais de um documento for carregado de uma única vez, supondo que um campo se identico no lote inserido, há a opção de classificar o primeiro documento, e repetir a classificação para os demais documentos. Para utilizar essa funcionalidade, selecionar os documentos desejados, e clicar no ícone Preencher Abaixo, localizado no campo superior direito. A sugestão poderá ser utilizada para um campo específico, ou para todos os campos conjuntamente. Atenção: é de extrema importância sempre avaliar se a repetição preencheu os campos corretamente. ',
    'Revisar e Confirmar classificação de documentos': 
        //TODO: substituir abaixo por um array
        'Na última tela do processo de inserção de documentos, mensagem de erros são exibidas caso haja algum problema com as etapas anteriores. ' +
        'O erro mais usual é classificar dois documentos diferentes, como a mesma extensão, com os parâmetros iguais. Caso se trate de duas versões de uma mesmo documento, a inserção deve ser feitas em duas etapas, incluindo primeiro as versões mais antigas, e depois as versões mais recentes. Caso algum documento já exista no sistema, o usuários será notificado, e uma nova versão pode ser carregada. ' + 
        'Nessa etapa do processo, caso algum erro tenha sido identificado, há opção de cancelar a inserção inteira, clicando no botão cancelar localizado no canto inferior esquerdo, ou cancelar um documento específico, alterando as barras horizontais azuis, a equerda da tela, para Não. ',
    'Excluindo uma inserção de documento': 
        'Caso algum documento tenha sido carregado erroneamente para o sistema, há opção de carregar uma versão mais nova, ou então solicitar a exclusão para dev@vbirealestate.com.',
    'Como funciona as Preferências de Notificação?': 
        'Na barra superior do sistema clicar em Preferências. Através da criação regras o usuário poderá escolher para quais entidades e/ou relatórios será notificado por e-mail caso um novo documento seja carregado no sistema. Toda regra é passivel de ser editada, ou deletada no sistema.',
    'Como criar uma nova regra?': 
        'Para criar uma nova regra, clicar no botão azul, no canto superior esquerdo, da tela Preferências. Um nome para regra é solicitado, assim como a escolha do relatório e da entidade de preferência. Em qualquer momento a regra pode ser editada ou excluido. Uma vez criada a regra, o usuário será notificado por e-maisl caso um novo documento seja carregado no sistema.',
};
