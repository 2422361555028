import './MobileMainMenu.css'

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  nested: {
    paddingLeft: '2rem',
  },
});


const MobileMainMenu = ({mainMenuOptions}) => {
  const classes = useStyles();
  const [labelAberto, setLabelAberto] = React.useState(null);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleClick = (labelClicado) => {    
    if(labelAberto === labelClicado){
      setLabelAberto(null);
    } else {
      setLabelAberto(labelClicado);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {mainMenuOptions.map((option) => {
          if(option.dropdownOptions){
            return (
              <>
                <ListItem button onClick={()=>(handleClick(option.label))}>
                  <ListItemText primary={option.label} />
                  {option.label === labelAberto ? <ExpandLess /> : <ExpandMore />}
                </ListItem>        
                <Collapse in={option.label === labelAberto} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {option.dropdownOptions.map(dropdownOption => {return(
                      <ListItem 
                        className={classes.nested}
                        component={Link}  
                        key={dropdownOption.label} 
                        to={dropdownOption.to} 
                        onClick={toggleDrawer(anchor, false)}
                        button 
                      >
                        <ListItemText primary={dropdownOption.label}  />
                      </ListItem>                      
                    )})}
                  </List>
                </Collapse>
              </>
            )
          } else {
            return (
              <ListItem 
                component={Link}  
                key={option.label} 
                to={option.to} 
                onClick={toggleDrawer(anchor, false)} 
                button
              >
                  <ListItemText primary={option.label} />
              </ListItem>
            )
          }
        })}
      </List>
    </div>
  );

  return (
    <div id="mobileMainMenu">
        <React.Fragment key={'left'}>
          <Button className="mainContainer" onClick={toggleDrawer('left', true)} >
            <MenuIcon className="item"/>  
            <span className="item">Menu</span> 
          </Button>
          <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
            {list('left')}
          </Drawer>
        </React.Fragment>
    </div>
  );
}

export default MobileMainMenu