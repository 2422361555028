
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';

const MenuItemLunar = withStyles(
    (theme) => {
      return {
        root: {      
          '&:hover': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.primary.main,
            },
          },
          '& .MuiListItemText-primary': {
            fontWeight: 500
          },     
          color: 'dimgray'     
        },
      }
    }
)(MenuItem)

export default MenuItemLunar;