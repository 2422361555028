import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LunarDialogTitle from 
  '../../components/LunarDialogTitle/LunarDialogTitle';
import LunarDialogActions from 
  '../../components/LunarDialogActions/LunarDialogActions'
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import {updateQueryStringParameters} from '../../support/publicFunctions'

import { KeyboardDatePicker} from '@material-ui/pickers';

import {format} from 'date-fns'

import { useLocation } from "react-router-dom";


const styles = () => ({
  root: {},
  textField: {
    marginBottom: "0.5rem",
  },
  dialogContent: {
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem"
  }
})


const QueryStringUpdateDialog = withStyles(styles)(({
  open, setOpen, title, inputs, classes
}) => {

  //Inicializações básicas
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();   

  //States
  const [values, setValues] = React.useState(getInitialValues());
  React.useEffect(()=>{console.log("values", values)},[values]);

  function getInitialValues(){
    let initialValues = {};
    inputs.map(input => {
      const queryValue =  query.get(input.key);
      const initialValue = input.type === "date" ? 
        (queryValue ? new Date(queryValue + "T00:00:00") : null) :
        queryValue
      initialValues = {...initialValues, [input.key]: initialValue}
      return false;
    })
    return initialValues
  }

  //Handles do Dialog    
  const handleChangeValues = ({key, isDate = false}) => {
    return (event) => {
      const newValue = isDate ? event : event.target.value
      setValues({...values, [key]: newValue})
    }
  }

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const parameters = inputs.map(input => ({
      key: input.key,
      value: input.type === "date" ? 
        (values[input.key] ? format(values[input.key],'yyyy-MM-dd') : "") :
        values[input.key] || ""
    }))
    updateQueryStringParameters(parameters);
    handleClose();
  }  


  return (
    <DialogCustom open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <LunarDialogTitle onClose={handleClose}>{title}</LunarDialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          {inputs.map(input=>{
            const props = {
              label: input.label || input.key,
              value: values[input.key],
              onChange: handleChangeValues({key: input.key, isDate: (input.type 
                === "date")}),
            }
            return (input.type === "date" ? (
              <KeyboardDatePickerCustom {...props}/>       
            ) : (
              <TextFieldCustom {...props} type={input.type}/>
            ))
          })}
        </DialogContent>      
        <LunarDialogActions 
          onCancel={handleClose}
          submitButtonLabel = {"Confirmar e Recarregar Página"}
        />  
      </form>
    </DialogCustom>
  );
});


const DialogCustom = withStyles(styles)(({...other}) => (
  <Dialog
    fullWidth={true}
    maxWidth={"sm"}
    disableBackdropClick      
    {...other} 
  />
))


const TextFieldCustom = withStyles(styles)(({classes, ...other}) => (
  <TextField
    fullWidth
    variant="outlined" 
    margin="dense"
    className={classes.textField}
    placeholder={other.label}
    {...other} 
  />
))


const KeyboardDatePickerCustom = withStyles(styles)(({classes, ...other}) => (
  <KeyboardDatePicker
    placeholder="dd/mm/aaaa"      
    variant="outlined" 
    inputVariant="outlined"
    fullWidth
    margin="dense"
    format="dd/MM/yyyy"
    autoOk={true}
    className={classes.textField}
    {...other} 
  />    
))


export default withStyles(styles)(QueryStringUpdateDialog);