import {useState, useEffect} from 'react';
import React from 'react';
import './SubPaginaArquivos.css'

import Grid from '@material-ui/core/Grid';

import MuiDatatable from '../../components/MuiDatatable/MuiDatatable'

import {formatarDataDeReferencia, capitalizeFirstLetter} from '../../support/publicFunctions'

import {format} from 'date-fns'
import {ptBR} from "date-fns/locale"

import { useHistory} from "react-router-dom";


import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import Dialog from '@material-ui/core/Dialog';
import BotaoDeCompartilhar from '../../pages/Testes/PaginaTeste'


//Componentes
import HelpMenu from '../../components/HelpMenu/HelpMenu'


//Ícones
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';

//Gifs de exemplo
import ordemColunasGif from '../../support/images/exemplos/ordemColunas.gif';
import novaAbaGif from '../../support/images/exemplos/novaAba.gif';

//Textos de ajuda
const textoSobreOrdenacaoDasColunas = "Você pode personalizar a ordem das colunas visíveis. Clique para visualizar um exemplo";
const textoSobreNovaAba = "Você pode segurar a tecla CTRL para abrir o documento em uma nova aba. Clique para visualizar um exemplo";



const SubPaginaArquivos = ({
    arquivos, usuarioNome
}) => { 

    let history = useHistory();

    const [colunas, setColunas] = useState([]);
    const [dados, setDados] = useState([]);
    const [ordemDasColunasDialog, setOrdemDasColunasDialog] = useState(false);
    const [novaAbaDialog, setNovaAbaDialog] = useState(false);


    // Define a ordem padrão das colunas e adiciona ao local storage
    const ordemPadraoDasColunas = [
        {label: '.Arquivo UUID', name: 'uuid', options: {display: "excluded", filter: false}},
        {label: '.Documento UUID', name: 'documentoUuid', options: {display: "excluded", filter: false}},
        {label: '.Número da Versão', name: 'numeroDaVersao', options: {display: "excluded", filter: false}},
        {label: 'Tema', name: 'tema', options: {display: true, filter: true}},
        {label: 'Nome do Arquivo', name: 'nome', options: {display: false, filter: false}},
        {label: 'Relatório', name: 'tipoDeDocumentoEDescritivo', options: {display: true, filter: true}},
        {label: 'Tipo de Documento', name: 'tipoDeDocumento', options: {display: false, filter: true}},
        {label: 'Descritivo', name: 'descritivo', options: {display: false, filter: true}},
        {label: 'Entidade', name: 'entidade', options: {display: true, filter: true}},
        {label: 'Razão Social', name: 'entidadeNomeCompleto', options: {display: false, filter: false}},
        {label: 'Data Ref.', name: 'dataDeReferencia', options: {display: true, filter: true}},
        {label: 'Frequência', name: 'frequenciaDotipoDeDocumento', options: {display: true, filter: true}},
        {label: 'Formato', name: 'extensao', options: {display: true, filter: true}},
        {label: 'Inserido Por', name: 'usuarioQueCarregou', options: {display: true, filter: true}},
        {label: 'Inserido Em', name: 'dataDeUpload', options: {display: false, filter: true}},
        {label: 'Inserido As', name: 'horaDeUpload', options: {display: false, filter: false}},
        {label: 'Identificação do Lote', name: 'identificacaoDoLoteMd5sum', options: {display: false, filter: true}},
        {label: 'Tamanho (KB)', name: 'tamanhoEmKilobytes', options: {display: false, filter: false}},
        {label: 'Versão', name: 'numeroDaVersaoComDisplayTrue', options: {display: false, filter: true}},
        {label: 'Downloads', name: 'numeroDeDownloads', options: {display: false, filter: false}}
    ];

    // Usa o item "ordemInicialConsulta" devido a uma particularidade do funcionamento da MUI tables,
    // de modo que a melhor maneira de se guardar em localstorage a ordem das colunas, é usando dois
    // itens separados
    var ordemDasColunas = [...ordemPadraoDasColunas];
    // "ordemInicialConsulta" serve para fazer o tracking de quais colunas estão sendo alteradas, enquanto
    // que "ordemConsulta" é o que de fato é usado para setar a ordem das colunas.
    // Toda vez que é acessada a página de resultado, ordemInicialConsulta é inicializada com o valor atual
    // de ordemConsulta (embora não acompanhe as mudanças imediatamente)
    localStorage.setItem("ordemInicialConsulta", localStorage.getItem("ordemConsulta"));
    if (localStorage.getItem("ordemConsulta") === null) {
        localStorage.setItem("ordemConsulta", JSON.stringify({ordemDasColunas}))
    };
    
    //Reseta local storage caso suas informações não sejam mais válidas
    function validarInformacoesNoLocalStorage(){
        // As informações guardadas no localStorage, partem, inicialmente, do
        // sistema. Com base nisso, podemos validar os dados do localStorage do
        // usuário checando se os dados que seriam guardados no navegador de um
        // novo usuário (i.e., os dados que partem do sistema), podem ser 
        // transformados de maneira consistente no que se encontra no navegador
        // do usuário atual, cuja informação do localStorage estão sendo 
        // validados
        
        // ordemConsulta
        // Começa removendo o item "options" dos objetos, dado que ele não
        // importa para efeitos de comparação. Após isso, checa se a ordem do
        // usuário contém a do sistema, e se a ordem do sistema contém a do usuário.
        // Em termos matemáticos, dois conjuntos A e B são iguais se e somente se
        // A contém B, e B contém A.
        var ordemConsultaDoSistema = ordemPadraoDasColunas.map(
            item => {
                var label = item.label;
                var name = item.name;
                return { label: label, name: name };
            });

        var ordemConsultaDoUsuario = JSON.parse(localStorage.getItem(
            "ordemInicialConsulta")).ordemDasColunas.map(
                item => {
                    var label = item.label;
                    var name = item.name;
                    return { label: label, name: name };
                });

        var ordemDoUsuarioIncluiDoSistema = ordemConsultaDoSistema.every(item => {
            return ordemConsultaDoUsuario.some(itemUsr => {
                return itemUsr.name === item.name && itemUsr.label === item.label
            });
        });

        var ordemDoSistemaIncluiDoUsuario = ordemConsultaDoUsuario.every(item => {
            return ordemConsultaDoSistema.some(itemSis => {
                return itemSis.name === item.name && itemSis.label === item.label
            });
        });
        if (!(ordemDoSistemaIncluiDoUsuario && ordemDoUsuarioIncluiDoSistema)) {
            localStorage.removeItem("ordemInicialConsulta");
            localStorage.setItem("ordemConsulta", JSON.stringify({ordemDasColunas}));;
            return false;
        };
    };
    validarInformacoesNoLocalStorage(); 

    // Associa os labels usados aos dados a serem usados
    const ordemPadraoDosDados = (obj) => {
        return {
            '.Arquivo UUID': obj['uuid'],
            '.Documento UUID': obj['documentoUuid'],
            '.Número da Versão': obj['numeroDaVersao'],
            'Tema': obj['tema'],
            'Nome do Arquivo': obj['nome'],
            'Relatório': obj['tipoDeDocumento'] + ( obj['descritivo'] ? ` - ${obj['descritivo']}` : ''),
            'Tipo de Documento': obj['tipoDeDocumento'],
            'Descritivo': obj['descritivo'],
            'Entidade': obj['entidade'],
            'Razão Social': obj['entidadeNomeCompleto'],
            'Data Ref.': formatarDataDeReferencia(obj['dataDeReferenciaDia'], obj['dataDeReferenciaMes'],obj['dataDeReferenciaAno'],), 
            'Frequência': capitalizeFirstLetter(obj['frequenciaDotipoDeDocumento'].toLowerCase()),
            'Formato': "." + obj['extensao'],
            'Inserido Por': obj['usuarioQueCarregou'],
            'Inserido Em': format(new Date(obj['dataDeUpload']), 'yyyy-MM-dd'),
            'Inserido As': format(new Date(obj['dataDeUpload']), 'HH:mm'),
            'Identificação do Lote' : obj['identificacaoDoLoteMd5sum'],
            'Tamanho (KB)' : Math.floor(obj['tamanhoEmBytes'] / 1000),
            'Versão': obj['numeroDaVersao'],
            'Downloads': obj['numeroDeDownloads']
        }
    };

    // 

    useEffect(() => {        
        const definirColunasEDadosDaTabela = () => {            
            var ordemDasColunas = JSON.parse(localStorage.getItem("ordemConsulta")).ordemDasColunas;
            if (window.location.href === sessionStorage.getItem("ultimaConsulta")) {
                var filtros = JSON.parse(sessionStorage.getItem("filtros")) || {};
                ordemDasColunas = ordemDasColunas.map((obj) => {
                    var nomeDaColuna = obj.name;
                    var filtro = filtros[nomeDaColuna] || [];
                    obj.options.filterList = filtro;
                    console.log(obj);
                    return obj;
                });
            } else {
                // Remove "filtros" da session storage, se existir
                // eslint-disable-next-line no-unused-vars
                var removerFiltro = sessionStorage.removeItem("filtros") || null;
            };
            setColunas(ordemDasColunas);
            setDados(arquivos.map((obj) => {
                var dados = [];
                for (var columnLabel of ordemDasColunas.map(col => col.label)) {
                    dados.push(ordemPadraoDosDados(obj)[columnLabel])
                };
                return dados;
            }));
        }
        definirColunasEDadosDaTabela();
    }, [arquivos]);



    /*-----------------------------------------------------------*/ 
    /*FUNÇÕES DE APOIO*/ 
    /*-----------------------------------------------------------*/     
    const handleClickRetornar = () => {
        // Remove os itens relacionados à filtragem armazenados na session storage
        // eslint-disable-next-line no-unused-vars
        var removerFiltro = sessionStorage.removeItem("filtros") || null;
        // eslint-disable-next-line no-unused-vars
        var removerUltimaConsulta = sessionStorage.removeItem("ultimaConsulta") || null;
        history.goBack();
    };

    const handleOrdemDasColunasDialogClose = () => {
        return setOrdemDasColunasDialog(false);
    };
    const handleClickAjudaOrdenacaoDasColunas = () => {
        setOrdemDasColunasDialog(!ordemDasColunasDialog);
    };

    const handleNovaAbaDialogClose = () => {
        return setNovaAbaDialog(false);
    };
    const handleClickAjudaNovaAba = () => {
        setNovaAbaDialog(!novaAbaDialog);
    };

    function getTextoDoBotaoDeCompartilhar(){
        //TODO: refatorar tudo abaixo!!
            //Obs: usar a função pública "obterDataPorExtenso"!
        return (
            `Consulta de Documentos realizada por ` +
            `${usuarioNome} em ` + 
            `${format(new Date(), 'dd', { locale: ptBR })}` +
            ` de ` + 
            `${format(new Date(), 'MMMM', { locale: ptBR })}` +
            ` de ` + 
            `${format(new Date(), 'yyyy', { locale: ptBR })}` +
            ` as ` + 
            `${format(new Date(), 'HH')}` + 
            `h` + 
            `${format(new Date(), 'mm')}` + 
            ` (disponível a todos os usuários do Sistema Apollo Lunar que tenham permissão de acesso a este conteúdo): `
        )
    }    

    /*-----------------------------------------------------------*/ 
    /*RENDERIZAÇÃO*/
    /*-----------------------------------------------------------*/   
    return(
        <div id="subPaginaArquivos">
            <Dialog maxWidth={'lg'} onClose={handleOrdemDasColunasDialogClose} open={ordemDasColunasDialog}>
                <img src={ordemColunasGif} alt="Gif com instruções de ajuda"/>
            </Dialog>
            <Dialog maxWidth={'lg'} onClose={handleNovaAbaDialogClose} open={novaAbaDialog}>
                <img src={novaAbaGif} alt="Gif com instruções de ajuda"/>
            </Dialog>
            <Grid container spacing={0}>
                <Grid item xs={12} md={12} >
                    <div className="linhaHorizontal"></div>
                </Grid>
                <Grid className="barraDeFerramentas publico" item xs={12} md={12} >
                    <Tooltip title='Retornar'>
                        <IconButton
                            onClick={handleClickRetornar}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>
                    {/* //TODO: apagar abaixo
                    <Tooltip title='Compartilhar esta pesquisa'>
                        <IconButton
                            onClick={handleClickExibirMensagemEmDesenvolvimento}
                        >
                            <ScreenShareIcon />
                        </IconButton>
                    </Tooltip>                             */}
                    {/* //TODO: refatorar tudo abaixo */}
                    <BotaoDeCompartilhar
                        textoDoBotao = {getTextoDoBotaoDeCompartilhar()}
                        tituloDoBotao = "Compartilhar Consulta"
                        tooltipTitle = 'Compartilhar esta Consulta'
                    >
                        <ScreenShareIcon />
                    </BotaoDeCompartilhar>                                        
                    <HelpMenu
                        dicas={
                            [
                                {
                                    texto: textoSobreOrdenacaoDasColunas,
                                    abrirDialog: handleClickAjudaOrdenacaoDasColunas
                                },
                                {
                                    texto: textoSobreNovaAba,
                                    abrirDialog: handleClickAjudaNovaAba
                                }
                            ]
                        }
                    >
                    </HelpMenu>
                </Grid>
                <Grid item xs={12} md={12} >
                    <MuiDatatable 
                        title={"Arquivos"}
                        data={dados}
                        columns={colunas}
                        nomeDaColunaQueContemUuidDoArquivo={"uuid"}
                        nomeDaColunaQueContemUuidDoDocumento={"documentoUuid"}
                        nomeDaColunaQueContemNumeroDaVersaoDoArquivo={"numeroDaVersao"}
                    />
                </Grid>
            </Grid>
    </div>
    )
}


export default (SubPaginaArquivos);
