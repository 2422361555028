import React from 'react';
import Layout from '../../layout/Layout'

import {useEffect} from 'react';
import {buscarLogsDeDownload} from '../../redux/actionsAdmin/logActions'
import {connect} from "react-redux";

import { useLocation } from "react-router-dom";

import VerticalBarContainer from './components/VerticalBarContainer/VerticalBarContainer';
import PieChartContainer from './components/PieChartContainer/PieChartContainer';

import Grid from '@material-ui/core/Grid';

import Loader from '../../components/Loader/Loader'

import Paper from '@material-ui/core/Paper';

import {handleClickRetornar} from '../../support/publicFunctions'
import {useHistory} from "react-router-dom";

import QueryStringUpdateDialog from 
  '../../components/QueryStringUpdateDialog/QueryStringUpdateDialog';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';  

import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const GraficosDeDownload = ({buscarLogsDeDownload, logsDeDownload, logsDeDownloadIsLoading}) => {

  //Inicializações básicas
  let history = useHistory(); 

  //Otber query strings
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();   
  const limite = query.get("limite");
  const dataInicio = query.get("dataInicio");
  const dataFim = query.get("dataFim");

  useEffect(() => {
    buscarLogsDeDownload(limite, dataInicio, dataFim); 
  // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []);


  //Parâmetros do dialog
  const [dialogOpen, dialogSetOpen] = React.useState(false);
  const dialogTitle = "Configurar limite de dados carregados"
  const dialogInputs = [
    {key: "limite", label: "Limite de Ocorrências", type: "number"},
    {key: "dataInicio", label: "Data Inicial", type: "date"},
    {key: "dataFim", label: "Data Final", type: "date"}
  ]

  return (<>
    {logsDeDownloadIsLoading ? (
      <Layout pageTitle="Carregando...">
        <Loader mensagem={""}/>
      </Layout>
    ) : (
      <Layout pageTitle={"Modo Administrador - Painel Inicial"}> 
        <Grid container spacing={0}>
          <Grid item xs={12} md={12} >
            <div className="linhaHorizontal publico"/>
          </Grid>
          <Grid item xs={12} md={12} className="barraDeFerramentas publico" >
            <Tooltip title='Retornar'>
              <IconButton onClick={()=>{handleClickRetornar(history)}}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Configurar Limitação de Dados'>
              <IconButton onClick={() => (dialogSetOpen(true))}>
                <PermDataSettingIcon />
              </IconButton>
            </Tooltip>
            <QueryStringUpdateDialog
              open={dialogOpen}
              setOpen={dialogSetOpen}
              title={dialogTitle}
              inputs={dialogInputs}
            />
          </Grid>     
          <Grid container spacing={4}>
          <Grid item sm={12} md={12} lg={8} style={{marginBottom: '1rem'}}>
            <Paper elevation={2} style={{height: '100%'}}>
              <div style={{padding: '0.25rem 1.5rem', borderBottom: 'solid 1px lightgray'}}>
                <h2 className="lunar" >Downloads Recentes</h2>      
              </div>
              <div style={{padding: '1rem 1.5rem'}}>
                <VerticalBarContainer logsDeDownload={logsDeDownload}/>
              </div>
            </Paper>
          </Grid>
          <Grid item sm={12} md={8} lg={4} style={{marginBottom: '1rem'}}>
            <Paper elevation={2} style={{height: '100%'}}>
              <div style={{padding: '0.25rem 1.5rem', borderBottom: 'solid 1px lightgray'}}>
                <h2 className="lunar" style={{display: "inline-block"}}>Downloads por Tipo</h2>
              </div>
              <div style={{padding: '1rem 1.5rem'}}>
                <PieChartContainer logsDeDownload={logsDeDownload}/>  
              </div>              
            </Paper>
          </Grid>            
          </Grid>
        </Grid>  
        <p className="lunar textoDescritivo">(Dados baseados nos últimos {logsDeDownload?.length} downloads)</p>      
      </Layout>
    )} 
  </>) 
}

const mapStateToProps = (state) => ({
  //logsDeDownload
  logsDeDownload: state.adminLogsDeDownload.logsDeDownload,
  logsDeDownloadIsLoading: state.adminLogsDeDownload.isLoading
});

export default connect(mapStateToProps, {buscarLogsDeDownload})(GraficosDeDownload)
