import {
    BUSCAR_DICIONARIO_LOADING, BUSCAR_DICIONARIO_SUCCESS, BUSCAR_DICIONARIO_FAIL,
} from '../actionTypes';


const initialState = {
    dicionario: null,
    isLoading: false,
    error: null,
};


const dicionario = (state = initialState, { type, payload }) => {
    switch (type) {
        case BUSCAR_DICIONARIO_LOADING:            
            return {
                ...state,
                isLoading: true,
                error: null,
            }                        
        case BUSCAR_DICIONARIO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                dicionario: payload.dicionario
            }                   
        case BUSCAR_DICIONARIO_FAIL:
            return {
                ...state,
                isLoading: false,
                dicionario: null, 
                error: payload.error                        
            }          
        default:
            return state;
    }
}

export default dicionario