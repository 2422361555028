import axios from 'axios';
import {attachTokenToHeaders, mapApiData, mapApiDataArray, handleErrorForAllActions} 
  from '../actionFunctions';

import {
  BUSCAR_DOCUMENTO_LOADING, BUSCAR_DOCUMENTO_SUCCESS, BUSCAR_DOCUMENTO_FAIL,
  BUSCAR_DOCUMENTOS_POR_ATRIBUTOS_LOADING, 
  BUSCAR_DOCUMENTOS_POR_ATRIBUTOS_SUCCESS, BUSCAR_DOCUMENTOS_POR_ATRIBUTOS_FAIL,
  BUSCAR_DOCUMENTOS_POR_UUID_LOADING, BUSCAR_DOCUMENTOS_POR_UUID_SUCCESS,
  BUSCAR_DOCUMENTOS_POR_UUID_FAIL,
  BUSCAR_TIPOS_DE_DOCUMENTO_LOADING, BUSCAR_TIPOS_DE_DOCUMENTO_SUCCESS, 
  BUSCAR_TIPOS_DE_DOCUMENTO_FAIL,
  CRIAR_DOCUMENTOS_LOADING, CRIAR_DOCUMENTOS_SUCCESS, CRIAR_DOCUMENTOS_FAIL,
  SUBSTITUIR_DOCUMENTOS_LOADING, SUBSTITUIR_DOCUMENTOS_SUCCESS, 
  SUBSTITUIR_DOCUMENTOS_FAIL
} from '../actionTypes';

import {sortArrayOfObjectsMultiple} from '../../support/publicFunctions'

const documentoApiDataMapping = {
  "id": "id",
  "uuid": "uuid",
  "data_referencia_dia": "dia",
  "data_referencia_mes": "mes",
  "data_referencia_ano": "ano",
  "data_vencmento": "dataDeVencimento",
  "data_insercao": "dataDeInsercao",
  "usuario_que_insere_id": "usuarioQueInsereId",
  "usuario_que_insere_nome": "usuarioQueInsere",
  "tipo_de_documento_que_tipifica_id": "tipoDeDocumentoId",
  "tipo_de_documento_que_tipifica_nome": "tipoDeDocumento",
  "tema_que_etiqueta_id": "temaId",
  "tema_que_etiqueta_nome": "tema",
  "descritivo_que_descreve_id": "descritivoId",
  "descritivo_que_descreve": "descritivo",
  "tipo_de_documento_que_tipifica_frequencia": "frequenciaDoTipoDeDocumento",
  "entidade_referida_id": "entidadeId",
  "entidade_referida_nome": "razaoSocialDaEntidade",
  "entidade_referida_apelido": "entidade",
  "entidade_referida_cnpj": "cnpjDaEntidade",
  "entidade_referida_isin": "isinDaEntidade",
  "entidade_referida_administrador": "administradorDaEntidade",
  "numero_total_downloads": "numeroTotalDeDownloads",
  "data_ultima_modificacao": "dataDaUltimaModificacao",
  "numero_versao_mais_recente": "numeroDaVersaoMaisRecente",  
};

const tipoDeDocumentoApiDataMapping = {
  "id": "id",
  "nome": "nome",
  "frequencia": "frequencia",
  "tema_id": "temaId",
  "tema": "tema",
  "data_desativacao": "dataDesativacao",
  "usuario_que_cadastra_id": "usuarioCadastraId",
  "usuario_que_cadastra": "usuarioCadastra",
  "usuario_que_desativa_id": "usuarioDesativaId",
  "usuario_que_desativa": "usuarioDesativa"
}

/* Parâmetros da função substituirDocumentos
 *
 *    documentos: 
 *        deve ser um array de objetos 
 *        o objeto dever ser do seguinte tipo:
 *        -------------------------
 *        {
 *          files: [],               
 *          uuid: ''
 *        },
 *        -------------------------
 *        files: deve ser um FileList
 *        uuid: deve ser o uuid do documento já existente
 *    
 *    numeroDoLote: 
 *       deve ser obtido com a action obterNumeroDoLote
 *       (opcionalmente null, quando houver inserção de um único documento, 
 *       em vez de múltiplos documentos)
 * 
 */
const substituirDocumentos = ({documentos, numeroDoLote}) => 
  async (dispatch, getState) => { 
  dispatch({ 
    type: SUBSTITUIR_DOCUMENTOS_LOADING 
  });  
  try {
    function substituirDocumento (files, uuid, numeroDoLote) {   
        //url
        const url = `/api/documentos/${uuid}/representacoes`;
        //headers
        const options = attachTokenToHeaders(getState, {
            'content-type': 'multipart/form-data',
            'Accept': 'application/json',
        });
        //formData
        const formData = new FormData();
        if(numeroDoLote){
            formData.append('numeroLote', numeroDoLote);
        }
        files.map(file => 
            formData.append('representacao',file)
        );
        //Inspect formData
        // console.log("formData");
        // for (var pair of formData.entries()) {
        //     console.log('    _ _ ' + pair[0]+ ', ' + pair[1]); 
        // }
        //return
        return axios.post(url, formData, options)
    }   
    const promises = documentos.map(documento => 
        substituirDocumento(
            documento.files, documento.uuid, numeroDoLote
        )
    )         
    const responses = await Promise.all(
      promises.map(p => p.then(r => r).catch(e => e))
    )    
  dispatch({
      type: SUBSTITUIR_DOCUMENTOS_SUCCESS,
      payload: {responses: responses},
    });
  } catch (err) {
    handleErrorForAllActions(err, getState);
    dispatch({
      type: SUBSTITUIR_DOCUMENTOS_FAIL,
      payload: {error: err},
    });
  }  
}

/* Parâmetros da função criarDocumentos
 *
 *    documentos: 
 *        deve ser um array de objetos 
 *        o objeto dever ser do seguinte tipo:
 *        -------------------------
 *        {
 *          files: [],               
 *          atributos: {
 *              'entidadeId': '',
 *              'descritivoId': '',
 *              'ano': '',
 *              'mes': '',
 *              'dia': ''
 *          }
 *        },
 *        -------------------------
 *        files deve ser um FileList
 *    
 *    numeroDoLote: 
 *       deve ser obtido com a action obterNumeroDoLote
 *       (opcionalmente null, quando houver inserção de um único documento, 
 *       em vez de múltiplos documentos)
 * 
 */
const criarDocumentos = ({documentos, numeroDoLote}) => 
  async (dispatch, getState) => { 
  dispatch({ 
    type: CRIAR_DOCUMENTOS_LOADING 
  });  
  try {
    function criarDocumento (files, atributos, numeroDoLote) {        
        //url
        const url = '/api/documentos';
        //headers
        const options = attachTokenToHeaders(getState, {
          'content-type': 'multipart/form-data',
          'Accept': 'application/json',
        });
        //formData
        const formData = new FormData();
        formData.append('entidadeId', atributos.entidadeId);
        formData.append('descritivoId', atributos.descritivoId);
        formData.append('ano', atributos.ano);
        formData.append('mes', atributos.mes);
        formData.append('dia', atributos.dia);
        if(numeroDoLote){
            formData.append('numeroLote', numeroDoLote);
        }
        files.map(file => 
            formData.append('representacao',file)
        );
        //Inspect formData
        // console.log("formData");
        // for (var pair of formData.entries()) {
        //     console.log('    _ _ ' + pair[0]+ ', ' + pair[1]); 
        // }
        //return
        return axios.post(url, formData, options)
    }    
    const promises = documentos.map(documento => 
        criarDocumento(
            documento.files, documento.atributos, numeroDoLote
        )
    )         
    const responses = await Promise.all(
      promises.map(p => p.then(r => r).catch(e => e))
    )    
  dispatch({
      type: CRIAR_DOCUMENTOS_SUCCESS,
      payload: {responses: responses},
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    dispatch({
      type: CRIAR_DOCUMENTOS_FAIL,
      payload: {error: err},
    });
  }  
}

const buscarDocumentosPorUuid = (uuids = []) => 
  async (dispatch, getState) => { 
  // uuids deve ser um array de strings contendo todos uuids a serem buscados
  // Esta action retorna um array de objetos, os itens deste array podem ser:
  //    -um documento válido (em caso de sucesso)
  //    -um objeto vazio (em caso de documento inexistente)
  //    -um erro (em caso de falha na busca)
  dispatch({ 
    type: BUSCAR_DOCUMENTOS_POR_UUID_LOADING 
  });
  try {
    const options = attachTokenToHeaders(
      getState, {'Accept': 'application/json'}
    );
    let urls = uuids.map(uuid => `/api/documentos/${uuid}`)
    const promises = urls.map(url=> axios.get(url, options)) 
    const responses = await Promise.all(promises
      .map(p => p.then(r => r).catch(e => e)))    
    const documentos = responses.map(response => {
      if(response?.data?.id){ //Verifica antes do map se o documento existe
        return (mapApiData(response.data, documentoApiDataMapping))
      } else 
        return (response);
    });
    dispatch({
      type: BUSCAR_DOCUMENTOS_POR_UUID_SUCCESS,
      payload: {documentos: documentos},
    });
  } catch (err) {
    handleErrorForAllActions(err, getState);
    dispatch({
      type: BUSCAR_DOCUMENTOS_POR_UUID_FAIL,
      payload: {error: err},
    });
  }    
}


const buscarDocumentosPorAtributos = (atributos = [{}]) => 
  async (dispatch, getState) => { 
// Atributos deve ser um array de objetos com as seguinte keys:
//    descritivoId 
//    entidadeId
//    ano
//    mes (opcional)
//    dia (opcional)
// Esta action retorna um array de objetos, os itens deste array pode, ser:
//    -um documento válido (em caso de sucesso)
//    -um objeto vazio (em caso de documento inexistente)
//    -um erro (em caso de falha na busca)
  dispatch({ 
    type: BUSCAR_DOCUMENTOS_POR_ATRIBUTOS_LOADING 
  });
  try {
    const options = attachTokenToHeaders(
      getState, {'Accept': 'application/json'}
    );
    let urls = atributos.map((atributo) => 
      `/api/documentos/atributos?` +
      `descritivoId=${atributo.descritivoId}&` +
      `entidadeId=${atributo.entidadeId}&` +
      `ano=${atributo.ano}&` +
      `mes=${atributo.mes}&` +
      `dia=${atributo.dia}`
    ) 
    const promises = urls.map(url=> axios.get(url, options)) 
    const responses = await Promise.all(promises
      .map(p => p.then(r => r).catch(e => e)))    
    const documentos = responses.map(response => {
      if(response?.data?.id){ //Verifica antes do map se o documento existe
        return (mapApiData(response.data, documentoApiDataMapping))
      } else 
        return (response);
    });
    dispatch({
      type: BUSCAR_DOCUMENTOS_POR_ATRIBUTOS_SUCCESS,
      payload: {documentos: documentos},
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    dispatch({
      type: BUSCAR_DOCUMENTOS_POR_ATRIBUTOS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
}



const buscarDocumento = (uuid) => async (dispatch, getState) => { //TODO: ver a viabilidade (?e se a melhor forma?) de colocar o history ou não
  dispatch({ 
    type: BUSCAR_DOCUMENTO_LOADING 
  });
  try {
    const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
    const response = await axios.get(`/api/documentos/${uuid}`, options);     
    const mappedData = mapApiData(response.data, documentoApiDataMapping)
    dispatch({
      type: BUSCAR_DOCUMENTO_SUCCESS,
      payload: {documento: mappedData},
    });

  } catch (err) {
    handleErrorForAllActions(err, getState); 
    if (err?.response.status === 404) { //TODO: refatorar o lado servidor para passar um error 404 caso não encontre o id!
      // history.push('/nao-encontrado'); 
        //TODO: fazer essa parte aqui
    }
    dispatch({
      type: BUSCAR_DOCUMENTO_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }

};


const buscarTiposDeDocumento = () => async (dispatch, getState) => {
  
  dispatch({ 
    type: (BUSCAR_TIPOS_DE_DOCUMENTO_LOADING) 
  });
  try {
    const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
    const response = await axios.get('api/tipos-de-documento', options); 
    const tiposDeDocumentoBuscados = mapApiDataArray(response.data, tipoDeDocumentoApiDataMapping);
    //ordena tipos de documento por tema e então por nome:
    const tiposDeDocumento = tiposDeDocumentoBuscados.sort(sortArrayOfObjectsMultiple("tema", "nome"));


    dispatch({
      type: (BUSCAR_TIPOS_DE_DOCUMENTO_SUCCESS),
      payload: {tiposDeDocumento: tiposDeDocumento},
    });

  } catch (err) {
    handleErrorForAllActions(err, getState); 
    dispatch({
      type: (BUSCAR_TIPOS_DE_DOCUMENTO_FAIL),
      payload: { error: err.message},
    });
  }
};


const enviarEmailsPendentesNoLote = (token, numeroDoLote) => {
  //url
  const url = `/api/documentos/lotes/${numeroDoLote}`;
  //headers
  const options = {
      headers: {
          'Authorization': 'Bearer ' + token,
      }
  }     
  //data
  const data = null;
  //return (api response)
  return axios.post(url, data, options);
}


const obterNumeroDoLote = (token) => {
  //url
  const url = '/api/documentos/lotes';
  //headers
  const options = {
      headers: {
          'Authorization': 'Bearer ' + token,
      }
  }
  //return (número do lote)              
  return axios.get(url, options);
}

export {
  buscarDocumento,
  buscarTiposDeDocumento,
  buscarDocumentosPorAtributos,
  buscarDocumentosPorUuid,
  criarDocumentos,
  enviarEmailsPendentesNoLote,
  obterNumeroDoLote,
  substituirDocumentos
}
