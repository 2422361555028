const MenuButtonLunar = ({children, ...other}) => {
    return (
      <div 
        style={{display: "inline-block"}} 
        aria-controls="simple-menu" 
        aria-haspopup="true"
        {...other}
      > 
        {children}
      </div>
    )
}

export default MenuButtonLunar;