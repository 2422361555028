import {
    BUSCAR_ENTIDADES_LOADING, BUSCAR_ENTIDADES_SUCCESS, BUSCAR_ENTIDADES_FAIL,
} from '../actionTypes';


const initialState = {
    entidades: [],
    isLoading: false,
    wasLoaded: false,
    error: null,
};


const entidades = (state = initialState, { type, payload }) => {
    switch (type) {
        case BUSCAR_ENTIDADES_LOADING:            
            return {
                ...state,
                isLoading: true,
                wasLoaded: false,
                error: null,
            }                        
        case BUSCAR_ENTIDADES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                wasLoaded: true,
                entidades: payload.entidades
            }                   
        case BUSCAR_ENTIDADES_FAIL:
            return {
                ...state,
                isLoading: false,
                wasLoaded: false,
                entidades: [], 
                error: payload.error                        
            }          
        default:
            return state;
    }
}

export default entidades