import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
//...
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
//Ícones
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";


const styles = (theme) => ({
  root: {},
  container: {
    display: "flex", 
    justifyContent: 'space-between', 
    alignItems: "center"
  }
});

const LunarDialogTitle = (props) => {
  const {children, classes, onClose, onHelp, helpTooltipTitle, ...other} = props;
  return(
    <DialogTitle {...other}>
      <div className={classes.container}>
        <div>
          <Typography variant="h6">{children}</Typography>
        </div>
        <div>
          {onHelp ? (
            <Tooltip title={helpTooltipTitle || 'Ajuda'}>
              <IconButton onClick={onHelp}>
                  <HelpIcon fontSize="small"/>
              </IconButton>            
            </Tooltip>
          ) : null}
          {onClose ? (
            <Tooltip title={'Fechar'}>
              <IconButton onClick={onClose}>
                <CloseIcon fontSize="small"/>
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </DialogTitle>
  )
};

export default withStyles(styles)(LunarDialogTitle);