import React from 'react';
import './LogsDeDownload.css'
import Layout from '../../layout/Layout'
import {useEffect} from 'react';
import {useHistory} from "react-router-dom";

import Grid from '@material-ui/core/Grid';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MUIDataTable from "mui-datatables";

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import {handleClickRetornar, formatarDataDeReferencia} from '../../support/publicFunctions'

import {buscarLogsDeDownload} from '../../redux/actionsAdmin/logActions'

import {connect} from "react-redux";

import Loader from '../../components/Loader/Loader'

import {format} from 'date-fns'

import textLabels from "../../support/MuiDatatableSupport/TextLabels"
import onDownload from "../../support/MuiDatatableSupport/onDownload"
import {ExcelDownloadIcon} from '../../components/Icons/Icons'

import { useLocation } from "react-router-dom";

import QueryStringUpdateDialog from 
  '../../components/QueryStringUpdateDialog/QueryStringUpdateDialog';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';

const LogsDeDownload = ({
    buscarLogsDeDownload, logsDeDownload, logsDeDownloadIsLoading
}) => { 

    let history = useHistory();    

    //Otber query strings
    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();   
    const limite = query.get("limite");

    useEffect(() => {
        buscarLogsDeDownload(limite); 
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, []);
    
    const colunasDaTabela = [
        {label: 'Baixado Por', name: 'usuarioQueBaixa', options: {display: true, filter: true}},
        {label: 'Baixado Por (Id)', name: 'usuarioQueBaixaId', options: {display: false, filter: true}},
        {label: 'Baixado Por (Ativo?)', name: 'usuarioQueBaixaEstaAtivo', options: {display: "excluded", filter: false}},  
        {label: 'Grupo', name: 'grupoDoUsuarioQueBaixa', options: {display: true, filter: true}},
        {label: 'Grupo Id', name: 'grupoDoUsuarioQueBaixaId', options: {display: false, filter: true}}, 
        {label: 'Tema', name: 'tema', options: {display: true, filter: true}},
        {label: 'Tema Id', name: 'temaId', options: {display: false, filter: true}},          
        {label: 'Arquivo Baixado', name: 'nomeDoArquivo', options: {display: true, filter: false}},    
        {label: 'Tamanho (KB)', name: 'tamanhoEmKilobytes', options: {display: false, filter: false}},
        {label: 'Baixado Em', name: 'dataDownload', options: {display: true, filter: true}},
        {label: 'Baixado As', name: 'horaDownload', options: {display: false, filter: false}},   
        {label: 'Arquivo Id', name: 'arquivoId', options: {display: false, filter: false}},
        {label: 'Arquivo Uuid', name: 'arquivoUuid', options: {display: false, filter: false}},
        {label: 'Documento Id', name: 'documentoId', options: {display: false, filter: false}},
        {label: 'Documento Uuid', name: 'documentoUuid', options: {display: false, filter: false}},
        {label: 'Hash', name: 'hash', options: {display: false, filter: false}},
        {label: 'Data Ref.', name: 'dataRef', options: {display: false, filter: true}}, 
        {label: 'Data Ref. Ano', name: 'dataRefAno', options: {display: false, filter: true}},
        {label: 'Data Ref. Mês', name: 'dataRefMes', options: {display: false, filter: true}},
        {label: 'Data Ref. Dia', name: 'dataRefDia', options: {display: false, filter: true}},
        {label: 'Frequência', name: 'frequencia', options: {display: false, filter: true}},
        {label: 'Data Vencimento', name: 'dataVencimento', options: {display: false, filter: true}},
        {label: 'Versão', name: 'numeroVersao', options: {display: false, filter: true}},
        {label: 'Extensão', name: 'extensao', options: {display: false, filter: true}},
        {label: 'Tipo de Documento', name: 'tipoDeDocumento', options: {display: false, filter: true}},
        {label: 'Tipo de Documento Id', name: 'tipoDeDocumentoId', options: {display: false, filter: true}},
        {label: 'Descritivo', name: 'descritivo', options: {display: false, filter: true}},
        {label: 'Descritivo Id', name: 'descritivoId', options: {display: false, filter: true}},
        {label: 'Entidade', name: 'entidadeApelido', options: {display: false, filter: true}},
        {label: 'Entidade Id', name: 'entidadeId', options: {display: false, filter: true}},
        {label: 'Razão Social', name: 'entidadeNomeCompleto', options: {display: false, filter: true}},
        {label: 'Inserido Por', name: 'usuarioQueSobe', options: {display: false, filter: true}},
        {label: 'Inserido Por (Id)', name: 'usuarioQueSobeId', options: {display: false, filter: true}},
        {label: 'Inserido Em', name: 'dataUpload', options: {display: false, filter: true}},
        {label: 'Inserido As', name: 'horaUpload', options: {display: false, filter: false}}, 
        {label: 'Lote Id', name: 'identificacaoLoteSequencial', options: {display: false, filter: true}},
        {label: 'Lote Md5sum', name: 'identificacaoLoteMd5Sum', options: {display: false, filter: true}},
    ]    

    const dadosDaTabela = function(logsDeDownload){return(
        logsDeDownload.map((log)=>{return[
            log.usuarioQueBaixa, //Baixado Por
            log.usuarioQueBaixaId, //Baixado Por (Id)
            log.usuarioQueBaixaEstaAtivo, //Baixado Por (Ativo?)
            log.grupoDoUsuarioQueBaixa, //Grupo
            log.grupoDoUsuarioQueBaixaId, //Grupo Id
            log.tema, //Tema
            log.temaId, //Tema Id
            log.nomeDoArquivo, //Arquivo
            Math.floor( log.tamanhoEmBytes / 1000), //Tamanho (KB)
            format(new Date(log.dataDownload), 'yyyy-MM-dd'), //Baixado Em
            format(new Date(log.dataDownload), 'HH:mm'), //Baixado As
            log.arquivoId, //Arquivo Id
            log.arquivoUuid, //Arquivo Uuid
            log.documentoId, //Documento Id
            log.documentoUuid, //Documento Uuid
            log.hash, //Hash
            formatarDataDeReferencia(log.dataRefDia, log.dataRefMes, log.dataRefAno),  //Data Ref.
            log.dataRefAno, //Data Ref. Ano
            log.dataRefMes, //Data Ref. Mês
            log.dataRefDia, //Data Ref. Dia
            log.frequencia, //Frequência
            log.dataVencimento, //Data Vencimento
            log.numeroVersao, //Versão
            log.extensao, //Extensão         
            log.tipoDeDocumento, //Tipo de Documento
            log.tipoDeDocumentoId, //Tipo de Documento Id
            log.descritivo, //Descritivo
            log.descritivoId, //Descritivo Id
            log.entidadeApelido, //Entidade
            log.entidadeId, //Entidade Id
            log.entidadeNomeCompleto, //Razão Social
            log.usuarioQueSobe, //Inserido Por
            log.usuarioQueSobeId, //Inserido Por (Id)
            format(new Date(log.dataUpload), 'yyyy-MM-dd'), //Inserido Em
            format(new Date(log.dataUpload), 'HH:mm'), //Inserido As
            log.identificacaoLoteSequencial, //Lote Id
            log.identificacaoLoteMd5Sum, //Lote Md5sum          
        ]})        
    )}

    const opcoesDaTabela = {
        textLabels: textLabels,
        onDownload: onDownload,
        elevation: 2,
        filterType: "multiselect",
        responsive: "standard",  
        selectableRows: 'none',
        draggableColumns: {enabled: true},
    }        


    const components = {
        icons: {
          DownloadIcon: ExcelDownloadIcon,
        }
    };    


    //Parâmetros do dialog
    const [dialogOpen, dialogSetOpen] = React.useState(false);
    const dialogTitle = "Configurar limite de dados carregados"
    const dialogInputs = [
        {key: "limite", label: "Limite de Ocorrências", type: "number"},
        {key: "dataInicio", label: "Data Inicial", type: "date"},
        {key: "dataFim", label: "Data Final", type: "date"}
    ]


    return (
        <>{(
            logsDeDownloadIsLoading
        ) ? (
            <Layout pageTitle="Carregando...">
                <Loader mensagem={""}/>
            </Layout>
        ) : (        
            <Layout pageTitle="Logs de Download">
                <div id="logsDeDownload">
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12} >
                            <div className="linhaHorizontal publico"/>
                        </Grid>
                        <Grid 
                            className="barraDeFerramentas publico" 
                            item xs={12} md={12}
                        >
                            <Tooltip title='Retornar'>
                                <IconButton onClick={
                                    ()=>{handleClickRetornar(history)}
                                }>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Configurar Limitação de Dados'>
                                <IconButton onClick={() => (dialogSetOpen(true))}>
                                    <PermDataSettingIcon />
                                </IconButton>
                            </Tooltip>
                            <QueryStringUpdateDialog
                                open={dialogOpen}
                                setOpen={dialogSetOpen}
                                title={dialogTitle}
                                inputs={dialogInputs}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} >                           
                            <MUIDataTable
                                title={'Downloads'}
                                data={dadosDaTabela(logsDeDownload)}
                                columns={colunasDaTabela}
                                options={opcoesDaTabela}
                                components={components}
                            />
                        </Grid>                        
                    </Grid>                    
                </div>
            </Layout>
        )}</>            
    ) 
}

const mapStateToProps = (state) => ({
    //logsDeDownload
    logsDeDownload: state.adminLogsDeDownload.logsDeDownload,
    logsDeDownloadIsLoading: state.adminLogsDeDownload.isLoading
});

export default connect(mapStateToProps, {buscarLogsDeDownload})(LogsDeDownload)