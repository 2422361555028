const tipos = {
    //PARÂMETROS
    ADM_FIDUCIARIOS: "ADM_FIDUCIARIOS",
    DESCRITIVOS: "DESCRITIVOS",
    ENTIDADES: "ENTIDADES",
    SEGMENTOS: "SEGMENTOS",
    TEMAS: "TEMAS",
    TIPOS_DE_DOCUMENTO: "TIPOS_DE_DOCUMENTO",
    //ACESSOS
    USUARIOS: "USUARIOS",
    GRUPOS_DE_ACESSO: "GRUPOS_DE_ACESSO",
    ADMINISTRADORES: "ADMINISTRADORES",
    //CONTROLES
    ARQUIVOS: "ARQUIVOS",
    DOCUMENTOS: "DOCUMENTOS",
    NOTIFICACOES: "NOTIFICACOES",
    SUGESTOES: "SUGESTOES"
}

const {ADM_FIDUCIARIOS, DESCRITIVOS, ENTIDADES, SEGMENTOS, TEMAS, 
    TIPOS_DE_DOCUMENTO, USUARIOS, GRUPOS_DE_ACESSO, ADMINISTRADORES,
    ARQUIVOS, DOCUMENTOS, NOTIFICACOES, SUGESTOES
} = tipos


const urls = {
    //PARÂMETROS
    [ADM_FIDUCIARIOS]: '/api/administradores/administradores',
    [DESCRITIVOS]: '/api/administradores/descritivos',
    [ENTIDADES]: '/api/administradores/entidades',
    [SEGMENTOS]: '/api/administradores/segmentos',
    [TEMAS]: '/api/administradores/temas',
    [TIPOS_DE_DOCUMENTO]: '/api/administradores/tipos-de-documento',
    //ACESSOS
    [USUARIOS]: '/api/administradores/usuarios',
    [GRUPOS_DE_ACESSO]: '/api/administradores/grupos-de-acesso',
    [ADMINISTRADORES]: '/api/administradores/',
    //CONTROLES
    [ARQUIVOS]: '/api/administradores/representacoes', 
    [DOCUMENTOS]: '/api/administradores/documentos', 
    [NOTIFICACOES]: '/api/administradores/usuarios/notificacoes',
    [SUGESTOES]: '/api/administradores/dicionario',
};
  
  
const configuracoes = {
    //PARÂMETROS
    [TEMAS]: {
        titulo: {
            noSingular: "Tema", 
            noPlural: "Temas",      
        },
        colunas: { 
          exibir:  ['id', 'nome', 'usuario_que_cadastra'], 
        },
        metodos: {
            podeSerCriado: true,
            podeSerRemovido: true,
            podeSerDesativado: false,
            podeSerEditado: true,
        },
        inputs: [
            {name: "nome", editName: "novoNome", coluna: "nome", label: "Nome", 
                type: "text", required: true, maxLength: 191, minLength: 3},
        ],
    },
    [ADM_FIDUCIARIOS]: {
        titulo: {
            noSingular: "Administrador Fiduciário",
            noPlural: "Administradores Fiduciários",
        },
        colunas: { 
          exibir:  ['id', 'cnpj', 'nome', 'entidades'], 
          formatarComoCnpj:  ['cnpj'],
          formatarComoArray:  [
            {coluna: 'entidades', label: 'apelido'}
          ]
        },
        metodos: {
            podeSerCriado: true,
            podeSerRemovido: true,
            podeSerDesativado: false,
            podeSerEditado: true,
        },        
        inputs: [
            {name: "nome", coluna: "nome", label: "Nome", type: "text", 
                required: true, maxLength: 191, minLength: 3},
            {name: "cnpj", coluna: "cnpj", label: "CNPJ", type: "cnpj", 
                required: true} 
        ]        
    },
    [DESCRITIVOS]: {
        titulo: {
            noSingular: "Descritivo",
            noPlural: "Descritivos",   
        },
        colunas: { 
          exibir:  ['id', 'nome', 'tipo_de_documento_nome', 
            'usuario_que_cadastra'], 
        },
        metodos: {
            podeSerCriado: true,
            podeSerRemovido: true,
            podeSerDesativado: false,
            podeSerEditado: true,
        },        
        inputs: [
            {name: "nome", editName: "novoNome", coluna: "nome", label: "Nome",
                 type: "text", required: false, maxLength: 191, minLength: 3,
                 converterNullParaEmptyString: true}, 
                 //obs: o nome do descritivo pode ser um empty string
            {name: "tipoDeDocumentoId", coluna: "tipo_de_documento_id", 
                label: "Tipo de Documento", type: "select", 
                optionsType: "TIPOS_DE_DOCUMENTO", required: true, 
                disabledNaEdicao: true}, 
        ]             
    },    
    [TIPOS_DE_DOCUMENTO]: {
        titulo: {
            noSingular: "Tipo de Documento",
            noPlural: "Tipos de Documento",    
        },
        colunas: { 
          exibir:  ['id', 'nome', 'frequencia', 'ativo', 'tema', 
            'usuario_que_cadastra', 'usuario_que_desativa', 'descritivos'],
          formatarComoBooleano: ['ativo'],
          formatarComoArray:  [
            {coluna: 'descritivos', label: 'nome'}
          ]          
        },
        metodos: {
            podeSerCriado: true,
            podeSerRemovido: false,
            podeSerDesativado: true,
            podeSerReativado: true,
            podeSerEditado: true,
        },
        inputs: [
            {name: "nome", coluna: "nome", label: "Nome", type: "text", 
                required: true, maxLength: 191, minLength: 3},
            {name: "temaId", coluna: "tema_id", label: "Tema", 
                type: "select", optionsType: "TEMAS", required: true}, 
            {name: "frequencia", coluna: "frequencia", label: "Frequência", 
                type: "select", optionsType: "TIPOS_DE_DOCUMENTO_FREQUENCIAS", 
                required: true, disabledNaEdicao: true}, 
        ]         
    },  
    [SEGMENTOS]: {
        titulo: {
            noSingular: "Segmento",
            noPlural: "Segmentos",
        },
        colunas: { 
          exibir:  ['id', 'nome', 'entidades'], 
          formatarComoArray:  [
            {coluna: 'entidades', label: 'entidade_apelido'}
          ]
        },
        metodos: {
            podeSerCriado: true,
            podeSerRemovido: true,
            podeSerDesativado: false,
            podeSerEditado: true,
        },        
        inputs: [
            {name: "nome", coluna: "nome", label: "Nome", type: "text", 
                required: true, maxLength: 70, minLength: 3},
            {name: "entidadeId", coluna: "entidades", label: "Entidades", 
                type: "select", optionsType: "ENTIDADES", 
                optionsMultiple: true, optionsMultipleColunaId: "entidade_id",
                required: false},           
        ]        
    },    
    [ENTIDADES]: {
        titulo: {
            noSingular: "Entidade",
            noPlural: "Entidades",
        },
        colunas: { 
          exibir:  ['id', 'apelido', 'ativa', 'canal_de_distribuicao', 'tipo', 
            'subtipo', 'qualificacao_do_investidor', 'modelada', 'cnpj', 'isin', 
            'administrador', 'identificacao_no_administrador', 
            'usuario_que_cadastra', 'usuario_que_desativa', 'segmentos'], 
          formatarComoBooleano: ['ativa', 'modelada'],
          formatarComoCnpj:  ['cnpj'],
          formatarComoArray:  [
            {coluna: 'segmentos', label: 'nome'}
          ]          
        },
        metodos: {
            podeSerCriado: true,
            podeSerRemovido: false,
            podeSerDesativado: true,
            podeSerReativado: true,
            podeSerEditado: true,
        },        
        inputs: [
            {name: "nome", coluna: "nome", label: "Nome", type: "text", 
                required: true, maxLength: 320, minLength: 3},
            {name: "apelido", coluna: "apelido", label: "Apelido", type: "text", 
                required: true, maxLength: 191, minLength: 3},
            {name: "canalDeDistribuicao", coluna: "canal_de_distribuicao", 
                label: "Canal de Distribuição", type: "select", 
                optionsType: "ENTIDADES_CANAIS_DE_DISTRIBUICAO", required: true}, 
            {name: "cnpj", coluna: "cnpj", label: "CNPJ", type: "cnpj", 
                required: true}, 
            {name: "isin", coluna: "isin", label: "ISIN", type: "text",  
                required: false, maxLength: 12, minLength: 12},  
            {name: "modeladaInternamente", coluna: "modelada", 
                label: "Modelada Internamente", type: "select", 
                optionsType: "BOOLEANO_0OU1", required: true },
            {name: "tipo", coluna: "tipo", label: "Tipo", type: "select",  
                optionsType: "ENTIDADES_TIPOS", required: true}, 
            {name: "subtipo", coluna: "subtipo", label: "Subtipo", 
                type: "select",  optionsType: "ENTIDADES_SUBTIPOS", 
                required: true}, 
            {name: "qualificacaoDoInvestidor", 
                coluna: "qualificacao_do_investidor", 
                label: "Qualificação do Investidor", 
                type: "select", 
                optionsType: "ENTIDADES_QUALIFICACOES_DO_INVESTIDOR",
                required: true, maxLength: null, minLength: null}, 
            {name: "administradorId", coluna: "administrador_id", 
                label: "Administrador", type: "select", 
                optionsType: "ADM_FIDUCIARIOS", required: false}, 
            {name: "identificacaoNoAdministrador", 
                coluna: "identificacao_no_administrador", 
                label: "Identificação no Administrador", type: "text",  
                required: false, maxLength: 40, minLength: 1}, 
            {name: "segmentos", coluna: "segmentos", label: "Segmentos", 
                type: "select", optionsType: "SEGMENTOS", 
                optionsMultiple: true, required: false},
        ],
        subParametrosTipos: [
            "Canais de Distribuição",
            "Tipos de Entidade",
            "Subtipos de Entidade",
            "Qualificações do Investidor"
        ]
    },
    //ACESSOS
    [USUARIOS]: {
        titulo: {
            noSingular: "Usuário", 
            noPlural: "Usuários",      
        },
        colunas: {
            exibir:  ['id', 'nome', 'grupo', 'data_primeiro_acesso', 
                'endereco_email', 'numero_downloads', 'insere', 'master', 
                'ativo'], 
            formatarComoBooleano: ['insere', 'master', 'ativo'],
        },
        metodos: {
            podeSerCriado: true,
            podeSerRemovido: false,
            podeSerDesativado: true,
            podeSerReativado: true,
            podeSerEditado: true,
        },    
        inputs: [
            {name: "nomeCompleto", coluna: "nome", label: "Nome Completo", 
                type: "text", required: true, maxLength: 281, minLength: 3},
            {name: "email", coluna: "endereco_email", label: "E-mail", 
                type: "email", required: true, maxLength: 320, minLength: 3},         
            {name: "grupoId", coluna: "grupo_id", label: "Grupo de Acesso", 
                type: "select", optionsType: "GRUPOS_DE_ACESSO", required: true},           
            {name: "insere", coluna: "insere", label:"Pode Inserir Documentos?", 
                type: "select", optionsType: "BOOLEANO_0OU1", required: true},
            {name: "master", coluna: "master", label: "É Administrador?", 
                type: "select", optionsType: "BOOLEANO_0OU1", required: true, 
                valorInicial: 0, disabledNaCriacao: true, disabledNaEdicao: true}, 
            {name: "ativo", coluna: "ativo", label: "Está Ativo?", 
                type: "select", optionsType: "BOOLEANO_0OU1", required: true,
                valorInicial: 1, disabledNaCriacao: true, disabledNaEdicao: true},
        ],
    },    
    [GRUPOS_DE_ACESSO]: {
        titulo: {
            noSingular: "Grupo de Acesso", 
            noPlural: "Grupos de Acesso",      
        },
        colunas: {
            exibir:  [], 
            formatarComoArray:  [
                {coluna: 'usuarios', label: 'nome'},
                {coluna: 'temas', label: 'tema'},
                {coluna: 'criterios', label: 'customView'},
            ] 
        },
        metodos: {
            podeSerCriado: true,
            podeSerRemovido: true,
            podeSerDesativado: false,
            podeSerEditado: true,
        },    
        inputs: [
            {name: "nome", coluna: "nome", label: "Nome do Grupo", type: "text", 
                required: true, maxLength: 191, minLength: 3},
            {name: "temas", coluna: "temas", label: "Temas que o Grupo Acessa", 
                type: "select", optionsType: "TEMAS", optionsMultiple: true, 
                optionsMultipleColunaId: "id_tema_acessado", required: true,
                disabledNaEdicao: false},
            {name: "restricoes", coluna: "criterios", 
                label: "Entidades que o Grupo Acessa", type: "select", 
                optionsType: "GRUPOS_DE_ACESSO_RESTRICOES", 
                optionsMultiple: true, required: true,
                optionsMultipleColunaId: "customId",
                disabledNaEdicao: false},
        ],
    },    
    [ADMINISTRADORES]: {
        titulo: {
            noSingular: "Administrador", 
            noPlural: "Administradores",      
            daPaginaPrincipal: "Administradores do Sistema"  
        },
        colunas: {
            exibir:  ['id', 'nome',  'grupo'], 
            formatarComoBooleano: ['insere', 'master', 'ativo'],
        },
        metodos: {
            podeSerCriado: false,
            podeSerRemovido: false,
            podeSerDesativado: false,
            podeSerEditado: false,
            podeConcederAdministrador: true,
            podeRetirarAdministrador: true,
        },    
    },     
    //CONTROLES
    [ARQUIVOS]: {
        titulo: {
            noSingular: "Arquivo", 
            noPlural: "Arquivos",  
            daPaginaPrincipal: "Desativar Arquivo"    
        },
        colunas: { 
          exibir:  ['nome_do_arquivo', 'usuario_que_sobe_nome', 
            'data_de_upload'], 
          formatarComoBooleano: ['ativa'],
        },
        metodos: {
            podeSerCriado: false,
            podeSerRemovido: false,
            podeSerDesativado: true,
            podeSerReativado: false,
            podeSerEditado: false,
            podeConfigurarLimitacaoDeDados: true
        },
    },
    [DOCUMENTOS]: {
        titulo: {
            noSingular: "Documento", 
            noPlural: "Documentos",     
            daPaginaPrincipal: "Desativar Versão mais recente de Documento", 
        },
        colunas: { 
          exibir:  ['titulo_versao_mais_recente', 'numero_versao_mais_recente'], 
          formatarComoBooleano: ['ativo'],
          formatarComoCnpj: ['entidade_referida_cnpj']
        },
        metodos: {
            podeSerCriado: false,
            podeSerRemovido: false,
            podeSerDesativado: false,
            podeSerReativado: false,
            podeSerEditado: false,
            podeDesativarVersaoMaisRecenteDeDocumento: true,
            podeConfigurarLimitacaoDeDados: true
        },
    },    
    [NOTIFICACOES]: {
        titulo: {
            noSingular: "Regra de Notificação", 
            noPlural: "Regras de Notificações",      
        },
        colunas: {
            exibir:  ['usuarioNome', 'nome', 'entidades', 'tiposDeDocumento'], 
            displayExcluded: ['id'],
            formatarComoArray:  [
                {coluna: 'entidades', label: 'apelido'},
                {coluna: 'tiposDeDocumento', label: 'nome'},
            ]     
        },
        metodos: {
            podeSerCriado: true,
            podeSerRemovido: true,
            podeSerDesativado: false,
            podeSerEditado: false,
        },
        inputs: [
            {name: "nome", coluna: "nome", label: "Nome da Regra", 
                type: "text", required: true, maxLength: 191, minLength: 3},
            {name: "usuarios", coluna: "usuario", 
                label: "Criar para este(s) usuário(s)", 
                type: "select", optionsType: "USUARIOS", 
                optionsMultiple: true, optionsMultipleColunaId: null, 
                required: true, maximumThatCanBeSelected: 10},                        
            {name: "listaDeTiposDeDocumento", coluna: "tiposDeDocumento", 
                label: "Notificá-los com estes Relatórios", 
                type: "select", optionsType: "TIPOS_DE_DOCUMENTO", 
                optionsMultiple: true, optionsMultipleColunaId: null,
                required: false},
            {name: "listaDeEntidades", coluna: "entidades", 
                label: "para estas Entidades", 
                type: "select", optionsType: "ENTIDADES", 
                optionsMultiple: true, optionsMultipleColunaId: null,
                required: false},                  
        ],        
    },
    [SUGESTOES]: {
        titulo: {
            noSingular: "Sugestão", 
            noPlural: "Sugestões",     
            daPaginaPrincipal: "Dicionário de Sugestões" 
        },
        colunas: { 
          exibir:  ["sugestaoTipo", "parametroNome", "chavesUnicas"], 
          displayExcluded: ['id'],
          formatarComoArray:  [
            {coluna: 'chavesUnicas', label: 'valor'},
          ]
        },
        metodos: {
            podeSerCriado: false,
            podeSerRemovido: false,
            podeSerDesativado: false,
            podeSerEditado: true,
        },
        inputs: [
            {name: "tipo", coluna: "tipo", label: "Tipo", 
                disabledNaEdicao: true},
            {name: "sugestao", coluna: "sugestao", label: "Sugestão", 
                disabledNaEdicao: true},
            {name: "chavesUnicas", coluna: "chavesUnicas", 
                label: "Chaves Únicas", 
                type: "select", optionsType: null,
                optionsMultiple: true, optionsMultipleColunaId: "valor",
                required: false
            },
        ],
    },

  }

export {
    configuracoes,
    tipos,
    urls
}