/* eslint-disable no-use-before-define */

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

/*Obs: manter itens abaixo como "small" pro ícone não ficar muito grande nas opções de seleção */
const icon1 = <CheckBoxOutlineBlankIcon fontSize="small" />; 
const checkedIcon1 = <CheckBoxIcon fontSize="small" />; 
const icon2 = <RadioButtonUncheckedIcon fontSize="small" />; 
const checkedIcon2 = <RadioButtonCheckedIcon fontSize="small" />; 


const optionsExemplo = [{nome: "Opção1"}, {nome: "Opção2"}, {nome: "Opção3"}]

// Por padrão, é usado option === value como critério para checagem dos valores selecionados, porém, como se tratam de objetos, o critério padrão de checagem não funcionava, de modo que usaremos um atributo único dos objetos
const checarSeEstaSelecionado = (option, value) => {
    // Se o objeto apresentar id, utiliza a id; se não, o nome
    if (option.id !== undefined) {
        return option.id === value.id
    } else {
        return option.nome === value.nome
    };
};

export default function CheckboxesTags({
  options=[...optionsExemplo],
  optionLabelKey = "nome", //utilizar esse parâmetro para indicar qual key (do objeto "options") possui os valores que serão utilizados como label para o usuário escolher as opções
  groupByKey=null, //utilizar esse parâmetro para indicar qual key (do objeto "options") possui os valores que serão utilizados como parâmetro do agrupamento das opções (deixar "null" caso não queira agrupamento)
  multiple=true,
  limitTags=1, //Set -1 to disable the limit.
  disableCloseOnSelect=true,
  label=null,
  placeholder=null,
  textFieldVariant="standard",
  value,
  onChange,  
  defaultValue

}) {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={option => option[optionLabelKey]}
      groupBy={(option) => option[groupByKey]}
      multiple={multiple}
      limitTags={limitTags} 
      disableCloseOnSelect={disableCloseOnSelect}
      value={value}
      onChange={onChange}   
      defaultValue={defaultValue}   
      getOptionSelected={checarSeEstaSelecionado}
      renderOption={(option, { selected }) => {
        return (
        <React.Fragment>
          <Checkbox
            icon={multiple ? icon1 : icon2}
            checkedIcon={multiple ? checkedIcon1 : checkedIcon2}
            style={{ marginRight: 8 }}
            checked={selected}
            color="primary"
          />
          {option[optionLabelKey]}
        </React.Fragment> 
      )}}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} variant={textFieldVariant}/>
      )}
    />
  );
}

