import React from 'react';

import './MainMenu.css'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import DesktopMainMenu from './components/DesktopMainMenu/DesktopMainMenu'
import MobileMainMenu from './components/MobileMainMenu/MobileMainMenu'

import { connect } from "react-redux";

import { useLocation } from 'react-router-dom'
import { estaNoModoAdmin } from '../../../support/publicFunctions'


const MainMenu = ({auth}) => {

    const location = useLocation();

    const defaultMenuOptions = [
        {label: 'Início', to: '/inicio'},
        {label: 'Consultar', to: '/consulta'},
        {label: 'Inserir', to: '/insercao'},
        {label: 'Preferências', to: '/preferencias'},
        {label: 'Ajuda', to: '/ajuda'},
    ]

    const adminMenuOptions = [
        {label: 'Painel', to: '/admin/inicio?limite=1000'},
        {
            label: 'Parâmetros', 
            activeLink: '/admin/parametros', 
            dropdownOptions: [
                {label: 'Administradores Fiduciários', to: '/admin/parametros/adm-fiduciarios'},
                {label: 'Descritivos', to: '/admin/parametros/descritivos'},
                {label: 'Entidades', to: '/admin/parametros/entidades'},
                {label: 'Segmentos', to: '/admin/parametros/segmentos'},
                {label: 'Temas', to: '/admin/parametros/temas'},
                {label: 'Tipos de Documento', to: '/admin/parametros/tipos-de-documento'},
        ]},
        {
            label: 'Acessos', 
            activeLink: '/admin/acessos', 
            dropdownOptions: [
                {label: 'Administradores do Sistema', to: '/admin/acessos/administradores'},
                {label: 'Grupos de Acesso', to: '/admin/acessos/grupos'},
                {label: 'Usuários', to: '/admin/acessos/usuarios'},
        ]},
        {
            label: 'Controles', 
            activeLink: '/admin/controles', 
            dropdownOptions: [
                {label: 'Desativação de Arquivo', to: '/admin/controles/arquivos?limite=1000'},
                {label: 'Desativação de Versão de Documento', to: '/admin/controles/documentos?limite=1000'},
                {label: 'Dicionário de Sugestões', to: '/admin/controles/sugestoes'},
                {label: 'Regras de Notificações', to: '/admin/controles/notificacoes'},
        ]},        
        {
            label: 'Logs', 
            activeLink: '/admin/logs', 
            dropdownOptions: [
                {label: 'Atividades', to: '/admin/logs/atividades?limite=1000'},
                {label: 'Downloads', to: '/admin/logs/downloads?limite=1000'},
        ]},
        {label: 'Ajuda', to: '/admin/ajuda'},
        {label: 'Voltar', to: '/admin/voltar'},
    ]        
        
    let mainMenuOptions = (
        estaNoModoAdmin(location, auth) ? adminMenuOptions : defaultMenuOptions
    );

    return (
        <div id="MainMenu">
            { useMediaQuery('(min-width:960px)') ? (
                <DesktopMainMenu mainMenuOptions={mainMenuOptions} />
            ):(
                <MobileMainMenu mainMenuOptions={mainMenuOptions} />
            )}
        </div>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps)(MainMenu);
