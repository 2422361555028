import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

//List Items
import ListItemText from '@material-ui/core/ListItemText';

//Icons
import HelpIcon from '@material-ui/icons/Help';


const StyledMenuItem = withStyles(
  (theme) => {
    return {
      root: {      
        '&:hover': {
          '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
              color: theme.palette.primary.main,
          },
        },
        '& .MuiListItemText-primary': {
          fontWeight: 500
        },     
        color: 'dimgray'     
      },
    }
  }
)(MenuItem)


const HelpMenu = ({
  dicas
}) => {
  // dicas = [dica1, ..., dican]
  // dica = { texto: "", abrirDialog: handleClickAjuda }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAbrirDialog = (dica) => {
    dica.abrirDialog();
    setAnchorEl(null);
  };


  return (
    <>
      <Tooltip title="Dicas">
        <IconButton
          className='iconButton'
          onClick={handleClick}      
          aria-controls="help-menu" //abre submenu c/ esta propriedade
          aria-haspopup="true" //indica que este elemento controle o submenu 
        >
        <HelpIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="help-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        //...
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem>
          <span style={{color: "dimgray"}}>Dicas de uso</span>
        </MenuItem>
        {dicas.map(dica => {return(
          <>
            <Divider light />
            <StyledMenuItem onClick={()=>{
              return handleAbrirDialog(dica)
            }}>
              <ListItemText primary={dica.texto}/>          
            </StyledMenuItem>          
          </>
        )})}
      </Menu>
    </>
  );
}

export default HelpMenu;
