import axios from 'axios';

import {attachTokenToHeaders, mapApiData} from '../actionFunctions';

import {
  LOGIN_LOADING,
  LOGIN_SUCCESS, 
  LOGIN_FAIL, 
  LOGOUT_SUCCESS,
  LOGOUT_LOADING,
  ME_LOADING,
  ME_SUCCESS,
  ME_FAIL,
  RESETAR_REDUX_STORE
} from '../actionTypes';


const apiDataMapping = {
  "id": "id",
  "nome": "nome",
  "endereco_email": "email",
  "grupo_id": "grupoId",
  "grupo": "grupo",
  "data_primeiro_acesso": "dataPrimeiroAcesso",
  "numero_downloads": "numeroDownloads",
  "insere": "insere",
  "master": "master",
  "ativo": "ativo",
  "avatar": "avatar",
};  


const resetarReduxStore = () => async (dispatch, getState) => {
  dispatch({ type: RESETAR_REDUX_STORE });
}

const loadMe = () => async (dispatch, getState) => {
  dispatch({ type: ME_LOADING });

  try {
    const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
    const response = await axios.get('/auth/me', options);
    const mappedData = mapApiData(response.data, apiDataMapping)

    dispatch({
      type: ME_SUCCESS,
      payload: { me: mappedData },
    });
  } catch (err) {
    dispatch({
      type: ME_FAIL,
      payload: { error: err},
    });
  }
};


const logInUser = (token) => async (dispatch, getState) => {
  dispatch({ type: LOGIN_LOADING });

  try {
    const headers = {
      'Accept': 'application/json',
      'Authorization': `bearer ${token}`,
    };

    const response = await axios.get('/auth/me', { headers });
    const mappedData = mapApiData(response.data, apiDataMapping)

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { me: mappedData, token },
    });
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload: { error: err },
    });
  }
};

// Log user out
const logOutUser = () => async (dispatch) => {
  dispatch({
    type: LOGOUT_LOADING
  });
  try {
    //deleta todos cookies
    deleteAllCookies();
    //Faz logout do usuário no lado servidor
    await axios.get('/auth/logout');
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    //Faz logout na conta do Auth Provider
    let logOutUrl
    switch (process.env.REACT_APP_AUTH_PROVIDER) {
      case "Google":
        logOutUrl = process.env.REACT_APP_GOOGLE_LOGOUT_URL;
        break;
      case "Microsoft":
        logOutUrl = process.env.REACT_APP_MICROSOFT_LOGOUT_URL;   
        break;
      default:
        throw new Error(`ERRO: não foi possível fazer logout da conta do(a)` + 
          ` '${process.env.REACT_APP_AUTH_PROVIDER}'`);
    }
    window.location.href= logOutUrl + process.env.REACT_APP_CLIENT_URL;
  } catch (err) {
    alert(err.message);
    window.location.href = process.env.REACT_APP_CLIENT_URL;
  }
};


function deleteAllCookies() {
  var cookies = document.cookie.split(';');

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

const forceLoginError = () => async (dispatch, getState) => {

  const loginError = getState().auth.loginError;

  if(!loginError){
    dispatch({
      type: LOGIN_FAIL,
      payload: { error: true },
    });
  }
}

export {
  loadMe,
  logInUser,
  logOutUser,
  forceLoginError,
  resetarReduxStore
}
