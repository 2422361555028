import React from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { withStyles } from '@material-ui/core/styles';


const styles = (theme) => ({
  root: {
    padding: "1rem 1.5rem"
  }
})

const LunarDialogActions = (props)=>{
  const {classes, onCancel, submitButtonLabel = "Salvar", ...other} = props;
  return(
    <DialogActions className={classes.root} {...other}>
      <Button onClick={onCancel} variant="contained">
        Cancelar
      </Button>
      <Button type="submit" color="primary" variant="contained">
        {submitButtonLabel}
      </Button>
    </DialogActions>
  )
};

export default withStyles(styles)(LunarDialogActions);