import axios from 'axios';

//Esta função cria um header a partir do getState
const attachTokenToHeaders = (getState, headers = {}) => {    
    const options = {
      headers: headers
    };
    const token = getState().auth.token;
    if (token) {
      options.headers['Authorization'] = `bearer ${token}`;
    }  
    return options;
};


//Esta função altera as keys da Api de acordo com o objeto mapping
  //A Api retorna seus nomes (object keys) em snake_case, é possível utilizar essa função para converter os nomes para camelCase
const mapApiData = (data, mapping) => {
    
  let mapped = Object.keys(data).reduce((acc, key) => {

    if(mapping[key]){
      acc[mapping[key]] = data[key];
    } 
    
    return acc;

  }, {});

  return mapped;

}


//Esta função é semelhante a mapApiData para utilizar quando a response é uma array de objetos, em vez de um objeto
const mapApiDataArray = (dataArray, mapping) => {

  let mapped = [];

  for (var i = 0; i < dataArray.length; i++) {
    mapped[i] = mapApiData(dataArray[i], mapping)
  }

  return mapped;

}

async function handleErrorForAllActions(err, getState) {
  switch (err?.response?.status) {
    case 401: 
        const tokenExpirou = (await verificarSeTokenExpirou(getState) === true);
        if(tokenExpirou){
            atualizarPaginaParaForcarNovoLogin();
        }
      break;
    default:
      break;
  }
  return false;
}


async function verificarSeTokenExpirou(getState){
  try {
    const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
    await axios.get('/auth/me', options);
  } catch (err) {
    if (err?.response?.status === 401){
      return true
    } 
  }
  return false
}


function atualizarPaginaParaForcarNovoLogin(){
  var forcouNovoLogin = sessionStorage.getItem('forcouNovoLogin') || '';
  if (forcouNovoLogin !== 'YES') { 
      sessionStorage.setItem('forcouNovoLogin','YES');
      alert("Sua sessão expirou. Favor fazer login novamente.");    
      window.location.reload(); 
        /*Recarregar a página após a expiração do token irá forçar um novo login, 
        (além de preservar a URL original e retornar o usuário para ela após o processo de login)*/ 
  }
}


function encontrarMensagemDeErro(err){
  const mensagemDeErro = 
    err?.response?.data?.message 
    || err?.response?.data?.sqlMessage 
    || err?.message 
    || err?.sqlMessage 
    || "ERRO: Erro não identificado"
  return mensagemDeErro;
}



export {
  attachTokenToHeaders,
  encontrarMensagemDeErro,
  handleErrorForAllActions,
  mapApiData,
  mapApiDataArray
}