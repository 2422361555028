import axios from 'axios';

import {attachTokenToHeaders, handleErrorForAllActions, 
  encontrarMensagemDeErro} from '../actionFunctions';
import {urls} from '../dataAdmin/subParametrosData'
import {
  BUSCAR_SUB_PARAMETROS_LOADING,
  BUSCAR_SUB_PARAMETROS_SUCCESS,
  BUSCAR_SUB_PARAMETROS_FAIL,
  CRIAR_SUB_PARAMETRO_LOADING,
  CRIAR_SUB_PARAMETRO_SUCCESS,
  CRIAR_SUB_PARAMETRO_FAIL,
  REMOVER_SUB_PARAMETRO_LOADING,
  REMOVER_SUB_PARAMETRO_SUCCESS,
  REMOVER_SUB_PARAMETRO_FAIL,  
} from '../actionTypesAdmin';


const buscarSubParametros = ({tipo}) => async (dispatch, getState) => {  
  dispatch({ 
    type: BUSCAR_SUB_PARAMETROS_LOADING,
    payload: {tipo},
  });
  try {
    const url = urls[tipo];
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(url, options); 
    const subParametros = (response.data).sort();
    dispatch({
      type: BUSCAR_SUB_PARAMETROS_SUCCESS,
      payload: {subParametros},
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    const error = {
      title:  `Os Sub-Parâmetros não foram buscados porque ocorreu um Erro`,
      message: encontrarMensagemDeErro(err)     
    }    
    alert(`${error?.title} \n\n${error?.message}` + 
      ` \n\nNenhuma modificação foi feita.`);
    dispatch({
      type: BUSCAR_SUB_PARAMETROS_FAIL,
      payload: {error},
    });
  }
}


const criarSubParametro = ({tipo, subParametro}) => async (dispatch, getState) => {
  dispatch({ 
    type: CRIAR_SUB_PARAMETRO_LOADING
  });
  try {
    const url = `${urls[tipo]}/${subParametro}`;    
    const options = attachTokenToHeaders(getState);
    await axios.post(url, null, options); 
    dispatch({
      type: CRIAR_SUB_PARAMETRO_SUCCESS,
      payload: {subParametro},
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    const error = {
      title:  `O Sub-Parâmetro não foi criado porque ocorreu um Erro`,
      message: encontrarMensagemDeErro(err)     
    }
    alert(`${error?.title} \n\n${error?.message}` + 
      ` \n\nNenhuma modificação foi feita.`);
    dispatch({
      type: CRIAR_SUB_PARAMETRO_FAIL,
      payload: {error, subParametro},
    });
  }
}


const removerSubParametro = ({tipo, subParametro}) => async (
  dispatch,  getState
) => {
  dispatch({ 
    type: REMOVER_SUB_PARAMETRO_LOADING,
    payload: { subParametro },
  });
  try {
    const url = `${urls[tipo]}/${subParametro}`;    
    const options = attachTokenToHeaders(getState);
    await axios.delete(url, options); 
    dispatch({
      type: REMOVER_SUB_PARAMETRO_SUCCESS,
      payload: { subParametro },
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    const error = {
      title:  `O Sub-Parâmetro não foi removido porque ocorreu um Erro ` + 
      `(Sub-Parâmetro: ${subParametro})`,
      message: encontrarMensagemDeErro(err)     
    }  
    alert(`${error?.title} \n\n${error?.message}` + 
      ` \n\nNenhuma modificação foi feita.`);
    dispatch({
      type: REMOVER_SUB_PARAMETRO_FAIL,
      payload: { error, subParametro },
    });
  }
}



export {  
  buscarSubParametros,
  criarSubParametro,
  removerSubParametro
}