import {
    CRIAR_REGRAS_DE_NOTIFICACAO_LOADING, 
    CRIAR_REGRAS_DE_NOTIFICACAO_SUCCESS, 
    CRIAR_REGRAS_DE_NOTIFICACAO_FAIL,
    REMOVER_REGRAS_DE_NOTIFICACAO_LOADING, 
    REMOVER_REGRAS_DE_NOTIFICACAO_SUCCESS, 
    REMOVER_REGRAS_DE_NOTIFICACAO_FAIL,
} from '../actionTypes';


const initialState = {
    criacaoIsLoading: false,
    criacaoError: null,
    remocaoIsLoading: false,
    remocaoError: null
};


const regraDeNotificacao = (state = initialState, { type, payload }) => {
    switch (type) {     
        //Criar   
        case CRIAR_REGRAS_DE_NOTIFICACAO_LOADING:            
            return {
                ...state,
                criacaoIsLoading: true,
                criacaoError: null,
            }                        
        case CRIAR_REGRAS_DE_NOTIFICACAO_SUCCESS:
            return {
                ...state,
                criacaoIsLoading: false,
            }                   
        case CRIAR_REGRAS_DE_NOTIFICACAO_FAIL:
            return {
                ...state,
                criacaoIsLoading: false,
                criacaoError: payload.error                        
            }           
        //Remover
        case REMOVER_REGRAS_DE_NOTIFICACAO_LOADING:            
            return {
                ...state,
                remocaoIsLoading: true,
                remocaoError: null,
            }                        
        case REMOVER_REGRAS_DE_NOTIFICACAO_SUCCESS:
            return {
                ...state,
                remocaoIsLoading: false,
            }                   
        case REMOVER_REGRAS_DE_NOTIFICACAO_FAIL:
            return {
                ...state,
                remocaoIsLoading: false,
                remocaoError: payload.error                        
            }     
        //default
        default:
            return state;
    }
}

export default regraDeNotificacao