import React, { useState, PureComponent  } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import throttle from "lodash.throttle"

import Loader from '../../../components/Loader/Loader'



pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`; //It is crucial for performance to use PDF.js worker whenever possible. This ensures that PDF files will be rendered in a separate thread without affecting page performance. #https://www.npmjs.com/package/react-pdf#standard-browserify-and-others 

export default class wrapperThatSetPdfWidth extends PureComponent  {
  //A função deste Wrapper é apenas fazer com o pdf fique com 100% de width (e nada mais)
    //Ou seja, ocupando o width completo do seu elemento pai
    //Referência: https://github.com/wojtekmaj/react-pdf/issues/129#issuecomment-359136222

  constructor(props) {
    super(props)
    this.state = {width: null}
    this.throttledSetDivSize = throttle(this.setDivSize, 500) //cria um evento para ficar constantemente "ouvindo" um "resize" no elemento wrapper
  }

  componentDidMount () {
    this.setDivSize() //atualiza o state com o width do elemento wrapper    
    window.addEventListener("resize", this.throttledSetDivSize) // event listener when window is resized
  }

  componentWillUnmount () {
    window.removeEventListener("resize", this.throttledSetDivSize) //remove o evento quando este componente é desmontado
  }

  setDivSize = () => {
    this.setState({width: this.pdfWrapper.getBoundingClientRect().width}) //atualiza o state com o width do elemento wrapper
  }


  render() {
    return (
        <div 
          id="pdfWrapper" 
          ref={(ref) => this.pdfWrapper = ref}
          style={{maxWidth: "1280px",  margin: "0 auto", display: "flex", justifyContent: "center", overflow: "hidden"}} 
        > 
          <ReactPdf wrapperDivSize={this.state.width} arquivoEmPdf={this.props.arquivoEmPdf} /> {/* //Passa 100% do width do elemento wrapper para o ReactPdf (obs: é justamente esta "passagem" que permite que o ReactPdf fique com 100% do width elemento pai) */}
        </div>
        /*Observações sobre o style no div acima:*/
            /* O display/flex e justifyContent/center acima faz com o que o pdf filho deste componente fique centralizado */
            /* Sobre o Ref acima: Refs fornecem uma forma de acessar os nós do DOM ou elementos React criados no método render. */
            /* O overflow hidden acima impede que contéudo do pdf sobre na lateral direita quando ele não é renderizado com exatamente o widht desejado */
    )
  }
}



function ReactPdf({wrapperDivSize, arquivoEmPdf}) {
  //TODO: Analisar a possibilidade de limitar um número máximo de páginas que podem ser exibidas no navegador caso exista problema de performance em documentos com muitas páginas!
      //-(obs: precisa testar também documentos com muitas páginas e analisar a questão da performance!)

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess(DocumentInfo) {
    setNumPages(DocumentInfo.numPages);
  }

  return (
    <div>
      <Document
        file={arquivoEmPdf}
        onLoadSuccess={onDocumentLoadSuccess}
        error="Falha ao carregar arquivo em PDF do Documento."
        loading={<Loader mensagem={"Carregando Documento…"} />}
        noData="Nenhum arquivo PDF especificado."
      >
          
            {/* Obs: O Array abaixo permite que sejam exibidas todas as páginas do documento */}
            {
              Array.from( 
                new Array(numPages),
                (el, index) => (
                  <>
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={wrapperDivSize} 
                      error="Falha ao carregar a página."
                      loading="Carregando página..."
                      noData="Nenhuma página especificada."
                    />
                  </>
                ),
              )
            }
      </Document>
      
    </div>
  );
}

 
