import {
    BUSCAR_LOGS_DE_ATIVIDADE_LOADING, BUSCAR_LOGS_DE_ATIVIDADE_SUCCESS, BUSCAR_LOGS_DE_ATIVIDADE_FAIL,
} from '../actionTypesAdmin';


const initialState = {
    logsDeAtividade: [],
    isLoading: false,
    error: null,
};


const logsDeAtividade = (state = initialState, { type, payload }) => {
    switch (type) {
        case BUSCAR_LOGS_DE_ATIVIDADE_LOADING:            
            return {
                ...state,
                isLoading: true,
                error: null,
            }                        
        case BUSCAR_LOGS_DE_ATIVIDADE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                logsDeAtividade: payload.logsDeAtividade
            }                   
        case BUSCAR_LOGS_DE_ATIVIDADE_FAIL:
            return {
                ...state,
                isLoading: false,
                logsDeAtividade: [], 
                error: payload.error                        
            }          
        default:
            return state;
    }
}

export default logsDeAtividade