import {useState} from 'react';
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import './CustomToolbarSelect.css'

import HelpMenu from '../../../../../../components/HelpMenu/HelpMenu';

//ícones
import TextRotateVerticalIcon  from '@material-ui/icons/TextRotateVertical'; 
import PostAddIcon from '@material-ui/icons/PostAdd';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

//Gifs de exemplo
import selecionarPorPesquisaGif from '../../../../../../support/images/exemplos/selecionarPorPesquisa.gif';
import preencherNaoSequencialmenteGif from '../../../../../../support/images/exemplos/preencherNaoSequencialmente.gif';

//Textos de ajuda
const textoSobreSelecionarPorPesquisa = "Você pode selecionar todos os resultados de uma pesquisa por texto. Clique para visualizar um exemplo";
const textoSobrePreencherNaoSequencialmente = "Você pode utilizar a ferramente \"Preencher abaixo\" para uma seleção não sequencial. Clique para visualizar um exemplo";

const CustomToolbarSelect = ({
    handleClickDeletarValores,
    handleClickRepetirValores,
    handleClickSugerirValores,
}) => {

    const [selecionarPorPesquisaDialog, setSelecionarPorPesquisaDialog] = useState(false);
    const [preencherNaoSequencialmenteDialog, setPreencherNaoSequencialmenteDialog] = useState(false);

    const handleSelecionarPorPesquisaDialogClose = () => {
        return setSelecionarPorPesquisaDialog(false);
    };
    const handleClickAjudaSelecionarPorPesquisa = () => {
        setSelecionarPorPesquisaDialog(!selecionarPorPesquisaDialog);
    };

    const handlePreencherNaoSequencialmenteDialogClose = () => {
        return setPreencherNaoSequencialmenteDialog(false);
    };
    const handleClickAjudaPreencherNaoSequencialmente = () => {
        setPreencherNaoSequencialmenteDialog(!preencherNaoSequencialmenteDialog);
    };

    const handleClickDeletarValoresComConfirm = (tipo) => {
        if (window.confirm('Você tem certeza que deseja deletar estes valores? (esta ação é irreversível)')) {
            if(tipo==='TODOS'){
                handleClickDeletarValores('DESCRITIVOS');
                handleClickDeletarValores('ENTIDADES');
                handleClickDeletarValores('REFERENCIAS');            
            } else {
                handleClickDeletarValores(tipo);
            }
        } else {
            alert('Nenhuma modificação foi feita.');
        }
    }


    const handleClickRepetirValoresComAlert = (tipo) => {
        let ocorreuAlteracao = false;
        
        if(tipo==='TODOS'){
            if(handleClickRepetirValores('DESCRITIVOS') === true){ocorreuAlteracao = true};
            if(handleClickRepetirValores('ENTIDADES') === true){ocorreuAlteracao = true};
            if(handleClickRepetirValores('REFERENCIAS') === true){ocorreuAlteracao = true};           
        } else {
            if(handleClickRepetirValores(tipo) === true ){ocorreuAlteracao = true};
        }

        if(!ocorreuAlteracao){alert('Nenhuma modificação foi feita.')}
    }


    return (       
        <React.Fragment>
            <Dialog maxWidth={'lg'} onClose={handleSelecionarPorPesquisaDialogClose} open={selecionarPorPesquisaDialog}>
                <img src={selecionarPorPesquisaGif} alt="Gif com instruções de ajuda"/>
            </Dialog>
            <Dialog maxWidth={'lg'} onClose={handlePreencherNaoSequencialmenteDialogClose} open={preencherNaoSequencialmenteDialog}>
                <img src={preencherNaoSequencialmenteGif} alt="Gif com instruções de ajuda"/>
            </Dialog>
        <div id={"CustomToolbarSelectClassificacao"}>               
            <div className="iconsContainer">  
                <div className="label">
                    Relatórios
                </div>                
                <Tooltip title={"Preencher com sugestões (preencher relatórios com sugestão baseada no nome do arquivo, nas linhas selecionadas)"}>
                    <IconButton onClick={() => handleClickSugerirValores('DESCRITIVOS')}  className='iconButton'> 
                        <PostAddIcon fontSize='small'/>
                    </IconButton>
                </Tooltip>                            
                <Tooltip title={"Preencher abaixo (repetir relatórios para baixo nas linhas selecionadas)"}>
                    <IconButton onClick={() => handleClickRepetirValoresComAlert('DESCRITIVOS') }  className='iconButton'> 
                        <TextRotateVerticalIcon fontSize='small'/>
                    </IconButton>
                </Tooltip>            
                <Tooltip title={"Deletar em lote (deletar relatórios nas linhas selecionadas)"}>
                    <IconButton onClick={() => handleClickDeletarValoresComConfirm('DESCRITIVOS') }  className='iconButton'> 
                        <DeleteOutlineIcon fontSize='small'/>
                    </IconButton>
                </Tooltip>
            </div>
            <div className="iconsContainer"> 
                <div className="label">
                    Entidades
                </div>
                <Tooltip title={"Preencher com sugestões (preencher entidades com sugestão baseada no nome do arquivo, nas linhas selecionadas)"}>
                    <IconButton onClick={() => handleClickSugerirValores('ENTIDADES')}  className='iconButton'> 
                        <PostAddIcon fontSize='small'/>
                    </IconButton>
                </Tooltip>                         
                <Tooltip title={"Preencher abaixo (repetir entidades para baixo nas linhas selecionadas)"}>                        
                    <IconButton onClick={() => handleClickRepetirValoresComAlert('ENTIDADES') }  className='iconButton'> 
                        <TextRotateVerticalIcon fontSize='small'/>
                    </IconButton>
                </Tooltip>            
                <Tooltip title={"Deletar em lote (deletar entidades nas linhas selecionadas)"}>
                <IconButton onClick={() => handleClickDeletarValoresComConfirm('ENTIDADES') }  className='iconButton'> 
                        <DeleteOutlineIcon fontSize='small'/>
                    </IconButton>
                </Tooltip>
            </div>            
            <div className="iconsContainer">
                <div className="label">
                    Datas
                </div>
                <div>       
                    <Tooltip title={"Preencher com sugestões (preencher datas com sugestão baseada no nome do arquivo, nas linhas selecionadas)"}>
                        <IconButton onClick={() => handleClickSugerirValores('REFERENCIAS')}  className='iconButton'> 
                            <PostAddIcon fontSize='small'/>
                        </IconButton>
                    </Tooltip>                      
                    <Tooltip title={"Preencher abaixo (repetir datas para baixo nas linhas selecionadas)"}>
                        <IconButton onClick={() => handleClickRepetirValoresComAlert('REFERENCIAS') }  className='iconButton'> 
                            <TextRotateVerticalIcon fontSize='small'/>
                        </IconButton>
                    </Tooltip>            
                    <Tooltip title={"Deletar em lote (deletar datas nas linhas selecionadas)"}>
                    <IconButton onClick={() => handleClickDeletarValoresComConfirm('REFERENCIAS') }  className='iconButton'> 
                            <DeleteOutlineIcon fontSize='small'/>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>  
            <div className="iconsContainer">
                <div className="label">
                    Todos Campos
                </div>
                <div>       
                    <Tooltip title={"Preencher com sugestões (preencher valores com sugestão baseada no nome do arquivo, nas linhas selecionadas)"}>
                        <IconButton onClick={() => handleClickSugerirValores('TODOS')}  className='iconButton'> 
                            <PostAddIcon fontSize='small'/>
                        </IconButton>
                    </Tooltip>                       
                    <Tooltip title={"Preencher abaixo (repetir valores para baixo nas linhas selecionadas)"}>
                        <IconButton onClick={() => {handleClickRepetirValoresComAlert('TODOS')}}  className='iconButton'> 
                            <TextRotateVerticalIcon fontSize='small'/>
                        </IconButton>
                    </Tooltip>            
                
                    <Tooltip title={"Deletar em lote (deletar valores nas linhas selecionadas)"}>
                        <IconButton onClick={() => {handleClickDeletarValoresComConfirm('TODOS')}}  className='iconButton'> 
                            <DeleteOutlineIcon fontSize='small'/>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>                        
            <div className="iconsContainer">
                <div className="label">
                    Ajuda
                </div>
                <div>       
                    <HelpMenu
                        dicas={
                            [
                                {
                                    texto: textoSobreSelecionarPorPesquisa,
                                    abrirDialog: handleClickAjudaSelecionarPorPesquisa
                                },
                                {
                                    texto: textoSobrePreencherNaoSequencialmente,
                                    abrirDialog: handleClickAjudaPreencherNaoSequencialmente
                                }
                            ]
                        }
                    >
                    </HelpMenu>
                </div>
            </div>                        
        </div>
    </React.Fragment>
    );
}


export default  (CustomToolbarSelect)
