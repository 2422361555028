import axios from 'axios';
import {attachTokenToHeaders, handleErrorForAllActions, 
  encontrarMensagemDeErro} from '../actionFunctions';

import {urls} from '../dataAdmin/parametrosData'

import {apoioBuscarParametro} from './parametroActions'

import {
  CRIAR_PARAMETRO_LOADING,
  CRIAR_PARAMETRO_SUCCESS,
  CRIAR_PARAMETRO_FAIL,
  REMOVER_PARAMETRO_LOADING,
  REMOVER_PARAMETRO_SUCCESS,
  REMOVER_PARAMETRO_FAIL
} from '../actionTypesAdmin';


const concederOuRetirarAdministrador = ({id, concederAdm}) => async (
  dispatch, getState
) => {
  dispatch({ 
    type: concederAdm ? CRIAR_PARAMETRO_LOADING : REMOVER_PARAMETRO_LOADING,
    payload: {id}
  });
  try {
    const tipo = "USUARIOS";
    const dadosDoUsuarioComOuSemAdm = await 
      apoio.obterDadosDoUsuarioComOuSemAdm({getState, tipo, id, concederAdm});
    const url = `${ urls[tipo] }/${ id }`;
    const options = attachTokenToHeaders(getState);
    //Edita o usuário para conceder ou retirar o Administrador
    await axios.patch(url, dadosDoUsuarioComOuSemAdm, options);
    //Consulta o novo usuário (após a edição)
    const novoUsuario = await apoio.buscarParametro({getState, tipo, id});
    //Faz o dispatch
    dispatch({
      type: concederAdm ? CRIAR_PARAMETRO_SUCCESS : REMOVER_PARAMETRO_SUCCESS,
      payload: {id, parametro: novoUsuario}
    })
  } catch (err) {
    handleErrorForAllActions(err, getState);
    const error = {
      title: `O Usuário não foi alterado porque ocorreu um Erro`,
      message: encontrarMensagemDeErro(err),
    }
    alert(`${error?.title} \n\nNenhuma modificação foi feita.`);
    dispatch({
      type: concederAdm ? CRIAR_PARAMETRO_FAIL : REMOVER_PARAMETRO_FAIL,
      payload: {error, id}
    })
  }
}


const apoio = {
  buscarParametro: apoioBuscarParametro,
  obterDadosDoUsuarioComOuSemAdm: async function({
    getState, tipo, id, concederAdm
  }){
    const usuario = await apoio.buscarParametro({getState, tipo, id})
    const dados = {
      "nomeCompleto": usuario.nome,
      "email": usuario.endereco_email,
      "grupoId": usuario.grupo_id,
      "insere": usuario.insere === 1 ? "true" : "false",
      "ativo": usuario.ativo === 1 ? "true" : "false",
      "master": concederAdm === true ? "true" : "false",
    }
    return dados
  }
}


export {  
  concederOuRetirarAdministrador,
}