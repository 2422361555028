import React from 'react';
import './LogsDeAtividade.css'
import Layout from '../../layout/Layout'
import {useEffect} from 'react';
import {useHistory} from "react-router-dom";

import Grid from '@material-ui/core/Grid';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MUIDataTable from "mui-datatables";

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import {handleClickRetornar} from '../../support/publicFunctions'

import {buscarLogsDeAtividade} from '../../redux/actionsAdmin/logActions'

import {connect} from "react-redux";

import Loader from '../../components/Loader/Loader'

import {format} from 'date-fns'

import textLabels from "../../support/MuiDatatableSupport/TextLabels"
import onDownload from "../../support/MuiDatatableSupport/onDownload"
import {ExcelDownloadIcon} from '../../components/Icons/Icons'

import { useLocation } from "react-router-dom";

import QueryStringUpdateDialog from 
  '../../components/QueryStringUpdateDialog/QueryStringUpdateDialog';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';

const LogsDeAtividade = ({
    buscarLogsDeAtividade, logsDeAtividade, logsDeAtividadeIsLoading
}) => { 

    let history = useHistory();    

    //Otber query strings
    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();   
    const limite = query.get("limite");
    const dataInicio = query.get("dataInicio");
    const dataFim = query.get("dataFim");
  

    useEffect(() => {
        buscarLogsDeAtividade(limite, dataInicio, dataFim);
    // eslint-disable-next-line react-hooks/exhaustive-deps            
    }, []);
    
    const colunasDaTabela = [
        {label: 'Usuário', name: 'usuario', options: {display: true, filter: true}},
        {label: 'Id do Usuário', name: 'usuarioId', options: {display: false, filter: true}},
        {label: 'Ação', name: 'acao', options: {display: true, filter: true}},
        {label: 'Tipo de Objeto', name: 'tipoDeObjeto', options: {display: true, filter: true}},
        {label: 'Objeto', name: 'objeto', options: {display: true, filter: true}},
        {label: 'Id do Objeto', name: 'objetoId', options: {display: false, filter: true}},
        {label: 'Data', name: 'data', options: {display: true, filter: true}},
        {label: 'Hora', name: 'hora', options: {display: false, filter: true}},
    ]    

    const dadosDaTabela = function(logsDeAtividade){return(
        logsDeAtividade.map((log)=>{return[
            log.usuario,
            log.usuarioId,
            log.acao,
            log.tipoDeObjeto,
            log.objeto,
            log.objetoId,
            format(new Date(log.data), 'yyyy-MM-dd'),
            format(new Date(log.data), 'HH:mm'),
        ]})        
    )}

    const opcoesDaTabela = {
        textLabels: textLabels,
        onDownload: onDownload,
        elevation: 2,
        filterType: "multiselect",
        responsive: "standard",  
        selectableRows: 'none'
    }        


    const components = {
        icons: {
          DownloadIcon: ExcelDownloadIcon,
        }
    };    


    //Parâmetros do dialog
    const [dialogOpen, dialogSetOpen] = React.useState(false);
    const dialogTitle = "Configurar limite de dados carregados"
    const dialogInputs = [
        {key: "limite", label: "Limite de Ocorrências", type: "number"},
        {key: "dataInicio", label: "Data Inicial", type: "date"},
        {key: "dataFim", label: "Data Final", type: "date"}
    ]


    return (
        <>{(
            logsDeAtividadeIsLoading
        ) ? (
            <Layout pageTitle="Carregando...">
                <Loader mensagem={""}/>
            </Layout>
        ) : (        
            <Layout pageTitle="Logs de Atividade">
                <div id="logsDeAtividade">
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12} >
                            <div className="linhaHorizontal publico"/>
                        </Grid>
                        <Grid 
                            className="barraDeFerramentas publico" 
                            item xs={12} md={12}
                        >
                            <Tooltip title='Retornar'>
                                <IconButton onClick={
                                    ()=>{handleClickRetornar(history)}
                                }>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Configurar Limitação de Dados'>
                                <IconButton onClick={() => (dialogSetOpen(true))}>
                                    <PermDataSettingIcon />
                                </IconButton>
                            </Tooltip>
                            <QueryStringUpdateDialog
                                open={dialogOpen}
                                setOpen={dialogSetOpen}
                                title={dialogTitle}
                                inputs={dialogInputs}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} >                           
                            <MUIDataTable
                                title={'Atividades'}
                                data={dadosDaTabela(logsDeAtividade)}
                                columns={colunasDaTabela}
                                options={opcoesDaTabela}
                                components={components}
                            />
                        </Grid>                        
                    </Grid>                    
                </div>
            </Layout>
        )}</>            
    ) 
}

const mapStateToProps = (state) => ({
    //logsDeAtividade
    logsDeAtividade: state.adminLogsDeAtividade.logsDeAtividade,
    logsDeAtividadeIsLoading: state.adminLogsDeAtividade.isLoading
});

export default connect(mapStateToProps, {buscarLogsDeAtividade})(LogsDeAtividade)