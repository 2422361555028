import React from 'react';
import {Bar} from 'react-chartjs-2';
import Layout from '../../layout/Layout'

import {useEffect} from 'react';
import {buscarLogsDeDownload} from '../../redux/actionsAdmin/logActions'
import {connect} from "react-redux";
import {format} from 'date-fns'


const VerticalBar = ({data}) => {
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return(
    <Bar data={data} options={options} />
  )
};


const PaginaTeste = ({buscarLogsDeDownload, logsDeDownload, logsDeDownloadIsLoading}) => {

  function getDias() {
    return (logsDeDownload.map(log => new Date(log.dataDownload).setHours(0,0,0,0))).sort();
  }

  function getDistribuicaoDiariaDeDownloads(){
    var dias = getDias();
    var distribuicao = {};
    for (var i = 0; i < dias.length; i++) {
      var dia = dias[i];
      distribuicao[dia] = distribuicao[dia] ? distribuicao[dia] + 1 : 1;
    }
    if(dias.length > 1 ){
      /* Excluir o dia mais antigo porque pode conter dados incompletos*/
      const diaMaisAntigo = dias[0]
      delete distribuicao[diaMaisAntigo];
    } 
    console.log("distribuicao", distribuicao);
    return distribuicao;
  }

  function getDiasUnicos(){
    return Object.keys(getDistribuicaoDiariaDeDownloads());
  }

  function getDiasUnicosFormatados(){
    return getDiasUnicos().map((dia)=>format(new Date(parseInt(dia)), 'dd/MMM/yy'));
  }

  function getChartLabels(){
    return getDiasUnicosFormatados();
  }

  function getDatasetsData(){
    return Object.values(getDistribuicaoDiariaDeDownloads());
  }

 
  const data = {
    labels: getChartLabels(),
    datasets: [
      {
        label: 'Downloads',
        data: getDatasetsData(),
        backgroundColor: [
          '#00b3f0'
        ],
        borderColor: [
          '#0083bd'
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
      buscarLogsDeDownload(); 
  }, []);

  return (<>
    {logsDeDownloadIsLoading ? (
      <Layout pageTitle={"Carregando"}> 
      </Layout>
    ) : (
      <Layout pageTitle={"Últimos Downloads"}> 
        <div style={{width: '50%'}}>
          <VerticalBar data={data}/>
        </div>
      </Layout>
    )} 
  </>) 
}


const mapStateToProps = (state) => ({
  //logsDeDownload
  logsDeDownload: state.adminLogsDeDownload.logsDeDownload,
  logsDeDownloadIsLoading: state.adminLogsDeDownload.isLoading
});

export default connect(mapStateToProps, {buscarLogsDeDownload})(PaginaTeste)

const logsDeDownloadExample = [
  {
    dataDownload: '2021-06-17T14:41:26.000Z',
    usuarioQueBaixaId: 14,
    usuarioQueBaixa: 'Diogo Massaro',
    grupoDoUsuarioQueBaixaId: 21,
    grupoDoUsuarioQueBaixa: 'Fundos',
    usuarioQueBaixaEstaAtivo: 1,
    arquivoId: 11873,
    arquivoUuid: 'e984e1a4cec711eba3541287e089f81f',
    documentoId: 7233,
    documentoUuid: 'e93c5871cec711eba3541287e089f81f',
    hash: '4312007d6c428aa058851f2d8f48a35a',
    tamanhoEmBytes: 13490,
    dataRefDia: 15,
    dataRefMes: 6,
    dataRefAno: 2021,
    dataVencimento: null,
    numeroVersao: 1,
    extensao: 'xlsx',
    tipoDeDocumentoId: 1,
    tipoDeDocumento: 'Carteira Administrador',
    frequencia: 'DIARIO',
    temaId: 1,
    tema: 'Controladoria de Ativo',
    descritivoId: 1,
    descritivo: '',
    entidadeId: 8,
    entidadeNomeCompleto: 'Fundo de Investimento Imobiliário - VBI CRI',
    entidadeApelido: 'VBI CRI FII',
    usuarioQueSobeId: 14,
    usuarioQueSobe: 'Diogo Massaro',
    dataUpload: '2021-06-16T17:25:57.000Z',
    identificacaoLoteSequencial: 357,
    identificacaoLoteMd5Sum: 'fb7b9ffa5462084c5f4e7e85a093e6d7',
    nomeDoArquivo: 'VBI CRI FII - Carteira Administrador - 2021.06.15.xlsx'
  },
]  