import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
//...
import {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {criarParametro, editarParametro} from 
  '../../../../redux/actionsAdmin/parametroActions';
import {criarRegrasDeNotificacao} from 
  '../../../../redux/actionsAdmin/notificacoesActions';

import {buscarOptions} from '../../../../redux/actionsAdmin/optionsActions';
import {getOnlyNumbers} from '../../../../support/publicFunctions'
//...
import LunarDialogTitle from 
  '../../../../components/LunarDialogTitle/LunarDialogTitle'
import LunarDialogActions from 
  '../../../../components/LunarDialogActions/LunarDialogActions'
//...
import { withStyles } from "@material-ui/core/styles";
import InputMask from 'react-input-mask';
//...
import Autocomplete from '@material-ui/lab/Autocomplete';
//...
import Loader from '../../../../components/Loader/Loader'


const styles = () => ({
  root: {},
  textField: {
    marginBottom: "0.5rem"
  }
})


const DialogParametro = ({ 
  classes, criarParametro, criarRegrasDeNotificacao, editarParametro, 
  inputs, openDialogName, setOpenDialogName, titulo, 
  tipoDeParametro, setIsEdit, isEdit, 
  setDadosIniciaisDaEdicao, dadosIniciaisDaEdicao,
  buscarOptions, options, optionsIsLoading
}) => {
  
  const [dadosDoInput, setDadosDoInput] = useState(null); //TODO: refatorar esta linha

  useEffect(()=>{
    function criarDadosIniciais() {
      setDadosDoInput(criarDadosIniciaisDoInput());
    }
    criarDadosIniciais();
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [inputs, dadosIniciaisDaEdicao]);


  useEffect(()=>{
    function buscarAsOptions(){
      if (!inputs?.length) {return false}
      const tipos = (inputs?.filter(i => i.type === "select"))?.map(
        i => i.optionsType);
      if (!tipos?.length) {return false};
      return buscarOptions({tipos});
    }
    buscarAsOptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [inputs]);

  
  //...
  function criarDadosIniciaisDoInput(){
    if(!inputs){return null};
    const dados = {};
    if(isEdit){
      inputs.map((input)=> {
        const dadoInicialDaEdicao = (dadosIniciaisDaEdicao ? 
          dadosIniciaisDaEdicao[input?.coluna] : null ); 
        const value = Array.isArray(dadoInicialDaEdicao) ? 
          dadoInicialDaEdicao.map (
            d =>  d[input.optionsMultipleColunaId] || d["id"]
          ) :
          dadoInicialDaEdicao;
        dados[input?.name] = value;
        return false;
      })
    } else {
      inputs.map((input)=> {
        const value = input?.valorInicial;
        dados[input?.name] = value;
        return false;
      })
    }
    return dados;
  }

  //Acompanhar states
  useEffect(()=>{console.log("dadosIniciaisDaEdicao", dadosIniciaisDaEdicao)},
    [dadosIniciaisDaEdicao])   //TODO: apagar esta linha 
  useEffect(()=>{console.log("dadosDoInput", dadosDoInput)},[dadosDoInput])    

  //...
  const handleCloseDialog = () => {
    setIsEdit(false); //TODO: analisar se está linha está adequada e precisa de refatoração
    setDadosDoInput(criarDadosIniciaisDoInput()); 
    setDadosIniciaisDaEdicao(null); //TODO: analisar se esta linha realmente está ok e deve existir
    setOpenDialogName(null);
  };

  const handleChangeDadosDoInput = (name, isSelect = false) => {
    return (event, newOption) => {
      let newValue
      if(isSelect){
        if(Array.isArray(newOption)){
          newValue = newOption?.map(n => n?.value)
        } else {
          newValue = newOption?.value;
        }
      } else {
        newValue = event.target.value;
      }
      setDadosDoInput({
        ...dadosDoInput,
        [name]: newValue
      })
    }
  }


  //TODO: acrescentar aqui algum processo de validação do dado, antes de criar o parâmetro?
  const handleCriarParametro = (event) => {
    event.preventDefault(); //evitar o reload da página após o submit
    const dados = ajustarDadosAntesDoInput({dadosDoInput, inputs});
    const tipo = tipoDeParametro
    if(tipo === "NOTIFICACOES"){
      criarRegrasDeNotificacao({dados});
    } else {
      criarParametro({tipo, dados}); //cria regra no servidor
    }
    return handleCloseDialog();
  };

  
  //TODO: acrescentar aqui algum processo de validação do dado, antes de editar o parâmetro?
  const handleEditarParametro = (event) => {
    event.preventDefault(); //evitar o reload da página após o submit
    const dados = ajustarDadosAntesDoInput({dadosDoInput, inputs, isEdit:true});
    const tipo = tipoDeParametro;
    const id = dadosIniciaisDaEdicao["id"];
    editarParametro({tipo, dados, id});
    return handleCloseDialog();
  };  


  function ajustarDadosAntesDoInput({dadosDoInput, inputs, isEdit = false}){
    let dados = dadosDoInput;
    for (const name in dados){
      const input = inputs.find(input => input.name === name);
      if(input.converterNullParaEmptyString) {dados[name] = dados[name] ? 
        dados[name] : ""};
      if(input.type === "cnpj") {dados[name] = getOnlyNumbers(dados[name])};
      if(input.optionsType === "BOOLEANO_0OU1") {dados[name] = 
        dados[name] === 1 ? "true" : dados[name] === 0 ? "false" : null};
    }
    if(isEdit){dados = ajustarEditNames(dadosDoInput, inputs)};
    return dados;
  }


  //TODO: analisar se a função abaixo (e todo o contexto que ela envolve) ainda 
      // requer fatoração (ou ao menos explicações como em comentários para 
      // maior clareza do que ela está fazendo)
  function ajustarEditNames(dadosDoInput, inputs){
    const dados = dadosDoInput;
    for (let name in dados){
      const input = inputs.find(input => input.name === name);
      const editName = input?.editName;
      if(editName){
        Object.defineProperty(dados, editName,
            Object.getOwnPropertyDescriptor(dados, name));
        delete dados[name];        
      }
    }
    return dados;
  }


  return (
    <Dialog
      open={openDialogName === "DialogParametro"}
      onClose={handleCloseDialog}      
      fullWidth={true}
      maxWidth={"sm"}
      disableBackdropClick
      aria-labelledby="form-dialog-title"
      className={classes.root}
    >
      {(optionsIsLoading) ? (
        <Loader />
      ) : (
        <form onSubmit={isEdit? handleEditarParametro : handleCriarParametro} >
          <LunarDialogTitle 
            id="form-dialog-title"
            onClose={handleCloseDialog} 
            // onHelp={aviso} //TODO: colocar o Help no Dialog de Parâmetros!
            helpTooltipTitle={'Funcionalidade em Desenvolvimento'}
          >
            {isEdit ? "Editar" : "Criar Novo(a)"} {titulo?.noSingular}
          </LunarDialogTitle>
          <DialogContent dividers>
            {inputs?.map((input, index)=>{
              const value = (dadosDoInput ? dadosDoInput[input?.name] : null);
              const onChange = handleChangeDadosDoInput(input?.name);
              const onChangeForSelect = handleChangeDadosDoInput(input?.name, 
                true);
              switch (input.type) {
                case 'select':
                  const myOptions = options[input.optionsType];
                  const myValue = input.optionsMultiple ?
                    value?.map(v => myOptions?.find(t => t.value === v)) || [] :
                    myOptions?.find(t => t.value === value);
                  const checkMaximumThatCanBeSelected = option => (
                    input.maximumThatCanBeSelected
                    && (myValue.length >= input.maximumThatCanBeSelected)
                    && !myValue.includes(option)
                  )
                  return(
                    <Autocomplete
                      options={myOptions}
                      getOptionLabel={(option) => option.label}
                      groupBy={(option) => option.group}
                      value = {myValue}
                      onChange={onChangeForSelect}                  
                      multiple={input.optionsMultiple}
                      disableCloseOnSelect={input.optionsMultiple? true: false}
                      disabled={(isEdit && input?.disabledNaEdicao) 
                        || (!isEdit && input?.disabledNaCriacao)}
                      getOptionDisabled={checkMaximumThatCanBeSelected}
                      renderInput={(params) => (
                        <TextFieldCustom
                          {...params} 
                          className={classes.textField}
                          input={input} 
                          index={index} 
                          isEdit={isEdit}
                          value = {input.optionsMultiple ? myValue : null}
                        />                      
                      )}
                    />
                  )              
                case 'cnpj':
                  const mask = "99.999.999/9999-99"; 
                  const pattern = "[^_]{18}";
                  return(
                    <InputMask mask={mask} value={value} onChange={onChange}>
                      {(params) => (
                        <TextFieldCustom 
                          {...params} 
                          className={classes.textField}
                          input={input} 
                          index={index} 
                          isEdit={isEdit}                          
                          inputProps={{pattern: pattern}}
                        />
                      )}
                    </InputMask>  
                  )
                default:
                  return(
                    <TextFieldCustom 
                      className={classes.textField}
                      input={input} 
                      index={index} 
                      isEdit={isEdit}
                      value={value}
                      onChange={onChange}   
                    />
                  )
              }
            })}
          </DialogContent>
          <LunarDialogActions 
            onCancel={handleCloseDialog}
            submitButtonLabel = {isEdit ? "Salvar Edição" : "Criar"}
          />
        </form>
      )}
    </Dialog>    
  );
};


const TextFieldCustom = ({input, index, isEdit, value, ...other}) => (
  <TextField
    autoFocus={index===0}
    label={input?.label}
    type={input?.type || "text"}
    required={
      input.optionsMultiple ? 
        (input?.required && value?.length === 0) : 
        input?.required
    }
    placeholder={"Digite o (a) " + input?.label} 
    disabled={(isEdit && input?.disabledNaEdicao) 
      || (!isEdit && input?.disabledNaCriacao)}
    fullWidth
    margin="dense"
    variant="outlined"
    value={value}
    {...other} 
    inputProps={{
      maxLength: input?.maxLength || null,
      minLength: input?.minLength || null,
      ...other?.inputProps
    }}
  />
)


const mapStateToProps = (state) => ({
  inputs: state.adminParametro.configuracoes?.inputs,
  options: state.adminOptions.options,
  optionsIsLoading: state.adminOptions.isLoading,
});

export default connect(mapStateToProps, {criarParametro,criarRegrasDeNotificacao, 
  editarParametro, buscarOptions})(withStyles(styles)(DialogParametro));