import {useEffect, useState} from 'react'
import Layout from '../../layout/Layout'

import {resetarReduxStore} from '../../redux/actions/authActions'
import {connect} from "react-redux";

import Stepper from './components/Stepper/Stepper'
import Step1 from './components/Step1/Step1'
import Step2 from './components/Step2/Step2'
import Step3 from './components/Step3/Step3'
import StepFinal from './components/StepFinal/StepFinal'

import './Insercao.css'

const Insercao = ({resetarReduxStore}) => { 

    // Reseta Redux Store
    useEffect(()=>{
        resetarReduxStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //states
    const [activeStep, setActiveStep] = useState(0);
    const [files, setFiles] = useState([]);
    const [documentosParaRevisar, setDocumentosParaRevisar] = useState([]);
    const [documentosParaCriar, setDocumentosParaCriar] = useState([]);
    const [documentosParaSubstituir,setDocumentosParaSubstituir] = useState([]);
    
    //acompanhar mudanças
    useEffect(()=>{
        console.log("activeStep", activeStep)
    },[activeStep])
    useEffect(()=>{
        console.log("files", files)
    },[files])
    useEffect(()=>{
        console.log("documentosParaRevisar", documentosParaRevisar)
    }, [documentosParaRevisar])
    useEffect(()=>{
        console.log("documentosParaCriar", documentosParaCriar)
    }, [documentosParaCriar])
    useEffect(()=>{
        console.log("documentosParaSubstituir", documentosParaSubstituir)
    }, [documentosParaSubstituir])


    function avancarEtapa(){
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    
    const handleReset = () => {
        window.location.href = ('/insercao') /*obs: aqui usa-se location.href em vez
            history.push justamente para que todos os states da páginas também sejam 
            resetados*/
    };

    const handleCancelar = () => {
        if(window.confirm(
            `Você tem certeza que deseja cancelar este processo? `+ 
            `(suas ações até aqui serão canceladas e esta ação é irreversível)`
        )){
            window.location.href = ('/insercao') /*obs: aqui usa-se location.href 
                em vez history.push justamente para que todos os states da páginas 
                também sejam cancelados*/
            alert('Processo cancelado com sucesso!')  
        } else {
            alert('Nenhuma modificação foi feita.')
        }    
    };


    function getSteps() {
        return [
            'Insira os Arquivos', 
            'Classifique os Arquivos', 
            'Revise e Confirme'
        ];
    }

    function getStepContent(stepIndex) {
        switch (stepIndex) {
          case 0:
            return <Step1 
                avancarEtapa={avancarEtapa}
                handleCancelar={handleCancelar}
                setFiles={setFiles}
                files={files}
            />;
          case 1:
            return <Step2 
                avancarEtapa={avancarEtapa}
                handleCancelar={handleCancelar}
                files={files} 
                setDocumentosParaRevisar={setDocumentosParaRevisar}
            />;
          case 2:
            return <Step3 
                avancarEtapa={avancarEtapa}
                handleCancelar={handleCancelar}
                documentosParaRevisar={documentosParaRevisar}
                setDocumentosParaCriar={setDocumentosParaCriar}
                setDocumentosParaSubstituir={setDocumentosParaSubstituir}
                documentosParaCriar={documentosParaCriar}
                documentosParaSubstituir={documentosParaSubstituir}
            />;
          case 'FINAL':
            return <StepFinal 
                handleReset={handleReset}
                documentosParaFiltrarECriar={documentosParaCriar}
                documentosParaFiltrarESubstituir={documentosParaSubstituir}
            />
          default:
            return 'Etapa desconhecida';
        }
    }


    return (
        <Layout pageTitle="Inserir Documentos">
            <div id="insercao">
                {/* <Aviso/> */}
                <Stepper 
                    getSteps={getSteps} 
                    getStepContent={getStepContent}
                    activeStep={activeStep} 
                />
            </div>
        </Layout>
    ) 
}

//TODO: apagar linha abaixo

// const Aviso = () => {
//     return (
//         <ClosableAlert
//             alertTitle="Funcionalidade em Fase de Testes"
//             severity="info"
//             marginBottom="2rem"
//         >
//             <p className="lunar">
//                 Favor, inserir apenas com  objetivo de testes. Os arquivos
//                  inseridos durante esta fase serão removidos diariamente.
//             </p>                               
//         </ClosableAlert>    
//     )
// }

export default connect(null, {resetarReduxStore}) (Insercao);