import React from 'react';
import Layout from '../../layout/Layout'
import {Link} from 'react-router-dom';
import {useTheme} from '@material-ui/core/styles';


import './NaoEncontrado.css'

const NaoEncontrado = () => { 
    const theme = useTheme();
    const linkStyle = {color: theme.palette.primary.dark};    
    return (
        <Layout pageTitle="Não Encontrado 404">
            <div id="naoEncontrado">
                <p className="lunar">O conteúdo não foi encontrado. O endereço pode ter sido digitado errado. Favor, tentar novamente.</p>
                <p className="lunar">O conteúdo também pode ter sido removido ou então talvez você não tenha permissão de acessá-lo.</p>
                <p className="lunar">
                    <span>Voltar para o </span> 
                    <span style={linkStyle}>
                        <Link className="textoLink" to="/inicio">Início</Link>.
                    </span>
                </p>
            </div>
        </Layout>
    ) 
}

export default NaoEncontrado