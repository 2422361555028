import {useEffect} from 'react';
import React from 'react';
import Layout from '../../layout/Layout'

import Grid from '@material-ui/core/Grid';

import {connect} from "react-redux";

import {buscarArquivosDeUmLote} from '../../redux/actions/arquivoActions'

import {obterDataPorExtenso, obterQuantidadeDeDocumentosUnicos} from '../../support/publicFunctions'

import { useLocation, useParams } from "react-router-dom";

import SubPaginaArquivos from '../../components/SubPaginaArquivos/SubPaginaArquivos' 

import Loader from '../../components/Loader/Loader'

const Notificacoes = ({
    buscarArquivosDeUmLote, arquivos, arquivosIsLoading,  usuarioNome
}) => { 

    //Query e Params
    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();    
    const numeroLoteHasheado = useParams().numeroLoteHasheado;
    const favoritos = query.get("favoritos") === "true" ? true : false;

    //Buscar Arquivos
    useEffect(()=>{
        buscarArquivosDeUmLote(numeroLoteHasheado, favoritos)
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    },[])

  

    /*-----------------------------------------------------------*/ 
    /*Funções de Apoio
    /*-----------------------------------------------------------*/   
    function getUsuarioQueCarregou(){
        return arquivos[0]?.usuarioQueCarregou
    }

    function getDataDeUpload(){
        return obterDataPorExtenso(new Date(arquivos[0]?.dataDeUpload));
    }

    function getQuantidadeDeDocumentos(){
        return obterQuantidadeDeDocumentosUnicos(arquivos);
    }


    /*-----------------------------------------------------------*/ 
    /*RENDERIZAÇÃO*/
    /*-----------------------------------------------------------*/   
    return(
        <>{(arquivosIsLoading) ? (
            <Layout pageTitle="Consultando Documentos...">
                <Loader mensagem={""}/>
            </Layout>
        ) : (<>      
            <Layout pageTitle={`${getQuantidadeDeDocumentos()}${favoritos ? " Novos" : ""} Documentos${favoritos? ' de Seu Interesse' : ` neste Lote`}`} >
                <div id="notificacoes">  
                    <Grid container spacing={1}> 
                        <Grid item xs={12} md={6}>
                            <p class="lunar subCabecalhoDescritivo">
                                <b>Inseridos em: </b>
                                <span>{getDataDeUpload()}</span>
                            </p>
                            <p class="lunar subCabecalhoDescritivo">
                                <b>Inseridos por: </b>
                                <span>{getUsuarioQueCarregou()}</span>
                            </p>     
                            {!favoritos &&
                                <p class="lunar subCabecalhoDescritivo">
                                    <b>Identificação do Lote: </b>
                                    <span>{numeroLoteHasheado}</span>
                                </p>
                            }                                    
                            <p class="lunar subCabecalhoDescritivo">
                                <b>Quantidade de Arquivos: </b>
                                <span>{arquivos.length}</span>
                            </p>
                        </Grid>                                                                                                
                    </Grid>          
                    <br />          
                    <SubPaginaArquivos 
                        arquivos={arquivos}
                        usuarioNome={usuarioNome}
                    />
                </div>
            </Layout>                   
        </>)}</>
    )
}

const mapStateToProps = (state) => ({
    //arquivos
    arquivos: state.arquivos.arquivos,
    arquivosIsLoading: state.arquivos.isLoading,
    usuarioNome: state.auth.me.nome,
});

export default connect(mapStateToProps, {buscarArquivosDeUmLote})(Notificacoes);
