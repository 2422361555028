import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

export default function onDownload(buildHead, buildBody, columns, values) {
    console.log(values)
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    // build the json, this is one way to do it
    console.log(columns)
    const json = values.reduce((result, val) => {
        const temp = {}
        val.data.forEach( (v, idx) => {
            if (Array.isArray(v)) {
                temp[columns[idx].name] = JSON.stringify(v);
            } else {
                temp[columns[idx].name] = v;
            };
        })
        result.push(temp)
        return result
    }, [])
    const fileName = `exportacao`
    const ws = utils.json_to_sheet(json)
    const wb = { Sheets: { 'dados': ws }, SheetNames: ['dados'] }
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    saveAs(data, fileName + fileExtension)
    // cancel default  CSV download from table
    return false
}
