import {combineReducers} from 'redux';

import {RESETAR_REDUX_STORE} from './actionTypes';

import adminLogsDeAtividade from "./reducers/adminLogsDeAtividade"
import adminLogsDeDownload from "./reducers/adminLogsDeDownload"
import adminParametro from "./reducers/adminParametro"
import adminSubParametro from "./reducers/adminSubParametro"
import adminOptions from "./reducers/adminOptions"
import arquivo from "./reducers/arquivo"
import arquivos from "./reducers/arquivos"
import arquivosCategorizados from "./reducers/arquivosCategorizados"
import auth from "./reducers/auth"
import criacaoDeDocumentos from "./reducers/criacaoDeDocumentos"
import descritivos from "./reducers/descritivos"
import dicionario from "./reducers/dicionario"
import documento from "./reducers/documento"
import documentosBuscadosPorAtributos from 
    "./reducers/documentosBuscadosPorAtributos"
import documentosBuscadosPorUuid from "./reducers/documentosBuscadosPorUuid"
import downloadDosArquivos from "./reducers/downloadDosArquivos"
import entidades from "./reducers/entidades"
import regraDeNotificacao from "./reducers/regraDeNotificacao"
import regrasDeNotificacao from "./reducers/regrasDeNotificacao"
import substituicaoDeDocumentos from "./reducers/substituicaoDeDocumentos"
import tiposDeDocumento from "./reducers/tiposDeDocumento"


const appReducer = combineReducers({
    //admin
    adminLogsDeAtividade: adminLogsDeAtividade,
    adminLogsDeDownload: adminLogsDeDownload,    
    adminParametro: adminParametro,
    adminSubParametro: adminSubParametro,
    adminOptions: adminOptions,
    //restante
    arquivo: arquivo,
    arquivos: arquivos,
    arquivosCategorizados: arquivosCategorizados,
    auth: auth,
    criacaoDeDocumentos: criacaoDeDocumentos,
    descritivos: descritivos,
    dicionario: dicionario,
    documento: documento,
    documentosBuscadosPorAtributos: documentosBuscadosPorAtributos,
    documentosBuscadosPorUuid: documentosBuscadosPorUuid,
    downloadDosArquivos: downloadDosArquivos,
    entidades: entidades,
    regraDeNotificacao: regraDeNotificacao,
    regrasDeNotificacao: regrasDeNotificacao,
    substituicaoDeDocumentos: substituicaoDeDocumentos,
    tiposDeDocumento: tiposDeDocumento,
});

const reducersCombination = (state, action) => {

    if (action.type === RESETAR_REDUX_STORE) {
        //Referências:
            //https://stackoverflow.com/a/35641992/5091674
            //https://medium.com/@asher.cassetto.cohen/resetting-redux-state-with-a-root-reducer-bonus-how-to-reset-state-selectively-e2a008d0de61
        const { auth } = state;
        state = { auth };
    }

    return appReducer(state, action)
};

export default reducersCombination

