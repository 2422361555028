import axios from 'axios';
import {attachTokenToHeaders, mapApiDataArray, handleErrorForAllActions} from '../actionFunctions';
import {sortArrayOfObjectsMultiple} from '../../support/publicFunctions'
import {
    BUSCAR_ENTIDADES_LOADING, BUSCAR_ENTIDADES_SUCCESS, BUSCAR_ENTIDADES_FAIL,
} from '../actionTypes';


const apiDataMapping = {
    "id": "id",
    "nome": "nome",
    "apelido": "apelido",
    "canal_de_distribuicao": "canalDeDistribuicao",
    "tipo": "tipo",
    "subtipo": "subtipo",
    "qualificacao_do_investidor": "qualificacaoDoInvestidor",
    "modelada": "modelada",
    "cnpj": "cnpj",
    "isin": "isin",
    "administrador": "administrador",
    "identificacao_no_administrador": "identificacaoNoAdministrador",
    "administrador_id": "administradorId",
    "data_desativacao": "dataDesativacao",
    "usuario_que_cadastra_id": "usuarioCadastraId",
    "usuario_que_cadastra": "usuarioCadastra",
    "usuario_que_desativa_id": "usuarioDesativaId",
    "usuario_que_desativa": "usuarioDesativa"
};


const buscarEntidades = () => async (dispatch, getState) => {
    dispatch({ 
      type: (BUSCAR_ENTIDADES_LOADING) 
    });
    try {
      const options = attachTokenToHeaders(getState, {'Accept': 'application/json'});
      const url = '/api/entidades';
      const response = await axios.get(url, options); 
      const entidadesBuscadas = mapApiDataArray(response.data, apiDataMapping);
      //ordena entidade por tipo e então por apelido:
      const entidades = entidadesBuscadas.sort(sortArrayOfObjectsMultiple("tipo", "apelido"));
      dispatch({
        type: (BUSCAR_ENTIDADES_SUCCESS),
        payload: {entidades: entidades},
      });
    } catch (err) {
      handleErrorForAllActions(err, getState); 
      dispatch({
        type: (BUSCAR_ENTIDADES_FAIL),
        payload: {error: err},
      });
    }
};


export {  
    buscarEntidades
}  
