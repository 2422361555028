import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

//List Items
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

//Icons
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FileCopyIcon from '@material-ui/icons/FileCopy';

//...
import CompareIcon from '@material-ui/icons/Compare';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreIcon from '@material-ui/icons/More';
import DescriptionIcon from '@material-ui/icons/Description';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import DvrIcon from '@material-ui/icons/Dvr';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'; //TPX
import ListAltIcon from '@material-ui/icons/ListAlt'; //TPX
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ListIcon from '@material-ui/icons/List';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import BurstModeIcon from '@material-ui/icons/BurstMode';


import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';

import FilterNoneIcon from '@material-ui/icons/FilterNone';
import FilterNone from "@material-ui/icons/FilterNone";

const StyledMenuItem = withStyles(
  (theme) => {
    return {
      root: {      
        '&:hover': {
          '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
              color: theme.palette.primary.main,
          },
        },
        '& .MuiListItemText-primary': {
          fontWeight: 500
        },     
        color: 'dimgray'     
      },
    }
  }
)(MenuItem)


const MenuDeAcessarOutrasVersoes = ({
  numeroDaVersaoMaisRecente, uuidDoDocumento, versaoAtual, tooltipTitle,children
}) => {
  // numeroDaVersaoMaisRecente (integer)
  // versaoAtual (integer)
  // uuidDoDocumento (string)
  // tooltipTitle (string)
  // children (ícone do Material UI)

  // numeroDaVersaoMaisRecente = 3;
  // versaoAtual = 3;
  // uuidDoDocumento = "33ec9d69aa2311eb9cfb16c6829208d3"
  // tooltipTitle="Acessar outras Versões";
  // children = <ListAltIcon />;  
  const versoes = [...Array(numeroDaVersaoMaisRecente).keys()]
    .slice(0).reverse().map(i => ++i);

  //========================

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

 
  return (
    <>
      <Tooltip title={tooltipTitle}>
        <IconButton
          onClick={handleClick}      
          aria-controls="acessar-outras-versoes-menu" //abre submenu c/ esta propriedade
          aria-haspopup="true" //indica que este elemento controle o submenu 
        >
          {children}
        </IconButton>
      </Tooltip>
      <Menu
        id="acessar-outras-versoes-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        //...
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem>
          <span style={{color: "dimgray"}}>Acessar Versão:</span>
        </MenuItem>
        <>
          <Divider light />
          <StyledMenuItem
            // onClick={handleClickExibirMensagemEmDesenvolvimento}
            component = 'a'
            href = {`/documentos/${uuidDoDocumento}/versoes/`}
          >
              <ListItemIcon>
                  <FileCopyIcon />
              </ListItemIcon>
              <ListItemText primary="Todas"/>          
          </StyledMenuItem>
        </>        
        {//TODO: decidir se a linha abaixo vai ser comentada ou não!
          // numeroDaVersaoMaisRecente > 1 &&  
            versoes.map(versao => {return(
              <>
                <Divider light />
                {/*Obs: a utilização abaixo do elemento "a" no lugar do elemento 
                  "Link" é proposital, para forçar o reset dos states*/}
                <StyledMenuItem 
                  disabled = {versao === versaoAtual}
                  component = 'a'
                  href = {`/documentos/${uuidDoDocumento}/versoes/${versao}`}
                >
                  <ListItemIcon>
                      <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary={versao}/>          
                </StyledMenuItem>          
              </>
        )})}

      </Menu>
    </>
  );
}

export default MenuDeAcessarOutrasVersoes;