/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ComboBox({
  id,
  label,
  options,
  optionLabelKey, 
  groupByKey, 
  minWidth,
  value,
  onChange,
  tabIndex,
  style = {},
  required = false,
}) {

  return (
    <Autocomplete
      id={id}
      
      clearOnEscape //limpa o valor quando o usuário clica em "esc"
      autoHighlight //isso aqui permite dar enter mesmo sem escolher algo com as setas ou mouse (muito bom!)
      autoComplete //TODO: ver se realmente vale deixar isso aqui, talvez seja mais uma perfumaria que outra coisa (e talvez uma perfumaria não tão boa)
      //autoSelect  //TODO: já isso aqui permite dar tab mesmo sem dar enter (melhor ainda) (combinar este com autoHighlight é perfeito!)(obs: infelizmente ele traz consigo também um comportamente bem indejado, que é o select sem querer). Ver possibilidade de eliminar completamente.
      //openOnFocus //TODO: apagar esta linha aqui, certamente este parâmetro é bem ruim
      options={options}
      getOptionLabel={(option) => option[optionLabelKey]}
      groupBy={(option) => option[groupByKey]}
      style={{ ...style, minWidth: minWidth }}

      value={value}
      onChange={onChange}

      renderInput={(params) => 
        <TextField 
          {...params} 
          label={label} 
          margin="normal" 
          inputProps={{ 
            ...params.inputProps,
            tabIndex: tabIndex 
          }}      
          required={required} 
        />
      }
    />
  );
}

