import {
    SUBSTITUIR_DOCUMENTOS_LOADING, 
    SUBSTITUIR_DOCUMENTOS_SUCCESS, 
    SUBSTITUIR_DOCUMENTOS_FAIL
} from '../actionTypes';
  
const initialState = {
    responses: [], 
    isLoading: false,
    error: null,
};

const substituicaoDeDocumentos = (state = initialState, { type, payload }) => { 
    switch (type) {
        case SUBSTITUIR_DOCUMENTOS_LOADING:
            return {
                ...state,
                isLoading: true,
                error: null,
            }        
        case SUBSTITUIR_DOCUMENTOS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                responses: payload.responses
            }                
        case SUBSTITUIR_DOCUMENTOS_FAIL:
            return {
                ...state,
                isLoading: false,
                responses: [],
                error: payload.error,
            }                     
        default:
            return state;
    }
}

export default substituicaoDeDocumentos