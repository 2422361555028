import React, { useEffect, useState  } from 'react';
import Layout from '../../layout/Layout'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CheckboxesTags from '../../components/CheckboxesTags/CheckboxesTags'
import Button from '@material-ui/core/Button';

import SearchIcon from '@material-ui/icons/Search';

import {connect} from "react-redux";
import {buscarEntidades} from '../../redux/actions/entidadeActions'
import {buscarTiposDeDocumento} from '../../redux/actions/documentoActions'
import {getDistinctValuesFromAnArrayOfObjects} from '../../support/publicFunctions'

import { Link } from 'react-router-dom';

import {format} from 'date-fns'

//Componentes
import Loader from '../../components/Loader/Loader'
import DatePickers from '../../components/DatePickers/DatePickers'

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Dialog from '@material-ui/core/Dialog';

import camposEmBrancoGif from '../../support/images/exemplos/camposEmBranco.gif';

import { useHistory } from "react-router-dom";

import './Consulta.css'

const textoSobreCamposEmBranco = "Você pode deixar qualquer um dos campos de consulta em branco. Clique para visualizar um exemplo";

const Consulta = ({
  buscarEntidades, entidadesBuscadas, entidadesWasLoaded, entidadesIsLoading, 
  buscarTiposDeDocumento, tiposDeDocumentoBuscados, tiposDeDocumentoWasLoaded, tiposDeDocumentoIsLoading, 
}) => { 

    let history = useHistory();

    // const umAnoAtras = new Date(new Date().setFullYear(new Date().getFullYear() - 1)); //TODO: apagar esta linha agora que ela não é mais utilizada?

    // Preenche o estado inicial da consulta com base no valor do item parametrosDeconsulta
    var parametrosIniciais = JSON.parse(sessionStorage.getItem("parametrosDeConsulta")) || {
        entidadesSelecionadas: [],
        tiposDeDocumentoSelecionados: [],
        temasSelecionados: [],
        dataInicialSelecionada: null,
        dataFinalSelecionada: null
    };
    // eslint-disable-next-line no-unused-vars
    var removerParametrosIniciais = sessionStorage.removeItem("parametrosDeConsulta") || null;

    const [entidades, setEntidades] = useState([]);
    const [tiposDeDocumento, setTiposDeDocumento] = useState([]);
    const [temas, setTemas] = useState([]);
    const [camposEmBrancoDialog, setCamposEmBrancoDialog] = useState(false);

    const [entidadesSelecionadas, setEntidadesSelecionadas] = React.useState(parametrosIniciais.entidadesSelecionadas);
    const [tiposDeDocumentoSelecionados, setTiposDeDocumentoSelecionados] = React.useState(parametrosIniciais.tiposDeDocumentoSelecionados);
    const [temasSelecionados, setTemasSelecionados] = React.useState(parametrosIniciais.temasSelecionados);

    const [tiposDeDocumentoFiltrados, setTiposDeDocumentoFiltrados] = React.useState([]);    

    const dataInicial = parametrosIniciais.dataInicialSelecionada;
    const dataFinal = parametrosIniciais.dataFinalSelecionada;

    const [dataInicialSelecionada, setDataInicialSelecionada] = React.useState(dataInicial? new Date(parametrosIniciais.dataInicialSelecionada) : null);
    const [dataFinalSelecionada, setDataFinalSelecionada] = React.useState(dataFinal? new Date(parametrosIniciais.dataFinalSelecionada) : null);
    
    const [dataInicialString, setDataInicialString] = React.useState("");
    const [dataFinalString, setDataFinalString] = React.useState("");

    const [urlFinal, setUrlFinal] = useState('/consulta/documentos');

    const handleChangeEntidadeselecionadas = (event, values) => setEntidadesSelecionadas(values)
    const handleChangeTiposDeDocumentoSelecionados = (event, values) => setTiposDeDocumentoSelecionados(values)
    const handleChangeTemasSelecionados = (event, values) => setTemasSelecionados(values)
    const handleChangeDataInicial = (date) => setDataInicialSelecionada(date); 
    const handleChangeDataFinal = (date) => setDataFinalSelecionada(date); 
    // Guarda os parâmetros de busca no session storage
    const handleButtonClick = (event) => {
        
        //...
        event.preventDefault();
      
        //Atualiza session storage
        var parametrosDeConsulta = {};
        parametrosDeConsulta.entidadesSelecionadas = entidadesSelecionadas;
        parametrosDeConsulta.tiposDeDocumentoSelecionados = tiposDeDocumentoSelecionados;
        parametrosDeConsulta.temasSelecionados = temasSelecionados;
        parametrosDeConsulta.dataInicialSelecionada = dataInicialSelecionada;
        parametrosDeConsulta.dataFinalSelecionada = dataFinalSelecionada;
        sessionStorage.setItem("parametrosDeConsulta", JSON.stringify(parametrosDeConsulta));
      
        //Verifica se parâmetros estão vazios
        if(
            !dataInicialSelecionada && !dataFinalSelecionada && 
            !entidadesSelecionadas.length && !temasSelecionados.length && 
            !tiposDeDocumentoSelecionados.length
        ){
            if(!window.confirm(
                `Você não selecionou nenhum campo e assim todos os documentos serão consultados. ` +
                `Por este motivo, este processo será demorado. Você deseja continuar mesmo assim? ` 
            )){
                return false;
            }
        }

        //Muda url
        history.push(urlFinal);
    };
    



    //Buscar entidades e tipos de documento (para servir de parâmetro/input do buscador)
    useEffect(() => {
      buscarEntidades(); 
      buscarTiposDeDocumento();  
    }, []); // eslint-disable-line 
              //(neste caso pode ser desativado, porque é desejável apenas uma única execução, logo quando a página carrega)


    //Depois que as entidades e documentos são carregados, estabelecer os parâmetros básicos do buscador (temas, entidades ordenadas e tiposDeDocumento ordenados)
    useEffect(() => {

        const DefinirParametrosIniciaisDoBuscador = () => {
            if (entidadesWasLoaded && tiposDeDocumentoWasLoaded) {
                setEntidades(entidadesBuscadas);
                setTiposDeDocumento(tiposDeDocumentoBuscados);
                setTiposDeDocumentoFiltrados(tiposDeDocumentoBuscados); //obs: estou definindo aqui apenas o valor inicial do tiposDeDocumentoFiltrados
                let temasDosTiposDeDocumento = getDistinctValuesFromAnArrayOfObjects(tiposDeDocumentoBuscados, "tema");
                let temas = temasDosTiposDeDocumento.map(item => ({"nome": item})) //TODO: talvez isso aqui fosse legal mover para dentro das Redux Acions, criando uma action buscarTemas que faz todo estes processos acima
                setTemas(temas);
            }
        }

        DefinirParametrosIniciaisDoBuscador();

    }, [entidadesWasLoaded, tiposDeDocumentoWasLoaded]); // eslint-disable-line 
                                                    //(neste caso pode ser desativado, porque é desejável apenas uma única execução, logo após o carregamento das entidades e tiposDeDocumento)    


    /* Toda vez que os temas selecionados mudam (e os tiposDeDocumentos são carregados) os tipos de documento que 
        serão exibidos pro usuário (ou seja, os TiposDeDocumentoFiltrados) e os tipos de documentos selecionados podem sofrer alterações também */
    useEffect(()=>{
        //Reconstroi o array de relatórios filtrados e selecionados toda vez que tem mudança nos temas
            //(ou seja, quando é gerado um novo filtro)
        const ajustarTiposDeDocumentosAMudançaDeTemasSelecionados = () => {
            if (temasSelecionados.length) {
                setTiposDeDocumentoFiltrados(
                    tiposDeDocumento.filter(
                        tipoDeDocumento => temasSelecionados.find(temaSelecionado => temaSelecionado.nome === tipoDeDocumento.tema))
                );
                setTiposDeDocumentoSelecionados(
                    tiposDeDocumentoSelecionados.filter(
                        tipoDeDocumentoSelecionado => temasSelecionados.find(temaSelecionado => temaSelecionado.nome === tipoDeDocumentoSelecionado.tema))
                )
            } else {
                setTiposDeDocumentoFiltrados(tiposDeDocumento);
                setTiposDeDocumentoSelecionados(tiposDeDocumentoSelecionados);
            }
        }

        ajustarTiposDeDocumentosAMudançaDeTemasSelecionados();
        
    },[temasSelecionados]) // eslint-disable-line 
                                //Neste caso posso desativar o Lint porque só quero para este effect ser disparado quando há mudança nos temas
                                    //(obs: e na verdade pode até causar bugs/comportamentos indesejados caso passe aqui outros parâmetros além de temasSelecionados)


    //Define a urlFinal
    useEffect(() => {
        
        const obterUrlDaPesquisa = () => {

            const definirDatas = () => {
                if(!dataInicialSelecionada){setDataInicialString("");
                } else {
                    try {setDataInicialString(format(dataInicialSelecionada,'yyyy-MM-dd'))
                    } catch (err) {setDataInicialString("DATA-INVALIDA")}
                }

                if(!dataFinalSelecionada){setDataFinalString("");
                } else {
                    try {setDataFinalString(format(dataFinalSelecionada,'yyyy-MM-dd'))
                    } catch (err) {setDataFinalString("Data Invalida")}
                }
            }

            definirDatas();

            const url = ('/consulta/documentos?'+
                `${dataInicialString? `data-inicial=${dataInicialString}&` : ''}` + 
                `${dataFinalString? `data-final=${dataFinalString}&` : ''}` +
                `${entidadesSelecionadas.length? (entidadesSelecionadas.map(entidade => `entidade=${entidade.apelido}&`).join('')) : ''}` +
                `${temasSelecionados.length? (temasSelecionados.map(tema => `tema=${tema.nome}&`).join('')) : ''}` +
                `${tiposDeDocumentoSelecionados.length? (tiposDeDocumentoSelecionados.map(tipoDeDocumento => `relatorio=${tipoDeDocumento.nome}&`).join('')) : ''}` +                
                ``).slice(0, -1)

            console.log("urlFinal", url)                

            return (url)

        }

        setUrlFinal(obterUrlDaPesquisa())

    }, [dataInicialSelecionada, dataFinalSelecionada, entidadesSelecionadas, temasSelecionados, tiposDeDocumentoSelecionados,  dataInicialString, dataFinalString]);



    return(
        <Layout pageTitle="Consultar Documentos">
            
            <div id="consulta">

                <Paper className="buscadorContainer" elevation={2}>

                    <>{(entidadesIsLoading || tiposDeDocumentoIsLoading) ? (

                        <Loader />

                    ) : (<>    
                        <Dialog maxWidth={'lg'} onClose={() => setCamposEmBrancoDialog(false)} open={camposEmBrancoDialog}>
                            <img src={camposEmBrancoGif} alt="Gif com instruções de ajuda" />
                        </Dialog>

                        <Grid container spacing={4}>
                            <Grid item xs={12} md={4}><div>
                                <CheckboxesTags 
                                    options={entidades}
                                    optionLabelKey={"apelido"}
                                    groupByKey={"tipo"}
                                    label={"Entidades"}
                                    placeholder={"Escolher"}
                                    value={entidadesSelecionadas}
                                    onChange={handleChangeEntidadeselecionadas}
                                />
                            </div></Grid>
                            <Grid item xs={12} md={4}><div>
                                <CheckboxesTags 
                                    options={temas}
                                    optionLabelKey={"nome"}
                                    groupByKey={null}
                                    label={"Temas"}
                                    placeholder={"Escolher"}
                                    value={temasSelecionados}
                                    onChange={handleChangeTemasSelecionados}
                                />
                            </div></Grid>
                            <Grid item xs={12} md={4}><div>
                                <CheckboxesTags 
                                    options={tiposDeDocumentoFiltrados} 
                                    optionLabelKey={"nome"}
                                    groupByKey={"tema"}
                                    label={"Relatórios"}
                                    placeholder={"Escolher"}
                                    value={tiposDeDocumentoSelecionados}
                                    onChange={handleChangeTiposDeDocumentoSelecionados}
                                />
                            </div></Grid>
                            <Grid item className="dateContainer">
                                <span className="textoData">De</span>
                                <DatePickers 
                                    tipo="DIARIO"
                                    value={dataInicialSelecionada}
                                    onChange={handleChangeDataInicial}
                                />
                            </Grid>  
                            <Grid item className="dateContainer">
                                <span className="textoData">Até</span>
                                <DatePickers 
                                    tipo="DIARIO"
                                    value={dataFinalSelecionada}
                                    onChange={handleChangeDataFinal}
                                />
                            </Grid>       
                            <Grid item xs={12} md={12} >
                                <div className="linhaHorizontal"></div>
                            </Grid>    
                        </Grid>

                        <Grid container spacing={0} justify="space-between"  className="centralizadoVerticalmente">                 
                            <Grid item>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    component={Link} 
                                    onClick={handleButtonClick}
                                >
                                    <SearchIcon className="searchIcon"/> 
                                    <span>CONSULTAR</span>
                                </Button>
                            </Grid>                      
                            <Grid item>
                                <Tooltip title={textoSobreCamposEmBranco}>
                                    <IconButton 
                                        onClick={()=>setCamposEmBrancoDialog(!camposEmBrancoDialog)}
                                    >
                                        <HelpIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>

                    </>)}</>
                </Paper>
            
            </div>

        </Layout>
    )
}




const mapStateToProps = (state) => ({
    //entidades
    entidadesBuscadas: state.entidades.entidades,
    entidadesIsLoading: state.entidades.isLoading,
    entidadesWasLoaded: state.entidades.wasLoaded,
    //tiposDeDocumento
    tiposDeDocumentoBuscados: state.tiposDeDocumento.tiposDeDocumento,
    tiposDeDocumentoIsLoading: state.tiposDeDocumento.isLoading,
    tiposDeDocumentoWasLoaded: state.tiposDeDocumento.wasLoaded,
});


export default connect(mapStateToProps, {buscarEntidades, buscarTiposDeDocumento})(Consulta) 



