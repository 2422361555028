import React from 'react';
import Layout from '../../layout/Layout'

import Paper from '@material-ui/core/Paper';

import CheckboxesTags from './ComponentesTestes/CheckboxesTagsTemp';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Tooltip from "@material-ui/core/Tooltip";


import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import PlusOneOutlinedIcon from '@material-ui/icons/PlusOneOutlined';
import CloseIcon from '@material-ui/icons/Close';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import BorderInnerIcon from '@material-ui/icons/BorderInner';


import {useHistory} from "react-router-dom";


const App = function() {

    let history = useHistory();    
    const handleClickRetornar = () => history.goBack()

    let defaultValueArray1 = [  
      {
          "nome": "Performance Breakdown",
          "tema": "Ativo",
          "frequencia": "Mensal"
      },
      {
        "nome": "Carteira Fechamento",
        "tema": "Ativo",
        "frequencia": "Mensal"
      },
      {
        "nome": "Demonstrativo de Caixa",
        "tema": "Ativo",
        "frequencia": "Mensal"
      },            
    ];
    let defaultValueArray2 = [  
      {
        "nome": "BREOF II FIP",
        "tipo": "Fundos"
      },
      {
        "nome": "BREOF FIDC",
        "tipo": "Fundos"
      },
      {
        "nome": "BREOF III FIP",
        "tipo": "Fundos"
      },
      {
        "nome": "Nova I FII",
        "tipo": "Fundos"
      },
      {
        "nome": "Trilliant Reit FIM",
        "tipo": "Fundos"
      },
      {
        "nome": "SBC FII",
        "tipo": "Fundos"
      },
      {
        "nome": "VBI Uliving FII",
        "tipo": "Fundos"
      },
      {
        "nome": "VBI CRI FII",
        "tipo": "Fundos"
      },
      {
        "nome": "VBI Reits FoF FII",
        "tipo": "Fundos"
      },
      {
        "nome": "VBI Logístico FII",
        "tipo": "Fundos"
      },
      {
        "nome": "VBI Prime Properties FII",
        "tipo": "Fundos"
      },      
    ];
    let defaultValueArray3 = [
      {
        "nome": "Demonstrativo de Caixa",
        "tema": "Ativo",
        "frequencia": "Mensal"
      }      
    ];
    let defaultValueArray4 = [
      {
        "nome": "Basset Hound LLC",
        "tipo": "INRs"
      },
      {
        "nome": "BREOF II Luxco SarL",
        "tipo": "INRs"
      },
      {
        "nome": "Canaryanna LLC",
        "tipo": "INRs"
      },
      {
        "nome": "Espirito Santo LLC",
        "tipo": "INRs"
      },
      {
        "nome": "Floripa",
        "tipo": "INRs"
      },
      {
        "nome": "Tabbyal",
        "tipo": "INRs"
      },
      {
        "nome": "BREOF III SCS",
        "tipo": "INRs"
      },
      {
        "nome": "Brazil Student Housing LLC",
        "tipo": "INRs"
      }      
    ];

  
    return (<>
      <Layout pageTitle="Preferências de Notificação">

        <div style={{maxWidth: "960px2"}}>

          {/* <div style={{marginBottom: "24px"}}>
            <a style={{color:"dimgray", fontSize: "0.875rem", marginBottom: "8px", display: "block"}} >
              Você receberá no seu e-mail os novos documentos inseridos no sistema conforme as regras de notificações abaixo.</a>
            <a style={{color:"dimgray", fontSize: "0.875rem", marginBottom: "8px", display: "block"}} >
              Mas você pode editar esta regras ou criar novas da forma que desejar. Favor, utilize as opções disponíveis abaixo.</a>
          </div> */}

        
        

        {/* <Button variant="contained" color="primary" style={{marginBottom: "40px"}}><AddCircleOutlineIcon /> &nbsp; Cadastrar nova regra</Button> */}
        <Button variant="contained" color="primary" style={{margin: "0rem 1rem 1.75rem 0rem"}}><AddCircleOutlineIcon /> &nbsp; Criar Nova Regra</Button>
        
        {/* <IconButton variant="contained" color="" style={{margin: "0rem 1rem 1.25rem 0rem"}}><HelpIcon style={{color: "dimgray"}}/></IconButton> */}
        
        {/* Linha Horizontal */}
        {/* <div style={{marginBottom: "1rem", borderBottom: "solid 1px lightgray"}}></div> */}


        <div id="">

            

            

            <Grid container spacing={0}>
              {/* <Grid item xs={12} md={12} >
                  <div className="linhaHorizontal" style={{ borderBottom: "solid 1px lightgray"}} ></div>
              </Grid>
              <Grid className="barraDeFerramentas" style={{ padding: "3px 0px" }}
                  item xs={12} md={12} 
              >
                  <Tooltip title='Retornar'>
                      <IconButton  
                          onClick={handleClickRetornar}
                      >
                          <ArrowBackIcon />
                      </IconButton>
                  </Tooltip>
                  <Tooltip title='Ajuda'>
                      <IconButton  
                      >
                          <HelpIcon />
                      </IconButton>
                  </Tooltip>
              </Grid> */}
               

              {/* <Grid item xs={12} md={12} >
                  <div className="linhaHorizontal" style={{ borderBottom: "solid 1px lightgray"}} ></div>
              </Grid>                */}

              <Grid item xs={12} md={12} >     

                {/* <Paper elevation={2} style={{marginTop: '1.25rem', padding: '0rem', backgroundColor: 'inherit'}}> */}
                
                    <Paper elevation={1} style={{ marginBottom: "0.5rem", marginTop: '1.25rem', padding: '0rem 1.5rem',  border: 'none', color: 'dimgray'}}>
                      <h2 className="lunar" style={{margin: '1.5rem 0 1.5rem', color: "black", display: "inline-block"}}>Minhas Regras</h2>
                      {/* <a className="lunar" style={{margin: "1.5rem 0 1rem", margin: '0', color: "dimgray", display: "block", fontWeight: "600", fontSize: "1rem"}}>Minhas Regras</a> */}
                      <span style={{display: "inline-block", float: "right", marginLeft: "24px", marginTop: "0.75rem"}}>
                        {/* <Tooltip title='Adicionar Nova Regra'><IconButton><AddCircleIcon /></IconButton></Tooltip> */}
                        <Tooltip title='Ajuda'><IconButton><HelpIcon /></IconButton></Tooltip>
                      </span>   
                    </Paper>
                    
                    <Paper elevation={2} style={{ marginBottom: "0.5rem",   border: 'none'}}>
                      
                      <div style={{padding: "0px 24px", borderBottom: "solid 1px white"}}>
                        {/* <a style={{fontSize: "0.875rem", fontWeight: "600", margin: "1rem 0", display: "inline-block", color: "dimgray"}}>Fechamento Mensal dos Fundos</a> */}
                        <a style={{fontSize: "0.875rem", fontWeight: "550", margin: "1.25rem 0 0rem", display: "inline-block", color: "black"}}>Fechamento Mensal dos Fundos</a>
                        <span style={{display: "inline-block", float: "right", marginLeft: "24px", marginTop: "0.5rem"}}>
                          <Tooltip title={"Editar Regra"}><IconButton style={{fontSize: "0.75rem"}}><EditIcon fontSize="small" /></IconButton></Tooltip><Tooltip title={"Deletar Regra"}><IconButton style={{fontSize: "0.75rem"}}><DeleteIcon fontSize="small" /></IconButton></Tooltip>
                        </span>                    
                      </div>



                      <div style={{padding: "0px 24px 0.25rem"}}>

                          <Grid container spacing={0}>

                              <Grid item xs={12} md>
                                <div style={{margin: "16px 16px 16px 0px"}}>
                                    <CheckboxesTags  multipleBoolen={true} labelName = "Receber estes Relatórios" optionsValues = {relatorios} groupByKey = "tema" defaultValueArray={defaultValueArray1}/>
                                </div>  
                              </Grid>
                              <Grid item xs={0} md style={{maxWidth: '1.5rem'}} className="centralizadoVerticalmente">
                                  <Tooltip title={"Esta notificação será uma combinação entre cada relatório à esquerda com cada entidade à direita"}>
                                    <ShuffleIcon fontSize="small" style={{color: "dimgray"}} />
                                  </Tooltip>
                              </Grid>                    
                              <Grid item xs={12} md>
                                <div style={{margin: "16px 0px 24px 16px"}}>
                                    <CheckboxesTags multipleBoolen={true} labelName = "para estas Entidades." optionsValues = {entidades} groupByKey = "tipo" defaultValueArray={defaultValueArray2}/>
                                  </div>
                              </Grid>

                          </Grid>
                          {/* <Grid item xs={12} md={6}>
                                <div style={{margin: "16px 24px 24px 0px"}}>
                                  <Tooltip title={"Editar Regra"}><IconButton style={{fontSize: "0.75rem"}}><EditIcon fontSize="small" /></IconButton></Tooltip><Tooltip title={"Deletar Regra"}><IconButton style={{fontSize: "0.75rem"}}><DeleteIcon fontSize="small" /></IconButton></Tooltip>
                                </div>  
                          </Grid> */}

                      </div>

                    </Paper>

                    <Paper elevation={2} style={{ marginBottom: "0px", border: 'none'}}>
                  

                      <div style={{padding: "0px 24px", borderBottom: "solid 1px white"}}>
                      <a style={{fontSize: "0.875rem", fontWeight: "600", margin: "1.25rem 0 0rem", display: "inline-block", color: "black"}}>Demonstrativo de Caixa dos INRs</a>
                        <span style={{display: "inline-block", float: "right", marginLeft: "24px", marginTop: "0.5rem"}}>
                          <Tooltip title={"Editar Regra"}><IconButton style={{fontSize: "0.75rem"}}><EditIcon fontSize="small" /></IconButton></Tooltip><Tooltip title={"Deletar Regra"}><IconButton style={{fontSize: "0.75rem"}}><DeleteIcon fontSize="small" /></IconButton></Tooltip>
                        </span>                    
                      </div>


                      <div style={{padding: "0px 24px 0.25rem"}}>

                          <Grid container  container spacing={0}>
                              <Grid item xs={12} md>
                                  <div style={{margin: "16px 16px 16px 0px"}}>
                                    <CheckboxesTags multipleBoolen={true} labelName = "Receber estes Relatórios" optionsValues = {relatorios} groupByKey = "tema" defaultValueArray={defaultValueArray3} />
                                  </div>
                              </Grid>
                              <Grid item xs={0} md style={{maxWidth: '1.5rem'}} className="centralizadoVerticalmente">
                                  <Tooltip title={"Esta notificação será uma combinação entre cada relatório à esquerda com cada entidade à direita"}>
                                    <ShuffleIcon fontSize="small" style={{color: "dimgray"}} />
                                  </Tooltip>
                              </Grid>                                
                              <Grid item xs={12} md>
                                <div style={{margin: "16px 0px 24px 16px"}}>
                                  <CheckboxesTags multipleBoolen={true} labelName = "para estas Entidades." optionsValues = {entidades} groupByKey = "tipo" defaultValueArray={defaultValueArray4} />
                                </div>  
                              </Grid>
                          </Grid>
                          {/* <Grid item xs={12} md={6}>
                                <div style={{padding: "0px 24px 4px 0px"}}>
                                  <Tooltip title={"Editar Regra"}><IconButton style={{fontSize: "0.75rem"}}><EditIcon fontSize="small" /></IconButton></Tooltip><Tooltip title={"Deletar Regra"}><IconButton style={{fontSize: "0.75rem"}}><DeleteIcon fontSize="small" /></IconButton></Tooltip>
                                </div>  
                          </Grid> */}

                      </div>
                      
                    </Paper>
                {/* </Paper>          */}
          
            </Grid>                        
          </Grid>       
          
          
          </div>
        </div>

      </Layout>
    
    </>);
  }


export default App;


const entidades = [
  {
    "nome": "BREOF II FIP",
    "tipo": "Fundos"
  },
  {
    "nome": "BREOF FIDC",
    "tipo": "Fundos"
  },
  {
    "nome": "BREOF III FIP",
    "tipo": "Fundos"
  },
  {
    "nome": "Nova I FII",
    "tipo": "Fundos"
  },
  {
    "nome": "Trilliant Reit FIM",
    "tipo": "Fundos"
  },
  {
    "nome": "SBC FII",
    "tipo": "Fundos"
  },
  {
    "nome": "VBI Uliving FII",
    "tipo": "Fundos"
  },
  {
    "nome": "VBI CRI FII",
    "tipo": "Fundos"
  },
  {
    "nome": "VBI Reits FoF FII",
    "tipo": "Fundos"
  },
  {
    "nome": "VBI Logístico FII",
    "tipo": "Fundos"
  },
  {
    "nome": "VBI Prime Properties FII",
    "tipo": "Fundos"
  },
  {
    "nome": "Basset Hound LLC",
    "tipo": "INRs"
  },
  {
    "nome": "BREOF II Luxco SarL",
    "tipo": "INRs"
  },
  {
    "nome": "Canaryanna LLC",
    "tipo": "INRs"
  },
  {
    "nome": "Espirito Santo LLC",
    "tipo": "INRs"
  },
  {
    "nome": "Floripa",
    "tipo": "INRs"
  },
  {
    "nome": "Tabbyal",
    "tipo": "INRs"
  },
  {
    "nome": "BREOF III SCS",
    "tipo": "INRs"
  },
  {
    "nome": "Brazil Student Housing LLC",
    "tipo": "INRs"
  }
 ]


const temas = [
  {
    "nome": "Ativo"
  },
  {
    "nome": "Contratos"
  },
  {
    "nome": "Passivo"
  },
  {
    "nome": "Contábil"
  },
  {
    "nome": "Eventos Societários"
  },
  {
    "nome": "Risco"
  }
 ]

 const relatorios = [
  {
    "nome": "Carteira Diária",
    "tema": "Ativo",
    "frequencia": "Mensal"
  },
  {
    "nome": "Carteira Fechamento",
    "tema": "Ativo",
    "frequencia": "Mensal"
  },  
  {
    "nome": "Demonstrativo de Caixa",
    "tema": "Ativo",
    "frequencia": "Mensal"
  },
  {
    "nome": "Histórico de Cotas e PL",
    "tema": "Ativo",
    "frequencia": "Diário"
  },
  {
    "nome": "Memória de Cálculo",
    "tema": "Ativo",
    "frequencia": "Mensal"
  },
  {
    "nome": "Performance Breakdown",
    "tema": "Ativo",
    "frequencia": "Mensal"
  },
  {
    "nome": "Relatório de distribuição de rendimentos",
    "tema": "Ativo",
    "frequencia": "Mensal"
  },
  {
    "nome": "Balancete dos Fundos",
    "tema": "Contábil",
    "frequencia": "Mensal"
  },
  {
    "nome": "Demonstração Financeira Auditada",
    "tema": "Contábil",
    "frequencia": "Semestral"
  },
  {
    "nome": "Razão dos Fundo",
    "tema": "Contábil",
    "frequencia": "Mensal"
  },
  {
    "nome": "Contratos de Prestador de Serviço do Fundo",
    "tema": "Contratos",
    "frequencia": "Pontual"
  },
  {
    "nome": "Regulamentos dos Fundos",
    "tema": "Contratos",
    "frequencia": "Unitário"
  },
  {
    "nome": "Atos Societários da Entidade",
    "tema": "Eventos Societários",
    "frequencia": "Pontual"
  },
  {
    "nome": "Extrato de Cotista do Fundo",
    "tema": "Passivo",
    "frequencia": "Mensal"
  },
  {
    "nome": "Posição de Investidores do Fundo",
    "tema": "Passivo",
    "frequencia": "Mensal"
  },
  {
    "nome": "Relatório de Controle de Enquadramento",
    "tema": "Risco",
    "frequencia": "Diário"
  }
 ]