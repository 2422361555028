//React
import React from 'react';
import ReactDOM  from 'react-dom';

//React Router
import { BrowserRouter } from 'react-router-dom';

//React Redux
import { Provider } from 'react-redux'
import reduxStore from './redux/reduxStore'

//Pages
import App from "./App";

//Material Ui
import {ThemeProvider } from '@material-ui/core/styles';
import muiTheme from './support/materialUI/muiTheme'

//Importações para utilizar o Material-UI pickers em português
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale"; 

//Css
import './index.css';

//Root Element
const rootElement = document.getElementById("root");


//---------------------------------------------------------------
//Renderização do Root do React
//---------------------------------------------------------------

ReactDOM.render(
    <ThemeProvider theme={muiTheme}>                                        {/*Material UI*/}
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>        {/*Material-UI pickers*/}
            <Provider store={reduxStore}>                                   {/*Redux*/}
                <BrowserRouter>                                             {/*React Routers*/}
                    <App />                                                 {/*App*/}
                </BrowserRouter>
            </Provider>
        </MuiPickersUtilsProvider>
    </ThemeProvider>,
    rootElement                                                             /*Seletor do elemento Root no Html*/
);

//--------------------------------------------------------------