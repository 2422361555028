import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  instructions: {
    marginTop: theme.spacing(4),
  },
  stepper: {
    borderRadius: "4px", 
    borderBottom: "solid 2px lightgray"
  }
}));

export default function HorizontalLinearStepper(
  {getSteps, getStepContent, activeStep}
){
  const classes = useStyles();
  const steps = getSteps();

  return (
    <div className={classes.root}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
        </Stepper>
        <div>
            {activeStep === steps.length ? (
              <Typography className={classes.instructions}> 
                {getStepContent('FINAL')}
              </Typography>
            ) : (
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
            )}
        </div>
    </div>
  );
}
