import React, { useEffect, useState } from 'react';
import Layout from '../../layout/Layout'
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import SimplerDataTable from './components/simplerDataTable/simplerDataTable'

import {buscarArquivos} from '../../redux/actions/arquivoActions'

import {sortArrayOfObjectsMultiple} from '../../support/publicFunctions'

import './Inicio.css'


const Inicio = ({arquivosCategorizados, buscarArquivos}) => { 

    const [arquivosFavoritos, setArquivosFavoritos] = useState({});    
    const [isLoadingArquivosFavoritos, setIsLoadingArquivosFavoritos] = useState(true);    
    
    const [arquivosEmGeral, setArquivosEmGeral] = useState({});
    const [isLoadingArquivosEmGeral, setIsLoadingArquivosEmGeral] = useState(true);

    // Limpa a session storage
    sessionStorage.clear();


    useEffect(() => {
        buscarArquivos({
            keyParaCategorizacao: "arquivosFavoritos", apenasFavoritos: true,  
            limite: 80, ordernarPorRecentes: true, apenasVersoesAtuais: true
        }); 
        buscarArquivos({
            keyParaCategorizacao: "arquivosEmGeral", apenasFavoritos: false, 
            limite: 80, ordernarPorRecentes: true, apenasVersoesAtuais: true 
        }); 
    }, []); // eslint-disable-line         
        /*Nesse caso aqui o eslint foi desativado porque o useEffect pode ser carregado uma única vez quando o componente é montado */

    useEffect(() => {
        if(arquivosCategorizados.arquivosFavoritos){
            if(arquivosCategorizados?.arquivosFavoritos?.arquivos?.length){
                setArquivosFavoritos(arquivosCategorizados.arquivosFavoritos.arquivos);
                setIsLoadingArquivosFavoritos(arquivosCategorizados.arquivosFavoritos.isLoading);
            } else {
                //Cria um Fallback caso não haja arquivo favoritos
                if(arquivosCategorizados?.arquivosEmGeral?.arquivos?.length){
                    const fallbackArquivosFavoritos = ([...arquivosCategorizados.arquivosEmGeral.arquivos])
                        .sort(sortArrayOfObjectsMultiple("-numeroDeDownloads", "-tamanhoEmBytes"));
                    setArquivosFavoritos(fallbackArquivosFavoritos);
                    setIsLoadingArquivosFavoritos(arquivosCategorizados.arquivosFavoritos.isLoading);
                }
            }
        }        
        if(arquivosCategorizados.arquivosEmGeral){
            setArquivosEmGeral(arquivosCategorizados.arquivosEmGeral.arquivos);
            setIsLoadingArquivosEmGeral(arquivosCategorizados.arquivosEmGeral.isLoading);
        }
    },[arquivosCategorizados]);             


    return(
        <Layout pageTitle="Sistema Apollo Lunar">
            <div id="inicio">
                {/* <AlertaDeSistemaEmDesenvolvimento /> */}
                <Grid container spacing={4}>
                     <Grid item xs={12} md={6}>
                         <SimplerDataTable 
                            title={"Documentos Em Destaque"} 
                            arquivos={arquivosFavoritos} 
                            isLoading={isLoadingArquivosFavoritos} 
                            noMatchMessage="Nenhum documento encontrado, favor configurar suas preferências de notificação"/>
                    </Grid>     
                    <Grid item xs={12} md={6} >
                         <SimplerDataTable 
                            title={"Documentos Recentes"} 
                            arquivos={arquivosEmGeral} 
                            isLoading={isLoadingArquivosEmGeral}/>
                    </Grid>     
                </Grid>
            </div> 
        </Layout>
    )
}

// TODO: apagar linha abaixo:

// const AlertaDeSistemaEmDesenvolvimento = () => {
//     const theme = useTheme();
//     const linkStyle = {color: theme.palette.primary.dark};
//     return(
//         <ClosableAlert 
//             alertTitle="Sistema Em Desenvolvimento"
//             severity={"info"}
//             marginBottom = {"2rem"}
//             elevation = {1}
//         >
//             <p className="lunar">Caso identifique algum problema, favor informar a equipe de desenvolvimento (
//                 <span style={linkStyle}>
//                     <a className="textoLink visitedColorChangeDisabled" href="mailto:dev@vbirealestate.com" >
//                         <b>dev@vbirealestate.com</b>
//                     </a>
//                 </span>                
//             ).</p>
//         </ClosableAlert>   
//     )
// }

const mapStateToProps = (state) => ({
    arquivosCategorizados: state.arquivosCategorizados
});

export default connect(mapStateToProps, {buscarArquivos})(Inicio);
