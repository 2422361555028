import {useEffect, useState} from 'react';

import MUIDataTable from "mui-datatables";
import textLabels from "../../../../support/MuiDatatableSupport/TextLabels"

import CustomToolbarSelect from './components/CustomToolbarSelect/CustomToolbarSelect'

import ComboBox from '../../../../components/ComboBox/ComboBox' 
import DatePickers from '../../../../components/DatePickers/DatePickers'

import {sortArrayOfObjects} from './../../../../support/publicFunctions'

import {parseFilename} from './functions/parseFilename/parseFilename'
// import dicionarioDeIds from './functions/parseFilename/dicionario.json'
    //TODO: remover linha acima completamente!

import {format} from 'date-fns'

import {connect} from "react-redux";
import {buscarEntidades} from '../../../../redux/actions/entidadeActions'
import {buscarDescritivos} from '../../../../redux/actions/descritivoActions'
import {buscarDicionario} from '../../../../redux/actions/dicionarioActions'

import Loader from '../../../../components/Loader/Loader'

import converterData from '../../../../support/helpers/converterData'

import Button from '@material-ui/core/Button';

import './Step2.css'


const Step2 = ({
    avancarEtapa, handleCancelar, files, setDocumentosParaRevisar,
    buscarEntidades, entidades, entidadesIsLoading, 
    buscarDescritivos, descritivos, descritivosIsLoading, 
    buscarDicionario, dicionarioDeIds, dicionarioIsLoading
}) => { 

    /*------------------------------------------------------------------------*/ 
    // 1. Parte Principal
    /*------------------------------------------------------------------------*/

    //definir dados
    const nomeDosArquivos = (files.map(file => file.name));
    const dadosIniciais= nomeDosArquivos.map(nome => [nome]);
    const [dados] = useState(dadosIniciais); 

    //definir valoresSelecionados
    let initialStateValores = {}
    const keysIndex = Array.from(Array(dados.length).keys());
    keysIndex.map((keyIndex)=>initialStateValores[keyIndex] = null)

    const [descritivosSelecionados, setDescritivosSelecionados] = useState(initialStateValores);
    const [entidadesSelecionadas, setEntidadesSelecionadas] = useState(initialStateValores);
    const [referenciasSelecionadas, setReferenciasSelecionadas] = useState(initialStateValores);
    const [frequencias, setFrequencias] = useState([]);

    //dicionário
    const [dicionario, setDicionario] = useState({});

    //acompanhar mudanças
    useEffect(()=>console.log("dados", dados),[dados])
    useEffect(()=>console.log("descritivosSelecionados", descritivosSelecionados),[descritivosSelecionados])
    useEffect(()=>console.log("entidadesSelecionadas", entidadesSelecionadas),[entidadesSelecionadas])
    useEffect(()=>console.log("referenciasSelecionadas", referenciasSelecionadas),[referenciasSelecionadas])
    useEffect(()=>console.log("frequencias", frequencias),[frequencias])
    useEffect(()=>console.log("dicionario", dicionario),[dicionario])

    //scrollToTop
    useEffect(() => {
        function scrollPageToTop(){
            window.scrollTo(0, 0)
        }
        scrollPageToTop()
    }, [])

    //buscar entidades, descritivos e dicionário:
    useEffect(() => {
        buscarEntidades(); 
        buscarDescritivos();
        buscarDicionario();
    }, []); // eslint-disable-line 
                //(neste caso pode ser desativado, porque é desejável apenas uma única execução, logo quando a página carrega)
  

    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 
    // 2. Funções para apoio
    /*-----------------------------------------------------------------------------------------------------------------------------------*/     

    //atualizarFrequencias
    useEffect(() => {
        function atualizarFrequencias(){
            const frequencias = Object.keys(descritivosSelecionados).map(key =>
                descritivosSelecionados[key]?.frequencia || null)
            setFrequencias(frequencias);
        }
        atualizarFrequencias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descritivosSelecionados]) /*obs: neste caso pode ignorar "exhaustive-
        deps" porque o efeito deve "escutar" somente mudanças nos descritivos*/ 


    //Criar Dicionário
    useEffect(() => {
        function criarDicionarioFinal () { 
            if(!entidades || !descritivos || !dicionarioDeIds){return false} //se ainda não carregou esses parâmetros a função não deve ser executada
            const dicionarioVazioComTodosNomes = {
                entidade: getDicionarioVazioComTodosNomes("entidade"),
                descritivo: getDicionarioVazioComTodosNomes("descritivo"),
            }
            const dicionarioDeNomes = {
                entidade: getDicionarioDeNomes(dicionarioDeIds, "entidade"),
                descritivo: getDicionarioDeNomes(dicionarioDeIds, "descritivo"),
            }
            let dicionarioFinal = {
                "entidade": {
                    ...dicionarioVazioComTodosNomes.entidade,
                    ...dicionarioDeNomes.entidade
                },
                "descritivo": {
                    ...dicionarioVazioComTodosNomes.descritivo,
                    ...dicionarioDeNomes.descritivo
                }
            }
            console.log("dicionarioDeIds", dicionarioDeIds);
            console.log("dicionarioFinal", dicionarioFinal);
            return dicionarioFinal
        }
        setDicionario(criarDicionarioFinal());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entidades, descritivos, dicionarioDeIds]); /*obs: neste caso pode ignorar "exhaustive-
        deps" porque o efeito deve "escutar" somente mudanças nos descritivos, entidade ou
        dicionarioDeIds*/


    //getDicionarioVazioComTodosNomes
    function getDicionarioVazioComTodosNomes(parametroTipo){
        let dicionario = {};
        switch (parametroTipo) {
            case "entidade":
                entidades.map(entidade => dicionario[entidade.apelido] = [])
                break;
            case "descritivo":
                descritivos.map(descritivo => dicionario[descritivo.nomeCompleto] = []) 
                break;
            default:
                throw new Error('ERRO: "parametroTipo" inválido');
        }
        return dicionario;
    }


    //getDicionarioDeNomes
    function getDicionarioDeNomes(dicionarioDeIds, parametroTipo){
        let parametros;
        switch (parametroTipo) {
            case "entidade": parametros = entidades; break;
            case "descritivo": parametros = descritivos; break;
            default: throw new Error('ERRO: "parametroTipo" inválido');            
        }
        let DicionarioDeNomes = {};
        Object.keys(dicionarioDeIds[parametroTipo]).map(parametroId => {
            // eslint-disable-next-line eqeqeq
            const parametro = (parametros.find(parametro => parametro.id == parametroId));
            const chavesUnicas = dicionarioDeIds[parametroTipo][parametroId];
            let nome = null;
            switch (parametroTipo) {
                case "entidade": nome = parametro?.apelido; break;
                case "descritivo": nome = parametro?.nomeCompleto; break;
                default: throw new Error('ERRO: "parametroTipo" inválido');
            }
            if(nome){
                DicionarioDeNomes = {
                    ...DicionarioDeNomes,
                    [nome]: chavesUnicas,
                }
            }
            return false;
        }) 
        return DicionarioDeNomes;
    }





    //Criar CustomToolbarSelect
    const CustomToolbarSelectCallback = (selectedRows, displayData, setSelectedRows) => {

        const handleClickRepetirValores = (tipo) => {
            let setValoresSelecionados
            let valoresSelecionados
            switch (tipo) {
                case 'DESCRITIVOS':
                    setValoresSelecionados = setDescritivosSelecionados;
                    valoresSelecionados = descritivosSelecionados;
                    break;
                case 'ENTIDADES':
                    setValoresSelecionados = setEntidadesSelecionadas;
                    valoresSelecionados = entidadesSelecionadas;
                    break;
                case 'REFERENCIAS':
                    setValoresSelecionados = setReferenciasSelecionadas;
                    valoresSelecionados = referenciasSelecionadas;
                    break;                   
                default:
                  break;
            }
            let newStateValoresSelecionados = valoresSelecionados;
            let ocorreuAlteracao = false;
            selectedRows.data = selectedRows.data.sort(sortArrayOfObjects("index")); //esta alteração permite que o sentido da repetição seja sempre para baixo (em relação ao estado das linhas como elas são apresentadas na tela)
            selectedRows.data.map((selectedRow, indexDaSelecao)=>{
                if(indexDaSelecao===0){ 
                    return false //Não alterar nada na primeira seleção (porque não existirá valor anterior)
                } else {        
                    let dataIndexAtual = selectedRow.dataIndex;
                    let valorAtual = newStateValoresSelecionados[dataIndexAtual];
                    let dataIndexAnterior = selectedRows.data[indexDaSelecao-1].dataIndex;
                    let valorAnterior = newStateValoresSelecionados[dataIndexAnterior];
                    if(valorAtual || !valorAnterior || valorAnterior.toString() === "Invalid Date"){
                        return false //Não alterar nada na linha que já possui valor ou se não há valor anterior (ou se o valor anterior é uma invalid date)
                    } else {
                        ocorreuAlteracao = true;
                        newStateValoresSelecionados = {...newStateValoresSelecionados, [dataIndexAtual]: valorAnterior };
                    }
                }
                return false;
            })
            setValoresSelecionados(newStateValoresSelecionados); 
            setSelectedRows([]);

            return (ocorreuAlteracao )
        }

        const handleClickDeletarValores = (tipo) => {
            let setValoresSelecionados
            let valoresSelecionados
            switch (tipo) {
                case 'DESCRITIVOS':
                    setValoresSelecionados = setDescritivosSelecionados;
                    valoresSelecionados = descritivosSelecionados;
                    break;
                case 'ENTIDADES':
                    setValoresSelecionados = setEntidadesSelecionadas;
                    valoresSelecionados = entidadesSelecionadas;
                    break;
                case 'REFERENCIAS':
                    setValoresSelecionados = setReferenciasSelecionadas;
                    valoresSelecionados = referenciasSelecionadas;
                    break;                   
                default:
                  break;
            }
            let newStateValoresSelecionados = valoresSelecionados;
            selectedRows.data.map(selectedRow=> newStateValoresSelecionados = ({...newStateValoresSelecionados, [selectedRow.dataIndex]: null }))
            setValoresSelecionados(newStateValoresSelecionados);
            setSelectedRows([]);
        }

        const handleClickSugerirValores = (tipo) => {
            
            let newStateDescritivosSelecionados = descritivosSelecionados;
            let newStateEntidadesSelecionadas = entidadesSelecionadas;
            let newStateReferenciasSelecionadas = referenciasSelecionadas;
            let sugestaoDeDescritivo;
            let sugestaoDeEntidade;
            let sugestaoDeReferencia;

            let sugestoesEncontradas = 0
            let sugestoesNaoEncontradas = 0;
            let camposQueJaEstavamPreenchidos = 0;

            selectedRows.data = selectedRows.data.sort(sortArrayOfObjects("index")); //esta alteração permite que o sentido da repetição seja sempre para baixo (em relação ao estado das linhas como elas são apresentadas na tela)

            selectedRows.data.map((selectedRow, indexDaSelecao)=>{
                let dataIndex = selectedRow.dataIndex;
                const colunaArquivos = 0;
                let fileName = dados[dataIndex][colunaArquivos];
                let {data, descritivo, entidade} = parseFilename(fileName, dicionario); 
                
                if(tipo==='DESCRITIVOS' || tipo==='TODOS'){
                    if(!descritivo){
                        sugestoesNaoEncontradas++
                    } else if (descritivosSelecionados[dataIndex]) {
                        camposQueJaEstavamPreenchidos++
                    } else {
                        sugestoesEncontradas++
                        sugestaoDeDescritivo = descritivos.find(obj => obj.nomeCompleto === descritivo)
                        newStateDescritivosSelecionados = ({...newStateDescritivosSelecionados, [dataIndex]: sugestaoDeDescritivo})
                    }
                }
                
                if(tipo==='ENTIDADES'  || tipo==='TODOS'){
                    if(!entidade){
                        sugestoesNaoEncontradas++
                    } else if (entidadesSelecionadas[dataIndex]) {
                        camposQueJaEstavamPreenchidos++
                    } else {
                        sugestoesEncontradas++
                        sugestaoDeEntidade = entidades.find(obj => obj.apelido === entidade)
                        newStateEntidadesSelecionadas = ({...newStateEntidadesSelecionadas, [dataIndex]: sugestaoDeEntidade})
                    }                    
                }
                
                if(tipo==='REFERENCIAS'  || tipo==='TODOS'){
                    if(!data ){
                        sugestoesNaoEncontradas++
                    } else if (referenciasSelecionadas[dataIndex]) {
                        camposQueJaEstavamPreenchidos++                        
                    } else {
                        sugestoesEncontradas++
                        let dateString = (data.join('-'))+"T00:00:00" //Referência de porque é feito desta forma: https://stackoverflow.com/a/66821884/5091674
                        sugestaoDeReferencia = new Date(dateString)
                        newStateReferenciasSelecionadas = ({...newStateReferenciasSelecionadas, [dataIndex]: sugestaoDeReferencia})
                    }
                }
                return false;
            })
           
            if(tipo==='DESCRITIVOS' || tipo==='TODOS'){setDescritivosSelecionados(newStateDescritivosSelecionados);}
            if(tipo==='ENTIDADES'  || tipo==='TODOS'){setEntidadesSelecionadas(newStateEntidadesSelecionadas);}
            if(tipo==='REFERENCIAS'  || tipo==='TODOS'){setReferenciasSelecionadas(newStateReferenciasSelecionadas);}
           
            function exibirMensagemFinal(){
                alert(
                    `${sugestoesEncontradas+sugestoesNaoEncontradas+camposQueJaEstavamPreenchidos} campo(s) processado(s): \n` +
                    `-${sugestoesEncontradas} preenchido(s) com sugestão(ões). \n` +
                    `-${sugestoesNaoEncontradas} sem sugestão(ões) \n` +
                    `-${camposQueJaEstavamPreenchidos} não alterado(s) por já possuir(em) valor(es). \n\n` +
                    `Favor, revisar o(s) valor(es) preenchido(s).`
                )
            }
           
            //o setTimeout faz com que a mensagem só seja exibida depois que a página é corretamente renderizada
            setTimeout(exibirMensagemFinal); 
        }        

        return(
            <CustomToolbarSelect 
                handleClickSugerirValores={handleClickSugerirValores}
                handleClickRepetirValores={handleClickRepetirValores}
                handleClickDeletarValores={handleClickDeletarValores}            
            />
        )
    }

    //Gerenciar handles
    const handleChangeValoresSelecionados = (dataIndex, setValoresSelecionados, valoresSelecionados) => {
        return (event, newValue) => {      
            setValoresSelecionados({...valoresSelecionados, [dataIndex]: newValue }); 
        }; 
    } 

    //Gerenciar handles
    const handleBlurReferenciasSelecionadas = (dataIndex) => { 
        //Referência: https://stackoverflow.com/a/63873596/5091674
        return (event) => {  
            if(event.target.value){
                let dateString = (event.target.value).replaceAll(".", "-")+"T00:00:00" //obs: concantenar com "T00:00:00" evita um "bug" bem indejável do JavaScript, mais explicações neste link: https://stackoverflow.com/a/66821884/5091674
                let date = new Date(dateString)
                setReferenciasSelecionadas({...referenciasSelecionadas, [dataIndex]: date}) 
            } else {
                setReferenciasSelecionadas({...referenciasSelecionadas, [dataIndex]: null})
            }
        }; 
    }   

    //Gerenciar handles
    const handleAcceptReferenciasSelecionadas = (dataIndex) => {
        //Referência: https://stackoverflow.com/a/63873596/5091674
        return  (date) => {   
            setReferenciasSelecionadas({...referenciasSelecionadas, [dataIndex]: date }); 
        }
    }    

    //colunas
    const columns = [
        {label: 'Nome Do Arquivo', name: 'nomeDoArquivo', 
            options: {filter: false, sort: true}
        },
        {label: 'Relatório', name: 'descritivo',
            options: {
                sort: false, 
                filter: false, 
                searchable: false, 
                customBodyRenderLite: (dataIndex, rowIndex)  => {
                    return(
                        <ComboBox
                            id={dataIndex}                            
                            options={descritivos}
                            optionLabelKey={'nomeCompleto'}
                            groupByKey={'tema'}
                            minWidth={'350px'}
                            tabIndex={1}
                            value={descritivosSelecionados[dataIndex]}
                            onChange={handleChangeValoresSelecionados(dataIndex, setDescritivosSelecionados, descritivosSelecionados)}
                            required={true}
                        />
                )}
        }},
        {label: 'Entidade', name: 'entidade',
            options: {
                sort: false, 
                filter: false, 
                searchable: false, 
                customBodyRenderLite: (dataIndex, rowIndex)  => {
                    return(
                        <ComboBox
                            id={dataIndex}
                            options={entidades}
                            optionLabelKey={'apelido'}
                            groupByKey={'tipo'}
                            minWidth={'250px'}
                            tabIndex={2}
                            value={entidadesSelecionadas[dataIndex]}
                            onChange={handleChangeValoresSelecionados(dataIndex, setEntidadesSelecionadas, entidadesSelecionadas)}
                            required={true}
                        />                                   
                )}
        }},        
        {label: 'Data de Referência', name: 'referencia',
            options: {
                sort: false, 
                filter: false, 
                searchable: false, 
                customBodyRenderLite: (dataIndex, rowIndex)  => {
                    return(
                        <div style={{minWidth: "200px"}}>   {/*//TODO: depois destinar esse inline style para um lugar adequado (e na verdade precisa analisar também se  a largura das colunas será feita desta foram aqui)*/}
                            <DatePickers
                                //Referência: https://stackoverflow.com/a/63873596/5091674
                                tipo = {frequencias[dataIndex] || "DIARIO"}
                                tipoDeFormato = "DATADEREFERENCIA"
                                tabIndex={3}
                                value={referenciasSelecionadas[dataIndex]}
                                onChange={()=>undefined} //Since onChange is mandatory returning undefined in the callback solves the issue but breaks the datePicker select option (does not apply the selected date to the input field).
                                onAccept={handleAcceptReferenciasSelecionadas(dataIndex)} //Because of the above, onAccept should be used thereby internal state logic can be omitted which is crucial.
                                onBlur={handleBlurReferenciasSelecionadas(dataIndex)} //Onblur fires when a field loses focus, while onchange fires when that field's value changes.
                                labelAPartirDoTipo
                                required={true}
                            />                        
                        </div>
                    )
                }
            }
        }
    ]

    const handleSubmit = (event) => {
        event.preventDefault();
        function datasSaoValidas(){
            /* //TODO: futuramente ver se há uma forma melhor de controlar o 
                submit de data não válidas ou mantenho da forma como está*/  
            for (var i = 0; i < dados.length; i++) {
                try{
                    if(referenciasSelecionadas[i]){
                        // eslint-disable-next-line no-unused-vars
                        let teste = format(referenciasSelecionadas[i],'yyyy.MM.dd');
                    }
                } catch(err){
                    return false;
                }
            }
            return true;
        }
        function criarDocumentosParaRevisar() {
            let listaTemporaria = {};
            dados.map(
                (dado, index) => {
                    let file = files[index];
                    let chaveUnica = 
                        `${descritivosSelecionados[index]?.nomeCompleto} - ` +
                        `${entidadesSelecionadas[index]?.apelido} - ` +
                        `${referenciasSelecionadas[index] ? format(referenciasSelecionadas[index],'yyyy.MM.dd') : ("")}`                    
                    if(!listaTemporaria[chaveUnica]){ 
                        const ano = referenciasSelecionadas[index] ? format(referenciasSelecionadas[index],'yyyy') : null;
                        const mes = referenciasSelecionadas[index] ? format(referenciasSelecionadas[index],'MM') : null;
                        const dia = referenciasSelecionadas[index] ? format(referenciasSelecionadas[index],'dd') : null;
                        const dataConvertida = converterData({ano, mes, dia}, frequencias[index]); 
                        listaTemporaria = {
                            ...listaTemporaria, 
                            [chaveUnica]: { 
                                'chaveUnica': chaveUnica, 
                                'dados' : {
                                    tema: descritivosSelecionados[index]?.tema,
                                    descritivoId: descritivosSelecionados[index]?.id,
                                    descritivoCompleto: descritivosSelecionados[index]?.nomeCompleto,
                                    entidadeId: entidadesSelecionadas[index]?.id,
                                    entidade: entidadesSelecionadas[index]?.apelido,
                                    ano: dataConvertida?.ano,
                                    mes: dataConvertida?.mes,
                                    dia: dataConvertida?.dia
                                },
                                'files': [file]
                            }}
                    } else {
                        listaTemporaria[chaveUnica]['files'].push(file) 
                    }
                    return false;
                }
            )
            //transforma a lista temporária de "objeto de objetos" para "array de objetos"
            let listaDeArrays = (Object.keys(listaTemporaria)).map(key =>listaTemporaria[key])
            //ordena lista temporária por chave única (ou seja, na ordem descritivo -> entidade -> data de referência)
            let listaOrdenada = listaDeArrays.sort(sortArrayOfObjects("chaveUnica"));
            //transforma a lista temporária em definitiva
            let documentosParaRevisar = listaOrdenada;
            //cria o state de documentosParaRevisar
            setDocumentosParaRevisar(documentosParaRevisar);
        }
        if(!datasSaoValidas()){
            alert('Verificar Formato de Data Inválido');
            return false;
        };
        criarDocumentosParaRevisar();
        return setTimeout(avancarEtapa); /*Avançar etapa somente depois da 
        re-renderização da página, por isso o setTimeout*/
    };     

    /*-----------------------------------------------------------------------------------------------------------------------------------*/ 
    // 3. Renderização
    /*-----------------------------------------------------------------------------------------------------------------------------------*/     

    return (
        <div id="step2">
            <>{(entidadesIsLoading || descritivosIsLoading || dicionarioIsLoading) ? (
                <Loader />
            ) : (<>            
                <form onSubmit={handleSubmit}>
                    <MUIDataTable
                        title={'Classificar os Arquivos'}
                        columns={columns}
                        data={dados}
                        options={{
                            elevation: 2,
                            responsive: "simple",
                            print: false,
                            download: false,
                            viewColumns: false,
                            search: true,
                            filter: false, 
                            filterType: "multiselect",                 
                            pagination: false,
                            selectableRows: 'multiple',
                            customToolbarSelect: CustomToolbarSelectCallback,
                            textLabels: textLabels
                        }}            
                    />
                    <br />
                    <div className="stepperButtons">
                        <Button className="backButton" onClick={handleCancelar}>
                            Cancelar
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            Avançar
                        </Button>
                    </div>
                </form>
            </>)}</>            
        </div>
    ) 
}


const mapStateToProps = (state) => ({
    //entidades
    entidades: state.entidades.entidades,
    entidadesIsLoading: state.entidades.isLoading,
    //descritivos
    descritivos: state.descritivos.descritivos,
    descritivosIsLoading: state.descritivos.isLoading,
    //dicionario
    dicionarioDeIds: state.dicionario.dicionario,
    dicionarioIsLoading: state.dicionario.isLoading    
});

export default connect(mapStateToProps, {buscarEntidades, buscarDescritivos, 
    buscarDicionario})(Step2)