import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
//...
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RestoreFromTrashOutlinedIcon from '@material-ui/icons/RestoreFromTrashOutlined';
import UndoIcon from '@material-ui/icons/Undo';
//...
import {connect} from "react-redux";
import {removerParametro, desativarParametro, reativarParametro} from 
  '../../../../../redux/actionsAdmin/parametroActions'
import {removerRegraDeNotificacao} from 
  '../../../../../redux/actionsAdmin/notificacoesActions'
import {concederOuRetirarAdministrador} from 
  '../../../../../redux/actionsAdmin/administradoresActions'  
import {desativarVersaoDeDocumento} from 
  '../../../../../redux/actionsAdmin/documentosActions'  
//...
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';  

const defaultToolbarSelectStyles = {
  iconButton: {},
  iconContainer: {
    marginRight: "1.5rem",
  },
};


const ToolbarSelectParametro = ({ 
  //...
  removerParametro, desativarParametro, reativarParametro, 
  removerRegraDeNotificacao, concederOuRetirarAdministrador,  
  desativarVersaoDeDocumento,
  //...
  classes, auth,
  //...
  selectedRows, displayData, setSelectedRows, 
  //...
  colunas, tipoDeParametro, handleClickOpenDialog, 
  setIsEdit, setDadosIniciaisDaEdicao, parametros, 
  configuracoes, desativados
}) => {

  function getParametroId(){
    //colunaIdIndex
    const colunaIdIndex = colunas.findIndex(o => o.name === "id");
    //dadosDoParametro
    const dataIndex = selectedRows?.data[0]?.dataIndex;
    const displayDataIndex = displayData.findIndex(o => o.dataIndex === dataIndex);
    const parametroDisplayData = displayData[displayDataIndex].data;
    const parametroId = parametroDisplayData[colunaIdIndex];
    //return
    return parametroId;
  }

  function getParametro () {
    //id
    const parametroId = getParametroId();
    //checa se o id é 'undefined' or 'null'?
    if (parametroId == null) return null;
    //parametro
    const parametro = parametros.find(p => p.id === parametroId);
    return parametro;
  }

  
  function handleDeleteActions({tipoDeAcao}){ //TODO: ver se estas linhas estão ok!!!
    return (e) => {
      e.preventDefault(); //TODO: ver se estas linhas estão ok!!!
      const textoDeConfirmacao = `Tem certeza que deseja` + 
        `${tipoDeAcao === "REMOVER" ? " remover" : ""}`  +
        `${tipoDeAcao === "DESATIVAR" ? " desativar" : ""}`  +
        `${tipoDeAcao === "REATIVAR" ? " reativar" : ""}`  +
        ` o ${configuracoes.titulo.noSingular} selecionado(a)?` + 
        `${tipoDeAcao === "REMOVER" ? " (esta ação não poderá ser desfeita)": ""}`
      if(window.confirm(textoDeConfirmacao)){
        const dados = {tipo: tipoDeParametro, id: getParametro()["id"]}; 
        switch (tipoDeAcao) {
          case 'REMOVER':
            if(tipoDeParametro === "NOTIFICACOES"){ 
              const parametro = getParametro();
              const dados = {dados: parametro};
              removerRegraDeNotificacao(dados);
            } else {
              removerParametro(dados)
            }
            break;
          case 'DESATIVAR':
            desativarParametro(dados)
            break;
          case 'REATIVAR':
            reativarParametro(dados)
            break;            
          default:
        }
        return setSelectedRows([]); //Após o delete a seleção de linhas é cancelada, melhorando a UX
      } else {
        return alert('Nenhuma modificação foi feita.');
      }
    }
  }

  //TODO: ?refatorar abaixo?
  function handleEditarParametro(e){
    e.preventDefault();
    setDadosIniciaisDaEdicao(getParametro());
    setIsEdit(true);
    if(tipoDeParametro === "SUGESTOES") return (
      handleClickOpenDialog("DialogSugestoes"));
    return handleClickOpenDialog("DialogParametro");
  }

  //TODO: ver se função abaixo precisa de refatoração
  function handleRetirarAdministrador(e){ //TODO: ver se estas linhas estão ok!!!
    e.preventDefault(); //TODO: ver se estas linhas estão ok!!!
    const textoDeConfirmacao = `Tem certeza que deseja` + 
      ` retirar o privilégio de Administrador para o usuário selecionado?`
    if(window.confirm(textoDeConfirmacao)){
      const id = getParametroId();
      if (id === auth.me.id) {
        return alert('Você não pode retirar o Administrador de si mesmo!');
      }
      concederOuRetirarAdministrador({id, concederAdm: false});
      return setSelectedRows([]); //Após o delete a seleção de linhas é cancelada, melhorando a UX
    } else {
      return alert('Nenhuma modificação foi feita.');
    }
  }  
  

  //TODO: ver se função abaixo precisa de refatoração
  function handleDesativarVersaoMaisRecenteDeDocumento(e){ 
    e.preventDefault();
    const id = getParametroId();
    const parametro = getParametro();
    const numeroVersao = parametro.numero_versao_mais_recente;
    const textoDeConfirmacao = `Tem certeza que deseja` + 
    	` desativar a versão mais recente para o documento delecionado?`
    if(window.confirm(textoDeConfirmacao)){
      if(numeroVersao > 1){
        return desativarVersaoDeDocumento({id, numeroVersao});
      } else {
        const textoDeConfirmacao2 = 
          `O documento selecionado só possui esta versão.` +
          ` Caso você continue, o próprio documento será desativado também.` +
          ` Deseja continuar?`
        if(window.confirm(textoDeConfirmacao2)){
          return desativarParametro({id, tipo: tipoDeParametro})
        }
      }
    } 
    return alert('Nenhuma modificação foi feita.');
  }  
  
  return (
    <div className={classes.iconContainer}>
      {configuracoes.metodos.podeSerEditado ? (
        <Tooltip title={`Editar ${configuracoes.titulo.noSingular}`}>
          <IconButton 
            className={classes.iconButton} 
            onClick={handleEditarParametro}
          >
            <EditIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      ) : null}
      {configuracoes.metodos.podeSerRemovido && !desativados ? (
        <Tooltip title={`Remover ${configuracoes.titulo.noSingular}`}>
          <IconButton 
            className={classes.iconButton} 
            onClick={handleDeleteActions({tipoDeAcao: "REMOVER"})}
          >
            <DeleteIcon  className={classes.icon} />
          </IconButton>
        </Tooltip>
      ) : (
        null)}      
      {configuracoes.metodos.podeSerDesativado ? (
        desativados ? (
          configuracoes.metodos.podeSerReativado ? (
            <Tooltip title={`Reativar ${configuracoes.titulo.noSingular}`}>
              <IconButton 
                className={classes.iconButton} 
                onClick={handleDeleteActions({tipoDeAcao: "REATIVAR"})}
              >
                <RestoreFromTrashOutlinedIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          ) : null
        ) : (
          <Tooltip title={`Desativar ${configuracoes.titulo.noSingular}`}>
            <IconButton
              className={classes.iconButton}
              onClick={handleDeleteActions({tipoDeAcao: "DESATIVAR"})}
            >
              <DeleteOutlineIcon  className={classes.icon} />
            </IconButton>
          </Tooltip>
        )
      ) : (
        null)}
      {configuracoes.metodos.podeRetirarAdministrador && !desativados ? (
        <Tooltip title={`Retirar Administrador`}>
          <IconButton 
            className={classes.iconButton} 
            onClick={handleRetirarAdministrador}
          >
            <PersonAddDisabledIcon  className={classes.icon} />
          </IconButton>
        </Tooltip>
        ) : (
          null)}
      {configuracoes.metodos.podeDesativarVersaoMaisRecenteDeDocumento 
        && !desativados ? (
        <Tooltip title={`Desativar a Versão mais recente do Documento` + 
          ` Selecionado`}>
          <IconButton 
            className={classes.iconButton} 
            onClick={handleDesativarVersaoMaisRecenteDeDocumento}
          >
            <UndoIcon  className={classes.icon} />
          </IconButton>
        </Tooltip>
        ) : (
          null)}          
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  removerParametro, desativarParametro, reativarParametro, 
  removerRegraDeNotificacao, concederOuRetirarAdministrador,
  desativarVersaoDeDocumento
})(
  withStyles(defaultToolbarSelectStyles)(ToolbarSelectParametro)
);
