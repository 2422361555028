import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LunarDialogTitle from 
  '../../../../components/LunarDialogTitle/LunarDialogTitle'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';


import TextField from "@material-ui/core/TextField";

import LinearProgress from '@material-ui/core/LinearProgress';


function DialogSimpleCrud({
  title, itens, onSubmit, onRemover, open, onClose, 
  loadingString = "Carregando...", minLength = 3
}) {

  const [valor, setValor] = React.useState(""); 
  const handleChange = (event) => setValor(event.target.value);
  const handleClose = () => {
    if(someItemIsLoading()){return false};
    onClose();
    return setValor("");
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(valor);
    return setValor("");
  }

  const handleRemover = (valor) => {
    return ((event) => {
      event.preventDefault();
      onRemover(valor);
    })
  }

  const someItemIsLoading = () => {
    return itens.find(item => item === loadingString);
  }  

  return(
    <Dialog 
      open={open}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <LunarDialogTitle onClose={handleClose}>{title}</LunarDialogTitle>
        <DialogContent>
          <List>
            {itens.map(item => (
              <ListItem>
                {item === loadingString ? (
                  <ListItem style={{paddingLeft: 0}}>
                    <LinearProgress style={{width: "100%"}}/>
                  </ListItem>
                ) : (
                  <ListItemText primary={item} />
                )}
                <ListItemSecondaryAction>
                  <Tooltip title='Remover'>
                    <IconButton edge="end" 
                      onClick={handleRemover(item)}
                      disabled = {someItemIsLoading()} 
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <ListItem >
              <TextField 
                placeholder={"Criar Novo"}
                value={valor}
                onChange={handleChange}
                autoFocus
                required
                disabled = {someItemIsLoading()}   
                fullWidth
                inputProps={{minLength}}
              />
              <ListItemSecondaryAction>
                <Tooltip title="Adicionar">
                  <IconButton  edge="end" type="submit" 
                    disabled = {someItemIsLoading()} 
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>            
            </ListItem>
          </List>
        </DialogContent>
      </form>
    </Dialog>
  )

}

export default (DialogSimpleCrud)