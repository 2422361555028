const tipos = {
    ENTIDADES_CANAIS_DE_DISTRIBUICAO: "Canais de Distribuição",
    ENTIDADES_TIPOS: "Tipos de Entidade",
    ENTIDADES_SUBTIPOS: "Subtipos de Entidade",
    ENTIDADES_QUALIFICACOES_DO_INVESTIDOR: "Qualificações do Investidor",
    TIPOS_DE_DOCUMENTO_FREQUENCIAS: "Frequências",
}

const {
    ENTIDADES_CANAIS_DE_DISTRIBUICAO, 
    ENTIDADES_TIPOS, 
    ENTIDADES_SUBTIPOS, 
    ENTIDADES_QUALIFICACOES_DO_INVESTIDOR, 
    TIPOS_DE_DOCUMENTO_FREQUENCIAS, 
} = tipos


const urls = {
    [ENTIDADES_CANAIS_DE_DISTRIBUICAO]: 
        '/api/administradores/entidades/parametros/canais-de-distribuicao',
    [ENTIDADES_TIPOS]: 
        '/api/administradores/entidades/parametros/tipos-de-entidade',
    [ENTIDADES_SUBTIPOS]: 
        '/api/administradores/entidades/parametros/subtipos',
    [ENTIDADES_QUALIFICACOES_DO_INVESTIDOR]: 
        '/api/administradores/entidades/parametros/qualificacoes',
    [TIPOS_DE_DOCUMENTO_FREQUENCIAS]: 
        '/api/administradores/tipos-de-documento/frequencias',
};  
  
  
export {
    tipos,
    urls
}