import {
    BUSCAR_PARAMETROS_LOADING, 
    BUSCAR_PARAMETROS_SUCCESS, 
    BUSCAR_PARAMETROS_FAIL,
    CRIAR_PARAMETRO_LOADING, 
    CRIAR_PARAMETRO_SUCCESS, 
    CRIAR_PARAMETRO_FAIL,
    DESATIVAR_PARAMETRO_LOADING, 
    DESATIVAR_PARAMETRO_SUCCESS, 
    DESATIVAR_PARAMETRO_FAIL,    
    REATIVAR_PARAMETRO_LOADING, 
    REATIVAR_PARAMETRO_SUCCESS, 
    REATIVAR_PARAMETRO_FAIL,      
    REMOVER_PARAMETRO_LOADING, 
    REMOVER_PARAMETRO_SUCCESS, 
    REMOVER_PARAMETRO_FAIL,
    EDITAR_PARAMETRO_LOADING,
    EDITAR_PARAMETRO_SUCCESS,
    EDITAR_PARAMETRO_FAIL,
    LIMPAR_ERRO_DE_PARAMETRO,
} from '../actionTypesAdmin';

const initialState = {
    //Default
    parametros: [], // (array: [{ ...parametros, isLoading, error } , ... ])
    isLoading: false,
    error: null, // (objeto: { title, message })
    //Específicos
    tipo: null, // (string)
    colunas: [],
    configuracoes: []
};

const parametros = (state = initialState, { type, payload }) => {
    switch (type) {
        //LOADING
        case BUSCAR_PARAMETROS_LOADING:            
            return {
                ...state,
                isLoading: true,
                tipo: payload.tipo,
            }    
        case CRIAR_PARAMETRO_LOADING: 
            return {
                ...state,
                parametros: [
                    {
                        id: 0, 
                        isLoading: true
                    },
                    ...state.parametros
                ],
            };
        case REMOVER_PARAMETRO_LOADING:            
        case EDITAR_PARAMETRO_LOADING: 
        case DESATIVAR_PARAMETRO_LOADING: 
        case REATIVAR_PARAMETRO_LOADING:
            return {
                ...state,
                parametros: state.parametros.map((p) => {
                    if (p.id === payload.id) return {...p, isLoading: true};
                    return p;
                })
            }                 
        //SUCCESS
        case BUSCAR_PARAMETROS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                parametros: payload.parametros,
                colunas: payload.colunas,
                configuracoes: payload.configuracoes,
            }         
        case CRIAR_PARAMETRO_SUCCESS:
            return {
                ...state,
                parametros: state.parametros.map((p) => {
                    if(p.id === 0) return payload.parametro;
                    return p;
                })
            };
        case REMOVER_PARAMETRO_SUCCESS:
        case DESATIVAR_PARAMETRO_SUCCESS:
        case REATIVAR_PARAMETRO_SUCCESS:
            return {
                ...state,
                parametros: state.parametros.filter((p) => p.id !== payload.id)
            }           
        case EDITAR_PARAMETRO_SUCCESS:
            return {
                ...state,
                parametros: state.parametros.map((p) => {
                    if (p.id === payload.parametro.id) return payload.parametro;
                    return p
                })
            }   
        //FAIL
        case REMOVER_PARAMETRO_FAIL: 
        case EDITAR_PARAMETRO_FAIL:
        case DESATIVAR_PARAMETRO_FAIL:
        case REATIVAR_PARAMETRO_FAIL:
            return {
                ...state,
                error: null,
                parametros: state.parametros.map((p) => {
                    if (p.id === payload.id) return {
                        ...p, isLoading: false, error: payload.error};
                    return p;
                })
            }            
        case BUSCAR_PARAMETROS_FAIL:
            return {
                ...state,
                isLoading: false,
                error: payload.error                        
            }        
        case CRIAR_PARAMETRO_FAIL: 
            return {
                ...state,
                isLoading: false,
                error: payload.error,
                parametros: state.parametros.filter((p)=>p.id !== 0),
            }
        case LIMPAR_ERRO_DE_PARAMETRO: { //TODO
            return {
                ...state,
            }               
        }
        default:
            return state;
    }
}

export default parametros