import React from 'react';
import DialogSimpleCrud from './DialogSimpleCrud';
import {connect} from "react-redux";
import {adicionarChaveUnica, removerChaveUnica} from 
  '../../../../redux/actionsAdmin/sugestoesActions';
import {definirSubParametros} from 
  '../../../../redux/actionsAdmin/sugestoesActions';
import {buscarParametros} from '../../../../redux/actionsAdmin/parametroActions'

function DialogSugestoes({
  openDialogName, setOpen, dadosIniciaisDaEdicao, adicionarChaveUnica, 
  removerChaveUnica, subParametros, subParametrosIsLoading, definirSubParametros
}) {

  //TODO: fazer a parte do subParametrosIsLoading

  React.useEffect(()=>{
    const chavesUnicas = dadosIniciaisDaEdicao ? 
      dadosIniciaisDaEdicao['chavesUnicas'].map(chave => chave.valor) : 
      [];
    definirSubParametros({chavesUnicas});
  }, [definirSubParametros, dadosIniciaisDaEdicao]);

  const parametro = dadosIniciaisDaEdicao;

  const open = openDialogName === "DialogSugestoes";
  const title = "Editar chaves únicas de '" + parametro?.parametroNome + "'";

  const onSubmit = (chaveUnica) => {
    adicionarChaveUnica({parametro, chaveUnica});
  }

  const onRemover = (chaveUnica) => {
    removerChaveUnica({parametro, chaveUnica});
  }

  const onClose = () => {
    setOpen(false)
  };

  return(
    <DialogSimpleCrud
      open = {open}
      setOpen = {setOpen}
      title={title}
      itens={subParametros}
      onSubmit={onSubmit}
      onRemover={onRemover}
      onClose={onClose}
    />
  )
}

const mapStateToProps = (state) => ({
  subParametros: state.adminSubParametro.subParametros,
  subParametrosIsLoading: state.adminSubParametro.isLoading,
});


export default connect(mapStateToProps,  {adicionarChaveUnica, removerChaveUnica, definirSubParametros, buscarParametros})(
  DialogSugestoes);