import './Preferencias.css'
import {useState, useEffect} from 'react';
import Layout from '../../layout/Layout'
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';    
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import CheckboxesTags from '../../components/CheckboxesTags/CheckboxesTags'
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";

//Ícones
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import CloseIcon from '@material-ui/icons/Close';

//...
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//...
import Chip from '@material-ui/core/Chip';

//...
import {connect} from "react-redux";
import {buscarTiposDeDocumento} from '../../redux/actions/documentoActions'
import {buscarEntidades} from '../../redux/actions/entidadeActions'
import {
  buscarRegrasDeNotificacao, removerRegraDeNotificacao, 
  criarRegraDeNotificacao, editarRegraDeNotificacao
} from '../../redux/actions/usuarioActions'

import Loader from '../../components/Loader/Loader'

import criarRegraGif from '../../support/images/exemplos/criarRegra.gif';

const textoSobreCriarRegra = "Para criar uma regra que seja válida para todos relatórios (entidades), basta não selecionar nenhum relatório (entidade). Clique para visualizar um exemplo";

const Preferencias = ({
  removerRegraDeNotificacao, criarRegraDeNotificacao, editarRegraDeNotificacao,
  buscarRegrasDeNotificacao, regrasBuscadas, regrasBuscadasLoading, 
  buscarTiposDeDocumento, tiposDeDocumentoBuscados, tiposDeDocumentoIsLoading, 
  buscarEntidades, entidadesBuscadas, entidadesIsLoading, 
  criacaoIsLoading, remocaoIsLoading
}) => { 

  useEffect(()=>{
      buscarTiposDeDocumento();  
      buscarEntidades(); 
      buscarRegrasDeNotificacao();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
      function definirRegrasIniciais(){
          if(regrasBuscadas.length){
            const regrasIniciais = ajustarRegrasBuscadas(
              regrasBuscadas, setContadorDeRegras
            );
            setRegras(regrasIniciais)
          }
      }
      definirRegrasIniciais();
  },[regrasBuscadas])

  //Inicializar States
  const [regras, setRegras] = useState([]);  
  const [contadorDeRegras, setContadorDeRegras] = useState(1);
  //States Dialog
  const [dialogIdentityDaRegra, setDialogIdentityDaRegra] = useState(null);
  // const [dialogNomeDaRegra, setDialogNomeDaRegra] = useState("");
  const [dialogNomeDaRegraOld, setDialogNomeDaRegraOld] = useState(""); //TODO: alterar estes nomes, substituindo o 1 e o 2
  const [dialogNomeDaRegraNew, setDialogNomeDaRegraNew] = useState(""); //TODO: alterar estes nomes, substituindo o 1 e o 2
  const [dialogEntidades, setDialogEntidades] = useState([]);
  const [dialogTiposDeDocumento, setDialogTiposDeDocumento] = useState([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [dialogIsForEditing, setDialogIsForEditing] = useState(false);
  const [criarRegraAjudaDialog, setCriarRegraAjudaDialog] = useState(false);
  
  //acompanhar mudanças
    //TODO: completar os console.log aqui em "acompanhar mudanças"
  useEffect(()=>{
    console.log("regras", regras)
  },[regras])    
  

  //================================================
  //Funções de apoio
  //================================================
  function ajustarRegrasBuscadas(regrasBuscadas, setContadorDeRegras){
    let contadorDeId = 1;
    const regrasAjustadas = {}; /*Objetos de objetos que será convertido para 
    array de objetos*/
    regrasBuscadas.map(regraBuscada => {
        //Cria nova regra se não existe
        if(!regrasAjustadas[regraBuscada.nome]){
            regrasAjustadas[regraBuscada.nome] = {
                identity: contadorDeId,
                nome: regraBuscada.nome,
                tipo: regraBuscada.tipo,
                tiposDeDocumentoSelecionados: [],
                entidadesSelecionadas: [],
            }
            contadorDeId++;
        }
        //Insere tipo de documento (se ele existe e ainda não foi inserido)
        if (regraBuscada.tipoDeDocumentoId &&
          !regrasAjustadas[regraBuscada.nome].tiposDeDocumentoSelecionados
            .some(o => o.id === regraBuscada.tipoDeDocumentoId)
        ){
          regrasAjustadas[regraBuscada.nome].tiposDeDocumentoSelecionados.push(
            {
              id: regraBuscada.tipoDeDocumentoId,
              nome: regraBuscada.tipoDeDocumento,
            }
          );
        }
        //Insere entidade (se ela existe e ainda não foi inserida)
        if (regraBuscada.entidadeId &&
          !regrasAjustadas[regraBuscada.nome].entidadesSelecionadas
            .some(o => o.id === regraBuscada.entidadeId) 
        ){
          regrasAjustadas[regraBuscada.nome].entidadesSelecionadas.push(
            {
              id: regraBuscada.entidadeId,
              apelido: regraBuscada.entidade,
            }
          );
        }
        return false;
    })
    //transforma regras finais de objeto de objeto para array
    const regrasAjustadasArray = Object.keys(regrasAjustadas).map(key => {
        return regrasAjustadas[key];
    })
    //atualizar o contadorDeRegras
    setContadorDeRegras(contadorDeId);
    //retorno
    return regrasAjustadasArray;
  }


  const handleCloseDialog = () => {
      return setDialogIsOpen(false);
  };

  const handleClickOpenDialog = (regra, isForEditing) => {
      setDialogIdentityDaRegra(regra?.identity || null);
      setDialogNomeDaRegraOld(regra?.nome || "");
      setDialogNomeDaRegraNew(regra?.nome || "");
      setDialogEntidades(regra?.entidadesSelecionadas || []);
      setDialogTiposDeDocumento(regra?.tiposDeDocumentoSelecionados || []);
      setDialogIsForEditing(isForEditing);
      return setDialogIsOpen(true);
  };

  const handleChangeTiposDeDocumento = (event, values) => setDialogTiposDeDocumento(values)
  const handleChangeEntidades = (event, values) => setDialogEntidades(values)
  const handleChangeNomeDaRegra = (event) => setDialogNomeDaRegraNew(event.target.value)
  
  const validarRegraAntesDeCriacaoOuEdicao = () => {
    if(!dialogEntidades?.length && !dialogTiposDeDocumento?.length){
      alert("Selecione pelo menos um Relatório ou pelo menos uma Entidade. " + 
        "No momento, você não está selecionando nada.");
      return false;
    }
    return true;
  }

  const handleClickCriarNovaRegra = (event) => {
    //Criar a regra no servidor
    criarRegraDeNotificacao({
      nomeDaRegra: dialogNomeDaRegraNew, 
      tiposDeDocumento: dialogTiposDeDocumento.map(o => o.id),
      entidades: dialogEntidades.map(o => o.id), 
    }); /*TODO: editar aqui para colocar 
    loading, tratamento de erro e tudo mais necessário para o correto 
    tratamento com o servidor*/ 
    event.preventDefault(); //evitar o reload da página após o submit
    if(!validarRegraAntesDeCriacaoOuEdicao()){return false};

    const novaIdentity = contadorDeRegras;

    const novaRegra =  {
      identity : novaIdentity,
      nome: dialogNomeDaRegraNew,
      tiposDeDocumentoSelecionados: dialogTiposDeDocumento,
      entidadesSelecionadas: dialogEntidades
    }

    setDialogIdentityDaRegra(novaIdentity);
    setContadorDeRegras(contadorDeRegras + 1);
    setRegras([...regras, novaRegra]);
    return handleCloseDialog();
  }


  const handleClickEditarRegra = (event) => {
    event.preventDefault(); //evitar o reload da página após o submit
    //Editar a regra no servidor
    
    if(!validarRegraAntesDeCriacaoOuEdicao()){return false};

    editarRegraDeNotificacao({
      nomeDaRegraOld: dialogNomeDaRegraOld,
      nomeDaRegraNew: dialogNomeDaRegraNew, 
      tiposDeDocumento: dialogTiposDeDocumento.map(o => o.id),
      entidades: dialogEntidades.map(o => o.id), 
    }); /*TODO: editar aqui para colocar 
    loading, tratamento de erro e tudo mais necessário para o correto 
    tratamento com o servidor*/
    
    const novaRegra =  {
      identity : dialogIdentityDaRegra,
      nome: dialogNomeDaRegraNew,
      tiposDeDocumentoSelecionados: dialogTiposDeDocumento,
      entidadesSelecionadas: dialogEntidades
    }

    //acha o index
    const indexDaRegra = regras.findIndex((obj => obj.identity === dialogIdentityDaRegra));
    console.log("indexDaRegra", indexDaRegra);

    let novasRegras = [...regras];
    novasRegras[indexDaRegra] = novaRegra;

    //Finaliza
    setRegras([...novasRegras]);
    return handleCloseDialog();
  }


  const handleClickDelete = (regra) => {
    //acha o index
    const indexDaRegra = regras.findIndex((obj => obj.identity === regra.identity));
    console.log("indexDaRegra", indexDaRegra);
    if(window.confirm(
      `Você tem certeza que deseja deletar esta regra? `+ 
      `(esta ação é irreversível)`
    )){
      //remove a regra no servidor
      removerRegraDeNotificacao(regra.nome); /*TODO: editar aqui para colocar 
      loading, tratamento de erro e tudo mais necessário para o correto 
      tratamento com o servidor*/       
      //remove da interface
      let novasRegras = [...regras];
      novasRegras.splice(indexDaRegra, 1);
      //Finaliza
      setDialogIdentityDaRegra(regra.identity);
      setRegras([...novasRegras]);
    } else {
        alert('Nenhuma modificação foi feita.')
    }   
  }  


  return (<>{
    (regrasBuscadasLoading || tiposDeDocumentoIsLoading || entidadesIsLoading)?(
        <Layout pageTitle="Carregando...">
            <Loader mensagem={""}/>
        </Layout>
    ) : (
      <Layout pageTitle="Preferências de Notificação"><div id="preferencias">
        <Dialog 
          disableBackdropClick 
          fullWidth={true} 
          maxWidth={'md'} 
          onClose={handleCloseDialog} 
          open={dialogIsOpen}
        >
          <Dialog
            maxWidth={'lg'}
            onClose={() => setCriarRegraAjudaDialog(false)}
            open={criarRegraAjudaDialog}
          >
            <img src={criarRegraGif} alt="Gif com instruções de ajuda" />
          </Dialog>
          <form onSubmit={dialogIsForEditing ? (handleClickEditarRegra) : (handleClickCriarNovaRegra)} >
            <DialogTitle id="form-dialog-title">
              <div style={{display: "flex", alignItems: "center", justifyContent: 'space-between'}}>
                <strong>{dialogIsForEditing ? "Editar Regra" : "Criar Nova Regra"}</strong>
                <div>
                  <Tooltip title={textoSobreCriarRegra}>
                    <IconButton 
                        onClick={()=>setCriarRegraAjudaDialog(!criarRegraAjudaDialog)}
                    >
                        <HelpIcon />
                    </IconButton>
                  </Tooltip>       
                  <IconButton onClick={handleCloseDialog}>
                    <CloseIcon fontSize="small"/>
                  </IconButton>
                </div>
              </div>
            </DialogTitle>              
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                fullWidth
                placeholder={"Escreva um título para a Regra de Notificação"}
                value={dialogNomeDaRegraNew}
                onChange={handleChangeNomeDaRegra}
                // multiline
                variant="outlined"
                inputProps={{
                  minLength: 3,
                  maxLength: 40, /*TODO: ver se o tamanho máximo da nome da 
                  regra vai ser só 40 caracteres mesmo, parece bem pequeno
                  (mas precisaria alterar em sintonia com o BD, se for o caso)!*/
                  style: {
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "0.5rem"
                  }
                }}
              />                      
            </DialogContent>
            <DialogContent>
              <DialogContentText>
                <div style={{margin: '0.75rem 0 1.25rem 0', fontSize: '1rem', fontWeight: '500'}}>
                  Serei notificado:
                </div>
              </DialogContentText>
              <CheckboxesTags 
                  options={tiposDeDocumentoBuscados}
                  optionLabelKey={"nome"}
                  groupByKey={"tema"}
                  placeholder={"Selecionar"}
                  label={"Com estes Relatórios"}
                  limitTags={-1}
                  textFieldVariant="outlined"
                  value={dialogTiposDeDocumento}
                  onChange={
                    handleChangeTiposDeDocumento
                  }
              /> 
            </DialogContent>
            <DialogContent>
              <CheckboxesTags 
                  options={entidadesBuscadas}
                  optionLabelKey={"apelido"}
                  groupByKey={"tipo"}
                  placeholder={"Selecionar"}
                  label={"para estas Entidades"}
                  limitTags={-1}
                  value={dialogEntidades}
                  textFieldVariant="outlined"
                  onChange={handleChangeEntidades}
              />       
            </DialogContent>
            <DialogContent>
              <DialogActions>
                <Button color="primary" onClick={handleCloseDialog}> 
                  Cancelar
                </Button>      
                {dialogIsForEditing ? (
                  <Button color="primary" variant="contained" type="submit" >
                    Salvar Edição
                  </Button>
                ) : (
                  <Button color="primary" variant="contained" type="submit" >
                    Salvar
                  </Button>
                )}          
              </DialogActions>                                        
            </DialogContent>
          </form>
        </Dialog>
        <p className="lunar textoDescritivo">
          Você será notificado via e-mail com novos documentos conforme 
          as regras de notificações abaixo, que você pode editar, 
          criar ou deletar como preferir. 
        </p>      
        <Button 
            variant="contained" 
            color="primary" 
            onClick={handleClickOpenDialog}
            className="botaoCriarNovaRegra"
        >
            <AddCircleOutlineIcon /> &nbsp; 
            Criar Nova Regra
        </Button>
        <h2 className="lunar h2preferencias">Minhas Regras</h2>    
        <Grid container spacing={0}>
          {regras.slice(0).reverse().map((regra) => {
              return (
                <Grid item xs={12} md={12}>
                    {(criacaoIsLoading || remocaoIsLoading) && regra.identity === dialogIdentityDaRegra ? (
                      <Paper className="paper">
                          <Loader mensagem={""}/>
                      </Paper>
                    ) : (
                      <Paper elevation={2} className="paper paperBody">
                          <div className="cardHead">
                            <span className="tituloDaRegra" onClick={
                                ()=>{handleClickOpenDialog(regra, true)}
                            }>
                              {regra.nome}
                            </span>
                            <span className="bodyIconsContainer" >
                                <Tooltip title={"Editar Regra"}>
                                    <IconButton
                                        onClick={
                                          ()=>{handleClickOpenDialog(regra, true)}
                                        }
                                    >
                                    <EditIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Deletar Regra"}>
                                    <IconButton
                                        onClick={
                                          ()=>{handleClickDelete(regra)}
                                        }
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </span>
                          </div>
                          <div className="cardBody">
                              <fieldset className="chipsContainer" onClick={
                                ()=>{handleClickOpenDialog(regra, true)}
                              }>
                                <legend className="subTituloDaRegra">
                                  Serei notificado com estes Relatórios
                                </legend>  
                                {(regra.tiposDeDocumentoSelecionados.length) ? (
                                  regra.tiposDeDocumentoSelecionados.map(
                                    (tipoDeDocumentoSelecionados) => {
                                      return <Chip 
                                        className="chip"
                                        label={tipoDeDocumentoSelecionados.nome} 
                                      />
                                    }
                                )) : (
                                  <Chip 
                                    className="chip chipTodos"
                                    icon={<AllInclusiveIcon fontSize="small"  />}
                                    label={"TODOS OS RELATÓRIOS"} 
                                  />
                                )}
                            </fieldset>                      
                          </div>
                              <fieldset className="chipsContainer" onClick={
                                ()=>{handleClickOpenDialog(regra, true)}
                              }>
                                <legend className="subTituloDaRegra">
                                  para estas Entidades. 
                                </legend>
                                {(regra.entidadesSelecionadas.length) ? (
                                  regra.entidadesSelecionadas.map(
                                    (entidadeSelecionada) => {
                                      return <Chip 
                                        className="chip"
                                        label={entidadeSelecionada.apelido} 
                                      />
                                    }
                                )) : (
                                  <Chip
                                      className="chip chipTodos"
                                      icon={<AllInclusiveIcon fontSize="small" />}
                                      label={"TODAS AS ENTIDADES"}
                                  />
                                )}                        
                              </fieldset>
                      </Paper>
                    )}
                </Grid>
              )
          })}
          {!regras.length && (
            <Grid item xs={12} md={12}>
                <Paper elevation={2} className="paper">
                    <div>
                      <p className="semRegrasTexto">
                        Você não possui Regras de Notificação.
                        Utilize o botão de criar nova regra para criá-las conforme
                        suas Preferências.
                      </p>
                    </div>
                </Paper>
            </Grid>
          )}
        </Grid>
      </div></Layout>
    )
  }</>)
}


const mapStateToProps = (state) => ({
  //regras
  regrasBuscadas: state.regrasDeNotificacao.regrasDeNotificacao,
  regrasBuscadasLoading: state.regrasDeNotificacao.isLoading,
  //entidades
  entidadesBuscadas: state.entidades.entidades,
  entidadesIsLoading: state.entidades.isLoading,
  //tiposDeDocumento
  tiposDeDocumentoBuscados: state.tiposDeDocumento.tiposDeDocumento,
  tiposDeDocumentoIsLoading: state.tiposDeDocumento.isLoading,
  //regraDeNotificacao
  criacaoIsLoading: state.regraDeNotificacao.criacaoIsLoading,
  remocaoIsLoading: state.regraDeNotificacao.remocaoIsLoading
});

export default connect(mapStateToProps, {
  removerRegraDeNotificacao, criarRegraDeNotificacao, editarRegraDeNotificacao,
  buscarRegrasDeNotificacao, 
  buscarTiposDeDocumento, buscarEntidades,
}) (Preferencias);
