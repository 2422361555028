//LOGS
export const BUSCAR_LOGS_DE_ATIVIDADE_LOADING = 'BUSCAR_LOGS_DE_ATIVIDADE_LOADING';
export const BUSCAR_LOGS_DE_ATIVIDADE_SUCCESS = 'BUSCAR_LOGS_DE_ATIVIDADE_SUCCESS';
export const BUSCAR_LOGS_DE_ATIVIDADE_FAIL = 'BUSCAR_LOGS_DE_ATIVIDADE_FAIL';

export const BUSCAR_LOGS_DE_DOWNLOAD_LOADING = 'BUSCAR_LOGS_DE_DOWNLOAD_LOADING';
export const BUSCAR_LOGS_DE_DOWNLOAD_SUCCESS = 'BUSCAR_LOGS_DE_DOWNLOAD_SUCCESS';
export const BUSCAR_LOGS_DE_DOWNLOAD_FAIL = 'BUSCAR_LOGS_DE_DOWNLOAD_FAIL';

//PARAMETROS
export const BUSCAR_PARAMETROS_LOADING = 'BUSCAR_PARAMETROS_LOADING';
export const BUSCAR_PARAMETROS_SUCCESS = 'BUSCAR_PARAMETROS_SUCCESS';
export const BUSCAR_PARAMETROS_FAIL = 'BUSCAR_PARAMETROS_FAIL';

export const CRIAR_PARAMETRO_LOADING = 'CRIAR_PARAMETRO_LOADING';
export const CRIAR_PARAMETRO_SUCCESS = 'CRIAR_PARAMETRO_SUCCESS';
export const CRIAR_PARAMETRO_FAIL = 'CRIAR_PARAMETRO_FAIL';

export const DESATIVAR_PARAMETRO_LOADING = 'DESATIVAR_PARAMETRO_LOADING';
export const DESATIVAR_PARAMETRO_SUCCESS = 'DESATIVAR_PARAMETRO_SUCCESS';
export const DESATIVAR_PARAMETRO_FAIL = 'DESATIVAR_PARAMETRO_FAIL';

export const REATIVAR_PARAMETRO_LOADING = 'REATIVAR_PARAMETRO_LOADING';
export const REATIVAR_PARAMETRO_SUCCESS = 'REATIVAR_PARAMETRO_SUCCESS';
export const REATIVAR_PARAMETRO_FAIL = 'REATIVAR_PARAMETRO_FAIL';

export const REMOVER_PARAMETRO_LOADING = 'REMOVER_PARAMETRO_LOADING';
export const REMOVER_PARAMETRO_SUCCESS = 'REMOVER_PARAMETRO_SUCCESS';
export const REMOVER_PARAMETRO_FAIL = 'REMOVER_PARAMETRO_FAIL';

export const EDITAR_PARAMETRO_LOADING = 'EDITAR_PARAMETRO_LOADING';
export const EDITAR_PARAMETRO_SUCCESS = 'EDITAR_PARAMETRO_SUCCESS';
export const EDITAR_PARAMETRO_FAIL = 'EDITAR_PARAMETRO_FAIL';
export const LIMPAR_ERRO_DE_PARAMETRO = 'LIMPAR_ERRO_DE_PARAMETRO';

//SUB_PARAMETROS
export const BUSCAR_SUB_PARAMETROS_LOADING = 'BUSCAR_SUB_PARAMETROS_LOADING';
export const BUSCAR_SUB_PARAMETROS_SUCCESS = 'BUSCAR_SUB_PARAMETROS_SUCCESS';
export const BUSCAR_SUB_PARAMETROS_FAIL = 'BUSCAR_SUB_PARAMETROS_FAIL';

export const CRIAR_SUB_PARAMETRO_LOADING = 'CRIAR_SUB_PARAMETRO_LOADING';
export const CRIAR_SUB_PARAMETRO_SUCCESS = 'CRIAR_SUB_PARAMETRO_SUCCESS';
export const CRIAR_SUB_PARAMETRO_FAIL = 'CRIAR_SUB_PARAMETRO_FAIL';

export const REMOVER_SUB_PARAMETRO_LOADING = 'REMOVER_SUB_PARAMETRO_LOADING';
export const REMOVER_SUB_PARAMETRO_SUCCESS = 'REMOVER_SUB_PARAMETRO_SUCCESS';
export const REMOVER_SUB_PARAMETRO_FAIL = 'REMOVER_SUB_PARAMETRO_FAIL';

//OPTIONS
export const BUSCAR_OPTIONS_LOADING = 'BUSCAR_OPTIONS_LOADING';
export const BUSCAR_OPTIONS_SUCCESS = 'BUSCAR_OPTIONS_SUCCESS';
export const BUSCAR_OPTIONS_FAIL = 'BUSCAR_OPTIONS_FAIL';

//OUTROS
