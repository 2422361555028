import axios from 'axios';

import {attachTokenToHeaders, handleErrorForAllActions, 
  encontrarMensagemDeErro} from '../actionFunctions';
import {sortArrayOfObjects, sortArrayOfObjectsMultiple, obterTituloDoDocumento} 
  from '../../support/publicFunctions'
import {configuracoes, urls} from '../dataAdmin/parametrosData'
import {
  BUSCAR_PARAMETROS_LOADING, 
  BUSCAR_PARAMETROS_SUCCESS, 
  BUSCAR_PARAMETROS_FAIL,
  CRIAR_PARAMETRO_LOADING, 
  CRIAR_PARAMETRO_SUCCESS, 
  CRIAR_PARAMETRO_FAIL,
  DESATIVAR_PARAMETRO_LOADING, 
  DESATIVAR_PARAMETRO_SUCCESS, 
  DESATIVAR_PARAMETRO_FAIL,
  REATIVAR_PARAMETRO_LOADING, 
  REATIVAR_PARAMETRO_SUCCESS, 
  REATIVAR_PARAMETRO_FAIL,  
  REMOVER_PARAMETRO_LOADING, 
  REMOVER_PARAMETRO_SUCCESS, 
  REMOVER_PARAMETRO_FAIL,
  EDITAR_PARAMETRO_LOADING,
  EDITAR_PARAMETRO_SUCCESS,
  EDITAR_PARAMETRO_FAIL,
} from '../actionTypesAdmin';


const buscarParametros = ({
  tipo, desativados = false, queryStrings={}
}) => async (
  dispatch, getState) => {  
  const {limite, dataInicio, dataFim} = queryStrings;
  dispatch({ 
    type: BUSCAR_PARAMETROS_LOADING,
    payload: { tipo },
  });
  try { 
    //1. Busca parâmetros 
    const url = `${urls[tipo]}?incluirInativos=true&recentes=true` +
      (limite ? `&limite=${limite}` : "") + 
      (dataInicio ? `&dataInicio=${dataInicio}` : "") + 
      (dataFim ? `&dataFim=${dataFim}` : ""); 
    const options = attachTokenToHeaders(getState, 
      {'Accept': 'application/json'});
    const response = await axios.get(url, options); 
    let parametros = (response.data);
    //1b. Casos Particulares
    if(tipo === "SUGESTOES") {parametros = await apoio.SUGESTOES
      .ajustarParametrosBuscados({parametros, getState})};
    //2. Faz configuracoes
    parametros = parametros.sort(sortArrayOfObjects("-id", true));
    parametros = parametros.filter(p => {
      if (desativados) {
        return (p.ativo === 0 || p.ativa ===0)
      } else {
        return (p.ativo !== 0 && p.ativa !==0)
      } 
    });
    //2b. Faz configurações para casos particulares também:
    if(tipo === "SUGESTOES") {parametros = apoio.SUGESTOES
      .ordenarParametrosBuscados({parametros})};      
    if(tipo === "NOTIFICACOES") {parametros = apoio.notificacoes
      .ajustarParametrosBuscados({parametros})};
    if(tipo === "ARQUIVOS") {parametros = apoio.ARQUIVOS
      .ajustarParametrosBuscados({parametros})};     
    if(tipo === "DOCUMENTOS") {parametros = apoio.DOCUMENTOS
        .ajustarParametrosBuscados({parametros})};           
    if(tipo === "GRUPOS_DE_ACESSO"){parametros = await apoio.gruposDeAcesso
      .ajustarParametrosBuscados({parametros, getState})};
    //2c. Obtém as colunas
    const colunas = Object.keys(parametros[0] || {});
    //3. Faz o dispatch
    dispatch({
      type: BUSCAR_PARAMETROS_SUCCESS,
      payload: { parametros, colunas, configuracoes: configuracoes[tipo] },
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    const error = {
      title:  `Os Parâmetros não foram buscados porque ocorreu um Erro`,
      message: encontrarMensagemDeErro(err)     
    }    
    alert(`${error?.title} \n\n${error?.message}`);
    dispatch({
      type: BUSCAR_PARAMETROS_FAIL,
      payload: {error},
    });
  }
}


const criarParametro = ({tipo, dados}) => async (dispatch, getState) => {
  dispatch({ 
    type: CRIAR_PARAMETRO_LOADING
  });
  try {
    //1. Criar parâmetro
    const url = urls[tipo];
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(url, dados, options); 
    const id = response?.data?.insertId || response?.data[0]?.insertId;
    //1b. Casos particulares (parte 1)
    if(tipo === "SEGMENTOS"){await apoio.adicionarEntidadesNoSegmento({getState, 
      tipo, id, entidadesId: dados.entidadeId})}
    //2. Busca e verifica se o parâmetro foi realmente criado
    let parametro = await apoio.buscarParametro({getState, tipo, id})
    if(!parametro?.id){
      throw new Error("ERRO: Parâmetro não criado. Erro não identificado.")};
    //2b. Casos particulares (parte 2)
    if(tipo === "GRUPOS_DE_ACESSO"){
      const segmentosNomes = await apoio.segmentos.buscarNomes({getState});
      parametro = apoio.gruposDeAcesso.ajustarParametroBuscado({parametro, 
        segmentosNomes})
    }
    //3. Se sim, faz o dispatch
    dispatch({
      type: CRIAR_PARAMETRO_SUCCESS,
      payload: {parametro},
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    const error = {
      title:  `O Parâmetro não foi criado porque ocorreu um Erro`,
      message: encontrarMensagemDeErro(err)     
    }
    alert(`${error?.title} \n\nNenhuma modificação foi feita.`);
    dispatch({
      type: CRIAR_PARAMETRO_FAIL,
      payload: {error},
    });
  }
}


const removerParametro = ({tipo, id}) => async (dispatch, getState) => {  
  dispatch({ 
    type: REMOVER_PARAMETRO_LOADING,
    payload: { id },
  });
  try {
    //1. Remove o parâmetro
    const url = `${ urls[tipo] }/${ id }`;
    const options = attachTokenToHeaders(getState);
    await axios.delete(url, options); 
    //2. Verifica se o parâmetro foi realmente removido
    const parametroRemovido = await apoio.buscarParametro({getState, tipo, id})
    if(parametroRemovido?.id === id){
      throw new Error("ERRO: Parâmetro não removido. Erro não identificado.")};  
    //3. Se sim, faz o dispatch
    dispatch({
      type: REMOVER_PARAMETRO_SUCCESS,
      payload: { id },
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    const error = {
      title:  `O Parâmetro não foi removido porque ocorreu um Erro ` + 
      `(ID do Parâmetro: ${id})`,
      message: encontrarMensagemDeErro(err)     
    }  
    alert(`${error?.title} \n\nNenhuma modificação foi feita.`);
    dispatch({
      type: REMOVER_PARAMETRO_FAIL,
      payload: { error, id },
    });
  }
}


const editarParametro = ({tipo, id, dados}) => async (dispatch, getState) => {
  dispatch({ 
    type: EDITAR_PARAMETRO_LOADING,
    payload: {id}
  });
  try {
    //0. Verifica parametro antes da edição
    let parametroInicial = await apoio.buscarParametro({getState, tipo, id})
    //1. Edita parâmetro
    const url = `${ urls[tipo] }/${ id }`;
    const options = attachTokenToHeaders(getState);
    await axios.patch(url, dados, options);
    //1b. Casos particulares
      //TODO: refatorar abaixo para switch case
    let segmentosNomes = []; //TODO: refatorar esta linha também 
    if(tipo === "ENTIDADES") {await apoio.atualizarSegmentosNaEntidade({
      getState, tipo, id, dados})}
    if(tipo === "SEGMENTOS") {await apoio.atualizarEntidadesNoSegmento({
      getState, tipo, id, dados, parametroInicial})}      
    if(tipo === "GRUPOS_DE_ACESSO") {
      segmentosNomes = await apoio.segmentos.buscarNomes({getState});
      parametroInicial = apoio.gruposDeAcesso.ajustarParametroBuscado({
        parametro: parametroInicial, segmentosNomes})
      /*TODO: analisar refatoração necessárias par alinhas abaixo e acima 
        neste função*/
      const dadosGrupo = {getState, tipo, id, dados, parametroInicial};
      await apoio.gruposDeAcesso.atualizarTemasNoGrupoDeAcesso(dadosGrupo);
      await apoio.gruposDeAcesso.atualizarRestricoesNoGrupoDeAcesso(dadosGrupo);
    }            
    //2. Verifica se o parâmetro foi realmente editado 
    let novoParametro = await apoio.buscarParametro({getState, tipo, id});
    if(JSON.stringify(novoParametro) === JSON.stringify(parametroInicial)){
      throw new Error(
        "ERRO: Parâmetro não foi editado. Erro não identificado.")};
    //2b. Casos particulares (parte 2)
    if(tipo === "GRUPOS_DE_ACESSO"){
      novoParametro = apoio.gruposDeAcesso.ajustarParametroBuscado({
        parametro: novoParametro, segmentosNomes})
    }        
    //3. Se sim, faz o dispatch
    dispatch({
      type: EDITAR_PARAMETRO_SUCCESS,
      payload: {parametro: novoParametro}
    })
  } catch (err) {
    handleErrorForAllActions(err, getState);
    const error = {
      title: `O Parâmetro não foi editado porque ocorreu um Erro`,
      message: encontrarMensagemDeErro(err),
    }
    alert(`${error?.title} \n\nNenhuma modificação foi feita.`);
    dispatch({
      type: EDITAR_PARAMETRO_FAIL,
      payload: {error, id}
    })
  }
}


const desativarParametro = ({tipo, id}) => async (dispatch, getState) => {  
  dispatch({ 
    type: DESATIVAR_PARAMETRO_LOADING,
    payload: { id },
  });
  try {
    //1. Desativa o parâmetro
    const url = `${ urls[tipo] }/${ id }`;
    const options = attachTokenToHeaders(getState);
    await axios.delete(url, options); 
    //2. Verifica se o parâmetro foi realmente desativado
    const parametroDesativado = await apoio.buscarParametro({
      getState, tipo, id});
    if(parametroDesativado?.ativo === 1 || parametroDesativado?.ativa === 1){
      throw new Error("ERRO: Parâmetro não desativado. Erro não identificado.")
    };  
    //3. Se sim, faz o dispatch
    dispatch({
      type: DESATIVAR_PARAMETRO_SUCCESS,
      payload: { id },
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    const error = {
      title:  `O Parâmetro não foi desativado porque ocorreu um Erro ` + 
      `(ID do Parâmetro: ${id})`,
      message: encontrarMensagemDeErro(err)     
    }  
    alert(`${error?.title} \n\nNenhuma modificação foi feita.`);
    dispatch({
      type: DESATIVAR_PARAMETRO_FAIL,
      payload: { error, id },
    });
  }
}


const reativarParametro = ({tipo, id}) => async (dispatch, getState) => {  
  dispatch({ 
    type: REATIVAR_PARAMETRO_LOADING,
    payload: { id },
  });
  try {
    //1. Reativa o parâmetro
    const url = `${ urls[tipo] }/${ id }`;
    const options = attachTokenToHeaders(getState);
    await axios.put(url, null, options); 
    //2. Verifica se o parâmetro foi realmente reativado
    const parametroReativado = await apoio.buscarParametro({
      getState, tipo, id});
    if(parametroReativado?.ativo === 0 || parametroReativado?.ativa === 0){
      throw new Error("ERRO: Parâmetro não reativado. Erro não identificado.")};  
    //3. Se sim, faz o dispatch
    dispatch({
      type: REATIVAR_PARAMETRO_SUCCESS,
      payload: { id },
    });
  } catch (err) {
    handleErrorForAllActions(err, getState); 
    const error = {
      title:  `O Parâmetro não foi reativado porque ocorreu um Erro ` + 
      `(ID do Parâmetro: ${id})`,
      message: encontrarMensagemDeErro(err)     
    }  
    alert(`${error?.title} \n\nNenhuma modificação foi feita.`);
    dispatch({
      type: REATIVAR_PARAMETRO_FAIL,
      payload: { error, id },
    });
  }
}


const apoio = {
  buscarParametro: async function({getState, tipo, id}){
      const options = attachTokenToHeaders(getState, {
        'Accept': 'application/json'}); 
      const url = `${urls[tipo]}/${id}`;
      const response = await axios.get(url, options);
      let parametro = response.data[0];
      //Caso particulares
      if(tipo === "DOCUMENTOS") {parametro = apoio.DOCUMENTOS
        .ajustarParametroBuscado({parametro})};     
      //Retorno
      return parametro   
  },
  adicionarEntidadesNoSegmento: async function({getState, tipo, id, 
    entidadesId
  }){ 
    const url = `${ urls[tipo] }/${ id }/entidades`;
    const options = attachTokenToHeaders(getState);
    const promises = entidadesId?.map(e => axios.post(url, {entidadeId: e}, 
      options)) || [];
    await Promise.all(promises);
  },
  removerEntidadesNoSegmento: async function({getState, tipo, id, entidadesId}){
    const options = attachTokenToHeaders(getState);
    const myUrls = entidadesId.map(e => `${urls[tipo]}/${id}/entidades/${e}`);
    const promises = myUrls.map(u => axios.delete(u, options));
    await Promise.all(promises);
  },
  atualizarEntidadesNoSegmento: async function({getState, tipo, id, dados, 
    parametroInicial}){
    //Definição das listas
    const listaInicialDeId = parametroInicial.entidades.map(e => e.entidade_id);
    const listaFinalDeId = dados.entidadeId;
    //Inserção de Ids (da lista final que não estão na inicial)
    const idsParaInserir = [];
    listaFinalDeId.map (f => {
      if (!listaInicialDeId.find(i => i === f)){
        idsParaInserir.push(f);
      }
      return false;
    })
    const promise1 = apoio.adicionarEntidadesNoSegmento({getState, tipo, id, 
      entidadesId: idsParaInserir});
    //Remoção de Ids (da lista inicial que não estão na final)
    const idsParaRemover = [];
    listaInicialDeId.map(i => {
      if (!listaFinalDeId.find(f => f === i)){
        idsParaRemover.push(i);
      }
      return false;
    })
    const promise2 = apoio.removerEntidadesNoSegmento({getState, tipo, id, 
      entidadesId: idsParaRemover});
    //Promise.all
    await Promise.all([promise1, promise2]);
    return false;
  },
  atualizarSegmentosNaEntidade: async function({getState, tipo, id, dados}){
    const url = `${ urls[tipo] }/${ id }/segmentos`; 
    const options = attachTokenToHeaders(getState);
    const dadosSegmentos = {"segmentos": dados.segmentos}
    await axios.patch(url, dadosSegmentos, options);
  },

  notificacoes: {
    ajustarParametrosBuscados: function({parametros}){
      //Ordena primeiro por usuário e depois por nome
      parametros = parametros.sort(sortArrayOfObjectsMultiple("usuarioNome", 
        "nome"))
      //Cria um "fake id"
      parametros = parametros.map(p => { 
        const fakeId = ([p.usuarioId, p.nome]).join(); 
        return {id: fakeId, ...p} 
      }) 
      //return
      return parametros;
    },
  },


  ARQUIVOS: {
    ajustarParametrosBuscados: function({parametros}){
      //Cria um "custom id"
      parametros = parametros.map(p => { 
        const customId = p.representacao_id;
        const nome_do_arquivo = p.nome_do_arquivo;
        return {nome_do_arquivo, id: customId, ...p} 
      }) 
      //Ordena de forma decrescente de acordo com o id
        //TODO: analisar esta linha abaixo de refatoração e refatorar 
          //(obs: porque ela está repetida com outra parte do código)
      parametros = parametros.sort(sortArrayOfObjects("-id", true));
      //return
      return parametros;
    },
  },


  DOCUMENTOS: {
    ajustarParametrosBuscados: function({parametros}){
      parametros = parametros.map(parametro => apoio.DOCUMENTOS
        .ajustarParametroBuscado({parametro})) 
      return parametros;
    },
    ajustarParametroBuscado: function({parametro}){
      const tipoDeDocumento = parametro.tipo_de_documento_que_tipifica_nome;
      const descritivo = parametro.descritivo_que_descreve;
      const entidade = parametro.entidade_referida_apelido;
      const ano = parametro.data_referencia_ano;
      const mes = parametro.data_referencia_mes;
      const dia = parametro.data_referencia_dia;
      const versao = parametro.numero_versao_mais_recente;
      const titulo_versao_mais_recente = obterTituloDoDocumento(tipoDeDocumento, 
        descritivo, entidade, ano, mes, dia, versao)
      const parametroAjustado = {titulo_versao_mais_recente, ...parametro};
      return parametroAjustado;
    },
  },



  gruposDeAcesso: {
    ajustarParametrosBuscados: async function({parametros, getState}){
      const segmentosNomes = await apoio.segmentos.buscarNomes({getState});
      const novosParametros = parametros.map(p => apoio.gruposDeAcesso
        .ajustarParametroBuscado({parametro: p, segmentosNomes})) 
      return novosParametros;
    },
    ajustarParametroBuscado: function({parametro, segmentosNomes}){
        const criterios = parametro?.criterios;
        const criteriosCustomizados = apoio.gruposDeAcesso
          .incluirCamposCustomizadosEmCriterios({criterios, segmentosNomes})
        const novoParametro = {...parametro, criterios: criteriosCustomizados};
        return novoParametro;
    },    
    incluirCamposCustomizadosEmCriterios: function({criterios, segmentosNomes}){   
      /*TODO: a forma como é definido customId e customView nesta função é 
        similar a forma como é definido o value/label na função 
        ajustarOptionParaRestricoes (em optionActions), ou seja, dois processos
        repetidos. Seria interessante refatorar ambos para ser um processo
        só */
      const criteriosOrdenados = criterios.sort(sortArrayOfObjects(
        "operador_logico")); //Garantir exibição do operador "E" antes do "OU"
      const criteriosCustomizados = criteriosOrdenados.map(c => {
        //define customId
        const criterio = c['criterio'];
        let operadorLogico = c['operador_logico'];
        let restricao = c['restricao'];
        const customId = `${criterio}:${restricao}:${operadorLogico}`
        //altera operadorLogico para view
        operadorLogico = operadorLogico.toLowerCase();
        //altera  para view
        switch (criterio) {
          case "Modelado":
            const modeladolabels = {"1": "Sim", "0": "Não", "%": "Todos"}
            const label = modeladolabels[restricao]; 
            restricao = label || restricao;
            break;
          case "Segmento":
            const segmentoId = restricao;
            const segmentoNome = segmentosNomes?.[segmentoId];
            restricao = segmentoNome;
            break;            
          default:
            break;
        }
        //define customView
        const customView = (`${operadorLogico}  ${criterio}: "${restricao}"` );
        //retorno
        return {...c, customView, customId}
      });
      return criteriosCustomizados;
    },


    atualizarTemasNoGrupoDeAcesso: async function({getState, tipo, id, dados, 
      parametroInicial}){
      /*TODO: obs: o processo que ocorre nesta função e muito semelhante ao 
        que ocorre na função "atualizarEntidadesNoSegmento", esta duplicação
        de código não parece uma boa prática. Refatorar ambas as funções para
        evitar esta duplicação indesejada*/

      //Definição das listas
      const listaInicialDeId = parametroInicial.temas.map(t => t.id_tema_acessado);
      const listaFinalDeId = dados.temas;
      //Inserção de Ids (da lista final que não estão na inicial)
      const idsParaInserir = [];
      listaFinalDeId.map (f => {
        if (!listaInicialDeId.find(i => i === f)){
          idsParaInserir.push(f);
        }
        return false;
      })
      const promise1 = apoio.gruposDeAcesso.adicionarTemasNoGrupoDeAcesso({getState, tipo, id, 
        temas: idsParaInserir});
      //Remoção de Ids (da lista inicial que não estão na final)
      const idsParaRemover = [];
      listaInicialDeId.map(i => {
        if (!listaFinalDeId.find(f => f === i)){
          idsParaRemover.push(i);
        }
        return false;
      })
      const promise2 = apoio.gruposDeAcesso.removerTemasNoGrupoDeAcesso({getState, tipo, id, 
        temas: idsParaRemover});
      //Promise.all
      await Promise.all([promise1, promise2]);
      return false;
    },    
    adicionarTemasNoGrupoDeAcesso: async function({
      getState, tipo, id, temas
    }){ 
      /*TODO: analisar se funções como esta requer fatoração, porque estão
        se repetindo muito*/
      const url = `${ urls[tipo] }/${ id }/temas`;
      const options = attachTokenToHeaders(getState);
      await axios.put(url, {temas}, options);
    },
    removerTemasNoGrupoDeAcesso: async function({
      getState, tipo, id, temas
    }){ 
      /*TODO: analisar se funções como esta requer fatoração, porque estão
        se repetindo muito*/
      const url = `${ urls[tipo] }/${ id }/temas`;
      const options = attachTokenToHeaders(getState);
      await axios.delete(url, {...options, data: {temas}});
    },    


    atualizarRestricoesNoGrupoDeAcesso: async function({getState, tipo, id, dados, 
      parametroInicial}){
      /*TODO: obs: o processo que ocorre nesta função e muito semelhante ao 
        que ocorre na função "atualizarEntidadesNoSegmento", esta duplicação
        de código não parece uma boa prática. Refatorar ambas as funções para
        evitar esta duplicação indesejada*/

      //Definição das listas
      console.log("parametroInicial", parametroInicial);
      const listaInicialDeId = parametroInicial.criterios.map(c => c.customId);
      const listaFinalDeId = dados.restricoes;
      console.log("listaInicialDeId", listaInicialDeId);
      console.log("listaFinalDeId", listaFinalDeId);
      //Inserção de Ids (da lista final que não estão na inicial)
      const idsParaInserir = [];
      listaFinalDeId.map (f => {
        if (!listaInicialDeId.find(i => i === f)){
          idsParaInserir.push(f);
        }
        return false;
      })
      const promise1 = apoio.gruposDeAcesso.adicionarRestricoesNoGrupoDeAcesso({getState, tipo, id, 
        restricoes: idsParaInserir});
      //Remoção de Ids (da lista inicial que não estão na final)
      const idsParaRemover = [];
      listaInicialDeId.map(i => {
        if (!listaFinalDeId.find(f => f === i)){
          idsParaRemover.push(i);
        }
        return false;
      })
      const promise2 = apoio.gruposDeAcesso.removerRestricoesNoGrupoDeAcesso({getState, tipo, id, 
        restricoes: idsParaRemover});
      //Promise.all
      await Promise.all([promise1, promise2]);
      return false;
    },    
    adicionarRestricoesNoGrupoDeAcesso: async function({
      getState, tipo, id, restricoes
    }){ 
      /*TODO: analisar se funções como esta requer fatoração, porque estão
        se repetindo muito*/
      const url = `${ urls[tipo] }/${ id }/restricoes`;
      const options = attachTokenToHeaders(getState);
      await axios.put(url, {restricoes}, options);
    },
    removerRestricoesNoGrupoDeAcesso: async function({
      getState, tipo, id, restricoes
    }){ 
      /*TODO: analisar se funções como esta requer fatoração, porque estão
        se repetindo muito*/
      const url = `${ urls[tipo] }/${ id }/restricoes`;
      const options = attachTokenToHeaders(getState);
      await axios.delete(url, {...options, data: {restricoes}});
    },    
    

  },
  
  
  segmentos: {
    buscarNomes: async function({getState}){
      const options = attachTokenToHeaders(getState, {'Accept': 
        'application/json'});
      const url = `/api/administradores/segmentos/`
      const response = await axios.get(url, options);
      const segmentos = response.data;
      let segmentosNomes = {};
      segmentos.map(segmento => {
        segmentosNomes = {...segmentosNomes, [segmento.id]: segmento.nome}
        return false;
      })
      return segmentosNomes
    },
  },


  ENTIDADES: {
    buscarNomes: async function({getState}){
      const options = attachTokenToHeaders(getState, {'Accept': 
        'application/json'});
      const url = '/api/administradores/entidades';
      const response = await axios.get(url, options);
      const parametros = response.data;
      let parametroNomes = {};
      parametros.map(parametro => {
        parametroNomes = {...parametroNomes, [parametro.id]: parametro.apelido}
        return false;
      })
      return parametroNomes
    },
  },

  DESCRITIVOS: {
    buscarNomes: async function({getState}){
      const options = attachTokenToHeaders(getState, {'Accept': 
        'application/json'});
      const url = '/api/administradores/descritivos';
      const response = await axios.get(url, options);
      const parametros = response.data;
      let parametroNomes = {};
      parametros.map(parametro => {
        const nomeCompleto = [parametro.tipo_de_documento_nome, 
          parametro.nome].filter(Boolean).join(" - ");
        parametroNomes = {...parametroNomes, [parametro.id]: nomeCompleto}
        return false;
      })
      return parametroNomes
    },
  },  


  SUGESTOES: {
    ajustarParametrosBuscados: async function({parametros, getState}){
      const promises = [
          apoio.ENTIDADES.buscarNomes({getState}),
          apoio.DESCRITIVOS.buscarNomes({getState}),
      ]
      const [entidadeNomes, descritoNomes] = await Promise.all(promises);
      //obterSugestoes()
      function obterSugestoes({dicionario, parametroTipo, sugestaoTipo, parametroNomes}){
        const dicionarioDesteParametro = dicionario[parametroTipo];
        const sugestoes = Object.keys(parametroNomes).map(parametroId => {
          const id = [parametroTipo, parametroId].join();
          const parametroNome = parametroNomes[parametroId];
          const chavesUnicas = dicionarioDesteParametro?.[parametroId] ?
            dicionarioDesteParametro[parametroId].map(chaveUnica => ({valor: chaveUnica})) :
            [];
          return { id, sugestaoTipo, parametroTipo, parametroId, parametroNome, chavesUnicas}
        })
        return sugestoes
      }
      //Ajustar Parâmetros
      parametros = [
        ...obterSugestoes({dicionario: parametros, sugestaoTipo: "Relatório", parametroTipo: "descritivo", 
          parametroNomes: descritoNomes}),
        ...obterSugestoes({dicionario: parametros, sugestaoTipo: "Entidade", parametroTipo: "entidade", 
          parametroNomes: entidadeNomes}),
      ];
      //return
      return parametros;
    },
    ordenarParametrosBuscados: function({parametros}){
      parametros = parametros.sort(sortArrayOfObjectsMultiple("sugestaoTipo", 
        "parametroNome"))
      return parametros;
    },    
  },


}

 
const apoioBuscarParametro = apoio.buscarParametro;

export {  
  buscarParametros,
  criarParametro,
  desativarParametro,
  editarParametro,
  reativarParametro,
  removerParametro,
  //apoio
  apoioBuscarParametro
}