//TODO: adicionar aqui um comentário explicando melhor o porquê da razão de existir deste reducer

import {
    BUSCAR_ARQUIVOS_E_CATEGORIZAR_LOADING, BUSCAR_ARQUIVOS_E_CATEGORIZAR_SUCCESS, BUSCAR_ARQUIVOS_E_CATEGORIZAR_FAIL
} from '../actionTypes';


const initialState = {};


const arquivosCategorizados = (state = initialState, { type, payload = {} }) => {
    switch (type) {
        case BUSCAR_ARQUIVOS_E_CATEGORIZAR_LOADING:
            return {
                ...state,
                [payload.key]: {
                    ...state[payload.key],
                    arquivos: [],
                    isLoading: true,
                    wasLoaded: false,
                    error: null
                }
            }                       
        case BUSCAR_ARQUIVOS_E_CATEGORIZAR_SUCCESS:
            return {
                ...state,
                [payload.key]: {
                    ...state[payload.key],
                    isLoading: false,
                    wasLoaded: true,
                    arquivos: payload.arquivos 
                }
            }   
        case BUSCAR_ARQUIVOS_E_CATEGORIZAR_FAIL:         
            return {
                ...state,
                [payload.key]: {
                    ...state[payload.key],
                    isLoading: false,
                    wasLoaded: false,
                    arquivos: [], 
                    error: payload.error  
                }
            }   
        default:
            return state;
    }
}

export default arquivosCategorizados