import { createStore, applyMiddleware, compose } from 'redux';
import reducersCombination from "./reducersCombination"
import thunk from 'redux-thunk';

const initialState = {};

const store = createStore(
    reducersCombination,
    initialState,
    compose(
        applyMiddleware(thunk),
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
          compose,
      ), 
);

export default store