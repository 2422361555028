import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

//List Items
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

//Icons
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EventNoteIcon from '@material-ui/icons/EventNote';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TodayIcon from '@material-ui/icons/Today';
import PageviewIcon from '@material-ui/icons/Pageview';
import SearchIcon from '@material-ui/icons/Search';

//...
import { Link } from 'react-router-dom';

const StyledMenuItem = withStyles(
  (theme) => {
    return {
      root: {      
        '&:hover': {
          '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
              color: theme.palette.primary.main,
          },
        },
        '& .MuiListItemText-primary': {
          fontWeight: 500
        },     
        color: 'dimgray'     
      },
    }
  }
)(MenuItem)


const MenuDeConsultarHistorico = ({
  documento, tituloDoDocumento, identificacaoDoLote, tooltipTitle, children
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
 
  return (
    <>
      <Tooltip title={tooltipTitle}>
        <IconButton 
          onClick={handleClick}   
          aria-controls="acessar-outras-versoes-menu" //abre submenu c/ esta propriedade
          aria-haspopup="true" //indica que este elemento controle o submenu 
        >
          {children}
        </IconButton>
      </Tooltip>    
      <Menu
        id="acessar-outras-versoes-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        //...
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem>
          <span style={{color: "dimgray"}}>Consultar:</span>
        </MenuItem>
        <>
          <Divider light />
          <StyledMenuItem
              component = 'Link'
              component={Link} 
              to={`/consulta/documentos?` + 
                  `entidade=${documento.entidade}&` + 
                  `tema=${documento.tema}&` + 
                  `relatorio=${documento.tipoDeDocumento}`
              }
            >
                <ListItemIcon>
                    <SearchIcon />
                </ListItemIcon>
                <ListItemText primary={"Histórico de '" + tituloDoDocumento + "'"}/>          
          </StyledMenuItem>
          <Divider light />
          <StyledMenuItem
            component = 'Link'
            component={Link} 
            to={`/consulta/documentos?` + 
                `tema=${documento.tema}&` + 
                `relatorio=${documento.tipoDeDocumento}`
            }
          >
              <ListItemIcon>
                  <SearchIcon />
              </ListItemIcon>
              <ListItemText primary={"Histórico de '" + documento.tipoDeDocumento + "'"}/>          
          </StyledMenuItem>   
          {identificacaoDoLote &&
            <>
              <Divider light />
              <StyledMenuItem
                  component = 'Link'
                  component={Link} 
                  to={`/lotes/${identificacaoDoLote}`}
                >
                    <ListItemIcon>
                        <SearchIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Documentos deste Lote"}/>          
              </StyledMenuItem>
            </>
          }
        </>
      </Menu>
    </>
  );
}

export default MenuDeConsultarHistorico;